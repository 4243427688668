import React, { Component } from 'react';
import '../css/newmetwo.css';
import '../css/newmeone.css';
import {Link} from "react-router-dom";
import fourone from '../photo/newfourone.png';
import fourtwo from '../photo/newfourtwo.png';
class Newmefour extends Component {

    componentDidMount(){

    }

    render(){
        return(
            <div className='bactwo'>
                <div className='newmebanner'>
                    <Link to='/newmeone'><div className="four_one" style={{position:'absolute',top:'0px',left:'0px',width:'30%',height:'100%'}}></div></Link>
                    <Link to='/newmetwo'><div className="four_two" style={{position:'absolute',top:'0px',left:'35%',width:'30%',height:'100%'}}></div></Link>
                    <Link to='/newmethree'><div className="four_three" style={{position:'absolute',top:'0px',left:'72%',width:'30%',height:'100%'}}></div></Link>
                </div>
                <div className='newmefourpone'></div>
                {/*
                <a href="http://m.wx798.com/H5/demo1s/index.html">
                <div className='newmefourone'>
                    <img className='newmefouronepic' src={fourone}/>
                    <div className='newmefouronep1'>熟悉公司的工作环境</div>
                    <div className='newmefouronep2'>请到人力资源和餐厅打卡！</div>
                </div>
                </a>
                */}
                <Link to='Anim'>
                <div className='newmefourtwo'>
                    <img className='newmefourtwopic' src={fourtwo}/>
                    <div className='newmefourtwop1'>根据公司企业文化的要求，</div>
                    <div className='newmefourtwop2'>在游戏场景做出选择！</div>
                </div>
                </Link>
                <Link to='newme'><div className='newmefourbactui'></div></Link>
            </div>

        )
    }
}
export default Newmefour
