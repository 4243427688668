import React, { Component } from "react";
import { connect } from "react-redux";
import { List, Avatar, Button, Dialog } from "antd-mobile";
import * as CourseAPI from "../API/CoursesAPI";
import {DollarCircleOutlined } from '@ant-design/icons'
import { ContentOutline, ClockCircleOutline, PayCircleOutline,SendOutline ,UserOutline} from "antd-mobile-icons";
import { withRouter } from "react-router-dom";
import "../css/personal.scss";
import * as user from '../API/user'
import {WhiteSpace} from 'antd-mobile-v2'

class Personal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            courses:[],
            page:1,
            loading:false,
            pageSize:5,
            hasMore:false
        }
    }

    componentDidMount() {
        document.title = "用户中心"
        this.getList()
        console.log(this.state.courses)
        console.log(this.props.login)
        console.log(this.props.userType)
    }

     getList = () => {
        this.setState({
            page: 1,
            loading: true
        }, () => {
            CourseAPI.getMyBusinessCourses(1, 7, 1)
                .then(data => {
                    if (data.status===0){
                             this.setState({
                            courses: data.data.list,
                            hasMore: data.data.list.length === 7
                        });
                        }
                       
                    console.log(data)
                }).finally(() => this.setState({loading: false}))
        });
    }

    // TODO 获取更多
    appendList = () => {
        const { page, pageSize, courses } = this.state;
        this.setState({
            page: page + 1,
            loading: true
        }, () => {
            CourseAPI.getMyBusinessCourses(page + 1, 7, 1)
                .then(data => {
                    if (data.status === 0) {
                        const temp = [...courses];
                        temp.push(data.data.list);
                        if(data.list.length!==0){
                         this.setState({
                            courses: temp,
                            hasMore: data.data.list.length === 7
                        });
                            
                            
                        }
                       
                    }
                }).finally(() => this.setState({loading: false}))
        })
    }


    checkUserType = type => {
        if (type === 0)
            this.props.selectPerson(() => {
                window.location.href = `https://${window.location.hostname}/personal`;
            })
         else
            this.props.selectBusiness(() => {
                window.location.href = `https://${window.location.hostname}/personal`;
            })
    };

    logout = () => {
        Dialog.confirm({
            title: "退出登录",
            content: '你确定要退出登录吗？',
            confirmText: '退出',
            onConfirm: () => {
                user.logout()
                    .finally(() => {
                        delete localStorage.token;
                        delete localStorage.noFirst;
                        window.location.href = `https://${window.location.hostname}/personal`;
                    })
            },
        })
    };

    render() {
        const { isLogin, userInfo, userType, company } = this.props;
        return (
            <div className="personal">
                 <div className="header-name">
                        <div onClick={ () => this.props.history.push("/") } className="header-link">首页</div>&nbsp;>&nbsp;<div onClick={ () => this.props.history.push("/courseList") } className="header-link">用户中心</div></div>
                <List>
                    {
                        isLogin ? (
                            <List.Item prefix={<Avatar src={userInfo.headUrl} />}>
                                <span style={{color: "#1890ff"}}>{userInfo.realname || userInfo.wechatnickname || userInfo.username}{ userType !== 0 && ( <span style={{color: "#AAAAAA"}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{company.companyName}</span>)}</span>
                            </List.Item>
                        ): (
                            <List.Item
                                prefix={<Avatar src="" />}
                                description='请登录后学习'
                                onClick={() => this.props.history.push('/login?from=' + window.location.href)}>
                                <span style={{color: "#1890ff"}}>登录</span>
                            </List.Item>
                        )
                    }
                </List>
                <WhiteSpace />
                <WhiteSpace />
                <List>
                    <List.Item prefix={<ContentOutline />} disabled={!isLogin} onClick={ () => this.props.history.push('/mycourses') }>
                        <div style={{width:"100%"}}><span style={{float:"left"}}>我的任务</span>{this.state.courses&&<span style={{float:"right",marginRight:"10px",color:"#1B7AF7"}}>{this.state.courses.length}</span>}</div>
                    </List.Item>
                    <List.Item prefix={<ClockCircleOutline />} disabled={!isLogin} onClick={ () => this.props.history.push('/history') }>
                        我的记录
                    </List.Item>
                    <List.Item prefix={<PayCircleOutline />} disabled={!isLogin} onClick={ () => this.props.history.push('/money') }>
                       <div style={{width:"100%"}}><span style={{float:"left"}}>我的资金</span><span style={{float:"right",marginRight:"10px",color:"#faad14"}}>￥{this.props.userType===1?this.props.company.restBalance:this.props.userInfo.priv}</span></div>
                    </List.Item>
                   <List.Item prefix={<DollarCircleOutlined />} disabled={!isLogin} onClick={ () => this.props.history.push('/integral') }>
                        <div style={{width:"100%"}}><span style={{float:"left"}}>我的积分</span><span style={{float:"right",marginRight:"10px",color:"#1B7AF7"}}>2000微豆</span></div>
                    </List.Item>
                    <List.Item prefix={<SendOutline />} disabled={!isLogin} onClick={ () => this.props.history.push('/myshare') }>
                        <div style={{width:"100%"}}><span style={{float:"left"}}>我的分享</span></div>
                    </List.Item>
                    <List.Item prefix={<UserOutline />} disabled={!isLogin} onClick={ () => this.props.history.push('/information') }>
                        <div style={{width:"100%"}}><span style={{float:"left"}}>个人信息</span></div>
                    </List.Item>
                </List>
                <br/>
                <br/>
                <br/>
                {
                    isLogin && <div className="logout">
                        {
                            userInfo.fromCompany !== -1 && (
                                userType === 0 ? (
                                    <Button size="large" fill="outline" color="primary" onClick={ () => this.checkUserType(1) }>切换为企业用户</Button>
                                ): (
                                    <Button size="large" fill="outline" color="primary" onClick={ () => this.checkUserType(0) }>切换为个人用户</Button>
                                )
                            )
                        }
                        <WhiteSpace />
                        <WhiteSpace />
                        <Button size="large" color="danger" onClick={this.logout}>退出登录</Button>
                    </div>
                }
            </div>
        )
    }
}

export default connect(
    ({ login}) => ({
        isLogin: login.isLogin,
        userInfo: login.userInfo,
        userType: login.type,
        company: login.company,
    })
)(withRouter(Personal));