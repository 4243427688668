import React, {Component} from 'react';
import {Input, Button, List, Toast, NoticeBar, Avatar, Checkbox, Result} from "antd-mobile";
import { withRouter } from 'react-router-dom';
import { toRegister } from "../../API/user";
import { connect } from 'react-redux';
import * as LoginAPI from "../../API/user";
import Loading from "../../component/Loading/Loading";
import { setAddPhoneForm, setRegisterData } from '../../action';
import * as Utils from '../../util/Utils';
import * as Base64 from "js-base64";
import uuid from "uuid";
import './addPhone.scss';

class AddPhone extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isSendByLogin: true,
            loading: false,
            sendTimeByLogin: 0,
            agree: false,
            isError: false
        };
        this.next = null;
    }

    breakHome = () => {
        window.location.href = this.next ? this.next : "";
    };

    componentDidMount() {
        let url = window.location.href;
        try {
            let params = Utils.parseQueryString(url);
            if (params['uu']) {
                let res = JSON.parse(Base64.decode(params['uu']))
                console.log(res);
                if (res.msg === "未注册" || res.msg === "请修改个人信息") {
                    this.props.setRegister(res.data);
                } else  {
                    let parStr = url.substring(url.indexOf("?") + 1).split("&")[1];
                    let next = parStr.substring(parStr.indexOf("=") + 1);
                    localStorage.token = res.data.unionid;
                    window.location.href = next;
                }
            } else
                this.setState({ isError: true });
            this.next = params.next;
        } catch (e) {
            console.log(e);
            this.setState({ isError: true });
        }
    }

    // TODO 验证手机号
    isPhone = number => {
        let phoneReg = /^[1][3-9][0-9]{9}$/img;
        return phoneReg.test(number);
    };

    // TODO 发送验证码
    sendChapter = () => {
        const { isSendByLogin } = this.state;
        const { phone } = this.props.addPhone;
        if (isSendByLogin) {
            if (this.isPhone(phone)) {
                this.setState({
                    loading: true
                }, () => {
                    LoginAPI.sendCaptcha(phone)
                        .then(res => {
                            if (res) {
                                this.setState({
                                    isSendByLogin: false,
                                    sendTimeByLogin: 60
                                }, () => {
                                    this.intimer = window.setInterval(() => {
                                        this.setState({sendTimeByLogin: this.state.sendTimeByLogin - 1});
                                    }, 1000);
                                    this.timer = window.setTimeout(() => {
                                        this.setState({sendTimeByLogin: 0, isSendByLogin: true});
                                        window.clearInterval(this.intimer);
                                    }, 60000);
                                });
                            } else
                                Toast.show({
                                    icon: 'fail',
                                    content: '验证码发送失败',
                                })
                        }).finally(() => this.setState({loading: false}));
                });
            } else Toast.show({
                icon: 'fail',
                content: '手机号有误!',
            });
        }
    };

    render() {
        const { isSendByLogin, sendTimeByLogin, loading, isError } = this.state;
        const { agree, addPhone } = this.props;
        const { phone, captcha, password, realName, email, nickname } = addPhone;
        return (
            isError ? (
                <div className='addPhone'>
                    <Result status="error" title="参数错误" description="参数错误请重试" />
                    <div style={{backgroundColor: "white", padding: "10px 20px"}}>
                        <Button block onClick={ () => this.props.history.goBack() } fill="none">返回</Button>
                    </div>
                </div>
            ): (
                <div className='addPhone'>
                    <h3 className='verify-phone-title'>请完善您的账号资料</h3>
                    <div className="verifyPhone-content">
                        <List style={{'--prefix-width': '6em', marginBottom: "20px"}}>
                            <List.Item style={{'--prefix-width': "60px"}} prefix={ <Avatar style={{width: "40px", height: "40px", margin: "10px 0"}} src={ this.props.userInfo.headUrl } />}>{ this.props.userInfo.username }</List.Item>
                        </List>
                        <List style={{'--prefix-width': '6em', marginBottom: "20px"}}>
                            <List.Item prefix='手机号'>
                                <Input value={ phone }
                                       maxLength={ 11 }
                                       placeholder='请输入手机号'
                                       clearable
                                       onChange={ event => {
                                           const copy = {...addPhone};
                                           copy.phone = event.replace(/[^0-9]/, '');
                                           this.props.setAddPhoneForm(copy);
                                       } }/>
                            </List.Item>
                            <List.Item prefix='验证码' extra={ <Button disabled={!isSendByLogin} color="primary" fill="none" onClick={this.sendChapter}>
                                {isSendByLogin ? '发送验证码' : `重新获取(${sendTimeByLogin}S)`}
                            </Button> }>
                                <Input
                                    value={ captcha }
                                    maxLength={ 6 }
                                    placeholder='请输入验证码'
                                    clearable
                                    onChange={ event => {
                                        const copy = {...addPhone};
                                        copy.captcha = event.replace(/[^0-9]/, '');
                                        this.props.setAddPhoneForm(copy);
                                    } }/>
                            </List.Item>
                            <List.Item prefix='密码'>
                                <Input
                                    value={ password }
                                    type="password"
                                    placeholder='请输入密码'
                                    clearable
                                    maxLength={18}
                                    onChange={ password => {
                                        const copy = {...addPhone};
                                        copy.password = password;
                                        this.props.setAddPhoneForm(copy);
                                    } }/>
                            </List.Item>
                            <NoticeBar content='密码必须由字母或数字(.-_)组成且长度保持在6-18位' color='alert' closeable />
                        </List>
                        <List style={{'--prefix-width': '6em',marginBottom: '20px'}}>
                            <List.Item prefix='昵称'>
                                <Input
                                    value={ nickname }
                                    placeholder='请输入昵称'
                                    maxLength={ 16 }
                                    onChange={ nickname => {
                                        const copy = {...addPhone};
                                        copy.nickname = nickname;
                                        this.props.setAddPhoneForm(copy);
                                    } }/>
                            </List.Item>
                            <List.Item prefix='真实姓名'>
                                <Input
                                    value={ realName }
                                    placeholder='请输入真实姓名(非必填)'
                                    maxLength={ 8 }
                                    clearable
                                    onChange={ realName => {
                                        const copy = {...addPhone};
                                        copy.realName = realName;
                                        this.props.setAddPhoneForm(copy);
                                    } }/>
                            </List.Item>
                            <List.Item prefix='邮箱'>
                                <Input
                                    value={ email }
                                    placeholder='请输入邮箱(非必填)'
                                    clearable
                                    onChange={ email => {
                                        const copy = {...addPhone};
                                        copy.email = email;
                                        this.props.setAddPhoneForm(copy);
                                    } }/>
                            </List.Item>
                        </List>
                        <div style={{padding: "0 10px"}}>
                            <div style={{padding: "8px 0 12px", display: 'flex'}} className="agreement" onClick={ () => {this.props.history.push('/agreement')}}>
                                <div style={{height: '22px'}}>
                                    <Checkbox style={{marginRight: "8px"}} checked={agree} />
                                </div><div style={{flex: 1, height: "22px", lineHeight: '22px'}}><span>我已经阅读并且接受</span><span style={{color: "blue"}} onClick={(e) => {
                                e.stopPropagation();
                                this.props.history.push('/agreement');
                            }}>《微训网服务协议》</span></div>
                            </div>
                            <Button block color='primary' onClick={ this.bindPhoneForPassword } size='large'>保存提交</Button>
                        </div>
                        <Loading loading={loading} />
                    </div>
                </div>
            )
        )
    }

    handle() {
        const { realName, phone, captcha, email, password, nickname } = this.props.addPhone;
        toRegister({
            nickname,
            realName,
            phone,
            val: captcha,
            email,
            password,
            name: uuid().substring(0,5),
            wechat_id: this.props.userInfo.unionid,
            head_url: this.props.userInfo.headUrl,
            usertype: this.props.userInfo.state,
            open_id: this.props.userInfo.openId
        }).then(data => {
            if (data.status === "0") {
                Toast.show({
                    icon: 'success',
                    content: data.msg,
                })
                localStorage.token = data.data.unionId;
                this.breakHome();
            } else if (data.status === "3") {
                Toast.show({
                    icon: 'fail',
                    content: data.msg,
                })
                this.breakHome();
            } else
                Toast.show({
                    icon: 'fail',
                    content: data.msg,
                })
        })
    }

    // TODO 提交验证
    bindPhoneForPassword = () => {
        const { phone, captcha, password, nickname } = this.props.addPhone;
        if (this.isPhone(phone)) {
            const char = '123456789';

            let isSimple = false,
                prevChar = password[0];

            if (char.indexOf(password) === -1) {
                for (let i = 1; i < password.length; i++) {
                    if (prevChar === password[i]) {
                        isSimple = false;
                        prevChar = password[i];
                    } else {
                        isSimple = true;
                        break;
                    }
                }
            }
            if (isSimple) {
                if (/[a-zA-Z0-9._-]{6,18}/img.test(password)) {
                    if (captcha.length === 6) {
                        if (nickname !== "") {
                            if (this.props.agree) {
                                this.handle();
                            } else
                                Toast.show({
                                    icon: 'fail',
                                    content: '请阅读同意用户协议！',
                                });
                        } else
                            Toast.show({
                                icon: 'fail',
                                content: '请输入昵称！',
                            });
                    } else Toast.show({
                        icon: 'fail',
                        content: '验证码有误！',
                    });

                } else
                    Toast.show({
                        icon: 'fail',
                        content: '密码格式错误！',
                    });
            } else
                Toast.show({
                    icon: 'fail',
                    content: '密码过于简单！',
                })
        } else
            Toast.show({
                icon: 'fail',
                content: '手机有误！',
            })
    }
}

export default connect(
    ({login}) => ({
        userInfo: login.register,
        agree: login.agree,
        addPhone: login.addPhoneForm
    }),
    dispatch => ({
        setAddPhoneForm: data => dispatch(setAddPhoneForm(data)),
        setRegister: data => dispatch(setRegisterData(data))
    })
)(withRouter(AddPhone));