import React, {Component} from 'react';
import { DotLoading } from 'antd-mobile';
import { withRouter } from 'react-router-dom';
import { setFirstPlay} from '../../action'
import { connect } from 'react-redux';
import { RedoOutline } from 'antd-mobile-icons';
import './microPlayer.scss';

class MicroPlayer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            canplay: false,
            isFullScreen: false,
            isHorizontal: false,
            isPlaying: false,
            isMuted: false,
            isLoading: false,
            proportion: null,
            firstPlay: true,
            playState: "ready",
            isShowCtrlBar: false,
            currentTime: 0,
            duration: 0,
        };
    }

    static defaultProps = {
        proportion: 16 / 9,
        onPlaying: () => {},
    }

    componentDidMount() {
        this.props.exp(this)
        this.setIsHorizontal();
        // TODO 兼容iOS 不会执行canplay事件
        if (!!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)) {
            this.videoPlayer.load();
            document.addEventListener('WeixinJSBridgeReady', this.videoCanplay)
            this.videoPlayer.addEventListener('canplay', this.videoCanplay);
        }
        else
            this.videoPlayer.addEventListener('canplay', this.videoCanplay);
        window.addEventListener('resize', this.setIsHorizontal);
        this.videoPlayer.addEventListener("waiting", this.videoWaiting);
        this.videoPlayer.addEventListener("playing", this.videoPlaying);
        this.videoPlayer.addEventListener("timeupdate", this.timeUpdate);
        this.videoPlayer.addEventListener("ended", this.videoEnded);
            }
     componentDidUpdate(){
     }
    

    timeUpdate = () => {
        this.setState({
            currentTime: this.videoPlayer.currentTime,
            duration: this.videoPlayer.duration,
        }, () => {
            this.props.onPlaying(this.state.currentTime, this.state.duration);
        });
    }

    videoWaiting = () => {
        this.setState({
            playState: "loading"
        })
    }

    videoPlaying = () => {
        this.setState({
            playState: "playing",
            currentTime: this.videoPlayer.currentTime,
        })
    }

    videoEnded = () => {
        if(this.props.sendData){
            this.props.sendData()
        }
        this.setState({
            playState: "ended",
        })
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.setIsHorizontal);
        if (!!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)) {
            document.removeEventListener('WeixinJSBridgeReady', this.videoCanplay)
            this.videoPlayer.removeEventListener('canplay', this.videoCanplay);
        } else
            this.videoPlayer.removeEventListener('canplay', this.videoCanplay);
        this.videoPlayer.removeEventListener("waiting", this.videoWaiting);
        this.videoPlayer.removeEventListener("playing", this.videoPlaying);
        this.videoPlayer.removeEventListener("timeupdate", this.timeUpdate);
        this.videoPlayer.removeEventListener("ended", this.videoEnded);
        // this.setState({playState:"ready",firstPlay:true})
    }

    videoCanplay = () => {
        this.setState({
            canplay: true,
            proportion: this.videoPlayer.videoWidth / this.videoPlayer.videoHeight
        });
    }

    // TODO 显示控制条
    showCtrlBar = () => {
        const { isShowCtrlBar } = this.state;
        if (isShowCtrlBar) {
            window.clearTimeout(this.timer);
            this.timer = window.setTimeout(this.hiddenCtrlBar, 7000);
        } else {
            this.setState({
                isShowCtrlBar: true
            }, () => {
                this.timer = window.setTimeout(this.hiddenCtrlBar, 7000);
            });
        }
    }

    // TODO 隐藏控制条
    hiddenCtrlBar = () => {
        window.clearInterval(this.timer);
        this.setState({
            isShowCtrlBar: false
        });
    }

    setIsHorizontal = () => {
        const { clientWidth, clientHeight } = document.documentElement;
        this.setState({
            isHorizontal: clientWidth > clientHeight
        });
    }

    videoPlayerRef = (ref) => {
        this.videoPlayer = ref;
    }

    formatTime = (time) => {
        let h = Math.floor(time / 3600);
        let m = Math.floor(time % 3600 / 60);
        let s = Math.floor(time % 60);
        return (h > 0 ? (h + ":") : "") + (m < 10 ? "0" + m : m) + ":" + (s < 10 ? "0" + s : s);
    }

    play = () => {
        console.log("123")
        if(this.props.setStartTime){
            this.props.setStartTime()
        }
        this.setState({ playState: "playing" }, () => this.videoPlayer.play())
    }

    pause = () => {
        if(this.props.sendData){
            this.props.sendData()
        }
        this.setState({ playState: "pause" }, () => this.videoPlayer.pause())
    }

    rePlay = () => {
        this.setState({ playState: "playing", currentTime: 0 }, () => {
            this.videoPlayer.currentTime = 0;
            this.videoPlayer.play();
        })
    };

    render() {
        const { address, name, id } = this.props,
              { isHorizontal, isFullScreen, canplay, isShowCtrlBar, playState, currentTime, duration } = this.state,
              { clientHeight, clientWidth } = document.documentElement,
                width = clientWidth,
                height = clientHeight,
                proportion = this.state.proportion ?? this.props.proportion,
                defProportion = MicroPlayer.defaultProps.proportion,
                videoContainerHeight = isHorizontal ? height : isFullScreen ? width / proportion : width / defProportion,
                videoWidth = isHorizontal ? height * proportion : (isFullScreen ? (proportion < 1 ? height * proportion : width) : (width / defProportion) * proportion),
                videoHeight = isHorizontal ? height : isFullScreen ? (proportion < 1 ? height : videoWidth / proportion ) : width / defProportion,
                microPlayerHeight = isHorizontal ? height : isFullScreen ? height : width / defProportion;

        return (
            <div className={`micro-player ${isHorizontal ? 'micro-player-horizontal' : (isFullScreen ? 'micro-player-full': 'micro-player-no-full')}`} style={{ width: `${width}px`, height: `${microPlayerHeight}px` }}>
                <div className="video-container" style= {{width: `${width}px`, height: `${videoContainerHeight}px`}}>
                    {playState === "playing" && id !== undefined && <marquee className="marquee">{id}</marquee>}
                    <video style={{width: `${videoWidth}px`, height: `${videoHeight}px`}}
                        className="player"
                        src={ address }
                        x5-video-player-type='h5-page'
                        webkit-playsinline="true"
                        playsInline
                        x-webkit-airplay="allow"
                        x5-video-orientation="portrait"
                        ref={ this.videoPlayerRef }  />
                </div>
                <div className="video-wall">
                    {
                        canplay ? (
                            this.props.firstPlay ? (
                                <div className="video-first-play" onClick={() => this.setState({firstPlay: false, playState: "playing"}, () => {
                                    this.props.setFirstPlay(false)
                                    this.videoPlayer.play();
                                    this.showCtrlBar();
                                    this.props.setStartTime()
                                })}>
                                    <div className="info">
                                        <div className="to-play"><i className="play-icon"><span><svg
                                            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22"><path
                                            d="M17.982 9.275L8.06 3.27A2.013 2.013 0 005 4.994v12.011a2.017 2.017 0 003.06 1.725l9.922-6.005a2.017 2.017 0 000-3.45z" /></svg></span></i></div>
                                        <div className="name">{ name ?? "点击播放" }</div>
                                    </div>
                                </div>
                            ) : <div className="video-wrapper-ctrl" onClick={ isShowCtrlBar ? this.hiddenCtrlBar : this.showCtrlBar }>
                                {
                                    playState === "loading" ? (
                                        <div className="play-loading" style={{color:'red'}}>
                                            <div className="info"><DotLoading color="white" />视频正在缓冲...</div>
                                        </div>
                                    ): (
                                        playState === "ended" ? (<div className="video-end">
                                                <div className="info" onClick={this.rePlay}><RedoOutline />&nbsp;重新观看</div>
                                            </div>) :
                                        <div onClick={ e => {e.stopPropagation();this.showCtrlBar();} } className={`video-ctrl-bar ${isShowCtrlBar ? "video-ctrl-bar-show" : "video-ctrl-bar-hidden"}`}>
                                            {isHorizontal && <div style={{width: "22px"}} />}
                                            {
                                                playState === "pause" ? (
                                                    <div className="ctrl-play" onClick={ this.play }>
                                                        <i className="play-icon"><span><svg
                                                            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22"><path
                                                            d="M17.982 9.275L8.06 3.27A2.013 2.013 0 005 4.994v12.011a2.017 2.017 0 003.06 1.725l9.922-6.005a2.017 2.017 0 000-3.45z"/></svg></span></i>
                                                    </div>
                                                ) : (
                                                    <div className="ctrl-pause" onClick={ this.pause }>
                                                        <i className="pause-icon"><span><svg
                                                            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22"><path
                                                            d="M7 3a2 2 0 00-2 2v12a2 2 0 104 0V5a2 2 0 00-2-2zM15 3a2 2 0 00-2 2v12a2 2 0 104 0V5a2 2 0 00-2-2z"/></svg></span></i>
                                                    </div>
                                                )
                                            }
                                            <div className="video-progress">
                                                <div className="video-currentTime">
                                                    <span>{ this.formatTime(currentTime) }</span>
                                                </div>
                                                <div className="progress-bar" onClick={ e => {
                                                    e.stopPropagation();
                                                    const { offsetX } = e.nativeEvent;
                                                    const { duration } = this.state;
                                                    const currentTime = offsetX / e.currentTarget.offsetWidth * duration;
                                                    this.setState({ currentTime }, () => this.videoPlayer.currentTime = currentTime);
                                                }}>
                                                    <div className="progress-bar-inner" style={{ width: `${currentTime/duration*100}%` }} />
                                                </div>
                                                <div className="video-duration">
                                                    <span>{ this.formatTime(duration) }</span>
                                                </div>
                                            </div>
                                            {
                                                isFullScreen ? (
                                                    <div className="to-no-full-btn" onClick={ () => this.setState({ isFullScreen: false }) }>
                                                        <i className="to-no-full-icon"><span><svg viewBox="0 0 22 22"
                                                                                        xmlns="http://www.w3.org/2000/svg"><g
                                                            fill="none" fillRule="evenodd" strokeLinecap="round"
                                                            strokeLinejoin="round" stroke="#FFF" strokeWidth="1.5"><path
                                                            d="M12.769 19.25v-6.519h6.481M9.26 2.75v6.512H2.75" /></g></svg></span></i>
                                                    </div>
                                                ): (
                                                    <div className="to-full-btn" onClick={ () => this.setState({ isFullScreen: true }) }>
                                                        <i className="to-full-icon"><span><svg viewBox="0 0 22 22"
                                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                                               xlinkHref="http://www.w3.org/1999/xlink"><defs><path
                                                            d="M17.262 9.995a.75.75 0 01.75.75v6.585a.75.75 0 01-.75.75h-6.515a.75.75 0 010-1.5h5.765v-5.835a.75.75 0 01.75-.75zm-6-6a.75.75 0 110 1.5H5.497v5.835a.75.75 0 01-1.5 0V4.745a.75.75 0 01.75-.75h6.515z"
                                                            id="pid-2-svgo-a" /></defs><g fill="none"
                                                                                               fillRule="evenodd"><path
                                                            d="M0 0h22v22H0z"/><mask id="pid-2-svgo-b"
                                                                                           fill="#fff"><use
                                                            xlinkHref="#pid-2-svgo-a" /></mask><use fill="#979797"
                                                                                                         fillRule="nonzero"
                                                                                                         xlinkHref="#pid-2-svgo-a" /><g
                                                            mask="url(#pid-2-svgo-b)" fill="#FFF" fillRule="nonzero"><path
                                                            d="M0 0h22v22H0z" /></g></g></svg></span></i>
                                                    </div>
                                                )
                                            }
                                            {isHorizontal && <div style={{width: "22px"}} />}
                                        </div>
                                    )
                                }
                            </div>
                        ) : <div className="video-first-loading">
                            <div><DotLoading color="white" />视频加载中...</div>
                        </div>
                    }
                </div>
            </div>
        );
    }
}

export default connect (
    ({firstPlay}) => ({
       firstPlay
    }), dispatch => ({
        setFirstPlay:(data)=>dispatch(setFirstPlay(data))
        }))
(withRouter(MicroPlayer));
