import React, { Component } from 'react';
import '../css/dakehu.css';
import {withRouter,Link} from 'react-router-dom';
class Dakehuteacher extends Component{
    render(){
        return(
            <div className='dakehuteacherbac'>
                <img src="https://weixun-demo.oss-cn-beijing.aliyuncs.com/dakehu/jiangshi.jpg" alt="teacherbac" className='dakehujiangshi'/>
                <Link to='dakehu'><div className='dakehutui'></div></Link>
            </div>

        )
    }
}

export default withRouter(Dakehuteacher);
