export const getnaire = (page)=>
fetch('/api/question?page='+page, {
    method: 'GET',
    headers:{
        'Authorization': localStorage.token
    },
}).then(result => result.json());

 export const addnaire = (naire)=>
 fetch("/api/question",{
 	method:'POST',
 	headers:{
            
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': localStorage.token,
          },
          mode:'cors',
          body: JSON.stringify(naire)
 }).then((res)=>(res.json()))