import React from "react";
import { Mask }  from "antd-mobile";
import { SpinLoading } from "antd-mobile";
import "./loading.scss";

const Loading = props => {
    return (
        <Mask visible={ props.loading } color="white">
            <div className="loading-content">
                <SpinLoading color='white'/>
            </div>
        </Mask>
    )
}

export default Loading;