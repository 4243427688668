import React, { Component } from 'react';
import bac from '../photo/duihuas.png';
import '../css/suntwo.css';
import {Link} from "react-router-dom";

class Sunthree extends Component {
    state={
        one:'sunthreebtnone',
        two:'sunthreebtntwo',
        three:'sunthreebtnthree',
        four:'sunthreebtnfour',
        five:'sunthreebtnfive',
        six:'sunthreebtnsix',
        tui:'none',
        neirong:'none'
    }
    a(){
        var neirong = document.getElementById('neirong');
        this.setState({
            one:'sunthreebtnones',
            two:'sunthreebtntwo',
            three:'sunthreebtnthree',
            four:'sunthreebtnfour',
            five:'sunthreebtnfive',
            six:'sunthreebtnsix',
            tui:'block',
            neirong:'-webkit-box'
        })
        neirong.innerHTML='公司鼓励健康的生活方式，每年定期举办员工运动会，篮球、足球和羽毛球比赛，以及定期举办新年晚会，歌咏比赛等活动';
    }
    b(){
        var neirong = document.getElementById('neirong');
        this.setState({
            one:'sunthreebtnone',
            two:'sunthreebtntwos',
            three:'sunthreebtnthree',
            four:'sunthreebtnfour',
            five:'sunthreebtnfive',
            six:'sunthreebtnsix',
            tui:'block',
            neirong:'-webkit-box'
        })
        neirong.innerHTML='公司为员工提供定期的健康体检，并为员工提供心理咨询的支持';
    }
    c(){
        var neirong = document.getElementById('neirong');
        this.setState({
            one:'sunthreebtnone',
            two:'sunthreebtntwo',
            three:'sunthreebtnthrees',
            four:'sunthreebtnfour',
            five:'sunthreebtnfive',
            six:'sunthreebtnsix',
            tui:'block',
            neirong:'-webkit-box'
        })
        neirong.innerHTML='公司招聘会从符合企业文化的角度进行选拔，配套有考核价值理念的面试方法。也欢迎员工进行推荐优秀人才加入公司。';
    }
    d(){
        var neirong = document.getElementById('neirong');
        this.setState({
            one:'sunthreebtnone',
            two:'sunthreebtntwo',
            three:'sunthreebtnthree',
            four:'sunthreebtnfours',
            five:'sunthreebtnfive',
            six:'sunthreebtnsix',
            tui:'block',
            neirong:'-webkit-box'
        })
        neirong.innerHTML='公司将定期为员工提供企业文化相关和职场培训，<br/>'+'《积极心态》<br/>《沟通技巧》<br/>《健康养生》<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;具体时间请关注公司官网，欢迎大家踊跃报名';
    }
    e(){
        var neirong = document.getElementById('neirong');
        this.setState({
            one:'sunthreebtnone',
            two:'sunthreebtntwo',
            three:'sunthreebtnthree',
            four:'sunthreebtnfour',
            five:'sunthreebtnfives',
            six:'sunthreebtnsix',
            tui:'block',
            neirong:'-webkit-box'
        })
        neirong.innerHTML='公司在晋升选拔时将重点选择符合公司文化的员工，绩效优异的同时，还要符合公司的文化价值理念，公司将对价值理念这一部分进行客观评价打分，与绩效成绩一起评估，选拔优秀人才晋升';
    }
    f(){
        var neirong = document.getElementById('neirong');
        this.setState({
            one:'sunthreebtnone',
            two:'sunthreebtntwo',
            three:'sunthreebtnthree',
            four:'sunthreebtnfour',
            five:'sunthreebtnfive',
            six:'sunthreebtnsixs',
            tui:'block',
            neirong:'-webkit-box'
        })
        neirong.innerHTML='公司每周三会有副总级以上领导在线与员工进行交流互动，大家有自己的观点想法，可以一起来进行沟通。';
    }
    g(){
        this.setState({
            tui:'none',
            neirong:'none'
        })
    }
    render(){

        return(
            <div className='sunbacthree'>
                <div className='sunthreetop'></div>
                <div id='sunthreebtnone' className={this.state.one} onClick={this.a.bind(this)} ></div>
                <div id='sunthreebtntwo' className={this.state.two} onClick={this.b.bind(this)}></div>
                <div id='sunthreebtnthree' className={this.state.three} onClick={this.c.bind(this)}></div>
                <div id='sunthreebtnfour' className={this.state.four} onClick={this.d.bind(this)}></div>
                <div id='sunthreebtnfive' className={this.state.five} onClick={this.e.bind(this)}></div>
                <div id='sunthreebtnsix' className={this.state.six} onClick={this.f.bind(this)}></div>
                <div className='sunthreetui' style={{display:this.state.tui}} onClick={this.g.bind(this)}></div>
                <div id="neirong" className='sunthreejuxing' style={{display:this.state.neirong}}>
                     公司鼓励健康的生活方式，每年定期举办员工运动会篮球足球和羽毛球比赛，以及定期举办新年晚会，歌咏比赛等活动
                </div>
                <Link to='Sun'><div className="twoone"></div></Link>
                <div className="twotwo"></div>
                <Link to='Suntwo'><div className="twothree"></div></Link>
                <Link to='Sunfour'><div className="twofour"></div></Link>
                <Link to='Anim'><div className='twobottom'></div></Link>
            </div>

        )
    }
}
export default Sunthree
