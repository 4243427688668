import React, { Component } from 'react';
import '../css/dakehu.css';
import { connect } from 'react-redux';
import {withRouter,Link} from 'react-router-dom';
import phone from '../util/phone';
class Dakehuthree extends Component{
    state={
        dakehuonem:'none',
        videoone:['https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/3/1.mp4','https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/3/2.mp4','https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/3/3.mp4','https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/3/4.mp4','https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/3/5.mp4','https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/3/6.mp4','https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/3/7.mp4','https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/3/8.mp4','https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/3/9.mp4','https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/3/10.mp4','https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/3/11.mp4'],
        videos:'',
        shenqing:'none'
    }
     panduan(videonum){
        if(phone.indexOf(this.props.userInfo.userInfo.phone)==-1){
            this.setState({
                shenqing:'block'
            })
            return -1;
        }else{
            console.log('成功')
            this.setState({
                dakehuonem:'block',
                videos:videonum
            });
            return 2;
        }
    }
    firstm(){
      var videonum=this.state.videoone[0];
        this.panduan(videonum);
    }
    secondm(){
        var videonum=this.state.videoone[1];
        this.panduan(videonum);
    }
    thirdm(){
       var videonum=this.state.videoone[2];
        this.panduan(videonum);
    }
    fourm(){
        var videonum=this.state.videoone[3];
        this.panduan(videonum);
    }
    fivem(){
       var videonum=this.state.videoone[4];
        this.panduan(videonum);
    }
    sixm(){
        var videonum=this.state.videoone[5];
        this.panduan(videonum);
    }
    sevenm(){
        var videonum=this.state.videoone[6];
        this.panduan(videonum);
    }
    eightm(){
        var videonum=this.state.videoone[7];
        this.panduan(videonum);
    }
    ninem(){
       var videonum=this.state.videoone[8];
        this.panduan(videonum);
    }
    shim(){
        var videonum=this.state.videoone[9];
        this.panduan(videonum);
    }
    shiyim(){
        var videonum=this.state.videoone[10];
        this.panduan(videonum);
    }
    
    closes(){
        var dakehuvideo=document.getElementById('dakehuvideo1');
        dakehuvideo.pause();
        this.setState({
            dakehuonem:'none'
        });
    }
    componentDidMount() {
        var dakehuvideo = document.getElementById('dakehuvideo1');
        var that=this;
        dakehuvideo.addEventListener('ended', function () {
            for(var i=0;i<that.state.videoone.length;i++){
                if(that.state.videos==that.state.videoone[i]){
                    if(that.state.wancheng.indexOf(i)!=-1){
                        console.log('包含');
                    }else{
                        that.setState({
                            wancheng:that.state.wancheng+i
                        })
                    }
                    console.log(that.state.wancheng);
                }

            }
        });
        dakehuvideo.addEventListener("timeupdate",function(){
            var timeDisplay;
            timeDisplay = Math.floor(dakehuvideo.currentTime);
        },true)
    }
    render(){
        return(
            <div className='dakehuthreebac'>
                <Link to='dakehu'><div className='dakehutui'></div></Link>
                <div className='dakehuthreecourseyi' onClick={this.firstm.bind(this)}></div>
                <div className='dakehuthreecourseer' onClick={this.secondm.bind(this)}></div>
                <div className='dakehuthreecoursesan' onClick={this.thirdm.bind(this)}></div>
                <div className='dakehuthreecoursesi' onClick={this.fourm.bind(this)}></div>
                <div className='dakehuthreecoursewu' onClick={this.fivem.bind(this)}></div>
                <div className='dakehuthreecourseliu' onClick={this.sixm.bind(this)}></div>
                <div className='dakehuthreecourseqi' onClick={this.sevenm.bind(this)}></div>
                <div className='dakehuthreecourseba' onClick={this.eightm.bind(this)}></div>
                <div className='dakehuthreecoursejiu' onClick={this.ninem.bind(this)}></div>
                <div className='dakehuthreecourseshi' onClick={this.shim.bind(this)}></div>
                <div className='dakehuthreecourseshiyi' onClick={this.shiyim.bind(this)}></div>
                <div className='dakehushenqing' style={{display:this.state.shenqing}} ></div>
                <video className='dakehuvideo1' id='dakehuvideo1'  style={{display:this.state.dakehuonem}} controls='controls' autoPlay='true'  src={this.state.videos}/>
                <div className='dakehuclose' onClick={this.closes.bind(this)} style={{display:this.state.dakehuonem}}>X</div>
            </div>

        )
    }
}

function mapStateToProps({ login}/*默认值加解构数组*/) {

    console.log(login)
    return {
        userInfo: login

    }
}

export default withRouter(connect(mapStateToProps)(Dakehuthree));
