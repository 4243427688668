import React, { Component } from 'react';
import '../css/dakehu.css';
import {withRouter,Link} from 'react-router-dom';
import phone from '../util/phone';
import { connect } from 'react-redux';
class Dakehuseven extends Component{
    state={
        dakehuonem:'none',
        videoone:['https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/7/1.mp4','https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/7/2.mp4','https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/7/3.mp4','https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/7/4.mp4','https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/7/5.mp4','https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/7/6.mp4','https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/7/7.mp4','https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/7/8.mp4','https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/7/9.mp4','https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/7/10.mp4','https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/7/11.mp4','https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/7/12.mp4','https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/7/13.mp4','https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/7/14.mp4','https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/7/15.mp4','https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/7/16.mp4','https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/7/17.mp4','https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/7/18.mp4'],
        videos:'',
        dakehuseve:'block',
        dakehusevenns:'none',
        shenqing:'none'
    }
     panduan(videonum){
        console.log(this.props.userInfo.userInfo,phone)
        if(phone.indexOf(this.props.userInfo.userInfo.phone)==-1){
            this.setState({
                shenqing:'block'
            })
            return -1;
        }else{
            console.log('成功')
            this.setState({
                dakehuonem:'block',
                videos:videonum
            });
            return 2;
        }
    }
    firstm(){
        var videonum=this.state.videoone[0];
        this.panduan(videonum);
    }
    secondm(){
        var videonum=this.state.videoone[1];
        this.panduan(videonum);
    }
    thirdm(){
        var videonum=this.state.videoone[2];
        this.panduan(videonum);
    }
    fourm(){
        var videonum=this.state.videoone[3];
        this.panduan(videonum);
    }
    fivem(){
        var videonum=this.state.videoone[4];
        this.panduan(videonum);
    }
    sixm(){
        var videonum=this.state.videoone[5];
        this.panduan(videonum);
    }
    sevenm(){
        var videonum=this.state.videoone[6];
        this.panduan(videonum);
    }
    eightm(){
        var videonum=this.state.videoone[7];
        this.panduan(videonum);
    }
    ninem(){
        var videonum=this.state.videoone[8];
        this.panduan(videonum);
    }
    shim(){
       var videonum=this.state.videoone[9];
        this.panduan(videonum);
    }
    shiyim(){
        var videonum=this.state.videoone[10];
        this.panduan(videonum);
    }
    shierm(){
        var videonum=this.state.videoone[11];
        this.panduan(videonum);
    }
    shisanm(){
        var videonum=this.state.videoone[12];
        this.panduan(videonum);
         
    }
    shisim(){
        var videonum=this.state.videoone[13];
        this.panduan(videonum);
    }
    shiwum(){
        var videonum=this.state.videoone[14];
        this.panduan(videonum);
    }
    shilium(){
        var videonum=this.state.videoone[15];
        this.panduan(videonum);
    }
    shiqim(){
        var videonum=this.state.videoone[16];
        this.panduan(videonum);
    }
    shibam(){
        var videonum=this.state.videoone[17];
        this.panduan(videonum);
    }

    closes(){
        var dakehuvideo=document.getElementById('dakehuvideo1');
        dakehuvideo.pause();
        this.setState({
            dakehuonem:'none'
        });
    }
    dakehusevennext(){
        var that=this;
        that.setState({
            dakehusevenbac:'url("https://weixun-demo.oss-cn-beijing.aliyuncs.com/dakehu/dakehusevenseven.jpg")',
            dakehusevenns:'block',
            dakehuseve:'none'
        });
    }
    dakehusevenback(){
        var that=this;
        that.setState({
            dakehusevenbac:'url("https://weixun-demo.oss-cn-beijing.aliyuncs.com/dakehu/dakehuseven.jpg")',
            dakehuseve:'block',
            dakehusevenns:'none'
        });
    }
    componentDidMount() {
        var dakehuvideo = document.getElementById('dakehuvideo1');
        var that=this;
        dakehuvideo.addEventListener('ended', function () {
            for(var i=0;i<that.state.videoone.length;i++){
                if(that.state.videos==that.state.videoone[i]){
                    if(that.state.wancheng.indexOf(i)!=-1){
                        console.log('包含');
                    }else{
                        that.setState({
                            wancheng:that.state.wancheng+i
                        })
                    }
                    console.log(that.state.wancheng);
                }

            }
        });
        dakehuvideo.addEventListener("timeupdate",function(){
            var timeDisplay;
            timeDisplay = Math.floor(dakehuvideo.currentTime);
        },true)
    }
    render(){
        return(
            <div className='dakehusevenbac' style={{backgroundImage:this.state.dakehusevenbac}}>
                <Link to='dakehu'><div className='dakehutui'></div></Link>
                <div className='dakehusevencourseyi' style={{display:this.state.dakehuseve}} onClick={this.firstm.bind(this)}></div>
                <div className='dakehusevencourseer' style={{display:this.state.dakehuseve}} onClick={this.secondm.bind(this)}></div>
                <div className='dakehusevencoursesan' style={{display:this.state.dakehuseve}} onClick={this.thirdm.bind(this)}></div>
                <div className='dakehusevencoursesi' style={{display:this.state.dakehuseve}} onClick={this.fourm.bind(this)}></div>
                <div className='dakehusevencoursewu' style={{display:this.state.dakehuseve}} onClick={this.fivem.bind(this)}></div>
                <div className='dakehusevencourseliu' style={{display:this.state.dakehuseve}} onClick={this.sixm.bind(this)}></div>
                <div className='dakehusevencourseqi' style={{display:this.state.dakehuseve}} onClick={this.sevenm.bind(this)}></div>
                <div className='dakehusevencourseba' style={{display:this.state.dakehuseve}} onClick={this.eightm.bind(this)}></div>
                <div className='dakehusevencoursejiu' style={{display:this.state.dakehuseve}} onClick={this.ninem.bind(this)}></div>
                <div className='dakehusevencourseshi' onClick={this.shim.bind(this)} style={{display:this.state.dakehuseve}}></div>
                <div className='dakehusevennext' onClick={this.dakehusevennext.bind(this)} style={{display:this.state.dakehuseve}}></div>
                <div className='dakehusevencourseshiyi' onClick={this.shiyim.bind(this)} style={{display:this.state.dakehusevenns}}></div>
                <div className='dakehusevencourseshier' onClick={this.shierm.bind(this)} style={{display:this.state.dakehusevenns}}></div>
                <div className='dakehusevencourseshisan'onClick={this.shisanm.bind(this)} style={{display:this.state.dakehusevenns}}></div>
                <div className='dakehusevencourseshisi' onClick={this.shisim.bind(this)} style={{display:this.state.dakehusevenns}}></div>
                <div className='dakehusevencourseshiwu' onClick={this.shiwum.bind(this)} style={{display:this.state.dakehusevenns}}></div>
                <div className='dakehusevencourseshiliu' onClick={this.shilium.bind(this)} style={{display:this.state.dakehusevenns}}></div>
                <div className='dakehusevencourseshiqi' onClick={this.shiqim.bind(this)} style={{display:this.state.dakehusevenns}}></div>
                <div className='dakehusevencourseshiba' onClick={this.shibam.bind(this)} style={{display:this.state.dakehusevenns}}></div>
                <div className='dakehusevenback' onClick={this.dakehusevenback.bind(this)} style={{display:this.state.dakehusevenns}}></div>
                <div className='dakehushenqing' style={{display:this.state.shenqing}} ></div>
                <video className='dakehuvideo1' id='dakehuvideo1'  style={{display:this.state.dakehuonem}} controls='controls' autoPlay='true' src={this.state.videos}/>
                <div className='dakehuclose' onClick={this.closes.bind(this)} style={{display:this.state.dakehuonem}}>X</div>
            </div>

        )
    }
}

function mapStateToProps({ login}/*默认值加解构数组*/) {

    console.log(login)
    return {
        userInfo: login

    }
}

export default withRouter(connect(mapStateToProps)(Dakehuseven));
