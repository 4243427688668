import React, { Component } from 'react';
import {withRouter} from 'react-router-dom';
import '../css/Treasure.css'
import * as answerAPI from '../API/answerAPI';
import { Radio, Button, Checkbox, Icon } from 'antd';

import * as css from '../util/getcss'
import { connect } from 'react-redux';
import { SharedInfo } from '../action';



class Treasure extends Component {

	

	state={
		question:[],
		completely:false,
		hasFinished:false,
		bg:"https://micro-weixun.oss-cn-beijing.aliyuncs.com/bgg.jpg",
		situation:[false,false,false],
		bear:'https://micro-weixun.oss-cn-beijing.aliyuncs.com/bearbox.png'

	}
	isChoosing=false

	orient() {
        
        let isDrag=false;
        let dom = this.refs.box,
            img = this.refs.bg;
        let img_width = img.width,
            img_height = img.height;

        let view_width = document.documentElement.clientWidth,
            view_height = document.documentElement.clientHeight;
        let last = 0,               // 上一次时间
            now = 0,                     // 当前时间
            lastBeta=0,
        lastGamma=0
        // 设置默认的left/top
        let styletop=-img_height*0.36
        let styleleft=-img_width*0.35
        if (true) {}
        let styleheight=view_height/(0.74*img_height)
        dom.style.top = styletop+'px';
        dom.style.left = styleleft+'px';
        img.style.height=styleheight*100+'%';
        img_width=parseInt(img.width)
        img_height=parseInt(img.height);
        let that=this;
        
        // window.addEventListener('deviceorientation', function (evt) {
        	
        // 	console.log(img.height)
        // 	console.log(view_height)
        //     if (!isDrag&&that.isChoosing===false) {
        //         now = new Date().getTime();
        //         // 控制时间片
        //         if (now  - last > 1) {
        //             last = now;
                    
        //             var x = evt.beta,            //x周 -180-180
        //                 y = evt.gamma;          //y轴 -90-90
        //             var top = parseInt(dom.style.top, 10) || 0,
        //                 left = parseInt(dom.style.left, 10) || 0;
        //             var top_current, left_current;
        //             let top_change=x / 180 * 20;
        //             let left_change=y / 90 * 20;
        //             top_current = top- (x / 180 * 20); //角的转换率 20为速度可以任意设置
        //             left_current = left + (y / 90 * 20);
        //             if (top_current >= 0) { //图片最上侧边缘与浏览器重合
        //                 // top_current = 0;
        //                 top_change=0;
        //             }
        //             else{
        //             	console.log(top_current+"123")
        //             	console.log(view_height - img_height)
        //                 if (top_current < (view_height - img_height)) {
        //                 	console.log(top_current+"123")
        //                     top_current=view_height - img_height //图片最下侧边缘与浏览器重合
        //                 }
        //                 else{
        //                     // top_current=top_current;
        //                     top_change=top_change;
        //                 }
        //             }


        //             //此处同上 只不过是两侧
        //             if (left_current >= 0) {
        //                 // left_current = 0;
        //                 left_change=0
        //             }
        //             else{
        //                 if (left_current < (view_width - img_width)) {
        //                     left_current=view_width - img_width
        //                 }
        //                 else{
        //                     // left_current=left_current;
        //                     left_change=left_change;
        //                 }
        //             }
                    
        //             // dom.style.top = top_current + 'px';
        //             // dom.style.left = left_current + 'px';
        //             dom.style.transform = "translate("+left_change+'px,'+top_change+'px)';
        //         }
        //     }
        // }, false);
        
        window.addEventListener('devicemotion', function (e) {
            
         
            // if (!isDrag&&that.isChoosing===false) {
            //     now = new Date().getTime();
            //     // 控制时间片
            //     var x = evt.beta;           //x周 -180-180
            //     var y = evt.gamma;
            //     if (now  - last > 10&&Math.abs(x-lastBeta)>0&&Math.abs(y-lastGamma)>0) {
            //         last = now;
            //         lastBeta=x
            //         lastGamma=y
            //                   //y轴 -90-90
            //         var top = parseInt(dom.style.top, 10) || 0,
            //             left = parseInt(dom.style.left, 10) || 0;


            //         let bg_transform=css.cssTransform(dom,'translate')
            //         let top_transform=parseInt(bg_transform[1]);
            //         let left_transform=parseInt(bg_transform[0]);
            //         console.log(top_transform)
            //         var top_current, left_current;

            //         let left_change=left_transform+y / 90 * 10;
            //         let top_change=top_transform+((x-40) / 90 * 10);
            //         top_current = top + top_change; //角的转换率 20为速度可以任意设置
            //         left_current = left + left_change;
            //         console.log(top_change)
            //         console.log(top + top_change)
            //         console.log(top)
            //         if (top_current >= 0) { //图片最上侧边缘与浏览器重合
            //             // top_current = 0;
            //             top_change = 0-top;
            //             top_change=Math.floor(top_change)
            //         }
            //         else{
            //             if (top_current<= view_height-img_height) {
                            
            //                 top_change=(0-top+view_height) - img_height;//图片最下侧边缘与浏览器重合
            //                 top_change=Math.floor(top_change)
            //             }
            //             else{
            //                 // top_current=top_current;
            //                 top_change=Math.floor(top_change);
                            
            //             }
            //         }


            //         //此处同上 只不过是两侧
            //         if (left_current >= 0) {
            //             // left_current = 0;
            //             left_change=0-left;
            //             left_change=Math.floor(left_change);
            //         }
            //         else{
            //             if (left_current < (view_width - img_width)) {
            //                 left_change = (0-left+view_width) - img_width
            //                 left_change=Math.floor(left_change);
            //             }
            //             else{
            //                 // left_current=left_current;
            //                 left_change=Math.floor(left_change);
            //             }
            //         }
                    
            //         // dom.style.top = top_current + 'px';
            //         // dom.style.left = left_current + 'px';
            //         dom.style.webkitTransform = "translate("+left_change+'px,'+top_change+'px)';
            //     }
                
            // }
            if (!isDrag&&that.isChoosing===false) {
                let motion = e.accelerationIncludingGravity;
                console.log(e.accelerationIncludingGravity)
            // e.accelerationIncludingGravity.x = -e.accelerationIncludingGravity.x;
            // e.accelerationIncludingGravity.y = -e.accelerationIncludingGravity.y;
            // e.accelerationIncludingGravity.z = -e.accelerationIncludingGravity.z;
                let bg_transform=css.cssTransform(dom,'translate')
                let top_transform=parseInt(bg_transform[1]);
                let left_transform=parseInt(bg_transform[0]);
                let left_change = left_transform-motion.x;
                let top_change = top_transform+motion.y;
                let top = parseInt(dom.style.top, 10) || 0,
                        left = parseInt(dom.style.left, 10) || 0;
                let top_current = top + top_change; //角的转换率 20为速度可以任意设置
                let left_current = left + left_change;
                if (top_current >= 0) { //图片最上侧边缘与浏览器重合
                        // top_current = 0;
                        top_change = 0-top;
                        top_change=Math.floor(top_change)
                }
                else{
                    if (top_current<= view_height-img_height) {
                        
                        top_change=(0-top+view_height) - img_height;//图片最下侧边缘与浏览器重合
                        top_change=Math.floor(top_change)
                    }
                    else{
                        // top_current=top_current;
                        top_change=Math.floor(top_change);
                        
                    }
                }


                //此处同上 只不过是两侧
                if (left_current >= 0) {
                    // left_current = 0;
                    left_change=0-left;
                    left_change=Math.floor(left_change);
                }
                else{
                    if (left_current < (view_width - img_width)) {
                        left_change = (0-left+view_width) - img_width
                        left_change=Math.floor(left_change);
                    }
                    else{
                        // left_current=left_current;
                        left_change=Math.floor(left_change);
                    }
                }
                dom.style.webkitTransform = "translate("+left_change+'px,'+top_change+'px)';
            }
        }, false);


        var lastTouchX,lastTouchY,startX,startY;
        var disX,disY,top_current,left_current
        let top_transform,left_transform
        window.addEventListener('touchstart',function(e){
            console.log(isDrag+" start")
        	if(that.isChoosing===false){
	            isDrag=true;
	            lastTouchX=e.changedTouches[0].pageX;
	            lastTouchY=e.changedTouches[0].pageY;
	            startY=parseInt(dom.style.top)
	            startX=parseInt(dom.style.left)
                let bg_transform=css.cssTransform(dom,'translate')
                top_transform=parseInt(bg_transform[1]);
                left_transform=parseInt(bg_transform[0]);
        	}
        },false)

        window.addEventListener('touchmove',function(e){
            console.log(isDrag+" move")
        	let img_height=parseInt(img.height);
            if (isDrag) {
                
                disX=e.changedTouches[0].pageX-lastTouchX
                disY=e.changedTouches[0].pageY-lastTouchY
                let leftMove=left_transform + disX
                let topMove=top_transform + disY
                top_current= startY + topMove;
                left_current= startX + leftMove;
                console.log(leftMove)
                if (left_current >= 0) {
                    leftMove=0-startX;
                    leftMove=Math.floor(leftMove);
                }
                else{
                    if (left_current <= (view_width - img_width)) {
                        leftMove = (0-startX+view_width) - img_width
                        leftMove=Math.floor(leftMove);

                    }
                    else{
                    	console.log(leftMove)
                        leftMove=Math.floor(leftMove);
                    }
                }

                if (top_current >= 0) { //图片最上侧边缘与浏览器重合
                    topMove=0-startY;
                    topMove=Math.floor(topMove);
                }
                else{
                    if (top_current <= (view_height - img_height)) {
                        topMove = (0-startY+view_height) - img_height
                        topMove=Math.floor(topMove);
                    }
                    else{
                        topMove=Math.floor(topMove);
                    }
                }
                // dom.style.top=topMove+'px'
                // dom.style.left=leftMove+'px'
                dom.style.webkitTransform = "translate("+leftMove+'px,'+topMove+'px)';
            }
        },{ passive: false })

        window.addEventListener('touchend',function(e){
        
            isDrag=false;
            console.log(isDrag+ " end")
        })

        let left2=img.width*0.42+'px'
        let left1=img.width*0.29+'px'
        let left3=img.width*0.55-25+'px'

        let top1=img.height*0.74+'px'
        let top2=img.height*0.56+'px'
        let top3=img.height*0.56+'px'
        console.log(img.width)
        this.setState({
        	completely:true,
        	left1:left1,
        	left2:left2,
        	left3:left3,
        	top1:top1,
        	top2:top2,
        	top3:top3
        })
    }

	componentDidMount(){
    	document.body.addEventListener('touchmove' , function(e){
　　			var e=e||window.event;
　　			e.preventDefault();
		},{ passive: false })

        let SharedInfo={}
        SharedInfo.title='年会活动答题DEMO'
        SharedInfo.img="https://micro-weixun.oss-cn-beijing.aliyuncs.com/demo.jpg"
        SharedInfo.des="请寻找图中的礼物盒，并回答问题"
        this.props.setSharedInfo(SharedInfo)


  	}
  	index=null;
  	showQuestion(i,e){
  		this.isChoosing=true;
  		e.preventDefault();
  		let question={}
  		Object.assign(question,this.question[i])
  		console.log(question,i,this.question[i])
  		question.isShow=true;
  		this.setState({
  			question: question,
  			answerResult:"nothing",
  		})
  		this.index=i;
  		
  	}

  	componentDidUpdate(){
  		let _question =this.refs.question;
  		if (_question!=null) {
  			_question.style.height=_question.clientWidth+'px'	
  		}
  		console.log("12344")
  	}

  	question=[{
			des:"增强学员参与度的方法是",
			choose:[
				"培训师多讲故事",
				"领导提出要求",
				"培训内容生动有趣,有效落地",
				"课前签到，课后打卡"
			],
			type:"single",
			answer:2,
			isShow:false,
			isFinished:false
		},
		{
			des:"防止学员在线学习时“刷课”的最有效方式",
			choose:[
				"领导抽查",
				"随时对学员进行人脸识别",
				"加入互动机制",
				"多讲故事"
			],
			type:"single",
			flag:"special",
			answer:2,
			isShow:false,
			isFinished:false
		},
		{
			des:"线上学习未来的趋势是",
			choose:[
				"视频加考试",
				"名师大咖分享",
				"高颜值讲师",
				"综合视频、互动、问答的综合形式"
			],
			type:"single",
			answer:3,
			isShow:false,
			isFinished:false
		},

	]


	chooseQuestion(e){
		console.log(e.target.value)
		this.setState({
			write:e.target.value,
			answerResult:"chang"
		})
	}

	hasFinished=false;
	finish=[null,null,null];
	answerQuestion(){
		let that=this
		if (this.state.write===this.state.question.answer) {
			let situation=[...this.state.situation]
			situation[this.index]=true

			this.setState({
				answerResult:"ok",
				situation:situation
			})
			let i;
			this.question[this.index].isFinished=true;

			for (i = 0; i < this.question.length; i++) {

				if (this.question[i].isFinished!==true) {
					break;
				}
			}
			console.log(i,this.question.length)
			if (i===this.question.length&&this.hasFinished===false) {
				this.hasFinished=true;
				answerAPI.answer().then(data=>{
					console.log(data.msg)
					this.index=0;
					window.addEventListener('click',function(e){
						console.log(e)
						window.location.href='https://learning.wx798.com'
					})
				})
				// setTimeout(function(){
				// 	that.props.history.push('/')
				// },2000);

			}
			this.closeQuestion()
			setTimeout(that.closeCon.bind(that),1000);

			
		}
		else{
			this.setState({
				answerResult:"error"
			})
		}
		setTimeout(function(){
			that.setState({
				answerResult:'chang'
			})
		},1500)
	}

	closeCon(){
		this.setState({
			answerResult:"ch",
		})
	}

	closeQuestion(){
		console.log("123")
		this.setState({
			question:[],
			write:"",
			hasFinished:this.hasFinished,
		})
		console.log("close")
		this.isChoosing=false
	}
		
	render(){
		return(
			<div className="father">
    			<div className="box" ref="box" style={{WebkitTransform:'translate(0px,0px)'}}>
    				{this.state.completely&&<div className="iconInclude one" style={{left:this.state.left1,top:this.state.top1,height:'36%'}} onClick={this.showQuestion.bind(this,0)}>
    											<img className="icon" src="https://micro-weixun.oss-cn-beijing.aliyuncs.com/daxiong2.png" style={{height:'100%'}}/>
    											{this.state.situation[0]&&<img src="https://micro-weixun.oss-cn-beijing.aliyuncs.com/ok.png" style={{position:'absolute',top:'70%',right:'45%',width:'30px'}}/>}
    										</div>}
    				{this.state.completely&&<div className="iconInclude two" style={{left:this.state.left2,top:this.state.top2,height:'30%'}} onClick={this.showQuestion.bind(this,1)}>
    											<img className="icon" src="https://micro-weixun.oss-cn-beijing.aliyuncs.com/man.png" style={{height:'100%'}}/>
    											{this.state.situation[1]&&<img src="https://micro-weixun.oss-cn-beijing.aliyuncs.com/ok.png" style={{position:'absolute',top:'0px',right:'0px',width:'30px'}}/>}
    										</div>}
    				{this.state.completely&&<div className="iconInclude three" style={{left:this.state.left3,top:this.state.top3,height:'30%'}} onClick={this.showQuestion.bind(this,2)}>
    											<img className="icon" src="https://micro-weixun.oss-cn-beijing.aliyuncs.com/woman.png" style={{height:'100%'}}/>
    											{this.state.situation[2]&&<img src="https://micro-weixun.oss-cn-beijing.aliyuncs.com/ok.png" style={{position:'absolute',top:'0px',right:'0px',width:'30px'}}/>}
    										</div>}
        									<img  className="bg" src="https://micro-weixun.oss-cn-beijing.aliyuncs.com/67711604043286_.pic_hd.jpg" alt="" ref="bg" onLoad={this.orient.bind(this)}/>
    			</div>
    			{ this.state.question.isShow ? (
					<div className='question-mask'>
						<div className='question' ref="question" style={{background:'url('+this.state.bg+') no-repeat',backgroundSize:'100% 100%',padding:"6% 6%" }}>
							{this.state.question.flag!=='special'?<h3 > { this.state.question.des }</h3>:<h3 style={{fontSize:'15px'}}>防止学员在线学习时“刷课”的<br/>最有效方式</h3>}
							
									<Radio.Group className='question_wrapper'  style={{width:'100%',margin:'0 auto 1%',border:'none'}} onChange={this.chooseQuestion.bind(this)}>
										{
											this.state.question.choose.map((item, index) => (
												<div className='list_item' key={index} style={{width:'100%'}}>
													<Radio value={index} style={{fontSize:'16px',width:'100%',whiteSpace:'normal'}}>
														{ item }
													</Radio>
												</div>
											))
										}
									</Radio.Group>
							<p className='answer_result' style={{ color: 'green',margin:'5px' }}>{this.state.answerResult !== 'chang'&&this.state.answerResult!=='nothing'?(this.state.answerResult==='ok'?'回答正确':'回答错误'):""}</p>
							<Button style={{marginLeft:"50%",transform:"translateX(-50%)"}} onClick={this.answerQuestion.bind(this)}>提交答案</Button>
						
						</div>
					</div>
				) : null }
				{this.state.hasFinished&&<div className="model" style={{width:'60%',height:'60%',position:'fixed',top:'50%',left:'50%',zIndex:999,transform:'translate(-50%,-50%)'}}>
					<img style={{width:'100%'}} src="https://micro-weixun.oss-cn-beijing.aliyuncs.com/finish.png"/>
				</div>}
				{this.state.answerResult==='ok'&&!this.state.hasFinished&&<div className="model" style={{width:'40%',height:'60%',position:'fixed',top:'50%',left:'50%',zIndex:999,transform:'translate(-50%,-50%)'}}>
					<img style={{width:'100%'}} src="https://micro-weixun.oss-cn-beijing.aliyuncs.com/right.png"/>
				</div>}
			</div>
		)
	}

}
function mapStateToProps(/*默认值加解构数组*/) {
  return {
   

  }
}

function mapDispatchToProps(dispatch) {
  return {
    setSharedInfo:(data)=>{dispatch(SharedInfo(data))},
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Treasure));





