import React, { Component } from 'react';
import '../css/video.css'
import { withRouter } from 'react-router-dom';
import { Player, BigPlayButton } from 'video-react';
import "video-react/dist/video-react.css";
import * as CoursesAPI from '../API/CoursesAPI';
import { getVideo ,setFullScreen} from '../action'
import { connect } from 'react-redux';
import { Button, Checkbox, Icon } from 'antd';


class Videoplayer extends Component {
	lowerCaseOpation = ['a', 'b', 'c', 'd', 'e', 'f'];
	upperCaseOpation = ['A', 'B', 'C', 'D', 'E', 'F'];
	isAndriod = true;

	state = {
		// 需要显示的题目
		question: {
			isShow: false,
			index: 0,
			timer: false
		},
		answer: {
			singleValue: '',
			chooseValue: []
		},
		answerResult: '',
		isAndroid: true,
		fullScreen: false,
		androidIsPlay: false,
		showCtrlBar: true,
		isLoading: false,
		currentTime: '00:00:00',
		totalTime: '00:00:00',
		questionStyle: { opacity: '0', transform: 'scale(1)' }
	};



	start = null

	isSend = false;

	currentTime = null

	componentWillUnmount() {
		clearTimeout(this.ctrlBarTimer);
		console.log("123")
		this.time = this.player.currentTime.toString()
		this.player.currentTime = 0.0;
		// this.player.pause();
	}

	componentDidMount() {
		this.isSend = false
		const lessonId = this.props.match.params.lessonId;
		const videoId = this.props.match.params.videoId;
		const chapter = this.props.match.params.chapter;
		this.start = new Date().getTime();
		let that = this
		// 是否为安卓手机
		this.isAndriod = navigator.appVersion.indexOf("Android") !== -1;
		this.setState({ isAndroid: this.isAndriod });

		CoursesAPI.getVideo(lessonId,videoId).then(data => {

			this.props.getVideo(data);
			// TODO 如果没有题目就不对视频进行监听
			this.androidJTing();
			this.openPlayerCtrlBar();

			// TODO 视频定位
			if (data.data.playtime !== null && data.data.playtime !== "null") {
				console.log(data.data.playtime === null)
				this.player.currentTime = data.data.playtime;
			}

			// TODO 视频进行缓冲
			this.player.onwaiting = () => {
				if (this.player.readyState === 2)
					this.setState({ isLoading: true });
			};
			// TODO 缓冲结束
			this.player.onplaying = () => {
				console.log("123456")
				if (this.player.readyState === 3 || this.player.readyState === 4)
					this.setState({ isLoading: false });
			};
			// TODO 播放结束
			this.player.onended = () => {
				this.setState({
					androidIsPlay: false,
					showCtrlBar: false,
					isLoading: false,
					currentTime: '00:00:00',
					totalTime: '00:00:00'
				}, () => CoursesAPI.recordProgress(data.data.id).then(e => console.log(e)))
			};

		})
	}
	// TODO 唤起播放器控制条 (2S 自动隐藏) ANDROID
	openPlayerCtrlBar = () => {
		this.setState({
			showCtrlBar: true,
		}, () => {
			this.ctrlBarTimer = setTimeout(() => {
				this.setState({ showCtrlBar: false });
			}, 2000);
		});
	};

	// TODO 立即隐藏控制条 ANDROID
	hiddenPlayerCtrlBar = () => {
		clearTimeout(this.ctrlBarTimer);
		if (this.state.showCtrlBar)
			this.setState({ showCtrlBar: false });
	};
	androidPlay = () => {   //暂停状态下得播放按钮
		this.player.play();
		this.setState({ androidIsPlay: true });
	};
	// TODO 暂停 ANDROID
	androidPause = () => {  
		this.player.pause();
		this.setState({ androidIsPlay: false });
	};
	// TODO 延长隐藏事件 ANDROID
	extendPlayerCtrlTimer = () => {
		clearTimeout(this.ctrlBarTimer);
		this.ctrlBarTimer = setTimeout(() => {
			this.setState({ showCtrlBar: false });
		}, 2000);
	};
	// TODO 进入全屏 IOS
	fullScreen = () => {
		console.log(this.props.setFullScreen)
		const { video } = this.player.video;

		if (video.requestFullscreen) {
			video.requestFullscreen();
		} else if (video.mozRequestFullScreen){
			video.mozRequestFullScreen();
		} else if (video.webkitRequestFullScreen) {
			video.webkitRequestFullScreen();
		}

	};
	// TODO 退出全屏 IOS
	exitFullscreen = () => {
		const { video } = this.player.video;
		if (video.webkitExitFullScreen) {
			video.webkitExitFullScreen();
		} else if (video.exitFullscreen) {
			video.exitFullscreen();
		} else if (video.mozCancelFullScreen) {
			video.mozCancelFullScreen();
		} else { }
	};
  // TODO 全屏 ANDROID
	androidFullScreen = () => {
		const { clientWidth } = document.documentElement;
		this.player.style = `
		width: ${document.documentElement.clientHeight}px;
		height: ${clientWidth}px;`;
		this.setState({ fullScreen: true });
	};
	// TODO 退出全屏 ANDROID
	androidExitFullScreen = () => {
		this.player.style = "";
		this.currentFullScreenState = this.state.fullScreen;
		this.setState({ fullScreen: false });
	};

	render() {
		let questionsList = {
			choose: null,
			answer: null,
			index: null,
			questions: null,
			submitType: null
		};
		// TODO 初始化题目
		if (this.state.question.isShow) {
			let that = this
			questionsList.index = this.state.question.index;
			questionsList.questions = this.props.video.questions[questionsList.index];
			questionsList.choose = questionsList.questions.choose.split(",");
			questionsList.answer = questionsList.questions.answer;
			switch (questionsList.questions.type) {
				case 'single':
					questionsList.submitType = <Button className='submit-single' onClick={this.state.answerResult !== 'ok' ? this.submitSingleAnswers : this.closeQuestion} type='primary'>{this.state.answerResult !== 'ok' ? '提交' : '回到课程'}</Button>
					break;
				case 'choose':
					questionsList.submitType = <Button className='submit-single' onClick={this.state.answerResult !== 'ok' ? this.submitSingleAnswers : this.closeQuestion} type='primary'>{this.state.answerResult !== 'ok' ? '提交' : '回到课程'}</Button>
					break;
			}
		} else {
			questionsList = null;
		}
		const { isAndroid, fullScreen, androidIsPlay, showCtrlBar, isLoading, currentTime, totalTime } = this.state;
		return (
			<div className="v_video">
				<div>
					<div onClick={showCtrlBar ? this.hiddenPlayerCtrlBar : this.openPlayerCtrlBar} className={`android-display ${fullScreen ? 'full' : 'no-full'}`}>
						<video height={230} x5-video-player-type='h5-page' webkit-playsinline="true" playsInline x-webkit-airplay="allow" x5-video-orientation="portrait" ref={player => this.player = player} className="player" src={"https://micro-weixun-input.oss-cn-beijing.aliyuncs.com/2-1.mp4"} />
						{
							isLoading && (<div className='playBtn'>
								<Icon type='loading' />
							</div>)
						}
						{
							androidIsPlay ? (
								<button hidden={isLoading} style={showCtrlBar ? {} : { opacity: 0, zIndex: '-1' }} className="playBtn" onClick={e => { e.stopPropagation(); this.androidPause(); this.extendPlayerCtrlTimer() }}>
									<i className='iconfont icon-pasue-play' />
								</button>
							) : (
								<button hidden={isLoading} style={showCtrlBar ? {} : { opacity: 0, zIndex: '-1' }} className="playBtn" onClick={e => { e.stopPropagation(); this.androidPlay(); this.extendPlayerCtrlTimer() }}>
									<i className='iconfont icon-play' />
								</button>
							)
						}
						<div hidden={isLoading} onClick={e => { e.stopPropagation(); this.extendPlayerCtrlTimer() }} style={showCtrlBar ? {} : { opacity: 0, zIndex: '-1' }} className="touch_bar">
							<div className='video-ctrl'>
								<span className='time'>{currentTime}</span>
								<i className='iconfont icon-daoTui' onClick={() => this.player.currentTime -= 10} />

								{
									androidIsPlay ? (
										<i className='iconfont icon-pasue-play' onClick={this.androidPause} />
									) : (
										<i className='iconfont icon-play' onClick={this.androidPlay} />
									)
								}
								<i className='iconfont icon-kuaijin' onClick={() => this.player.currentTime += 10} />
								<span className='time'>{totalTime}</span>
							</div>
							{
								fullScreen ? (
									<button onClick={this.androidExitFullScreen}><Icon type="fullscreen-exit" /></button>
								) : (
									<button onClick={this.androidFullScreen}><Icon type="fullscreen" /></button>
								)
							}
						</div>
					</div>
				</div>
				{this.state.question.isShow ? (
					<div className='question-mask' style={this.state.questionStyle}>
						<div className='question'>
							<span className='close' onClick={this.closeQuestion}><Icon type='left' /></span>
							<h3>123选择题</h3>
							<p>{questionsList.questions.question}</p>
							{
								questionsList.questions.type === "single" ? (
									<ul className='question_wrapper'>
										{
											questionsList.choose.map((item, index) => (
												<li className={`choose_item ${this.state.answer.singleValue === this.lowerCaseOpation[index] ? 'selected' : 'select'}`} onTouchEnd={() => this.handleSingleOptionChanged(this.lowerCaseOpation[index])}>{item}</li>
											))
										}
									</ul>
								) : questionsList.questions.type === "choose" ? (
									<Checkbox.Group className='question_wrapper' onChange={this.handleChooseOptionChanged} >
										{
											questionsList.choose.map((item, index) => (
												<div className='list_item' key={index}>
													<Checkbox value={this.lowerCaseOpation[index]}>{this.upperCaseOpation[index]}. {item}</Checkbox>
												</div>
											))
										}
									</Checkbox.Group>
								) : null /* TODO 其他类型题目预留view */
							}
							{this.state.answerResult === 'ok' ? (
								<p className='answer_result' style={{ color: 'green' }}>回答正确</p>
							) : (this.state.answerResult === 'error' ? (
								<p className='answer_result' style={{ color: 'red' }}>回答错误</p>
							) : null)
							}
							{questionsList.submitType}
						</div>
					</div>
				) : null}
			</div>
		)
	}
}
// TODO 秒数序列化 00：00：00
const timeFormat = s => {
	if (s >= 3600) {
		let hour = ~~(s / 3600);
		let minS = s % 3600;
		let min = ~~(minS / 60);
		let ss = ~~(minS % 60);
		return `${hour > 10 ? hour : `0${hour}`}:${min > 10 ? min : `0${min}`}:${ss > 10 ? ss : `0${ss}`}`;

	} else {
		let min = ~~(s / 60);
		let ss = ~~(s % 60);
		return `00:${min > 10 ? min : `0${min}`}:${ss > 10 ? ss : `0${ss}`}`;
	}
};

export default connect (
	({login, course ,setFullSccreen}) => ({
		userInfo: login.userInfo,
		msg: course.msg,
		video: course.video,
		setFullSccreen,
	}), dispatch => ({
		getVideo: (data) => dispatch(getVideo(data)),
		setFullSccreen: (data)=>dispatch(setFullScreen(data))
		}))
	(withRouter(Videoplayer));