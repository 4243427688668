import React, { Component } from 'react';
import * as CourseAPI from "../../API/CoursesAPI";
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Avatar, Ellipsis, List, Tabs, DotLoading, Divider, Empty,Mask, SpinLoading,ProgressBar } from 'antd-mobile';
import "./myCourse2.css";
import { Pie, measureTextWidth } from '@ant-design/plots';

class MyCourse2 extends Component {

    constructor(props) {
        super(props);
        this.state = {
            courses: [],
            type: "1",
            page: 1,
            loading: true,
            hasMore: true,
            pageSize: 10,
            TabClass:['Tab1 active','Tab2'],
            port:null,
            className1:"active",
            className2:"unactive",
            activeKey:"1",
            total:"0"
        };
    }

    renderStatistic(containerWidth, text, style) {
        const { width: textWidth, height: textHeight } = measureTextWidth(text, style);
        const R = containerWidth / 2; // r^2 = (w / 2)^2 + (h - offsetY)^2
    
        let scale = 1;
    
        if (containerWidth < textWidth) {
          scale = Math.min(Math.sqrt(Math.abs(Math.pow(R, 2) / (Math.pow(textWidth / 2, 2) + Math.pow(textHeight, 2)))), 1);
        }
    
        const textStyleStr = `width:${containerWidth}px;`;
        return `<div style="${textStyleStr};font-size:${scale}em;line-height:${scale < 1 ? 1 : 'inherit'};">${text}</div>`;
      }
      data = [
        {
          key: "asdf",
          value: 27,
        },
        {
            key: "zxcvxzc",
          value: 25,
        },
        
      ];
      config = {
        appendPadding:10,
        legend: false, // 图例
        animation: false,
        angleField: 'value',
        radius: 0.6,
        colorField: 'key',
        appendPadding: 10,
        innerRadius: 0.8,
        label: {
            type: 'spider',
            labelHeight: 28,
            content: (_re) => `${_re.name}\n${_re.value}`
        },
        interactions: [{ type: 'tooltip', enable: false }],
        data:this.data
    }

    componentDidMount() {
        document.title = "用户中心";
        this.setState({
            pageSize: Math.ceil((document.documentElement.clientHeight - 90) / 88) + 1
        }, this.getList);
CourseAPI.getBacklog()
          .then(data=>{
            console.log(data)
            let _data=[
              {'key':"课程",value:data.data.course},
              {'key':"测评",value:data.data.preview}
            ]
            let total=data.data.course+data.data.preview

            this.setState({configData:_data,partA:data.data.course,partB:data.data.preview,total})
            console.log(this.state.configData)
                let config = {
                        legend: false, // 图例
                        animation: false,
                        angleField: 'value',
                        radius: 1,
                        colorField: 'key',
                        color:['blue','#fadb14'],
                        appendPadding: 5,
                        innerRadius: 0.5,
                        label: {
                            type: 'inner',
                            labelHeight: 28,
                            content: (_re) => {return(`${_re.key}\n${_re.value}`)}
                        },
                        interactions: [{ type: 'tooltip', enable: false }],data:this.state.configData,
                        statistic: {
                          title: false,
                          content: {
                            style: {
                              whiteSpace: 'pre-wrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              fontSize:"13px"
                            },
                            content: '待办汇总',
                          }
                        }
                        }
            this.setState({config:config})
            console.log(this.state.config)
          })
         CourseAPI.getMyBusinessCourses()


    }


    getList = () => {
        const { type, pageSize } = this.state;
        this.setState({
            page: 1,
            loading: true
        }, () => {
            CourseAPI.getMyBusinessCourses(1, pageSize, type)
                .then(data => {
                    if (data.status===0){
                             this.setState({
                            courses: data.data.list,
                            hasMore: data.data.list.length === pageSize
                        });
                        }
                       
                    console.log(data)
                }).finally(() => this.setState({loading: false}))
        });
    }
   
   getpercentage=(arr)=>{
    let total=0
      for(let i=0;i<arr.length;i++){
        if(arr[i]==="y"){
           total++
        }
      }
      console.log(total)
      let percentage=(total/arr.length).toFixed(2)
      console.log(percentage)
      return  percentage
        
   }



    // TODO 获取更多
    appendList = () => {
        const { page, pageSize, type, courses } = this.state;
        this.setState({
            page: page + 1,
            loading: true
        }, () => {
            CourseAPI.getMyBusinessCourses(page + 1, pageSize, type)
                .then(data => {
                    if (data.status === 0) {
                        const temp = [...courses];
                        temp.push(data.data.list);
                        if(data.list.length!==0){
                         this.setState({
                            courses: temp,
                            hasMore: data.data.list.length === pageSize
                        });
                            
                            
                        }
                       
                    }
                }).finally(() => this.setState({loading: false}))
        })
    }

    changeTab(type){
        let TabClass = []
        TabClass[0] = this.state.TabClass[0]
        TabClass[1] = this.state.TabClass[1]
        if(type === '0'){
            TabClass[0] = 'Tab1 active'
            TabClass[1] = 'Tab2'
        }
        else{
            TabClass[0] = 'Tab1'
            TabClass[1] = 'Tab2 active'
        }
        this.setState({
            type,
            TabClass
        }, this.getList)
    }
    handelTab1=()=>{
        if(this.state.activeKey==="2"){
          this.setState({activeKey:"1",className1:"active",className2:"unactive",type:"1"},this.getList)
        }
    }

    handelTab2=()=>{
        if(this.state.activeKey==="1"){
        this.setState({activeKey:"2",className2:"active",className1:"unactive",type:"0"},this.getList)
        }
    }

    render() {
        const { courses, type, hasMore, loading } = this.state;
        const { userInfo } = this.props;
        console.log(courses)
        return (
            <div className="mycourse_total">
            <div className="mycourse_head" style={{backgroundImage:"url("+require("../../img/blue_img.png")+")"}}>

            </div>
                {/*<div className="mycourse_backlog">
                    <div className="mycourse_backlog_des">
                        <div style={{width:'144px',height:'137px',float:'left'}}>
                            <Pie {...this.config} />
                        </div>
                        <div style={{position:'absolute',left:'200px',top:'39px',width:'140px',height:'43px'}}>
                            <p style={{float:'left'}}>我的待办任务</p>
                            <div style={{position:'absolute',left:'0px',top:'43px',width:'100%',height:'20px'}}>
                                <p style={{float:'left'}}><div style={{width:'10px',height:'10px',borderRadius:'50%',background:'blue',display:'inline-block'}}></div>课程</p>
                                <p style={{float:'right'}}><div style={{width:'10px',height:'10px',borderRadius:'50%',background:'blue',display:'inline-block'}}></div>测评</p>
                            </div>
                        </div>
                    </div>
                </div>*/}
                <div class="mycourse_backlog">
                    <div style={{width:'140px',height:'140px',float:'left'}}>
                        {this.state.config?<Pie {...this.state.config} {...this.statistic} {...this.interactions}/>
                        :<Mask visible={true} opacity={0.8} color="white">
                           <SpinLoading style={{ '--size': '48px',margin:'auto',lineHeight:"200px"}} color='primary'/>
                        </Mask>
                        }
                    </div>
                    
                    <div style={{position:'absolute',left:'170px',top:'20px',width:'140px',height:'112px'}}>
                        <p style={{float:'left',fontSize:'18px'}}>我的待办任务:{this.state.total}</p>
                        <div style={{position:'absolute',left:'0px',top:'70px',width:'100%',height:'20px'}}>
                            <p style={{float:'left'}}><div style={{width:'10px',height:'10px',borderRadius:'50%',background:'blue',display:'inline-block'}}></div>课程&nbsp;{this.state.partA}</p>
                            <p style={{float:'left',marginLeft:'15px'}}><div style={{width:'10px',height:'10px',borderRadius:'50%',background:'#fadb14',display:'inline-block'}}></div>测评&nbsp;{this.state.partB}</p>
                        </div>
                    </div>
                </div>
                

                <div style={{width:"100%",height:"40px",background:"#3974F6",borderRadius:"20px",position:'relative',top:"13%"}}>
                <div  className={this.state.className1}  onClick={this.handelTab1}>进行中</div>
                <div  className={this.state.className2}  onClick={this.handelTab2}>已完成</div>
                </div>
                <div style={{position:"relative",top:"15%"}}>
                    {
                    this.state.activeKey==="1"? (
                                courses.map((item, index) => (
                                    // <div className="mycourse_courses"></div>
                                    <List.Item
                                        clickable={false}
                                        key={item.lesson.lessonName}
                                        onClick={ () => this.props.history.push("/course/"+item.lesson.id) }
                                        prefix={<div style={{width: "112px", height: "63px"}}><img src={item.lesson.lessonIcon} alt=""/></div>}
                                        description={
                                            <div style={{width:"80%",position:"relative"}}>
                                            <Ellipsis direction='end' rows={1} content={ item.lesson.lessonName} />
                                            <Ellipsis direction='end' rows={2} content={ item.lesson.lessonDescription} />
                                            <div style={{width:"30%",height:"20px",position:'absolute',top:"50%",right:'-45%',transform:"translateX(-50%)",background:"white"}}>
                                                {/*<div style={{height:"20px",background:"#3974F6",borderRadius:"30px",width:"30%"}}></div>*/}
                                                <p style={{position:'absolute',left:'0px'}}>{parseInt(this.getpercentage(item.lesson.progress)*100)}%</p>
                                                <ProgressBar
                                                    percent={parseInt(this.getpercentage(item.lesson.progress)*100)}
                                                    style={{
                                                      '--track-width': '20px',
                                                      '--fill-color': "#ffc53d"
                                                    }}
                                                  />
                                            </div>
                                            </div>
                                        }
                                    ></List.Item>
                                ))
                            ):
                            (
                             courses.map((item, index) => (
                                    <List.Item
                                        key={index}
                                        onClick={ () => this.props.history.push("/course/"+item.lesson.id) }
                                        prefix={<div style={{width: "112px", height: "63px"}}><img src={item.lesson.lessonIcon} alt=""/></div>}
                                        description={
                                            <Ellipsis direction='end' rows={2} content={ item.lesson.lessonDescription} />
                                        }
                                    >{item.lesson.lessonName}</List.Item>
                                ))
                                )
                }

                </div>
                
               {/*} <Tabs style={{'--content-padding': 0, backgroundColor: "#3974F6",color:'white',borderRadius:'20px',position:'relative',top:"13%",overflow:"hidden"}} type="card" activeKey={ type } onChange={ this.changeTab.bind(this)}>
                    <Tabs.Tab className={this.state.TabClass[1]} title='进行中' key='1'>
                        <List style={{'--border-top': "10px",position:'relative',top:'30%'}}>
                           
                        </List>
                    </Tabs.Tab>
                    <Tabs.Tab className={this.state.TabClass[0]} title='已完成' key='0'>
                        <List style={{'--border-top': "10px",position:'relative',top:"30%"}}>
                            {
                                courses.map((item, index) => (
                                    <List.Item
                                        key={index}
                                        onClick={ () => this.props.history.push("/course/"+item.lesson.id) }
                                        prefix={<div style={{width: "112px", height: "63px"}}><img src={item.lesson.lessonIcon} alt=""/></div>}
                                        description={
                                            <Ellipsis direction='end' rows={2} content={ item.lesson.lessonDescription} />
                                        }
                                    >{item.lesson.lessonName}</List.Item>
                                ))
                            }
                        </List>
                    </Tabs.Tab>
                </Tabs>*/}
                {
                    courses.length === 0 ? (
                        <Empty
                            style={{ padding: '64px 0', backgroundColor: "white",position:'relative',top:'13%' }}
                            imageStyle={{ width: 128 }}
                            description='暂无数据'
                        />
                    ):(hasMore ? (
                        <div className="getMore">
                            {
                                loading ? (
                                    <span style={{ fontSize: 18 }}>
                                        <DotLoading /> 加载中...
                                    </span>
                                ): (
                                    <span onClick={ this.appendList } style={{ fontSize: 18 }}>
                                        点击此处获取更多
                                    </span>
                                )
                            }
                        </div>
                    ): (
                        <Divider style={{position:"relative",top:"20%"}}>我有底线的</Divider>
                    ))
                }
            </div>
        );
    }
}

export default connect(
    ({ login }) => ({
        isLogin: login.isLogin,
        userInfo: login.userInfo
    })
)(withRouter(MyCourse2));