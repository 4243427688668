import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { message } from 'antd';
import { createStore, applyMiddleware, compose } from 'redux';
import {ConfigProvider} from "antd";
import moment from 'moment';
import reducer from './reducers'
import 'antd-mobile-v2/dist/antd-mobile.css';
import './index.css';
import "moment/locale/zh-cn"
import zhCN from 'antd/lib/locale/zh_CN';

moment.locale("zh_cn")
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const logger = store => next => action => {
  
  let result = next(action)
  
  return result
}

message.config({
  top: 20,
  duration: 2,
  maxCount: 1,
});

const store = createStore(
  reducer,
  composeEnhancers(
    applyMiddleware(logger)
  )
)

ReactDOM.render(<ConfigProvider locale={zhCN}><Provider store={store}><BrowserRouter><App /></BrowserRouter></Provider></ConfigProvider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
