import React, { Component } from 'react';
import '../css/suntwo.css';
import {Link} from "react-router-dom";

class Sunfour extends Component {

    render(){
        return(
            <div className='bacfour'>
                <Link to='Sun'><div className="twoone"></div></Link>
                <Link to='Sunthree'><div className="twotwo"></div></Link>
                <Link to='Suntwo'><div className="twothree"></div></Link>
               <div className="twofour"></div>
                <Link to='Anim'> <div className='twobottom'></div></Link>
            </div>

        )
    }
}
export default Sunfour
