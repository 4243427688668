import React, { Component } from "react";
import * as API from "../API/CoursesAPI";
import { withRouter } from 'react-router-dom';
import * as CourseAPI from "../API/CoursesAPI";
import { CapsuleTabs } from "antd-mobile";
import Loading from "../component/Loading/Loading";
import "../css/courseList.scss";
import { Select, Input, Modal } from "antd"
import { SideBar } from 'antd-mobile'
import { ToTopOutlined } from '@ant-design/icons';
const { Option } = Select;
const { Search } = Input;

class CourseList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            courses: [],
            type1s: [
                { id: 0, categoryName: "全部" }
            ],
            type1Index: "0",
            type1Name: "全部",
            type2s: [
                { id: 0, categoryName: "全部" }
            ],
            type2Index: "0",
            type2Name: "全部",
            showType: null,
            showModal: false,
            coursesType: [0],
            openType2: false,
            page: 1,
        }
    }

    componentDidMount() {
        window.addEventListener('scroll', this.bindHandleScroll)
        window.addEventListener('touchmove', this.bindHandleScroll)
        document.title = "精品微课";
        CourseAPI.getDeep()
            .then(_data => {
                if (_data.status === 0) {
                    const types = [..._data.data];
                    types.unshift({ id: 0, categoryName: "全部" });
                    this.setState({
                        type1s: types
                    }, this.updateLessons([0], 1));
                }
            }).finally(() => this.setState({ loading: false }));
    }
    componentWillUnmount() {
        window.removeEventListener('scroll', this.bindHandleScroll);
        window.addEventListener('touchmove', this.bindHandleScroll);
    }

    bindHandleScroll = (event) => {
        const scrollTop = document.documentElement.scrollTop || window.pageYOffset || document.body.scrollTop
        const scrollHeight = document.body.scrollHeight
        const clientHeight = document.body.clientHeight
            || window.pageYOffset
            || (event.srcElement ? event.srcElement.body.scrollTop : 0);

        if (scrollTop > 100) {
            this.title.style.position = "fixed"
            this.title.style.height = "80px"
        } else {
            this.title.style.position = "static"
        }
        if (scrollHeight - (scrollTop + clientHeight) < 10) {
            let kk = this.state.page
            kk++
            this.setState({ page: kk })
            API.getCourseList(this.state.coursesType, kk).then(data => {
                if (data.status === 0) {
                    let copyCourses = [...this.state.courses]
                    let copy = [...data.data.lessons]
                    this.setState({ courses: copyCourses.concat(copy) })
                }


            })
        }
    }
    changeType1 = index => {
        if (index === "0") {
            this.setState({
                type1Index: "0",
                type1Name: "全部",
                type2s: [
                    { id: 0, categoryName: "全部" }
                ],
                type2Index: "0",
                type2Name: "全部"
            });
        } else {
            const type1s = this.state.type1s;
            const temp = [...type1s[index].children];
            temp.unshift({ id: 0, categoryName: "全部" });
            this.setState({
                type1Index: index,
                type1Name: type1s[index].categoryName,
                type2s: temp,
                type2Index: "0",
                type2Name: "全部"
            });
        }

        if (index == 0) {
            this.setState({ coursesType: [0] })
            this.updateLessons([0], 1)
        } else {
            let copy = [...this.state.type1s[index].children]
            let coursesType = [];
            for (let i in copy) {
                coursesType.push(copy[i].id)
            }
            this.setState({ coursesType: coursesType, openType2: true })
            this.updateLessons(coursesType, 1)
        }

    };

    changeType2 = index => {
        this.setState({
            type2Index: index,
            type2Name: this.state.type2s[index].categoryName,
            openType2: !this.state.openType2
        }, () => {
            if (index !== "0") {
                let copy = [this.state.type2s[index].id]
                this.updateLessons(copy, 1)
            } else {
                let copy1 = [...this.state.type2s]
                let coursesType = []
                for (let i = 1; i < this.state.type2s.length; i++) {
                    coursesType.push(copy1[i].id)
                }
                this.setState({ coursesType: coursesType })
                this.updateLessons(coursesType, 1)
            }
            this.ref.blur()
        });
    };
    handleOptionClick = (e) => {
        console.log(e)
        e.stopPropagation();
        this.setState({ openType2: !this.state.openType2 },)
    }

    updateLessons = (type, page) => {
        this.setState({
            loading: true
        }, () => {
            API.getCourseList(type, page).then(data => {
                if (data.status === 0)
                    this.setState({ courses: data.data.lessons },console.log(data));
            }).finally(() => this.setState({ loading: false }));
        });
    }

    render() {
        const { loading, courses, type1s, type1Index, type1Name, type2s, type2Index, type2Name } = this.state;
        return (
            <div className="courseList">
                <div className="type-select" ref={(title) => this.title = title}>
                    <div style={{ width: "50px", height: "25px", background: "#1677ff", borderRadius: "5px", color: "white", textAlign: "center", lineHeight: "25px", float: "left", marginTop: "4px", marginLeft: "5px" }} onClick={() => this.setState({ showModal: true })}>选择</div>
                    <Modal title="选择课程类型" visible={this.state.showModal} onOk={() => this.setState({ showModal: false })} onCancel={() => this.setState({ showModal: false, openType2: false })}
                        footer={null}
                    >
                        <Select activeKey={type1Index} placeholder="全部类型" style={{ width: "40%", marginRight: "5%", marginLeft: "5%" }} onChange={index => { if (index !== type1Index) this.changeType1(index) }} dropdownMatchSelectWidth={true} listHeight={250}>
                            {
                                type1s.map((item, index) => (
                                    <Option style={type1s.length === 1 ? { maxWidth: "100%" } : {}} title={item.categoryName} key={index} >{item.categoryName}</Option>
                                ))
                            }
                        </Select>
                        <Select activeKey={type2Index} placeholder="全部类型" style={{ width: "40%", marginLeft: "5%" }}
                            onSelect={index => { this.changeType2(index) }}
                            open={this.state.openType2} onBlur={() => this.setState({ openType2: false })}
                            onFocus={() => this.setState({ openType2: true }, () => console.log("aaa"))} ref={e => this.ref = e}>
                            {
                                type2s.map((item, index) => (
                                    <Option style={type2s.length === 1 ? { maxWidth: "120px" } : {}} title={item.categoryName} key={index} >{item.categoryName}</Option>
                                ))
                            }
                        </Select>
                    </Modal>

                    <div style={{ float: "left", height: "20px", marginLeft: "15px" }}><Search placeholder="search course" style={{ width: 270 }} onClick={() => this.props.history.push("/search_result")} /></div>
                    <div style={{ float: "left", marginTop: "20px", fontSize: "12px", marginBottom: "10px", marginLeft: "-50px", width: "200px" }}>{this.state.type1Name === "全部" ? "全部类型" : ("已选择:" + this.state.type1Name + ">" + this.state.type2Name)}</div>
                    {this.state.courses && <div style={{ float: "right", marginTop: "20px", marginBottom: "10px", marginRight: "10px" }}>{"共" + this.state.courses.length + "个课程"}</div>}
                </div>
                <div className="courses">
                    {
                        this.state.courses && courses.map((item, index) => (
                            <div className="course-item" key={index} onClick={() => this.props.history.push("/course/" + item.id)}>
                                <div className="course-index"
                                    style={{
                                        width: `${((document.documentElement.offsetWidth - 20) / 2 - 20)}px`,
                                        height: `${((document.documentElement.offsetWidth - 20) / 2 - 20) / (16 / 9)}px`,
                                    }}>
                                    <img src={item.lessonIcon} alt="" />
                                </div>
                                <p className="lessonName">{item.lessonName}</p>
                                <p className="lessonPrice">¥{item.lessonPrice}</p>
                            </div>
                        ))
                    }
                </div>
                <Loading loading={loading} />
            </div>
        )
    }
}

export default withRouter(CourseList);