import React, {Component} from 'react';
import {connect} from 'react-redux';
import {setAgree} from '../../action';
import {withRouter} from 'react-router-dom';
import {Button} from 'antd-mobile';
import "./agreement.scss";

class Agreement extends Component {

    constructor(props) {
        super(props);
        this.state = {
            agreable: false,
            time: 15
        };
    }

    componentDidMount() {
        this.timer = window.setInterval(() => {
            this.setState({time: this.state.time - 1});
        }, 1000);
        this.timer2 = window.setTimeout(() => {
            this.setState({time: 0, agreable: true});
        }, 15000);
    }

    componentWillUnmount() {
        clearInterval(this.timer);
        clearTimeout(this.timer2);
    }

    render() {
        const { agreable, time } = this.state;
        return (
            <div className="agreement">
                <h2 className="agree-title">《微训网服务协议》</h2>
                <div className="agreement-content">
                    <p >欢迎您使用微训网的网站及服务！</p>

                    <p >请您认真阅读并遵守《微训网服务协议》（以下简称“本协议”）以及&nbsp;《微训网隐私权政策》&nbsp;请您务必审慎阅读、充分理解各条款内容，特别是免除或者限制责任的条款、争议解决和法律适用条款。我们特别提醒您，关于免除或者限制责任的条款，您应重点阅读。</p>

                    <p >当您按照注册页面提示填写信息、阅读并同意本协议且完成全部注册程序后，或您按照激活页面提示填写信息、阅读并同意本协议且完成全部激活程序后，或您以其他微训网允许的方式实际使用本服务时，即表示您已充分阅读、理解并接受本协议的全部内容，本协议即产生法律约束力。您承诺接受并遵守本协议的约定，届时您不应以未阅读本协议的内容或者未获得微训网对您问询的解答等理由，主张本协议无效或要求撤销本协议。</p>

                    <p ><b>一、协议缔约主体</b></p>

                    <p >本协议由通过微训网网站、移动客户端、微信小程序及PC端微训网应用软件、以及其他方式使用微训网服务的用户（以下简称“用户”或“您”）与北京优幕科技有限责任公司、厦门优鹿互动科技有限责任公司（以下合称“微训网公司”、“微训网 ”或“我们”）共同缔结。</p>

                    <p >&nbsp;</p>

                    <p ><b>二、协议内容和效力</b></p>

                    <p >1、本协议内容包括本协议正文及所有我们已经发布或将来可能发布的隐私权政策、各项政策、规则、声明、通知、警示、提示、说明（以下简称“规则”）。前述规则为本协议不可分割的组成部分，与本协议具有同等法律效力。</p>

                    <p >2、微训网公司有权根据需要不时制订、修改本协议及相关规则，变更后的协议和规则将通过 <a href="http://www.wx798.com">微训网官方网站</a>提前七（7）天公告的形式通知您并就并经公告完毕后即取代原协议及规则生效。如您不同意相关变更，应当立即停止使用微训网服务；如您继续使用微训网服务或进行任何网站活动，即表示您已接受经修订的协议和规则。</p>

                    <p >&nbsp;</p>

                    <p ><b>三、服务内容</b></p>

                    <p >1、微训网产品和服务包括微训网官网（域名为wx798、微训网客户端App及微训网移动网页版https://learning.wx798.com以及微训网微信小程序（微训网拍照、微训网签到、微训网互动）及将微训网部分功能 /服务作为第三方服务集成在微训网公司关联公司（见定义条款）和/或其他第三方智能硬件、软件或服务中（“微训网服务”，具体以微训网公司提供的为准）。微训网公司会不断丰富您使用本服务的终端、形式等。如您已注册使用一种形式的服务，则可以以同一账号使用其他服务，本协议自动适用于您对所有版本的软件和服务的使用。
                    </p>

                    <p >2、微训网公司依据本协议、
                        《微训网隐私权政策 》以及其他适用的规则，许可您对微训网软件进行不可转让的、非排他性的使用。您可以制作本软件的一个副本，仅用作备份。备份副本必须包含原软件中含有的所有著作权信息，本条及本协议其他条款未明示授权的其他一切权利仍由微训网公司保留，您在行使这些权利时须另外取得微训网公司的书面许可。微训网公司如果未行使前述任何权利，并不构成对该权利的放弃。
                    </p>

                    <p >3、本服务内容包含支持视频微课、视频训练、视频会议教学、互动式直播教学等技术功能，这些功能服务可能根据用户需求的变化，随着因服务版本不同、或服务提供方的单方判断而被优化或修改，或因定期、不定期的维护而暂缓提供。
                    </p>

                    <p >4、微训网公司有权自行决定对服务或服务任何部分及其相关功能、应用软件进行变更、升级、修改、转移，并在微训网官网（微训网）上公示通知。</p>

                    <p >5、经企业组织书面授权，您可以代表企业组织申请管理员账号，通过微训网公司审核之后可通过该管理员账号上传和管理您所在企业组织的企业组织子账号，邀请企业内的成员加入相应企业组织群组，并通过微训网服务实现企业组织内部成员培训、学习互动、协同教学等场景。企业组织及授权企业管理员在对您的企业组织成员进行身份管理之前，应向您的企业组织成员充分说明基于企业管理需求所开通的微训网相关服务。
                    </p>

                    <p >6、如果您在使用本服务时接受邀请成为某个企业组织的成员并维持该身份时，您可以享受微训网为该企业组织及其成员提供的工作圈服务内容，包括成员通信录、语音服务、应用服务等（以微训网公司实际提供的企业版学习平台功能及企业组织或群组开通情况为准）。您所在企业组织及其管理员基于企业管理需求，对您的企业组织成员身份及基于该身份的相关权益进行管理，包括但不限于加入、删除以及调整权限和限制服务内容等。您保证在遵守微训网服务规范的同时，按照企业组织的授权范围和规范使用微训网服务。
                    </p>

                    <p >&nbsp;</p>

                    <p ><b>四、注册及账号管理</b></p>

                    <p >1、您确认，在您完成注册程序或以其他微训网公司允许的方式实际使用服务时，您应当是具备完全民事权利能力和与所从事的民事行为相适应的行为能力的自然人、法人或其他组织。若您不具备前述主体资格，请勿使用服务，否则您及您的监护人应承担因此而导致的一切后果，且微训网公司有权注销（永久冻结）您的账户，并向您及您的监护人索偿。如您代表一家企业、组织机构或其他法律主体进行注册或以其他微训网公司允许的方式实际使用本服务，则您声明和保证，您已经获得充分授权并有权代表该公司、组织机构或法律主体注册使用微训网服务，并受本协议约束。
                    </p>

                    <p >2、您可以使用您提供或确认的手机号或者微训网允许的其它方式作为账号进行注册
                        ,
                        您注册时应提交真实、准确、完整和反映当前情况的身份及其他相关信息，同时系统将为你自动分配一个微训网互动号码，该互动号码目前不提供个性化定制。你理解并同意，您承诺注册的账号名称、头像和简介等信息中不得出现违法和不良信息，不得冒充他人，不得未经许可为他人注册，不得以可能导致其他用户误认的方式注册账号，不得使用可能侵犯他人权益的用户名（包括不限于涉嫌商标权、名誉权侵权等），否则微训网公司有权不予注册或停止服务并收回账号，因此产生的损失由您自行承担。
                    </p>

                    <p >3、您了解并同意，微训网注册账号（包括客户端账号及服务管理账号）所有权归属于微训网公司，注册完成后，您仅获得账号使用权。微训网账号使用权仅归属于初始申请注册人，不得以任何方式转让或被提供予他人使用，否则，微训网公司有权立即不经通知收回该账号，由此带来的因您使用本服务产生的全部数据、信息等被清空、丢失等的损失，您应自行承担。
                    </p>

                    <p >4、在您成功注册后，微训网将根据您的身份要素识别您的身份和授权登录。“身份要素”包括但不限于您的账户名称、密码、短信校验码、手机号码、身份证件号码及人脸信息、眼纹信息。您同意基于不同的终端以及您的使用习惯，微训网可能采取不同的验证措施识别您的身份。例如您的账户在新设备首次登录的，我们可能通过密码加短信校验码或人脸验证的方式确认您的身份。请您务必妥善保管您的终端及账户、密码和
                        /或身份要素信息，并对利用该账户和密码/身份信息所进行的一切活动（包括但不限于网上点击同意或提交各类规则协议或购买服务、分享信息或图片、发起视频会议、直播等）负全部责任。您承诺，在密码或账户遭到未获授权的使用，或者发生其他任何安全问题时，将立即通知微训网公司，且您同意并确认，除法律另有规定外，微训网公司不对上述情形产生的任何直接或间接的遗失或损害承担责任。除非有法律规定或司法裁定，且征得微训网公司的同意，否则，您的账户、密码不得以任何方式转让、赠与或继承（与账户相关的财产权益除外）。
                    </p>

                    <p >5、您了解并同意，如您注册微训网账号后连续超过
                        12个月未登录，微训网公司为优化管理等目的，有权回收该账号。</p>

                    <p >6、如您注销微训网或停止使用服务时，微训网公司有权自行对账号相关内容及信息以包括但不限于删除等方式进行处理，且无需就此向用户承担任何责任；如果账号为管理员账号，微训网公司有权取消和删除该管理员账号以及通信录成员基于该企业的一切信息和相关权益，包括但不限于解除与企业之间的服务关系、解散企业子账号关联关系、取消企业版成员与该企业相关的权益、删除管理员账号中的所有信息、删除企业通信录成员基于企业的所有信息等，您或你您代表的企业组织也有义务及时删去存储在微训网服务器上的所有企业信息。
                    </p>

                    <p >&nbsp;</p>

                    <p ><b>五、服务使用规范</b></p>

                    <p >1、您充分了解并同意，微训网仅为用户提供在线服务平台，您应自行对利用在线服务从事的所有行为及结果承担责任。相应地，您应了解，使用微训网服务可能发生来自他人非法或不当行为（或信息）的风险，您应自行判断及行动，并自行承担相应的风险。
                    </p>

                    <p >2、您应自行承担因使用服务所产生的费用，包括但不限于上网流量费、通信服务费等。
                    </p>

                    <p >3、除非另有说明，本协议项下的服务只能用于非商业用途。您承诺不对本服务任何部分或本服务之使用或获得，进行复制、拷贝、出售、转售或用于包括但不限于广告及任何其它商业目的。
                    </p>

                    <p >4、您承诺不会利用本服务进行任何违法或不当的活动，包括但不限于下列行为
                        :</p>

                    <p >4.1 上载、传送或分享含有下列内容之一的信息：
                    </p>

                    <p >(a) 反对宪法所确定的基本原则的；</p>

                    <p >(b) 危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；
                    </p>

                    <p >(c) 损害国家荣誉和利益的；</p>

                    <p >(d) 煽动民族仇恨、民族歧视、破坏民族团结的；
                    </p>

                    <p >(e) 破坏国家宗教政策，宣扬邪教和封建迷信的；
                    </p>

                    <p >(f) 散布谣言，扰乱社会秩序，破坏社会稳定的；
                    </p>

                    <p >(g) 散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；
                    </p>

                    <p >(h) 侮辱或者诽谤他人，侵害他人合法权利的；
                    </p>

                    <p >(i) 含有虚假、诈骗、有害、胁迫、侵害他人隐私、骚扰、侵害、中伤、粗俗、猥亵、或其它道德上令人反感的内容；
                    </p>

                    <p >(j) 含有中国或其您所在国国家管辖法所适用的法律、法规、规章、条例以及任何具有法律效力之规范所限制或禁止的其它内容的；
                    </p>

                    <p >4.2 冒充任何人或机构，或以虚伪不实的方式陈述或谎称与任何人或机构有关；
                    </p>

                    <p >4.3 伪造标题或以其他方式操控识别资料，使人误认为该内容为微训网公司或其关联公司所传送；
                    </p>

                    <p >4.4
                        将依据任何法律或合约或法定关系（例如由于雇佣关系和依据保密合约所得知或揭露之内部资料、专属及机密资料）知悉但无权传送之任何内容加以上载、传送或分享；
                    </p>

                    <p >4.5 将涉嫌侵害他人权利（包括但不限于著作权、专利权、商标权、商业秘密等知识产权）之内容上载、传送或分享；
                    </p>

                    <p >4.6 跟踪或以其它方式骚扰他人, 或通过本服务向好友或其他用户发送大量信息；
                    </p>

                    <p >4.7
                        将任何广告、推广信息、促销资料、“垃圾邮件”、“滥发信件”、“连锁信件”、“直销”或其它任何形式的劝诱资料加以上载、传送或分享；供前述目的使用的专用区域或专用功能除外；
                    </p>

                    <p >4.8
                        因本服务并非为某些特定目的而设计，您不可将本服务用于包括但不限于核设施、军事用途、医疗设施、交通通讯等重要领域。如果因为软件或服务的原因导致上述操作失败而带来的人员伤亡、财产损失和环境破坏等，微训网公司不承担法律责任；
                    </p>

                    <p >4.9 进行任何超出正常的好友或用户之间内部或外部信息沟通、交流等目的的行为；
                    </p>

                    <p >4.10
                        出于超出正常关注或用户之间内部或外部信息沟通、交流等目的（包括但不限于为发送广告、垃圾、骚扰或违法违规等信息的目的），通过自己添加或诱导他人添加等任何方式使自己与其他用户形成关注关系（关注关系包括但不限于单向关注关系和双向关注关系，下同）；
                    </p>

                    <p >4.11 违反遵守法律法规、社会主义制度、国家利益、公民合法利益、公共秩序、社会道德风尚和信息真实性等“七条底线”要求的行为；
                    </p>

                    <p >4.12 从事任何违反中国法律、法规、规章、政策及规范性文件的行为。
                    </p>

                    <p >5、您承诺不对本软件和服务从事以下行为：
                    </p>

                    <p >5.1
                        将有关干扰、破坏或限制任何计算机软件、硬件或通讯设备功能的软件病毒或其他计算机代码、档案和程序之资料，加以上载、张贴、发送电子邮件或以其他方式传送；
                    </p>

                    <p >5.2 干扰或破坏本服务或与本服务相连线之服务器和网络，或违反任何关于本服务连线网络之规定、程序、政策或规范；
                    </p>

                    <p >5.3
                        通过修改或伪造软件运行中的指令、数据，增加、删减、变动软件的功能或运行效果，或者将用于上述用途的软件、方法进行运营或向公众传播，无论这些行为是否为商业目的；
                    </p>

                    <p >5.4 通过非微训网公司开发、授权的第三方软件、插件、外挂、系统，登录或使用软件及服务，或制作、发布、传播上述工具；
                    </p>

                    <p >5.5 自行、授权他人或利用第三方软件对本软件及其组件、模块、数据等进行干扰。
                    </p>

                    <p >6、您承诺，使用微训网服务时您将严格遵守本协议。
                    </p>

                    <p >7、您同意并接受微训网公司有权对您使用服务的情况进行审查、监督并采取相应行动，包括但不限于删除信息、中止或终止服务，及向有关机关报告。
                    </p>

                    <p >8、您承诺不以任何形式使用本服务侵犯微训网公司的商业利益，或从事任何可能对微训网造成损害或不利于微训网的行为。
                    </p>

                    <p >9、您了解并同意，在您使用微训网服务过程中，微训网公司及其关联公司自行或由其他第三方合作伙伴向您发送广告、推广或宣传信息（包括商业或非商业信息）的，微训网公司将依照法律的规定对广告商履行相关义务（包括向您提供选择关闭广告信息的功能），您应当自行判断广告信息的真实性可可靠性并为自己的判断行为负责；除法律明确规定外，您因依该广告信息进行的交易或前述广告商提供的内容而遭受的损失或损害，微训网公司不承担责任。
                    </p>

                    <p >10、如您作为企业组织用户申请管理员账号或子管理员账号，则应自行添加、指定或变更管理成员和
                        /或子管理员，并对管理成员的行为自行承担责任；微训网根据您管理员/子管理员账号的行为对所在企业组织成员服务的调整亦由您承担后果，因此给微训网公司或他人造成损失的，您应当予以赔偿。
                    </p>

                    <p >11、如您在微训网设置课程收费、赞赏功能功能，应当依照法律、法规等规范性文件的规定开展教学活动，不得以非法方式使用收费、赞赏功能进行非课程服务收付、转账，也不得将课程收费、赞赏功能用于实施非法活动，例如实施赌博、非法吸收公众存款、集资诈骗、开展传销等违法犯罪行为，或为违法犯罪行为提供协助。因此给微训网公司或他人造成损失的，您应当予以赔偿。
                    </p>

                    <p >12、您充分了解并同意，您必须为自己注册帐号下的一切行为负责，包括您所发表的任何内容以及由此产生的任何后果。您应对使用本服务时接触到的内容与服务自行加以判断，并承担因使用内容与服务而引起的所有风险，包括因对内容的正确性、完整性或实用性的依赖而产生的风险。微训网公司无法且不会对您因前述风险而导致的任何损失或损害承担责任。
                    </p>

                    <p >13、如果微训网公司发现或收到他人举报您有违反本协议约定的，微训网公司有权依照相关法律法规的规定对相关举报内容核实、转通知以及删除、屏蔽等措施，以及采取包括但不限于收回账号，限制、暂停、终止您使用部分或全部本服务，追究法律责任等措施。
                    </p>

                    <p >&nbsp;</p>

                    <p ><b>六、服务费用</b></p>

                    <p >1、本服务的任何免费试用或免费功能和服务不应视为微训网公司放弃后续收费的权利。微训网公司有权提前
                        7天在微训网官网上和/或具体微训网服务应用内以公告的形式通知您收费标准及收费方式。若您继续使用相关微训网服务，则需按微训网公司公布的收费标准支付相应费用。
                    </p>

                    <p >2、所有费用需通过微训网接受的支付方式事先支付。前述使用费不包含其它任何税款、费用或相关汇款等支出，否则您应补足付款或自行支付该费用。
                    </p>

                    <p >3、微训网有权根据实际情况提前7天单方调整费用标准及收费方式，并以公告形式通知您，而无需获得您的事先同意，如您不同意收费应当立即停止服务的使用，否则使用则视为您已同意并应当缴纳费用。
                    </p>

                    <p >4、您应当自行支付使用本服务可能产生的上网费以及其他第三方收取的通讯费、信息费等。
                    </p>

                    <p >5、微训网收取的服务费用是基于您所购买的服务或内容，而非您实际使用的服务或内容。您应当及时履行支付义务，该支付义务不可取消。服务或内容开通后，您所支付的费用不可退还。您购买的服务或内容数量在相关订阅期内也不能减少或退订。
                    </p>

                    <p >&nbsp;</p>

                    <p ><b>七、特别授权</b></p>

                    <p >1、当您向微训网公司及关联公司作出任何形式的承诺，且相关公司已确认您违反了该承诺，则微训网公司有权立即按您的承诺约定的方式对您的账户采取限制措施，包括但不限于中止或终止向您提供服务，并公示相关公司确认的您的违约情况。您了解并同意，微训网公司无须就相关确认与您核对事实，或另行征得您的同意，且微训网公司无须就此限制措施或公示行为向您承担任何的责任。
                    </p>

                    <p >2、一旦您违反本协议，或与微训网公司签订的其他协议的约定，微训网公司有权以任何方式通知微训网公司关联公司，要求其对您的权益采取限制措施，包括但不限于要求微训网公司关联公司中止、终止对您提供部分或全部服务，且在其经营或实际控制的任何网站公示您的违约情况。
                    </p>

                    <p >&nbsp;</p>

                    <p ><b>八、第三方应用及服务</b></p>

                    <p >1、微训网允许第三方通过微训网提供教学服务，微训网亦通过与微信、支付宝合作，由微信、支付宝就微训网课程收费及赞赏功能向您提供收付、赞赏红包等支付相关功能，微训网通过引入电信运营商服务以便您使用微训网互动式直播和视频会议服务，以及与接入第三方表情包服务以便于您使用静态
                        /动态表情包服务。您了解并同意，微训网仅作为平台提供者，微训网仅为了您便利操作而提供该功能模块或服务订购和
                        /或使用入口，相关应用由该第三方独立提供，除法律法规和本协议另有规定外，微训网公司不对您对该应用的使用承担任何责任。
                    </p>

                    <p >2、您了解并同意，除法律另有明确规定外，如我们对微训网服务及第三方服务做出调整、中止或终止而对第三方应用服务产生影响的，微训网公司不承担相应责任。
                    </p>

                    <p >3、您通过第三方应用或服务使用微训网时，微训网可能会调用第三方系统或者通过第三方支持您的使用或访问，使用或访问的结果由该第三方提供。
                    </p>

                    <p >4、您理解并同意，您在使用微训网服务中的第三方应用及服务时，除遵守本协议的约定外，还应遵守第三方用户协议。您在选择使用第三服务前应充分了解第三方服务的产品功能、服务协议及隐私保护政策，再选择是否开通功能并遵守第三方用户协议。
                    </p>

                    <p >&nbsp;</p>

                    <p ><b>九、服务中止或终止</b></p>

                    <p >1、鉴于互联网服务的特殊性，微训网公司有权对服务内容进行变更，也可能会中断、中止或终止全部或者任一部分的服务，包括免费服务和
                        /或收费服务，并在微训网官网上以公示的形式通知您。</p>

                    <p >2、您了解并同意，微训网公司可能定期或不定期地对提供网络服务的平台设备、设施和软硬件进行维护或检修，如因此类情况而造成收费服务在合理时间内中止，微训网公司无需承担责任，但应事先在微训网官网或微训网客户服务
                        QQ群等以公示的形式通告您。</p>

                    <p >3、如存在下列违约情形之一，微训网公司有权立即对您中止或终止服务，并要求您赔偿损失：
                    </p>

                    <p >3.1 用户违反第四条之注册义务；</p>

                    <p >3.2 用户使用收费服务时未按规定支付相应服务费；
                    </p>

                    <p >3.3 用户违反第五条服务使用规范之规定。
                    </p>

                    <p >&nbsp;</p>

                    <p ><b>十、隐私政策</b></p>

                    <p >1、您在微训网服务注册的账户具有密码保护功能，以确保您基本信息资料的安全，请您妥善保管账户及密码信息。
                    </p>

                    <p >2、微训网公司努力采取各种合理的物理、电子和管理方面的安全措施来保护您的信息，使您存储在微训网中的信息和通信内容不会被泄漏、毁损或者丢失，包括但不限于
                        SSL、信息加密存储、数据中心的访问控制。我们对可能接触到信息的员工或外包人员也采取了严格管理，包括但不限于根据岗位的不同采取不同的权限控制，与他们签署保密协议，监控他们的操作情况等措施。微训网会按现有技术提供相应的安全措施来保护您的信息，提供合理的安全保障，微训网将在任何时候尽力做到使您的信息不被泄漏、毁损或丢失，但同时也请您注意在信息网络上不存在绝对完善的安
                    </p>

                    <p >全措施，请妥善保管好相关信息。</p>

                    <p >3、您应当保管好终端、账号及密码，并妥善保管相关信息和内容。因您自身原因导致的数据丢失或被盗以及在本软件及服务中相关数据的删除或储存失败的责任由您自行承担。
                    </p>

                    <p >4、其他隐私条款见 《 微训网隐私权政策 》。</p>

                    <p >&nbsp;</p>

                    <p ><b>十一、知识产权</b></p>

                    <p >1、您了解及同意，除非微训网公司另行声明，本协议项下服务包含的所有产品、技术、软件、程序、数据及其他信息（包括但不限于文字、图像、图片、照片、音频、视频、图表、色彩、版面设计、电子文档）的所有知识产权（包括但不限于版权、商标权、专利权、商业秘密等）及相关权利均归微训网公司或其关联公司所有。
                    </p>

                    <p >2、您应保证，除非取得微训网公司书面授权，对于上述权利您不得（并不得允许任何第三人）实施包括但不限于出租、出借、出售、散布、复制、修改、转载、汇编、发表、出版、还原工程、反向汇编、反向编译，或以其它方式发现原始码等的行为。
                    </p>

                    <p >3、微训网的Logo、“微训网
                        ”、
                        “优幕”、“优鹿互动”、“微训网互动”等文字、图形及其组合，以及微训网其他标识、徽记、微训网服务的名称等为微训网公司及其关联公司在中国和其他国家的注册商标。未经微训网公司书面授权，任何人不得以任何方式展示、使用或做其他处理（包括但不限于复制、传播、展示、镜像、上传、下载），也不得向他人表明您有权展示、使用或做其他处理。
                    </p>

                    <p >4、微训网所有的产品、服务、技术与所有应用程序或其组件
                        /功能/名称（以下或简称“技术服务”）的知识产权均归属于微训网公司所有或归其权利人所有。
                    </p>

                    <p >5、您理解并同意授权微训网公司在宣传和推广中使用您的名称、商标、标识，但仅限于表明您属于我们的客户或合作伙伴。如果您希望限制您的名称、商标、标识被微训网使用，请与我们联系。
                    </p>

                    <p >&nbsp;</p>

                    <p ><b>十二、有限责任</b></p>

                    <p >1、服务将按&quot;现状
                        &quot;和按&quot;可得到&quot;的状态提供。微训网公司在此明确声明对服务不作任何明示或暗示的保证，包括但不限于对服务的可适用性，没有错误或疏漏，持续性，准确性，可靠性，适用于某一特定用途之类的保证，声明或承诺。
                    </p>

                    <p >2、微训网对服务所涉的技术和信息的有效性，准确性，正确性，可靠性，质量，稳定，完整和及时性均不作承诺和保证。
                    </p>

                    <p >3、不论在何种情况下，微训网均不对由于Internet连接故障，电脑，通讯或其他系统的故障，电力故障，罢工，劳动争议，暴乱，起义，骚乱，疫情、生产力或生产资料不足，火灾，洪水，风暴，爆炸，不可抗力，战争，政府行为，国际、国内法院的命令或第三方的不作为而造成的不能服务或延迟服务承担责任。
                    </p>

                    <p >4、不论是否可以预见，不论是源于何种形式的行为，微训网不对由以下原因造成的任何特别的，直接的，间接的，惩罚性的，突发性的或有因果关系的损害或其他任何损害（包括但不限于利润或利息的损失，营业中止，资料灭失）承担责任：
                    </p>

                    <p >4.1 使用或不能使用服务；</p>

                    <p >4.2 通过服务购买或获取任何产品，样品，数据，信息或进行交易等，或其他可替代上述行为的行为而产生的费用；
                    </p>

                    <p >4.3 未经授权的存取或修改数据或数据的传输；
                    </p>

                    <p >4.4 第三方通过服务所作的陈述或行为；
                    </p>

                    <p >4.5 其它与服务相关事件，包括疏忽等，所造成的损害。</p>

                    <p >5、您充分了解并同意，鉴于互联网体制及环境的特殊性，您在服务中分享的信息及个人资料有可能会被他人复制、转载、擅改或做其它非法用途；您在此已充分意识此类风险的存在，并确认此等风险应完全由您自行承担，微训网对此不承担任何责任。</p>

                    <p >6、您了解并同意，在使用服务过程中可能存在来自任何他人的包括威胁性的、诽谤性的、令人反感的或非法的内容或行为或对他人权利的侵犯（包括知识产权）及匿名或冒名的信息的风险，基于服务使用规范所述，该等风险应由您自行承担，微训网对此不承担任何责任。</p>

                    <p >&nbsp;</p>

                    <p ><b>十三、违约责任及赔偿</b></p>

                    <p >1、如果我们发现或收到他人举报您有违反本协议约定的，微训网公司有权不经通知随时对相关内容进行删除、屏蔽，并采取包括但不限于限制、暂停、终止您使用微训网账号及服务，限制、暂停、终止您使用部分或全部本服务（包括但不限于解除与团体或成员的所属关系、限制增加成员、限制创建课程等），追究法律责任等措施。</p>

                    <p >2、您同意，由于您通过服务上载、传送或分享之信息、使用本服务其他功能、违反本协议、或您侵害他人任何权利因而衍生或导致任何第三人向微训网公司及其关联公司提出任何索赔或请求，或微训网公司及其关联公司因此而发生任何损失，您同意将足额进行赔偿（包括但不限于合理律师费）。</p>

                    <p >&nbsp;</p>

                    <p ><b>十四、有效通知</b></p>

                    <p >1、微训网公司向您发出的任何通知，可采用电子邮件、页面公开区域公告、个人网络区域提示、手机短信或常规信件等方式，且该等通知应自发送之日起7天内视为已向用户送达或生效。</p>

                    <p >2、您同意，您向微训网发出的任何通知应发至微训网公司对外正式公布或以第十六条所述方式告知用户的线上或线下联系方式、通信地址、电子邮件、等联系信息，或使用其他微训网公司认可的其他通知方式进行送达。</p>

                    <p >&nbsp;</p>

                    <p ><b>十五、争议解决及其他</b></p>

                    <p >1、本协议之解释与适用，以及与本协议有关的争议，均应依照中华人民共和国法律予以处理，并以北京市海淀区人民法院为第一审管辖法院。</p>

                    <p >2、如本协议的任何条款被视作无效或无法执行，则上述条款可被分离，其余部份则仍具有法律效力。</p>

                    <p >3、微训网公司于用户过失或违约时放弃本协议规定的权利的，不得视为其对用户的其他或以后同类之过失或违约行为弃权。</p>

                    <p >4、本协议应取代双方此前就本协议任何事项达成的全部口头和书面协议、安排、谅解和通信。</p>

                    <p >5、您理解并同意，微训网公司有权自主决定经营策略并根据业务调整情况将本协议项下的全部权利义务一并转移给其关联公司，转让将以本协议规定的方式通知，用户承诺对此不持有异议。</p>

                    <p >&nbsp;</p>

                    <p ><b>十六、联系方式</b></p>

                    <p >您可以通过5*8小时客服热线400 007 2121与我们联系，您也可以选择通过邮件的方式联系我们，联系信息及地址如下：</p>

                    <p >北京优幕科技有限责任公司</p>

                    <p >联系部门：微训网运营组</p>

                    <p >电子邮箱：support@微训网.com</p>

                    <p >联系地址：北京市西城区宣武门外大街庄胜办公楼第一座9层 906，微训网运营组收</p>

                    <p >邮编：100052</p>
                </div>
                    <div className="btn-flex">
                        <Button block={true} disabled={!agreable} color="primary" size="large" onClick={() => {
                            this.props.agree();
                            this.props.history.goBack();
                        }}>{agreable ? '我知晓已阅读并同意': `请仔细阅读协议(${time < 10 ? `0${time}` : time}S)`}</Button>
                    </div>
            </div>
        )
    }
}

export default connect(
    undefined,
    dispatch => ({
        agree: () => dispatch(setAgree(true)),
    })
)(withRouter(Agreement));