import React, { Component } from 'react';
import '../css/sun.css';
import {withRouter,Link} from 'react-router-dom';
import { connect } from 'react-redux';
import { SharedInfo } from '../action';


class Sun extends Component{
    b(){
        this.setState(({
            top:10
        }))
    }
    state={
        top:0
    }

    componentDidMount(){
        document.body.addEventListener('touchmove' , function(e){
　　          var e=e||window.event;
　　          e.preventDefault();
        },{ passive: false })

        let SharedInfo={}
        SharedInfo.title='企业文化游戏式学习DEMO'
        SharedInfo.img="https://weixun-demo.oss-cn-beijing.aliyuncs.com/sun/logo.png"
        SharedInfo.des="企业文化组合式培训+游戏"
        this.props.setSharedInfo(SharedInfo)


    }
    render(){
        return(
            <div className='sunbac'>
                <div className='sunbanner'></div>
                <div className='suntitle'></div>
                <Link to='Sunone'><div className='sunbtnone'>阳光文化</div></Link>
                <Link to='Suntwo'><div className='sunbtntwo'>董事长寄语</div></Link>
                <Link to='Sunthree'><div className='sunbtnthree'>配套制度</div></Link>
                <Link to='Sunfour'><div className='sunbtnfour'>优秀模范</div></Link>
                <Link to='Anim'><div className='sunbottoms'></div></Link>
            </div>
        )
    }
}
function mapStateToProps(/*默认值加解构数组*/) {
  return {
   

  }
}

function mapDispatchToProps(dispatch) {
  return {
    setSharedInfo:(data)=>{dispatch(SharedInfo(data))},
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Sun));