import React, { Component } from "react";
import '../../css/propagandaDemo.scss';
import PreloadVideo from "../PreloadVideo";

class Demo extends Component {

    constructor(props) {
        super(props);
        this.state = {
            currentPage: 1,
            maxPage: 3
        }
    }

    // TODO 上一页
    toTop = () => { const { currentPage } = this.state;if (currentPage !== 1) this.setState({currentPage: currentPage - 1});}

    // TODO 下一页
    toNext = () => { const { currentPage, maxPage } = this.state; if (currentPage !== maxPage) this.setState({currentPage: currentPage + 1});}

    // TODO 回到主页
    backHome = () => this.props.backHome();

    render() {
        const { currentPage, maxPage } = this.state;
        return (
            currentPage !== 3 ? (<div className='demo'>
                <header className="header">
                    <div className="home" onTouchEnd={ this.backHome } />
                    <div className="title">{currentPage === 1 ? "案例-员工健康与安全" : "发力-新员工入职培训"}</div>
                </header>
                <div className="content">
                    {currentPage === 1 && (<div className='container-1'>
                        <div className='top'>企业对员工健康与安全的重视程度越来越高，此类培训在大中型企业越来越多。传统的培训模式枯燥，员工参与度较低，培训效果不明显</div>
                        <div className="center"><PreloadVideo url='https://micro-weixun.oss-cn-beijing.aliyuncs.com/micro_mobile_propaganda/video/video-4-1.mp4' /></div>
                        <div className='bottom'>通过游戏式培训方式，导入视频、问答、实景VR和闯关等方式，可以大幅提高员工参与度，进行批量化培训，并且可以检验学习效果</div>
                    </div>)}
                    {currentPage === 2 && (<div className='container-2'>
                        <div className='top'>新员工大量招聘的企业，如地产、餐饮、保险、银行等单位，新员工培训人员数量巨大，培训成本较高</div>
                        <div className="center"><PreloadVideo url='https://micro-weixun.oss-cn-beijing.aliyuncs.com/micro_mobile_propaganda/video/video-4-2.mp4' /></div>
                        <div className='bottom'>通过游戏式培训方式，导入视频、问答、实景VR和闯关等方式，可以大幅提高员工参与度，进行批量化培训，并且可以检验学习效果</div>
                    </div>)}
                    { currentPage !== 1 && (<div className='toTop' onTouchEnd={ this.toTop } />) }
                    { currentPage !== maxPage && (<div className='toNext' onTouchEnd={ this.toNext } />) }
                </div>
            </div>) : (
                <div className="demo">
                    <div className='container'>
                        <div className='logo' />
                        <p className='slogan'>微训，将学习快乐到底！</p>
                        <div className="qrCode"><img src="https://micro-weixun.oss-cn-beijing.aliyuncs.com/micro_mobile_propaganda/header/core-4-3.png" alt=""/></div>
                        <p className="companyName">微训网（北京）管理咨询有限公司</p>
                        { currentPage !== 1 && (<div className='toTop' onTouchEnd={ this.toTop } />) }
                        { currentPage !== maxPage && (<div className='toNext' onTouchEnd={ this.toNext } />) }
                    </div>
                </div>
            )
        )
    }
}

export default Demo;