import React, { Component } from 'react';
import '../css/dakehu.css';
import {withRouter,Link} from 'react-router-dom';
class Dakehucourse extends Component{
    render(){
        return(
            <div className='dakehucoursebac'>
                <Link to='dakehu'><div className='dakehutui'></div></Link>
            </div>

        )
    }
}

export default withRouter(Dakehucourse);
