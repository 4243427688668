import React, { Component } from "react";
import '../../css/propagandaSupport.scss';
import PreloadVideo from "../PreloadVideo";

class Support extends Component {

    constructor(props) {
        super(props);
        this.state = {
            currentPage: 1,
            maxPage: 4
        }
    }

    // TODO 上一页
    toTop = () => { const { currentPage } = this.state;if (currentPage !== 1) this.setState({currentPage: currentPage - 1});}

    // TODO 下一页
    toNext = () => { const { currentPage, maxPage } = this.state; if (currentPage !== maxPage) this.setState({currentPage: currentPage + 1});}

    // TODO 回到主页
    backHome = () => this.props.backHome();

    render() {
        const { currentPage, maxPage } = this.state;
        return (
            <div className='support'>
                <header className="header">
                    <div className="home" onTouchEnd={ this.backHome } />
                    <div className="title">{ currentPage === 1 ? "动画制作" : (currentPage === 2 ? "VR技术" : (currentPage === 3 ? "互动功能" : "拍摄技术及效果")) }</div>
                </header>
                <div className="content">
                    {currentPage === 1 && (<div className='container-1'>
                        <p className='detail'>可以提供标准的卡通人物，也可以量身定制卡通人物，如西家小安或者其他人物</p>
                        <div className="videoContainer"><PreloadVideo url='https://micro-weixun.oss-cn-beijing.aliyuncs.com/micro_mobile_propaganda/video/video-3-2.mp4' /></div>
                        <p className='info'>请点击左侧视频，出现播放按钮后，点击观看DEMO，或者全屏状态下播放</p>
                    </div>)}
                    {currentPage === 2 && (<div className='container-2'>
                        <ul>
                            <li>通过全景融入的方式展现画面</li>
                            <li>增强参与感提升娱乐性</li>
                            <li>贴合实际场景，学习效果好</li>
                        </ul>
                        <div className="videoContainer"><PreloadVideo url='https://micro-weixun.oss-cn-beijing.aliyuncs.com/micro_mobile_propaganda/video/video-3-3.mp4' /></div>
                        <p className='info'>请点击左侧视频，出现播放按钮后，点击观看DEMO，或者全屏状态下播放</p>
                    </div>)}
                    {currentPage === 3 && (<div className='container-3'>
                        <p className='detail'>灵活而先进的互动技术，可以以“选择题”或者“方向选择”或者“物体选择”等多种方式进行互动，并可植入视频、图片、网页中，可以判断对错记分，并提供解析</p>
                        <div className="videoContainer"><PreloadVideo url='https://micro-weixun.oss-cn-beijing.aliyuncs.com/micro_mobile_propaganda/video/video-3-4.mp4' /></div>
                        <p className='info'>请点击左侧视频，出现播放按钮后，点击观看DEMO，或者全屏状态下播放</p>
                    </div>)}
                    {currentPage === 4 && (<div className='container-4'>
                        <div className='top'>
                            <div className="left"><img src="https://micro-weixun.oss-cn-beijing.aliyuncs.com/micro_mobile_propaganda/header/left-3-5.png" alt=""/></div>
                            <div className="right">
                                采用绿棚拍摄真人，将真人和卡通有机组合，提升观赏性
                            </div>
                        </div>
                        <div className='center'><img src="https://micro-weixun.oss-cn-beijing.aliyuncs.com/micro_mobile_propaganda/header/core-3-5-2.png" alt=""/></div>
                        <div className='bottom'><img src="https://micro-weixun.oss-cn-beijing.aliyuncs.com/micro_mobile_propaganda/header/core-3-5-1.png" alt=""/>
                        <p className='info'>图片来自影视资料</p></div>
                    </div>)}
                    { currentPage !== 1 && (<div className='toTop' onTouchEnd={ this.toTop } />) }
                    { currentPage !== maxPage && (<div className='toNext' onTouchEnd={ this.toNext } />) }
                </div>
            </div>
        )
    }
}

export default Support;