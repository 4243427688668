import React, { Component,useState  } from 'react';
import { connect } from 'react-redux';
import * as CoursesAPI from '../API/CoursesAPI'
import { getCourse, SharedInfo } from '../action'
import { withRouter } from "react-router-dom";
import Coupon from '../component/Coupon';
import { Comment, Tooltip ,Select,Modal} from "antd"
import { ShareAltOutlined } from '@ant-design/icons'
import { Avatar, Button, Dialog, Tabs, Tag, Toast, Popup, Form, Input,Checkbox,Picker} from "antd-mobile"
import uuid from "uuid";
import { CheckCircleOutline, ClockCircleOutline } from "antd-mobile-icons"
import Loading from "../component/Loading/Loading";
import '../css/Details.scss'
import zhCN from 'antd/lib/locale/zh_CN';
import moment from 'moment';

const {Option}=Select;
class Details extends Component {

    constructor(props) {
        super(props);
        this.state = {
            disabled: true,
            disabled1: false,
            totaltitle: 0,
            singletitle: 0,
            maxItem: 100,
            count: 0,
            increase: 0,
            reduce: 0,
            list:[],
            scrollHeight: 0,
            detailType:"detail_item",
            selectType:"全部",
            detailShowModal:false,
            detail: [
                { action: "完成《大客户销售》", type: "increase", number: 80, time: "2022-03-15 16时24分" },
                { action: "兑换《小客户销售》", type: "reduce", number: 80, time: "2022-04-15 16时24分" },
                { action: "兑换《小客户销售》", type: "reduce", number: 80, time: "2022-04-15 16时24分" },
                { action: "1", type: "increase", number: 80, time: "2022-05-15 16时24分" },
                { action: "2", type: "increase", number: 80, time: "2022-05-15 16时24分" },
                { action: "3", type: "increase", number: 80, time: "2022-05-15 16时24分" },
                { action: "4", type: "increase", number: 80, time: "2022-05-15 16时24分" },
                { action: "5", type: "increase", number: 80, time: "2022-05-15 16时24分" },
                { action: "6", type: "increase", number: 80, time: "2022-05-15 16时24分" },
                { action: "7", type: "increase", number: 90, time: "2022-05-15 16时24分" },
                { action: "8", type: "increase", number: 80, time: "2022-05-15 16时24分" },
                { action: "兑换《小客户销售》", type: "reduce", number: 80, time: "2022-04-15 16时24分" },
                { action: "9", type: "increase", number: 90, time: "2022-05-15 16时24分" },
                { action: "10", type: "increase", number: 80, time: "2022-05-15 16时24分" },
                { action: "11", type: "increase", number: 90, time: "2022-05-15 16时24分" },
                { action: "12", type: "increase", number: 80, time: "2022-05-15 16时24分" },
                { action: "13", type: "increase", number: 90, time: "2022-05-15 16时24分" },
                { action: "14", type: "increase", number: 80, time: "2022-05-15 16时24分" },
                { action: "15", type: "increase", number: 90, time: "2022-05-15 16时24分" },
                { action: "16", type: "increase", number: 80, time: "2022-05-15 16时24分" },
                { action: "2", type: "increase", number: 80, time: "2022-05-15 16时24分" },
                { action: "3", type: "increase", number: 80, time: "2022-05-15 16时24分" },
                { action: "4", type: "increase", number: 80, time: "2022-05-15 16时24分" },
                { action: "5", type: "increase", number: 80, time: "2022-05-15 16时24分" },
                { action: "6", type: "increase", number: 80, time: "2022-05-15 16时24分" },
                { action: "7", type: "increase", number: 90, time: "2022-05-15 16时24分" },
                { action: "8", type: "increase", number: 80, time: "2022-05-15 16时24分" },
                { action: "9", type: "increase", number: 90, time: "2022-05-15 16时24分" },
                { action: "10", type: "increase", number: 80, time: "2022-05-15 16时24分" },
                { action: "11", type: "increase", number: 90, time: "2022-05-15 16时24分" },
                { action: "12", type: "increase", number: 80, time: "2022-05-15 16时24分" },
                { action: "13", type: "increase", number: 90, time: "2022-05-15 16时24分" },
                { action: "14", type: "increase", number: 80, time: "2022-05-15 16时24分" },
                { action: "15", type: "increase", number: 90, time: "2022-05-15 16时24分" },
                { action: "16", type: "increase", number: 80, time: "2022-05-15 16时24分" },
                { action: "2", type: "increase", number: 80, time: "2022-05-15 16时24分" },
                { action: "3", type: "increase", number: 80, time: "2022-05-15 16时24分" },
                { action: "4", type: "increase", number: 80, time: "2022-05-15 16时24分" },
                { action: "5", type: "increase", number: 80, time: "2022-05-15 16时24分" },
                { action: "6", type: "increase", number: 80, time: "2022-05-15 16时24分" },
                { action: "7", type: "increase", number: 90, time: "2022-05-15 16时24分" },
                { action: "8", type: "increase", number: 80, time: "2022-05-15 16时24分" },
                { action: "9", type: "increase", number: 90, time: "2022-05-15 16时24分" },
                { action: "10", type: "increase", number: 80, time: "2022-05-15 16时24分" },
                { action: "11", type: "increase", number: 90, time: "2022-05-15 16时24分" },
                { action: "12", type: "increase", number: 80, time: "2022-05-15 16时24分" },
                { action: "13", type: "increase", number: 90, time: "2022-05-15 16时24分" },
                { action: "14", type: "increase", number: 80, time: "2022-05-15 16时24分" },
                { action: "15", type: "increase", number: 90, time: "2022-05-15 16时24分" },
                { action: "16", type: "increase", number: 80, time: "2022-05-15 16时24分" },
                { action: "2", type: "increase", number: 80, time: "2022-05-15 16时24分" },
                { action: "3", type: "increase", number: 80, time: "2022-05-15 16时24分" },
                { action: "4", type: "increase", number: 80, time: "2022-05-15 16时24分" },
                { action: "5", type: "increase", number: 80, time: "2022-05-15 16时24分" },
                { action: "6", type: "increase", number: 80, time: "2022-05-15 16时24分" },
                { action: "7", type: "increase", number: 90, time: "2022-05-15 16时24分" },
                { action: "8", type: "increase", number: 80, time: "2022-05-15 16时24分" },
                { action: "9", type: "increase", number: 90, time: "2022-05-15 16时24分" },
                { action: "10", type: "increase", number: 80, time: "2022-05-15 16时24分" },
                { action: "11", type: "increase", number: 90, time: "2022-05-15 16时24分" },
                { action: "12", type: "increase", number: 80, time: "2022-05-15 16时24分" },
                { action: "13", type: "increase", number: 90, time: "2022-05-15 16时24分" },
                { action: "14", type: "increase", number: 80, time: "2022-05-15 16时24分" },
                { action: "15", type: "increase", number: 90, time: "2022-05-15 16时24分" },
                { action: "16", type: "increase", number: 80, time: "2022-05-15 16时24分" },
            ],
        }
    }
    componentDidMount() {
       
        var totaltitle = document.documentElement.clientHeight - 31
        // var singletitle=~~totaltitle/80
        var singletitle = Math.floor(totaltitle / 80)
        console.log(singletitle)

        let increase = this.state.increase
        let reduce = this.state.reduce
        // let list = this.state.detail.splice()
        //收入与支出综合
        this.state.detail.map((item, index) => {
            if (item.type === 'increase') {
                increase += item.number
            } else {
                reduce += item.number
            }
        })
        this.setState({
            totaltitle: totaltitle,
            singletitle: singletitle,
            // count:this.state.count+1
            increase: increase,
            reduce: reduce

        })
        // console.log('初始化', this.state)  
    }
    //下滑加载更多
    appendList = () => {
        console.log()
      
        this.setState({
            count:this.state.count+1
        })
           
    }
    // backpace = () => {
    //     this.props.history.push('/integral')
    //     console.log(this.state)

    // }
    // UpPage = () => {
    //     if(this.state.count<=0){
    //         alert('您已经到达了最顶层')
    //         this.setState({
    //             disabled:true
    //         })
    //     }else{
    //         this.setState({
    //             count:this.state.count-1,
    //             disabled1:false
    //         })
    //     }
    //     console.log(this)


    // }
    //下一页
    // NextPage = () => {
    //     const { count, detail ,totaltitle,singletitle,maxItem} = this.state
    //     if(count*singletitle+singletitle<Math.min(maxItem,detail.length)){
    //         this.setState({
    //             disabled1:false,
    //             disabled:false,
    //             count:count+1
    //         })


    //     }else {
    //         this.setState({
    //             disabled1:true
    //         })
    //         alert('您已经到达了末端')
    //     }
    //   console.log(this.state)

    // }
    handelSelect=(value)=>{ //select
        console.log(1)
     if(value==="未兑换"){
        this.setState({
            selectType:value,
            detailType:"detail_item_checkbox"
        })
     }else{
        this.setState({
            selectType:value,
            detailType:"detail_item"
        })
     }
    }

 



    render() {
        const { count, detail, totaltitle, singletitle, maxItem, increase, reduce } = this.state
        const aa = totaltitle / singletitle

        console.log('render')
        return (
            <div className="details" ref="onPullUp">
                <Modal title="账单分类" visible={this.state.detailShowModal} onOk={()=>{this.setState({detailShowModal:false})}} onCancel={()=>{this.setState({detailShowModal:false})}}>
                   <Select defaultValue="全部" style={{width:120}} onChange={(value)=>{this.handelSelect(value)}}>
                       <Option value="全部">全部</Option>
                       <Option value="已兑换">支出</Option>
                       <Option value="未兑换">收入</Option>
                  </Select>
                </Modal>
                <div>
                    
                </div>
                
                <div className="detail_head">收入{increase}微豆&nbsp;&nbsp;支出{reduce}微豆</div>
                <div style={{ height: "30px" }}></div>
                <div className='Slidingload' >
                    {
                        detail.slice(0, Math.min((count + 1) * singletitle, maxItem)) !== '' && detail.slice(count * singletitle, Math.min((count + 1) * singletitle, maxItem)).map((item, index) => (
                            <div className="detail_item" style={{ height: `${aa}px` }} key={index}>
                                <div className="head_url"><Avatar style={{ '--size': '50px', 'border-radius': '40px' }} src={this.props.userInfo.headUrl} /></div>
                                <div className="detail_main"><span style={{ width: "80%" }}>{item.action}</span>
                                    {item.type === 'increase' ? <span style={{ color: "#faad14", float: "right" }}>+{item.number}</span>
                                    :
                                    <span style={{ float: "right" }}>-{item.number}</span>}
                                </div>
                                <div className="detail_time">{item.time}</div>
                               
                            </div>
                            
                        ))
                    }
                    <span onClick={this.appendList} style={{ fontSize: 18 }}>
                        点击此处获取更多
                    </span>
                </div>
            </div>
        )
    }
}
export default connect(
    ({ login, course }) => ({
        isLogin: login.isLogin,
        userInfo: login.userInfo,
        course: course.data,
        userType: login.type,
        company: login.company
    }), dispatch => ({
        setSharedInfo: (data) => dispatch(SharedInfo(data)),
    })
)(withRouter(Details));
