import React, { Component } from "react";
import * as API from "../API/CoursesAPI";
import { withRouter } from 'react-router-dom';
import * as CourseAPI from "../API/CoursesAPI";
import {Button, SearchBar, Space, Toast,Empty } from "antd-mobile";
import Loading from "../component/Loading/Loading";
import { LeftOutline} from 'antd-mobile-icons'
import "../css/searchResult.scss";
class SearchResult extends Component{
    constructor(props){
    	super(props)
    	this.state={
    		courses:[]
    	}
    }
    componentDidMount(){
     
    }
    handleCancel=()=>{
    	console.log(1)
    this.props.history.push("/courseList/")
    }
    handelSearch=(value)=>{
    	if(value){
        console.log(typeof value)
        CourseAPI.searchlesson(value,1)
        .then(data=>{
        	const copy=[...data.data]
        	this.setState({courses:copy})
        })
    	}
    }

    render(){
    	return(
           <div>
            <SearchBar placeholder="请输入内容" style={{'--background':'#ffffff',width:"90%",marginLeft:"5%",marginTop:"15px"}} 
            showCancelButton 
            onCancel={this.handleCancel} 
            onSearch={(val)=>this.handelSearch(val)}
            />
            <h4 className="searchTitle">搜索结果</h4>
            <div className="courses">
              {
                        this.state.courses&&this.state.courses.map((item, index) => (
                            <div className="course-item" key={index} onClick={ () => this.props.history.push("/course/"+item.id)} >
                                <div className="course-index"
                                    style={{
                                        width: `${((document.documentElement.offsetWidth-20)/2-20)}px`,
                                        height: `${((document.documentElement.offsetWidth-20)/2-20)/(16/9)}px`,
                                    }}>
                                    <img src={item.lessonIcon} alt=""/>
                                </div>
                                <p className="lessonName">{item.lessonName}</p>
                                <p className="lessonPrice">¥{item.lessonPrice}</p>
                            </div>
                        ))
               }
               {
               	this.state.courses.length===0&&
                <Empty
		          style={{ margin:"100px auto"}}
		          imageStyle={{ width: 128 }}
		          description='未找到匹配的课程'
                />
               }

               
            </div>
           </div>
    		)
    }

}

export default withRouter(SearchResult);