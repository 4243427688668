import React, {Component} from 'react';
import {connect} from 'react-redux';
import * as CoursesAPI from '../API/CoursesAPI'
import {getCourse, SharedInfo} from '../action'
import {withRouter} from "react-router-dom";
import Coupon from '../component/Coupon';
import {Comment,Tooltip} from "antd"
import { ShareAltOutlined } from '@ant-design/icons'
import {Avatar, Button, Dialog, Tabs, Tag, Toast,Popup,Form,Input} from "antd-mobile"
import uuid from "uuid";
import { CheckCircleOutline, ClockCircleOutline } from "antd-mobile-icons"
import Loading from "../component/Loading/Loading";
import '../css/Myshare.scss'
import moment from 'moment';

class Myshare extends Component {

    constructor(props) {
        super(props);
        this.state={
          myshare:[
          {courseId:1,courseName:"商务演讲",friendName:"大熊",createTime:"2202年4月3日 12:45",price:80 },
          {courseId:2,courseName:"大客户销售",friendName:"胖虎",createTime:"2202年4月3日 12:45",price:80 },
          {courseId:3,courseName:"职业测评",friendName:"小夫",createTime:"2202年4月3日 12:45",price:80 },
          {courseId:4,courseName:"商务沟通技巧",friendName:"静香",createTime:"2202年4月3日 12:45",price:80 },
          {courseId:5,courseName:"招聘面试新法",friendName:"图图",createTime:"2202年4月3日 12:45",price:80 },
          {courseId:6,courseName:"新员工培训",friendName:"小美",createTime:"2202年4月3日 12:45",price:80 },
          {courseId:7,courseName:"新经理的风火轮",friendName:"牛爷爷",createTime:"2202年4月3日 12:45",price:80 },
          {courseId:8,courseName:"结构化思维",friendName:"胡英俊",createTime:"2202年4月3日 12:45",price:80 },
          {courseId:1,courseName:"商务演讲",friendName:"大熊",createTime:"2202年4月3日 12:45",price:80 },
          {courseId:2,courseName:"大客户销售",friendName:"胖虎",createTime:"2202年4月3日 12:45",price:80 },
          {courseId:3,courseName:"职业测评",friendName:"小夫",createTime:"2202年4月3日 12:45",price:80 },
          {courseId:4,courseName:"商务沟通技巧",friendName:"静香",createTime:"2202年4月3日 12:45",price:80 },
          {courseId:5,courseName:"招聘面试新法",friendName:"图图",createTime:"2202年4月3日 12:45",price:80 },
          {courseId:6,courseName:"新员工培训",friendName:"小美",createTime:"2202年4月3日 12:45",price:80 },
          {courseId:7,courseName:"新经理的风火轮",friendName:"牛爷爷",createTime:"2202年4月3日 12:45",price:80 },
          {courseId:8,courseName:"结构化思维",friendName:"胡英俊",createTime:"2202年4月3日 12:45",price:80 },
          {courseId:1,courseName:"商务演讲",friendName:"大熊",createTime:"2202年4月3日 12:45",price:80 },
          {courseId:2,courseName:"大客户销售",friendName:"胖虎",createTime:"2202年4月3日 12:45",price:80 },
          {courseId:3,courseName:"职业测评",friendName:"小夫",createTime:"2202年4月3日 12:45",price:80 },
          {courseId:4,courseName:"商务沟通技巧",friendName:"静香",createTime:"2202年4月3日 12:45",price:80 },
          {courseId:5,courseName:"招聘面试新法",friendName:"图图",createTime:"2202年4月3日 12:45",price:80 },
          {courseId:6,courseName:"新员工培训",friendName:"小美",createTime:"2202年4月3日 12:45",price:80 },
          {courseId:7,courseName:"新经理的风火轮",friendName:"牛爷爷",createTime:"2202年4月3日 12:45",price:80 },
          {courseId:8,courseName:"结构化思维",friendName:"胡英俊",createTime:"2202年4月3日 12:45",price:80 },
          ],
          aggregate:0,
          activeNumber:1,
        }
    }
    componentDidMount(){
     document.title="我的分享"
     let aggregate=0
     for(let i in this.state.myshare){
      aggregate+=this.state.myshare[i].price
     }
     this.setState({aggregate:aggregate})
    }

    render(){
        const { userInfo } = this.props;
        return(
          <div className="myshare">
          <div className="course-header">
                    <div className="header-name">
                        <div onClick={ () => this.props.history.push("/") } className="header-link">首页</div>
                        &nbsp;>&nbsp;
                        <div onClick={ () => this.props.history.push("/personal") } className="header-link">个人中心</div>
                        &nbsp;>&nbsp;
                        <div onClick={ () => this.props.history.push("/myshare") } className="header-link">我的分享</div>
                    </div>
                    <div className="header-user" onClick={ () => this.props.history.push("/personal") }>
                        <Avatar style={{ '--size': '30px' }} src={userInfo.headUrl} />
                    </div>
                </div>
        <div style={{position:"relative",height:"67px"}}>
        <div className="head-right">
         <span>成功分享:{this.state.myshare.length}人</span>&nbsp;&nbsp;
         <span>累计收益:{this.state.aggregate}微豆</span>
         </div>
        <div className="head-left">
         <div className={this.state.activeNumber===1?"head-active":"head-common"} onClick={()=>this.setState({activeNumber:1})}>本年</div>
         <div className={this.state.activeNumber===2?"head-active":"head-common"} onClick={()=>this.setState({activeNumber:2})}>本月</div>
         <div className={this.state.activeNumber===3?"head-active":"head-common"} onClick={()=>this.setState({activeNumber:3})}>本周</div>
         </div>
        </div>
        <div className="share-content">
        {
            this.state.myshare.map((item,index)=>(
             <div className="item-content" onClick={ () => this.props.history.push("/course/"+item.courseId) }>
            
             <div className="item-coursename"><span style={{color:"#85a5ff"}}>{item.friendName}&nbsp;&nbsp;</span>购买了您分享的《{item.courseName}》</div>
             <div className="item-time">{item.createTime}</div>
             <div className="item-friend-isbuy">收益:{item.price*2}微豆</div>

             </div>
            ))
        }
        </div>
        
        <div>
         
        </div>
          </div>
            )
    }
}

    export default connect(
    ({login, course}) => ({
        isLogin: login.isLogin,
        userInfo: login.userInfo,
        course: course.data,
        userType: login.type,
        company: login.company
    }), dispatch => ({
        setSharedInfo: (data) => dispatch(SharedInfo(data)),
    })
)(withRouter(Myshare));
