import React, { Component } from 'react';
import { connect } from 'react-redux';
import '../css/dakehu.css';
import {withRouter,Link} from 'react-router-dom';
import phone from '../util/phone';
class Dakehuone extends Component{

    state={
        dakehuonem:'none',
        dakehuonemf:'true',
        videoone:['https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/1/1.mp4','https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/1/2.mp4','https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/1/3.mp4','https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/1/4.mp4','https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/1/5.mp4','https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/1/6.mp4','https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/1/7.mp4'],
        videos:'',
        wancheng:'',
        wenti:'none',
        ques:['下面哪一项最符合大客户销售的特质'],
        ans:[['A，热情奔放','B，潇洒时长','C，英姿飒爽','D，低调沉稳']],
        shenqing:'none'
    }
    panduan(videonum){
        if(phone.indexOf(this.props.userInfo.userInfo.phone)==-1){
            console.log(this.props.userInfo.data.user.phone)
            this.setState({
                shenqing:'none'
            })
            return -1;
        }else{
            console.log('成功')
            this.setState({
                dakehuonem:'block',
                videos:videonum
            });
            return 2;
        }
    }
    firstm(){
        var videonum=this.state.videoone[0];
        this.panduan(videonum);


    }
    secondm(){
         var videonum=this.state.videoone[1];
        this.panduan(videonum);
    }
    thirdm(){
         var videonum=this.state.videoone[2];
        this.panduan(videonum);
    }
    fourm(){
        var videonum=this.state.videoone[3];
        this.panduan(videonum);
    }
    fivem(){
         var videonum=this.state.videoone[4];
        this.panduan(videonum);
    }
    sixm(){
         var videonum=this.state.videoone[5];
        this.panduan(videonum);
    }
    sevenm(){
        var videonum=this.state.videoone[6];
        this.panduan(videonum);
    }
    closes(){
        var dakehuvideo=document.getElementById('dakehuvideo1');
        dakehuvideo.pause();
        this.setState({
            dakehuonem:'none'
        });
    }
    wentiA(){
        var that=this;
        var dakehuvideo=document.getElementById('dakehuvideo1');

        that.setState({
            quesan:'shaky-slow 0.7s',

        });
        setTimeout(function () {
            that.setState({
                quesan:'',

            })
        },1000);

    }
    wentiB(){
        var that=this;
        var dakehuvideo=document.getElementById('dakehuvideo1');

        that.setState({
            quesan:'shaky-slow 0.7s',

        });
        setTimeout(function () {
            that.setState({
                quesan:'',

            })
        },1000);

    }
    wentiC(){
        var that=this;
        var dakehuvideo=document.getElementById('dakehuvideo1');

        that.setState({
            quesan:'shaky-slow 0.7s',

        });
        setTimeout(function () {
            that.setState({
                quesan:'',

            })
        },1000);

    }
    wentiD(){
        var that=this;
        var dakehuvideo=document.getElementById('dakehuvideo1');

        that.setState({
            wenti:'none',
            dakehuonem:'block'
        });
        dakehuvideo.currentTime=4;
        dakehuvideo.play();

    }
    componentDidMount(){
        var dakehuvideo=document.getElementById('dakehuvideo1');
        /*
        this.timer = setInterval(() => {
            if (long >= dakehuvideo.duration) {
                long = 0.00;
            } else {
                long += 0.01;
                if (long >= dakehuvideo.duration) {
                    long = 0.01
                }
            }
            console.log(long.toFixed(2));
            if(dakehuvideo.currentTime>=5){
                dakehuvideo.pause();
                this.setState({
                    wenti:'block'
                });
                clearInterval(this.timer);
            }
        }, 1000);
*/
        var that=this;
        dakehuvideo.addEventListener('ended', function () {
            for(var i=0;i<that.state.videoone.length;i++){
                if(that.state.videos==that.state.videoone[i]){
                    if(that.state.wancheng.indexOf(i)!=-1){
                        console.log('包含');
                    }else{
                        that.setState({
                            wancheng:that.state.wancheng+i
                        })
                    }
                    console.log(that.state.wancheng);
                }

            }
        });
        dakehuvideo.addEventListener("timeupdate",function(){

            var timeDisplay;
            //用秒数来显示当前播放进度
            timeDisplay = Math.floor(dakehuvideo.currentTime);
            /*
            if(timeDisplay == 2){
                dakehuvideo.pause(); //视频暂停  play();视频播放
                this.webkitExitFullScreen();
                    setTimeout(() => {
                    that.setState({
                        wenti: 'block',
                        dakehuonem:'none',
                        dakehuonemf:'false',
                    })
                }, 0)
            }
            */
        },true)


    }


    render(){
        return(
            <div className='dakehuonebac'>
                <Link to='dakehu'><div className='dakehutui'></div></Link>
                <div className='dakehuonecourseling'  onClick={this.firstm.bind(this)}>
                </div>
                <div className='dakehuonecourseyi' onClick={this.secondm.bind(this)}></div>
                <div className='dakehuonecourseer' onClick={this.thirdm.bind(this)}></div>
                <div className='dakehuonecoursesan' onClick={this.fourm.bind(this)}></div>
                <div className='dakehuonecoursesi' onClick={this.fivem.bind(this)}></div>
                <div className='dakehuonecoursewu' onClick={this.sixm.bind(this)}></div>
                <div className='dakehuonecourseliu' onClick={this.sevenm.bind(this)}></div>
                <div className='dakehushenqing' style={{display:this.state.shenqing}} ></div>
                <div className='shenqingqueding'></div>
                <div className='wenti'  style={{display:this.state.wenti,animation:this.state.quesan}}></div>
                <div className='dakehuques' style={{display:this.state.wenti,animation:this.state.quesan}}>{this.state.ques[0]}</div>
                <div className='dakehuans1' onClick={this.wentiA.bind(this)} style={{display:this.state.wenti}}>{this.state.ans[0][0]}</div>
                <div className='dakehuans2' onClick={this.wentiB.bind(this)} style={{display:this.state.wenti}}>{this.state.ans[0][1]}</div>
                <div className='dakehuans3' onClick={this.wentiC.bind(this)} style={{display:this.state.wenti}}>{this.state.ans[0][2]}</div>
                <div className='dakehuans4' onClick={this.wentiD.bind(this)} style={{display:this.state.wenti}}>{this.state.ans[0][3]}</div>
                <video className='dakehuvideo1' id='dakehuvideo1' autoPlay='true' controls="controls" x5-video-player-type="h5-page"  style={{display:this.state.dakehuonem}}    src={this.state.videos}/>
                <div className='dakehuclose' onClick={this.closes.bind(this)} style={{display:this.state.dakehuonem}}>X</div>
            </div>

        )
    }
}
function mapStateToProps({ login}/*默认值加解构数组*/) {

    console.log(login)
    return {
        userInfo: login

    }
}

export default withRouter(connect(mapStateToProps)(Dakehuone));
