export const IS_LOGIN='IS_LOGIN'
export const COURSE='COURSE'
export const VIDEO='VIDEO'
export const ORDER='ORDER'
export const EXAM='EXAM'
export const SHARE='SHARE'
export const SET_REGISTER_DATA = 'SET_REGISTER_DATA'
export const SET_AGREE = 'SET_AGREE'
export const SET_ADD_PHONE_FORM = 'SET_ADD_PHONE_FORM'
export const SET_FULL_SCREEN = 'SET_FULL_SCREEN'
export const SET_LESSON_ICON ='SET_LESSON_ICON'
export const SET_FIRSTPLAY ='SET_FIRSTPLAY'

export function isLogin (data) {
	console.log(data)
  return {
    type: IS_LOGIN,
    userInfo:data,
  }
}

export function getExamQuestions (data) {
	console.log(data)
  return {
    type: EXAM,
    data: data,
  }
}

export function getCourse (data) {
	console.log(data)
  return {
    type: COURSE,
    data:data,
  }
}

export function getVideo (data) {
  console.log(data)
  return {
    type: VIDEO,
    data:data,
  }
}

export function createOrder (data) {
  console.log(data)
  return {
    type: ORDER,
    data:data,
  }
}

export function SharedInfo(data){
  return {
    type: SHARE,
    data:data,
  }
}

export function setRegisterData(data){
  return {
    type: SET_REGISTER_DATA,
    data:data,
  }
}

export function setAgree(data){
  return {
    type: SET_AGREE,
    data: data,
  }
}

export function setAddPhoneForm(data){
  return {
    type: SET_ADD_PHONE_FORM,
    data: data,
  }
}

export function setFullScreen(data){
  return{
    type: SET_FULL_SCREEN,
    data: data
  }
}

export function setFirstPlay(data){
  return{
    type:SET_FIRSTPLAY,
    data:data
  }
}

export function setLessonIcon(data){
  return{
    type: SET_LESSON_ICON,
    data: data
  }
}
