import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import * as API from '../API/answerAPI'
import {Icon} from "antd";
import ReactLoading from 'react-loading';
import '../css/preloadVideo.scss'

class PreloadVideo extends Component {

	constructor(props) {
		super(props);
		this.state = {
			isOpen: false,
			isLoading: true,
			data: null,
			playing:false,
			showloading:true
		}
	}
	componentDidMount(){
		API.getVideoBlob(this.props.url).then(data=>{
			console.log(data)
			this.setState({
				data: window.URL.createObjectURL(data),
				isLoading: false,
				showloading:false,
				isOpen:this.props.showVideo,
				
			})
		});
	}

	open(){
		this.setState({
			playing:true
		})
		this.refs.video.play()
	}
	videoPlay = () => this.refs.video.play();

	// open =  () => {console.log("123");this.videoPlay()};

	close(){
		this.setState({
			playing:false
		})
		this.refs.video.pause();
	}

	render() {
		const { isOpen, data, isLoading } = this.state;
		const { headerImage } = this.props;
		return (
			<div className='preloadVideo'>
				{this.state.showloading&&<div style={{position:'absolute',top:'50%',left:'50%',transform:'translate(-50%,-50%)'}}><ReactLoading type={'spokes'} /></div>}
				{headerImage && <img className='headerImage' src={ headerImage } alt=""/> }
				{isOpen && <div className='video_content'>
					{!isLoading&&!this.state.playing&&<div className='open_btn' style={{zIndex:'999'}} onClick={ this.open.bind(this) }><Icon type="play-circle" theme="filled" /></div>}
					{!isLoading&& <video src={ data } playsInline className='video' ref="video" height='90%' poster={this.props.poster}/>}
					{/*<div className='close' onClick={ this.close.bind(this) }><Icon type='close' /> </div>*/}
				</div>}
			</div>
		)
	}

}

export default withRouter(PreloadVideo);