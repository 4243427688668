import React, { Component } from 'react';
import { connect } from 'react-redux';
import '../css/dakehu.css';
import {withRouter,Link} from 'react-router-dom';
import phone from '../util/phone';
class Dakehutwo extends Component{
    state={
        dakehuonem:'none',
        videoone:['https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/2/1.mp4','https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/2/2.mp4','https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/2/3.mp4','https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/2/4.mp4','https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/2/5.mp4','https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/2/6.mp4','https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/2/7.mp4','https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/2/8.mp4','https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/2/9.mp4','https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/2/10.mp4','https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/2/11.mp4','https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/2/12.mp4','https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/2/13.mp4','https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/2/14.mp4','https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/2/15.mp4'],
        videos:'',
        dakehutwobac:'url("https://weixun-demo.oss-cn-beijing.aliyuncs.com/dakehu/dakehutwo.jpg")',
        dakehutws:'block',
        dakehutwon:'block',
        dakehutwons:'none',
        shenqing:'none'
    }
    panduan(videonum){
        if(phone.indexOf(this.props.userInfo.userInfo.phone)==-1){
            this.setState({
                shenqing:'block'
            })
            return -1;
        }else{
            console.log('成功')
            this.setState({
                dakehuonem:'block',
                videos:videonum
            });
            return 2;
        }
    }
    firstm(){
         var videonum=this.state.videoone[0];
        this.panduan(videonum);
    }
    secondm(){
          var videonum=this.state.videoone[1];
        this.panduan(videonum);
    }
    thirdm(){
          var videonum=this.state.videoone[2];
        this.panduan(videonum);
    }
    fourm(){
          var videonum=this.state.videoone[3];
        this.panduan(videonum);
    }
    fivem(){
          var videonum=this.state.videoone[4];
        this.panduan(videonum);
    }
    sixm(){
          var videonum=this.state.videoone[5];
        this.panduan(videonum);
    }
    sevenm(){
          var videonum=this.state.videoone[6];
        this.panduan(videonum);
    }
    eightm(){
          var videonum=this.state.videoone[7];
        this.panduan(videonum);
    }
    ninem(){
          var videonum=this.state.videoone[8];
        this.panduan(videonum);
    }
    shim(){
          var videonum=this.state.videoone[9];
        this.panduan(videonum);
    }
    shiyim(){
          var videonum=this.state.videoone[10];
        this.panduan(videonum);
    }
    shierm(){
          var videonum=this.state.videoone[11];
        this.panduan(videonum);
    }
    shisanm(){
          var videonum=this.state.videoone[12];
        this.panduan(videonum);
    }
    shisim(){
          var videonum=this.state.videoone[13];
        this.panduan(videonum);
    }
    shiwum(){
          var videonum=this.state.videoone[14];
        this.panduan(videonum);
    }
    shilium(){
          var videonum=this.state.videoone[15];
        this.panduan(videonum);
    }
    closes(){
        var dakehuvideo=document.getElementById('dakehuvideo1');
        dakehuvideo.pause();
        this.setState({
            dakehuonem:'none'
        });
    }
    componentDidMount() {
        var dakehuvideo = document.getElementById('dakehuvideo1');
        var that=this;
        dakehuvideo.addEventListener('ended', function () {
            for(var i=0;i<that.state.videoone.length;i++){
                if(that.state.videos==that.state.videoone[i]){
                    if(that.state.wancheng.indexOf(i)!=-1){
                        console.log('包含');
                    }else{
                        that.setState({
                            wancheng:that.state.wancheng+i
                        })
                    }
                    console.log(that.state.wancheng);
                }

            }
        });
        dakehuvideo.addEventListener("timeupdate",function(){
            var timeDisplay;
            timeDisplay = Math.floor(dakehuvideo.currentTime);
        },true)
    }
    dakehutwonext(){
        var that=this;
        that.setState({
            dakehutwobac:'url("https://weixun-demo.oss-cn-beijing.aliyuncs.com/dakehu/dakehutwotwo.jpg")',
            dakehutwons:'block',
            dakehutws:'none'
        });
    }
    dakehutwoback(){
        var that=this;
        that.setState({
            dakehutwobac:'url("https://weixun-demo.oss-cn-beijing.aliyuncs.com/dakehu/dakehutwo.jpg")',
            dakehutws:'block',
            dakehutwons:'none'
        });
    }
    render(){
        return(
            <div className='dakehutwobac' style={{backgroundImage:this.state.dakehutwobac}}>
                <Link to='dakehu'><div className='dakehutui'></div></Link>
                <div className='dakehutwocourseyi' onClick={this.firstm.bind(this)} style={{display:this.state.dakehutws}}></div>
                <div className='dakehutwocourseer' onClick={this.secondm.bind(this)} style={{display:this.state.dakehutws}}></div>
                <div className='dakehutwocoursesan' onClick={this.thirdm.bind(this)} style={{display:this.state.dakehutws}}></div>
                <div className='dakehutwocoursesi' onClick={this.fourm.bind(this)} style={{display:this.state.dakehutws}}></div>
                <div className='dakehutwocoursewu' onClick={this.fivem.bind(this)} style={{display:this.state.dakehutws}}></div>
                <div className='dakehutwocourseliu' onClick={this.sixm.bind(this)} style={{display:this.state.dakehutws}}></div>
                <div className='dakehutwocourseqi' onClick={this.sevenm.bind(this)} style={{display:this.state.dakehutws}}></div>
                <div className='dakehutwocourseba' onClick={this.eightm.bind(this)} style={{display:this.state.dakehutws}}></div>

                <div className='dakehutwonext' onClick={this.dakehutwonext.bind(this)} style={{display:this.state.dakehutws}}></div>
                <div className='dakehutwocoursejiu' onClick={this.ninem.bind(this)} style={{display:this.state.dakehutwons}}></div>
                <div className='dakehutwocourseshi' onClick={this.shim.bind(this)} style={{display:this.state.dakehutwons}}></div>
                <div className='dakehutwocourseshiyi' onClick={this.shiyim.bind(this)} style={{display:this.state.dakehutwons}}></div>
                <div className='dakehutwocourseshier' onClick={this.shierm.bind(this)} style={{display:this.state.dakehutwons}}></div>
                <div className='dakehutwocourseshisan' onClick={this.shisanm.bind(this)} style={{display:this.state.dakehutwons}}></div>
                <div className='dakehutwocourseshisi' onClick={this.shisim.bind(this)} style={{display:this.state.dakehutwons}}></div>
                <div className='dakehutwocourseshiwu' onClick={this.shiwum.bind(this)} style={{display:this.state.dakehutwons}}></div>
                <div className='dakehutwocourseshiliu' onClick={this.shilium.bind(this)} style={{display:this.state.dakehutwons}}></div>
                <div className='dakehutwoback' onClick={this.dakehutwoback.bind(this)} style={{display:this.state.dakehutwons}}></div>
                <video className='dakehuvideo1' id='dakehuvideo1'  style={{display:this.state.dakehuonem}} controls='controls' autoPlay='true'  src={this.state.videos}/>
                <div className='dakehuclose' onClick={this.closes.bind(this)} style={{display:this.state.dakehuonem}}>X</div>
                          <div className='dakehushenqing' style={{display:this.state.shenqing}} ></div>  
            </div>

        )
    }
}
function mapStateToProps({ login}/*默认值加解构数组*/) {

    console.log(login)
    return {
        userInfo: login

    }
}

export default withRouter(connect(mapStateToProps)(Dakehutwo));
