import React, {Component} from 'react';
import {connect} from 'react-redux';
import * as CoursesAPI from '../API/CoursesAPI'
import {getCourse, SharedInfo} from '../action'
import {withRouter} from "react-router-dom";
import Coupon from '../component/Coupon';
import {Comment,Tooltip} from "antd"
import { ShareAltOutlined } from '@ant-design/icons'
import {Avatar, Button, Dialog, Tabs, Tag, Toast,Popup,Form,Input,List} from "antd-mobile"
import uuid from "uuid";
import { CheckCircleOutline, ClockCircleOutline } from "antd-mobile-icons"
import Loading from "../component/Loading/Loading";
import '../css/ChangeInformation.scss'
import moment from 'moment';

class ChangeInformation extends Component {

    constructor(props) {
        super(props);
        this.state={
            id: null,
            realname: null,
            username: null,
            password: null,
            phone: null,
            companyName: null,
            salt: null,
            nickName: null,
            fromCompany: null,
            headUrl: null,
            wechatId: null,
            wechatNickname: "",
            bouns: null,
            job: null,
            type: 0,
            priv: -1
        }
    }
    componentDidUpdate(props){
        if(this.props!==props){
       this.setState(this.props.userInfo)
      this.setState(this.props.company)
      console.log(1)
        }
    }
    componentDidMount(){
        console.log(1)
      document.title="修改个人信息"
      this.setState(this.props.userInfo)
      this.setState(this.props.company)
      console.log(this.state.id)
    }

    render(){
        const {userInfo,userType,company}=this.props
        return(
           <div className="information">
          <div className="information-header">
          <div className="header-name">
                        <div onClick={ () => this.props.history.push("/") } className="header-link">首页</div>&nbsp;>&nbsp;
                        <div onClick={ () => this.props.history.push("/personal") } className="header-link">用户中心</div>&nbsp;>&nbsp;
                        <div onClick={ () => this.props.history.push("/information") } className="header-link">个人信息</div>&nbsp;>&nbsp;
                        </div>
                    <div className="header-user" onClick={ () => this.props.history.push("/personal") }>
                    </div>
            </div>
            <div style={{background:"white"}}>
            <List.Item prefix={<Avatar src={userInfo.headUrl} />} style={{lineHeight:"40px",marginLeft:"20px"}}>
                  <span style={{color: "#1890ff",marginLeft:"20px"}}>{userInfo.realname || userInfo.wechatnickname || userInfo.username}{ userType !== 0 && ( <span style={{color: "#AAAAAA"}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{company.companyName}</span>)}</span>
            </List.Item>
             </div>
             <div className="information-content">
             <div className="content-avatar">
              <Avatar src={userInfo.headUrl} style={{ '--size': '60px' }}/>
             </div>
            {this.state.id!==null&&<Form layout='vertical'>
             <Form.Item label='昵称' name='username' style={{height:"70px"}}>
            <Input placeholder='请输入用户名' clearable defaultValue={this.state.wechatnickname}/>
             </Form.Item>
             <Form.Item label='真实姓名' name='username' style={{height:"70px"}}>
            <Input placeholder='请输入密码' clearable  defaultValue={this.state.realname}/>
             </Form.Item>
             <Form.Item label='单位' name='username'  style={{height:"70px"}}>
            <Input placeholder='请输入用户名' clearable defaultValue={this.state.companyName}/>
             </Form.Item>
             <Form.Item label='手机号' name='username' style={{height:"70px"}}>
            <Input placeholder='请输入密码' clearable  defaultValue={this.state.phone}/>
             </Form.Item>
             <Form.Item label='邮箱' name='username' style={{height:"70px"}}>
            <Input placeholder='请输入用户名' clearable defaultValue={this.state.email}/>
             </Form.Item>
             <Form.Item label='银行卡账号' name='username'>
            <Input placeholder='请输入密码' clearable  defaultValue={this.state.bankCard}/>
             </Form.Item>
           </Form>}
             <div className="change-information"><Button>确认修改</Button></div>
             </div>
          </div>
            )
    }
}

    export default connect(
    ({login, course}) => ({
        isLogin: login.isLogin,
        userInfo: login.userInfo,
        course: course.data,
        userType: login.type,
        company: login.company
    }), dispatch => ({
        setSharedInfo: (data) => dispatch(SharedInfo(data)),
    })
)(withRouter(ChangeInformation));
