import React, { Component } from 'react';
import '../css/Video2.css'
import VideoComponent from '../component/VideoComponent'
import {withRouter} from 'react-router-dom';

class Video2 extends Component {

	data=[]

	state={
		address:"",
		question:[]
	}

	componentDidMount(){
		const chapter = parseInt(this.props.match.params.chapter)
		const name=parseInt(this.props.match.params.name)
		this.setState({
			address:this.address[chapter-1][name-1]
			// question:this.question[chapter][name]
		})
	}

	address="https://micro-weixun-input.oss-cn-beijing.aliyuncs.com/9-2.mp4"

	address=[
		[
			"https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/1/1.mp4",
			"https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/1/2.mp4",
			"https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/1/3.mp4",
			"https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/1/4.mp4",
			"https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/1/5.mp4",
			"https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/1/6.mp4",
			"https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/1/7.mp4",
			"https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/1/1.mp4"
		],
		[
			"https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/2/1.mp4",
			"https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/2/2.mp4",
			"https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/2/3.mp4",
			"https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/2/4.mp4",
			"https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/2/5.mp4",
			"https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/2/6.mp4",
			"https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/2/7.mp4",
			"https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/2/8.mp4",
			"https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/2/9.mp4",
			"https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/2/10.mp4",
			"https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/2/11.mp4",
			"https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/2/12.mp4",
			"https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/2/13.mp4",
			"https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/2/14.mp4",
			"https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/2/15.mp4",
			"https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/2/16.mp4"
		],
		[
			"https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/3/1.mp4",
			"https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/3/2.mp4",
			"https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/3/3.mp4",
			"https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/3/4.mp4",
			"https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/3/5.mp4",
			"https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/3/6.mp4",
			"https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/3/7.mp4",
			"https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/3/8.mp4",
			"https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/3/9.mp4",
			"https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/3/10.mp4",
			"https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/3/11.mp4"
		],
		[
			"https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/4/1.mp4",
			"https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/4/2.mp4",
			"https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/4/3.mp4",
			"https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/4/4.mp4",
			"https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/4/5.mp4",
			"https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/4/6.mp4",
			"https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/4/7.mp4",
			"https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/4/8.mp4",
			"https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/4/9.mp4",
			"https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/4/10.mp4",
			"https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/4/11.mp4",
			"https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/4/12.mp4",
			"https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/4/13.mp4",
			"https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/4/14.mp4",
			"https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/4/15.mp4",
			"https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/4/16.mp4",
			"https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/4/17.mp4",
			"https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/4/18.mp4",
			"https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/4/19.mp4",
			"https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/4/20.mp4"
		],
		[
			"https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/5/1.mp4",
			"https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/5/2.mp4",
			"https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/5/3.mp4",
			"https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/5/4.mp4",
			"https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/5/5.mp4",
			"https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/5/6.mp4",
			"https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/5/7.mp4",
			"https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/5/8.mp4",
			"https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/5/9.mp4",
			"https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/5/10.mp4",
			"https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/5/11.mp4",
			"https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/5/12.mp4",
			"https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/5/13.mp4",
			"https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/5/14.mp4",
			"https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/5/15.mp4",
			"https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/5/16.mp4",
			"https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/5/17.mp4",
			"https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/5/18.mp4"
		],
		[
			"https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/6/1.mp4",
			"https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/6/2.mp4",
			"https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/6/3.mp4",
			"https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/6/4.mp4",
			"https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/6/5.mp4",
			"https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/6/6.mp4",
			"https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/6/7.mp4",
			"https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/6/8.mp4",
			"https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/6/9.mp4",
			"https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/6/10.mp4",
			"https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/6/11.mp4",
			"https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/6/12.mp4",
			"https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/6/13.mp4",
			"https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/6/14.mp4",
			"https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/6/15.mp4",
			"https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/6/16.mp4",
			"https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/6/17.mp4",
			"https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/6/18.mp4",
			"https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/6/19.mp4",
			"https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/6/20.mp4",
			"https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/6/21.mp4"		
		],
		[
			"https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/7/1.mp4",
			"https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/7/2.mp4",
			"https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/7/3.mp4",
			"https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/7/4.mp4",
			"https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/7/5.mp4",
			"https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/7/6.mp4",
			"https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/7/7.mp4",
			"https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/7/8.mp4",
			"https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/7/9.mp4",
			"https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/7/10.mp4",
			"https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/7/11.mp4",
			"https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/7/12.mp4",
			"https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/7/13.mp4",
			"https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/7/14.mp4",
			"https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/7/15.mp4",
			"https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/7/16.mp4",
			"https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/7/17.mp4",
			"https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/7/18.mp4"
		],
		[
			"https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/8/1.mp4",
			"https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/8/2.mp4",
			"https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/8/3.mp4",
			"https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/8/4.mp4",
			"https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/8/5.mp4",
			"https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/8/6.mp4",
			"https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/8/7.mp4",
			"https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/8/8.mp4",
			"https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/8/9.mp4",
			"https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/8/10.mp4",
			"https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/8/11.mp4",
			"https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/8/12.mp4",
			"https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/8/13.mp4",
			"https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/8/14.mp4",
			"https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/8/15.mp4",
			"https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/8/16.mp4",
			"https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/8/17.mp4",
		],
	]

	question=[
		[
				[

				],
		],

	]

	// questions=[
	// 	{"time":"30","type":"single","question":"职业化的含义是","choose":"穿着服饰非常专业,积极配合领导的工作,既要有突出的能力；也要有良好的态度,只要有突出的能力就可以","answer":"c","explain":null},
	// 	{"time":"263","type":"single","question":"硬能力指的是","choose":"强势的沟通方法,身体强壮；体能好,不怕吃苦的能力,可以通过测量、考试进行量化评估的能力","answer":"d","explain":null},
	// 	{"time":"448","type":"single","question":"领导为什么看重服装、守时等小节","choose":"因为能够让领导感觉到安全可靠,因为领导都比较谨慎,因为领导比较挑剔,因为领导比较重视着装","answer":"a","explain":null},
	// 	{"time":"659","type":"single","question":"对那些重要但是不紧急的事务不及时处理可能会有什么后果？","choose":"其实没有什么影响,会立即有巨大影响,长期看会有一点小影响,长期地看；重要而紧急的事情会集中爆发","answer":"d","explain":null},
	// 	{"time":"1369","type":"single","question":"薪酬的高与低取决于什么","choose":"职务的高低,与领导的关系好坏,不可替代性,学历","answer":"c","explain":null}
	// ]

	render(){
		return(
			<div className="v_video_test">
				<VideoComponent video={{address:this.state.address,questions:this.state.question}} />
			</div>
		)
	}
}


export default withRouter(Video2);