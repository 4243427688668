import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Input, Button, message } from 'antd';
import { getZhengshuByEmail } from '../API/CoursesAPI';
class ToPcDownload extends Component {
    constructor (props) {
        super(props);
        this.state = {
            email: '',
            lessonId: null,
            name: ''
        }
    }
    componentDidMount () {
        const { lessonId, name } = this.props.match.params;
        this.setState({
            lessonId,
            name,
        })
    }
    render (){ 
        return (
            <div>
                <h3 style={{ textAlign: 'center' }}>输入邮箱用来获取你的证书及学习工具</h3>
                <div style={{ display: 'flex' }}>
                    <Input
                        type='text'
                        placeholder='输入你的邮箱'
                        onChange={
                            event => { this.setState({email: event.target.value}) }
                        }
                        value={ this.state.email }
                        style={{
                            borderBottomRightRadius: '0',
                            borderTopRightRadius: '0',
                            borderRight: 'none'
                        }}
                    />
                    <Button
                        type='primary'
                        style={{
                            borderBottomLeftRadius: '0',
                            borderTopLeftRadius: '0'
                        }}
                        onClick={ this.send }>确认发送</Button>
                </div>
            </div>
        )
    }

    send = () => {
        const emailRegexp = /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
        if (emailRegexp.test(this.state.email)) {
            message.info("正在发送");
            getZhengshuByEmail({
                lessonId: this.state.lessonId,
                name: this.state.name,
                email: this.state.email
            }).then(data => {
                if (data.state === 1) {
                    message.success("发送成功！");
                } else {
                    message.error("发送失败！" + data.msg);
                }
            })
        } else {
            message.error("您输入邮箱不正确!");
        }
    };

    goHome = event => {
        this.props.history.push("/", this.state);
    }
}

export default withRouter(ToPcDownload);