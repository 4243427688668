import React, {Component} from 'react';
import {connect} from 'react-redux';
import * as CoursesAPI from '../API/CoursesAPI'
import {getCourse, SharedInfo} from '../action'
import {withRouter} from "react-router-dom";
import Coupon from '../component/Coupon';
import {Table} from "antd"
import { ShareAltOutlined,DollarCircleTwoTone } from '@ant-design/icons'
import {Avatar, Button, Dialog, Tabs, Tag, Toast,Popup,Form,Input} from "antd-mobile"
import uuid from "uuid";
import { CheckCircleOutline, ClockCircleOutline,ContentOutline, PayCircleOutline,SendOutline ,UserOutline,HandPayCircleOutline,TextOutline,ExclamationCircleFill} from "antd-mobile-icons"
import Loading from "../component/Loading/Loading";
import '../css/Integral.scss'
import zhCN from 'antd/lib/locale/zh_CN';
import moment from 'moment';
import QRCode  from 'qrcode.react';
import html2canvas from "html2canvas"


class Integral extends Component {

    constructor(props) {
        super(props);
        this.state={
            
            showDetail:true
        }
    }
    componentDidMount(){
        document.title = "我的积分"
    }
    //点击保存按钮转成图片
    savePicture=()=>{
      console.log("save");
      html2canvas(this.picture).then(canvas => {
        this.imgUrl = canvas.toDataURL("image/png");
        this.saveFile(this.imgUrl, new Date().toLocaleString())
      });
    }
    //将图片保存到本地
    saveFile=(data, filename)=>{
      const save_link = document.createElementNS('http://www.w3.org/1999/xhtml', 'a');
      save_link.href = data;
      save_link.download = filename;
      const event = document.createEvent('MouseEvents');
      event.initMouseEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
      save_link.dispatchEvent(event);
    }
    render(){
        const { course, isBuy, company, userInfo, isLogin } = this.props
        return(
          <div className="integral">
          
            <div className="integral-header">
                    <div className="header-name">
                        <div onClick={ () => this.props.history.push("/") } className="header-link">首页</div>&nbsp;>&nbsp;
                        <div onClick={ () => this.props.history.push("/personal") } className="header-link">用户中心</div>&nbsp;>&nbsp;
                        <div onClick={ () => this.props.history.push("/integral") } className="header-link">我的积分</div></div>
                    <div className="header-user" onClick={ () => this.props.history.push("/personal") }>
                        <Avatar style={{ '--size': '30px' }} src={userInfo.headUrl} />
                    </div>
                </div>
                <div className="myWallet"><span><DollarCircleTwoTone  twoToneColor='#fadb14'/>余额：</span>&nbsp;&nbsp;&nbsp;<span>{this.props.userInfo.beans}微豆</span></div>
                <div className="integral-content">
                <Button  className="conversion" onClick={()=>this.props.history.push("/withdraw")}><HandPayCircleOutline />积分兑换</Button>
                <Button  className="detail" onClick={()=>this.props.history.push("/details")}><TextOutline />明细</Button> 
                </div>
                 {
              Dialog.show({
                header: (
                  <div style={{width:"100%",height:"150px"}}>
                  <img src="https://micro-weixun.oss-cn-beijing.aliyuncs.com/micro_pc_fe/micro-www-logo.png"  style={{margin:"50px auto 0",objectFit:"cover",width:"100px"}}/>
                  </div>
                ),
                image:"http://micro-weixun.oss-cn-beijing.aliyuncs.com/micro_mobile/home/welcome.JPG",
                title:"欢迎您，胖虎",
                content: (
                  <div>
                  <div style={{lineHeight:"28px"}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;您是微训网公司的员工，公司管理员已经为您选择了5门课程</div>
                  </div>
                ),
                closeOnAction: true,
                actions: [
                   [
                                                    {
                                                        key: 'exit',
                                                        text: '取消'
                                                    },
                                                    {
                                                        key: 'toLearn',
                                                        text: '去学习',
                                                        onClick: () => {
                                                            this.props.history.push('/mycourses');
                                                        }
                                                    }
                                                ]
                  ]
              })
               }
          </div>
            )
    }
}

    export default connect(
    ({login, course}) => ({
        isLogin: login.isLogin,
        userInfo: login.userInfo,
        course: course.data,
        userType: login.type,
        company: login.company
    }), dispatch => ({
        setSharedInfo: (data) => dispatch(SharedInfo(data)),
    })
)(withRouter(Integral));
