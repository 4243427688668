import { Button, Input, message } from "antd";
import React, { Component } from "react";
import * as naire from "../API/naire";
class Writequestionnaire extends Component{

    state = {

            name:'',
            phone:'',
            email:'',
            allow:false
    }
    handleName = (e)=>{
        this.setState({
            name:e.target.value
        })
    }
    handleEmail = (e)=>{
        this.setState({
            email:e.target.value
        })
    }
    handlePhone = (e)=>{
        this.setState({
            phone:e.target.value
        })
    }

    handleSubmit = ()=>{
        let content = {}
        content.name = this.state.name
        content.phone = this.state.phone
        content.email = this.state.email
        if(content.name === '' ||( content.phone === ''&&content.email === '')){
            message.error("请您留下手机或邮箱 以便我们后续联系")
            return;
        }
        this.setState({
            allow:true
        })
        naire.addnaire(content).then(data=>{
            if(data.status !== 0){
                message.error("上传错误")
            }
            else{
                message.success("上传成功")
            }
            this.setState({
                allow:false
            })
        })

    }
    render(){
        return(
            <div>
                <div style = {{padding:'50px'}}>
                    <div>姓名:</div>
                    <Input onChange = {this.handleName}/>
                    <div>电话:</div>
                    <Input onChange = {this.handlePhone}/>
                    <div>邮箱:</div>
                    <Input onChange = {this.handleEmail} style = {{marginBottom:"20px"}}/>
                    <Button type="primary" disabled = {this.state.allow} style = {{width:'100%',height:'30px'}} onClick = {this.handleSubmit}>提交</Button>
                </div>
                
            </div>
        )
    }
    

}


export default Writequestionnaire;