

const api2 = "/api/getvideoById.do"
const api3 = "/api/gettestvideo.do"
export const getall = (name) =>
  fetch("/api/getlesson.do?id=" + name, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': localStorage.token===undefined?null:localStorage.token,
    },
    mode: 'cors',
  }).then((res) => (res.json()))

export const record = (data)=>fetch("/api/record1.do",{
    method:'POST',
    headers:{
             'Access-Control-Allow-Origin':'*',
             'Content-Type': 'application/json',
             'Accept': 'application/json',
             'Authorization': localStorage.token
           },
           mode:'cors',
           body: JSON.stringify(data)
  }).then((res)=>(res.json()))

export const getVideo = (lessonId,videoId) =>

  fetch(`${api2}?lesson_id=${lessonId}&videoId=${videoId}`, {

    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': localStorage.token===undefined?null:localStorage.token,
    },
    mode: 'cors',
  }).then(res => res.json())
    .then(data => data)


export const gettestVideo = (chapter, lessonId) =>
  fetch(`${api3}?chapter=${chapter}&lesson_id=${lessonId}`, {

    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': localStorage.token===undefined?null:localStorage.token,
    },
    mode: 'cors',
  }).then(res => res.json())
    .then(data => data)

export const getExamPermission = (id) => {
  return fetch(`/api/getExamPermission.do?id=${id}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': localStorage.token,
    }
  })
  .then(res => res.json())
}

export const setAnswer = (videoId, questionsId) => {
  return fetch('/api/answer.do', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': localStorage.token,
    },
    body: JSON.stringify({
      videoId,
      questionsId
    })
  })
  .then(res => res.json())
}

export const getExamQuestions = (lessonId) => {
  return fetch(`/api/getExamQuestions.do?id=${lessonId}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': localStorage.token
    }
  }).then(
    res => res.json()
  )
}

export const getCourseBycoupon = (lessonId,res,code) => {
  return fetch(`/api/verify.do?coursesId=${lessonId}&res=${res}&code=${code}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': localStorage.token
    }
  }).then(
    res => res.json()
  )
}
export const getZhengshuByEmail = body => {
    return fetch(`/api/getZhengshuByEmail.do`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': localStorage.token
        },
        body: JSON.stringify(body)
    }).then(
        res => res.json()
    )
}

export const getScore = lessonId =>
    fetch(`/api/getScore.do?lesson_id=${lessonId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': localStorage.token
      }
    }).then(
        res => res.json()
    );

export const grader = testPaper =>
    fetch('/api/grader.do', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': localStorage.token
        },
        body: JSON.stringify(testPaper)
    }).then(
        res => res.json()
    );

export const time = time =>
    fetch('/api/recordtime.do', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': localStorage.token===undefined?null:localStorage.token
        },
        body: JSON.stringify(time)
    }).then(
        res => res.json()
    );

// TODO 标记视频播放完毕
export const recordProgress = videoId =>
    fetch(`/api/recordprogress.do?videoId=${videoId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': localStorage.token===undefined?null:localStorage.token
        },
    }).then(
        res => res.json()
    );

export const getLessons = (num = 1, count = 30) =>
    fetch(`/api/getlessons.do?num=${num}&count=${count}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
    }).then(
        res => res.json()
    );

export const getDeep = (parentId = 31) =>
    fetch(`/api/get_deep.do?parentId=${parentId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
    }).then(
        res => res.json()
    );

// page Integer 页数
// limit Integer 个数
// status Integer 状态 0代表已完成 1代表为完成 2代表无所谓
export const getMyBusinessCourses = (page = 1, pageSize, status) =>
    fetch(`/api/getMybusinessCourses.do?page=${page}&limit=${pageSize}&status=${status}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': localStorage.token===undefined?null:localStorage.token
        },
    }).then(
        res => res.json()
    );

export const sendMessage = courseName =>
    fetch(`/api/mosendmessage.do?courseName=${courseName}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': localStorage.token===undefined?null:localStorage.token
        },
    }).then(res => res.json());

export const getCourseList =(types,page)=>
    fetch(`/api/getlesson.do`,{
        method:'POST',
        headers:{
             'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': localStorage.token===undefined?null:localStorage.token
        },
          body: JSON.stringify({types,page})
    }).then(res=>res.json());

export const searchlesson =(lessonName,page)=>
    fetch(`/api/searchlesson.do?lessonName=${lessonName}&page=${page}`,{
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': localStorage.token===undefined?null:localStorage.token
        },
    }).then(
        res => res.json()
    );

export const getBacklog=()=>
   fetch(`/api/backlog.do`,{
    method:'GET',
    headers:{
        'Content-Type':'application/json',
        'Accept':'application/json',
        'Authorization': localStorage.token===undefined?null:localStorage.token
    },
   }).then(
      res=>res.json()
   );
