import React, { Component } from 'react';
import {withRouter,Link} from 'react-router-dom';
import  jsPDF from 'jspdf';
import '../css/zhengshu.css';
import  html2canvas from 'html2canvas';
window.html2canvas = html2canvas;



const download = () => {

    let img = new Image()
    var element = document.getElementById("demo");    // 这个dom元素是要导出pdf的div容器
    var w = element.offsetWidth;    // 获得该容器的宽
    var h = element.offsetWidth;    // 获得该容器的高
    var offsetTop = element.offsetTop;    // 获得该容器到文档顶部的距离
    var offsetLeft = element.offsetLeft;    // 获得该容器到文档最左的距离
    var canvas = document.createElement("canvas");
    var abs = 0;
    var win_i =  document.body.clientWidth;    // 获得当前可视窗口的宽度（不包含滚动条）
    var win_o = window.innerWidth;    // 获得当前窗口的宽度（包含滚动条）
    if (win_o > win_i) {
        abs = (win_o - win_i) / 2;    // 获得滚动条长度的一半
    }
    canvas.width = w * 2;    // 将画布宽&&高放大两倍
    canvas.height = h * 2;
    var context = canvas.getContext("2d");
    context.scale(2, 2);
    context.translate(-offsetLeft - abs, -offsetTop);
    // 这里默认横向没有滚动条的情况，因为offset.left(),有无滚动条的时候存在差值，因此
    // translate的时候，要把这个差值去掉
    html2canvas(element,{
        allowTaint: true,
        scale: 2 // 提升画面质量，但是会增加文件大小
    }).then(function (canvas) {
        var contentWidth = canvas.width;
        var contentHeight = canvas.height;
        //一页pdf显示html页面生成的canvas高度;
        var pageHeight = contentWidth / 592.28 * 841.89;
        //未生成pdf的html页面高度
        var leftHeight = contentHeight;
        //页面偏移
        var position = 0;
        //a4纸的尺寸[595.28,841.89]，html页面生成的canvas在pdf中图片的宽高
        var imgWidth = 595.28;
        var imgHeight = 592.28 / contentWidth * contentHeight;

        var pageData = canvas.toDataURL('image/jpeg', 1.0);

        var pdf = new jsPDF('', 'pt', 'a4');
        const url = canvas.toDataURL("image/jpeg", 1.0);
        console.log(url);
        var shengcheng=document.getElementById('shengcheng');
        var wenzi=document.getElementById('wenzi');
        wenzi.style.display='none';
        shengcheng.src=url;
        img.src = url;
        img.onload = () => {
            let href = url
            let a = document.createElement('a');     // 创建一个a节点插入的document
            a.download = 'test.jpeg'  // 设置a节点的download属性值
            a.href = href ;         // 将图片的src赋值给a节点的href
            a.click()
        }


        //有两个高度需要区分，一个是html页面的实际高度，和生成pdf的页面高度(841.89)
        //当内容未超过pdf一页显示的范围，无需分页
        if (leftHeight < pageHeight) {
            pdf.addImage(pageData, 'JPEG', 0, 0, imgWidth, imgHeight);
        } else {    // 分页
            while (leftHeight > 0) {
                pdf.addImage(pageData, 'JPEG', 0, position, imgWidth, imgHeight)
                leftHeight -= pageHeight;
                position -= 841.89;
                //避免添加空白页
                if (leftHeight > 0) {
                    pdf.addPage();
                }
            }
        }
        pdf.save('你的名字.pdf');
    });
};


class Zhengshu extends Component {
    render() {
        return (
            <div id="demo">
                <div className='zhengshutitlep'>长按保存您的证书</div>

                <div className='wenzi' id='wenzi' style={{marginTop:15}}>
                    <img className='wenziimg' src='https://weixun-demo.oss-cn-beijing.aliyuncs.com/photo/zhengshu.png'/>
                    <div className='zhengshutp'>
                        培训证书
                    </div>
                    <div className='zhengshuname'>
                        尊敬的金志宇
                    </div>
                    <div className='zhengshup1'>恭喜您，您已完成精品微课</div>
                    <div className='zhengshup2'>《大客户销售128招》的全部学习内容，</div>
                    <div className='zhengshup3'>经过测试，成绩优异！</div>
                    <div className='zhengshue1'>特发此证！</div>
                    <div className='zhengshue2'>发证单位：微训网</div>
                </div>

                <img id='shengcheng' className='shengcheng'/>
            </div>

        )
    }
}
export default withRouter(Zhengshu);