import React, {Component} from 'react';
import {connect} from 'react-redux';
import * as CoursesAPI from '../API/CoursesAPI'
import {getCourse, SharedInfo} from '../action'
import {withRouter} from "react-router-dom";
import Coupon from '../component/Coupon';
import {Comment,Tooltip} from "antd"
import { ShareAltOutlined } from '@ant-design/icons'
import {Avatar, Button, Dialog, Tabs, Tag, Toast,Popup,Form,Input} from "antd-mobile"
import uuid from "uuid";
import { CheckCircleOutline, ClockCircleOutline } from "antd-mobile-icons"
import Loading from "../component/Loading/Loading";
import '../css/course.scss'
import zhCN from 'antd/lib/locale/zh_CN';
import moment from 'moment';
import Video from './Video'

class CouseTabs extends Component {

    constructor(props) {
        super(props);
        this.state = {
            course: {},
            loading: true,
            chapters: [],
            code: "",
            orderId: "",
            data: {},
            buy: false,
            err_msg: "",
            openid: "",
            visible: false,
            isVip: false,
            showDialog: false,
            verify: false,
            sky:'1',
            ExampleComment:null,
            visible:false,
            showIntegral:false,
            comments:[
            {headUrl:"",name:"",content:"",time:"",replyFrom:[{headUrl:"",name:"",content:"",time:"",replyFrom:[]}] }
            ]
        }
    }

    // TODO 向管理员推荐课程
    shareToAdmin = () => {
        this.setState({
            loading: true
        }, () => {
            CoursesAPI.sendMessage(this.props.course.lessonName)
                .then(data => {
                    Toast.show({
                        icon: data.status === 0 ? "success": "fail",
                        content: data.msg
                    });
                }).finally(() => this.setState({loading: false}))
        })
    };

    // TODO 切换个人用户身份
    checkUserType = () => this.setState({ loading: true }, () => this.props.selectPerson(() => window.location.href = ""));

    componentDidMount() {
        
        if(this.props.course.lessonName){

        }
        const res = this.props.location.search.split("&", 1)
        const from = res[0].split("=")[1]
        sessionStorage.setItem("from", from);
        
        const id = this.props.match.params.id;
        CoursesAPI.getall(id).then(data => {
            console.log(data)

            if (data.status === 0){
                console.log("m")
                const course = data.data,
                    {isChapter, result} = this.format(course.chapters, course.ids);
                course.isChapter = isChapter;
                course[course.isChapter ? 'chapters' : 'selections'] = result;
                const selections = course.isChapter ? course.chapters[0].selections : course.selections;
                if (selections.length >= 2) {
                    selections[0].free = true;
                    selections[1].free = true;
                } else if (selections.length === 1)
                    selections[0].free = true;
                this.props.getCourse({msg: data.msg, data: course});
                const { isLogin, userType } = this.props;
                // TODO 判断是否为企业管理员推荐此课程
                if (isLogin && (userType === 1) && (data.msg !== '已购买') && (this.props.userInfo.fromCompany !== -1)) {
                    this.setState({
                        showDialog: true
                    });
                }
                document.title = course.lessonName;
                // if (this.props.userInfo.data !== undefined && (this.props.userInfo.data.userName === "王梓恒" || this.props.userInfo.data.userName === "韬" || this.props.userInfo.data.userName === "Jingjing" || this.props.userInfo.data.userName === "miya祝娟" || this.props.userInfo.data.userName === "崔小屹" || this.props.userInfo.data.userName === "闫静" || this.props.userInfo.data.userName === "monica" || this.props.userInfo.data.userName === "张瑜儿" || this.props.userInfo.data.userName === "王蘅" || this.props.userInfo.userName === "宗" || this.props.userInfo.data.userName === "赵纪" || this.props.userInfo.data.userName === "火风鼎（guobinzhang）" || this.props.userInfo.data.userName === "王慧🍃" || this.props.userInfo.data.userName === "?" || this.props.userInfo.data.userName === "良园")) {
                //     this.setState({
                //         isBuy: "已购买",
                //     })
                //     alert("您是本站vip，所有课程免费浏览")
                // } else {
                //     this.setState({
                //         isBuy: data.msg,
                //     })
                // }
                let SharedInfo = {}
                SharedInfo.title = data.data.lessonName
                SharedInfo.img = data.data.lessonIcon
                SharedInfo.des = '游戏式学习'
                this.props.setSharedInfo(SharedInfo)
            }
        }).finally(() => this.setState({loading: false}));

          console.log(this.props.userType,this.props.isBuy)
    
    }
    setvisible=()=>{
        this.setState({visible:true})
    }

    showTeacher = () => {
        this.setState({
            icon1: true,
        });
    }

    showMore = () => {
        this.setState({
            icon2: true,
        });
    }

    hide1 = () => {
        this.setState({
            icon1: false,
        });
    }

    hide2 = () => {
        this.setState({
            icon2: false,
        });
    }

    handleCancel = (e) => {
        this.setState({
            visible: false,
        });
    }

    

    // 去考试
    toExam = () => {
        CoursesAPI.getExamPermission(this.props.course.id)
            .then(data => {
                if (data.status === 0) {
                    this.props.history.push(`/exam/${this.props.match.params.id}`, this.state)
                } else {
                    alert(data.msg);
                }
            });
    }

   

    cancelVerify() {
        this.setState({
            verify: false,
        });
    }

    handleCouponPay() {
        this.setState({
            isBuy: "已购买",
        })
    }

    componentWillUnmount = () => {
        this.setState = (state,callback)=>{
            return;
        };
    }
    sky=(key)=>{  //简介 目录 老师介绍 tabs组件onChange
        if(key!=='5'){
         this.setState({sky:key})
         console.log(this.props.course)
        }else{
            this.setState({visible:true})
        }
    }

    format = (arr, ids) => {
        let result = [];
        if (arr.length !== 0 && arr[0]['chapter_sequence'] === 0) {
            for (let i = 0; i < arr.length; i++) {
                const selectionIndex = arr[i]['selection_sequence'] === 0 ? 0 : arr[i]['selection_sequence'] - 1;
                result[selectionIndex] = {
                    key: uuid(),
                    id: arr[i].id,
                    name: arr[i].name
                }
            }
            return {isChapter: false, result};
        } else {
            for (let i = 0; i < arr.length; i++) {
                let chapterIndex = arr[i]['chapter_sequence'] === 0 ? 0 : arr[i]['chapter_sequence'] - 1;
                let selectionIndex = arr[i]['selection_sequence'] === 0 ? 0 : arr[i]['selection_sequence'] - 1;
                if (result[chapterIndex]) {
                    result[chapterIndex].selections[selectionIndex] = {
                        key: uuid(),
                        id: arr[i].id,
                        name: arr[i].name
                    };
                } else {
                    const selections = [];
                    selections[selectionIndex] = {
                        key: uuid(),
                        id: arr[i].id,
                        name: arr[i].name
                    };
                    result[chapterIndex] = {
                        key: uuid(),
                        chapterName: arr[i]['chapter'],
                        selections
                    }
                }
            }
            return {isChapter: true, result};
        }
    }
    handelIntegral=()=>{
        this.setState({showIntegral:true},console.log(this.state.showIntegral))
    }
    renderVideoList = (videos, course) =>
        videos.map((item, index) => {
            if (item.free) {
                return (<div className='video video-ok' key={index}>
                    <div className='ok-play' onClick={() => this.props.history.push({ pathname: `/test/${course.id}/${course.ids[index]}/${item.name}` })}>
                        <div className="video-after"><Tag fill="outline" color="primary">视频</Tag></div>
                        <div className="video-name" style={{ width: `${document.documentElement.clientWidth - 132}px` }}>{item.name}</div>
                        <div className="video-state">
                            <Tag color="warning">免费</Tag>
                        </div>
                    </div>
                </div>);
            } else {
                return (
                    <div className={`video video-ok`} key={index}>
                        {
                            this.props.isBuy ?
                                <div className="ok-play" onClick={() => this.props.history.push({ pathname: `/video/${course.id}/${course.ids[index]}/${item.name}` })}>
                                    <div className="video-after"><Tag fill="outline" color="primary">视频</Tag></div>
                                    <div className="video-name" style={{ width: `${document.documentElement.clientWidth - 132}px` }}>{item.name}</div>
                                    {course.progress && <div className="video-state">{course.progress[index] === "y" ?
                                        <CheckCircleOutline style={{ color: "green" }} /> : <ClockCircleOutline />}</div>}
                                </div>
                                :
                                <div className="ok-play" onClick={() => {
                                    if (this.props.isLogin) {
                                        Dialog.alert({
                                            title: "提示",
                                            content: "请购买后学习！"
                                        })
                                    } else
                                        this.props.history.push(`/login?from=https://learning.wx798.com/course/${course.id}`);
                                }}>
                                    <div className="video-after"><Tag fill="outline" color="primary">视频</Tag></div>
                                    <div className="video-name" style={{ width: `${document.documentElement.clientWidth - 132}px` }}>{item.name}</div>
                                    {course.progress && <div className="video-state">{course.progress[index] === "y" ?
                                        <CheckCircleOutline style={{ color: "green" }} /> : <ClockCircleOutline />}</div>}
                                </div>
                        }
                    </div>
                )
            }
        }
        )


    render(){
        const { course, isBuy, company, userInfo, isLogin } = this.props;
        const {
            lessonIcon,
            teacher,
            lessonName,
            lessonDescription,
            lessonPrice,
            lessonMore,
            chapters,
            isChapter,
            selections,
            teacherName,
            teacher_icon
        } = course;
        const { loading, showDialog } = this.state;
        return(
            <div className="course">
              <Tabs style={{backgroundColor: "white", "--content-padding": "0"}} tabs={[{title: '简介', sub: '1'}, {title: '课程类表', sub: '2'}, {title: '老师介绍', sub: '3'},{title:'评论',sub:'4'}]} initialPage={0} activeKey={this.state.sky} onChange={this.sky}>
                    <Tabs.Tab title='简介' key='1' >
                        <div style={{ padding: '10px', backgroundColor: "#f5f5f9"}}>
                            <h5 className="block-title">课程简介</h5>
                            <div className="lessonInfo">
                                <h3 className="lessonName">{lessonName}</h3>
                                <p className="lessonDescription">{lessonDescription}</p>
                                <p className="lessonPrice">¥{lessonPrice}</p>
                            </div>
                            <h5 className="block-title">课程介绍</h5>
                            <div dangerouslySetInnerHTML={{__html: lessonMore}}></div>
                        </div>
                    </Tabs.Tab>
                    <Tabs.Tab title='课程列表' key='2' ref="Tab2" >
                        <div className="video-list" style={{ padding: '10px', backgroundColor: "#f5f5f9"}}>
                            {
                                isChapter ? (
                                        chapters.map((chapter, chapterIndex)=>(
                                            <div className="chapter-item" key={chapterIndex}>
                                                <div className="chapterName">{chapter.chapterName}</div>
                                                {this.renderVideoList(chapter.selections, course.isBuy)}
                                            </div>
                                        ))
                                    ) : this.renderVideoList(selections, course)
                            }
                            {
                                (isLogin && isBuy) && (
                                    <Button size="large" block onClick={this.toExam}>去考试</Button
>                                )
                            }
                        </div>
                    </Tabs.Tab>
                    <Tabs.Tab title='老师介绍' key='3' >
                        <div style={{ padding: '10px', backgroundColor: "#f5f5f9"}}>
                            <h3 className="lessonName" style={{paddingLeft: "10px"}}>{teacherName}</h3>
                            {teacher.length<70&&<img src={teacher} className="lessonInfo" />}
                            {teacher.length>70&&<div dangerouslySetInnerHTML={{__html:teacher}}></div>}
                        </div>
                    </Tabs.Tab>
                    {isBuy&&<Tabs.Tab title='评价' key='4'>
                    <div style={{padding: '10px', backgroundColor: "#f5f5f9"}}>
                   
                            <div style={{width:"90%"}}>
                         <Comment
                        actions={[<span key="comment-nested-reply-to">回复</span>]}
                        author={this.props.userType===1?<a>{this.props.company.companyName}</a>:<a>{this.props.userInfo.realname||this.props.userInfo.wechatnickname}</a>}
                        avatar={<Avatar src={this.props.userInfo.headUrl} />}
                        content={
                          <p>
                            We supply a series of design principles, practical patterns and high quality design
                            resources (Sketch and Axure).
                          </p>
                        }
                        datetime={
                              <Tooltip title={moment().subtract(1, 'days').format('YYYY-MM-DD HH:mm:ss')}>
                                <span>{moment().subtract(1, '天').fromNow()}</span>
                              </Tooltip>
                            }
                       >
                      </Comment>
                             <div style={{width:"80%",marginLeft:"10%"}}>
                              <Comment
                        actions={[<span key="comment-nested-reply-to">回复</span>]}
                        author={this.props.userType===1?<a>{this.props.company.companyName}</a>:<a>{this.props.userInfo.realname||this.props.userInfo.wechatnickname}</a>}
                        avatar={<Avatar src={this.props.userInfo.headUrl} />}
                        content={
                          <p>
                            We supply a series of design principles, practical patterns and high quality design
                            resources (Sketch and Axure).
                          </p>
                        }
                        datetime={
                              <Tooltip title={moment().subtract(1, 'days').format('YYYY-MM-DD HH:mm:ss')}>
                                <span>{moment().subtract(1, '天').fromNow()}</span>
                              </Tooltip>
                            }
                       >
                      </Comment>
                             </div>
                            </div>
                
                        <div className="send_comments"><div style={{width:"70%",float:"left",marginLeft:"15px"}}><Input placeholder='发言要友善呀~'/></div><Button>发送</Button></div>
                           
                    </div>
                    </Tabs.Tab>
                }
                {
                    isBuy&&<Tabs.Tab title={<ShareAltOutlined />} key='5'>
                       555
                    </Tabs.Tab>
                }
                </Tabs>
                 <Popup
              visible={this.state.visible}
              onMaskClick={() => {this.setState({visible:false})}}
              bodyStyle={{
                borderTopLeftRadius: '40px',
                borderTopRightRadius: '40px',
                minHeight: '30vh',
              }}
            >
            <div style={{height:"30px",width:"60%",marginLeft:"20%",marginTop:"30px",color:"black"}}>你可以将课程推荐给有需要的人！推荐成功后你将获得<a onClick={()=>this.props.history.push("/integral_api")}>积分</a>!</div>
            <div className="share">
            <Button className="shareWechat"><img src={require("../photo/微信.png")}/></Button>
            <Button className="shareWechatCircle"><img src={require("../photo/朋友圈.png")}/></Button>
            <Button className="savePhoto"><img src={require("../photo/图片.png")}/></Button>
            </div>
            </Popup>
            </div>
            )
    }
}

export default connect(
    ({login, course}) => ({
        isLogin: login.isLogin,
        userInfo: login.userInfo,
        isBuy: course.msg === "已购买",
        course: course.data,
        userType: login.type,
        company: login.company
    }), dispatch => ({
        setSharedInfo: (data) => dispatch(SharedInfo(data)),
        getCourse: (data) => dispatch(getCourse(data))
    })
)(withRouter(CouseTabs));




