import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import '../css/propaganda.scss';
import OurIdea from "../component/propaganda/OurIdea";
import Demo from "../component/propaganda/Demo";
import Support from "../component/propaganda/Support";
import Design from "../component/propaganda/Design";

class Propaganda extends Component {

    constructor(props) {
        super(props);
        this.state = {
            type: null
        };
    }
    // TODO 按钮抬起
    todo = type => this.setState({type});

    // TODO 回到首页
    backHome = () => this.setState({type: null});


    switch = (type, components) => {
        let view;
        switch (type) {
            case "ourIdea":
                view = components.ourIdea;
                break;
            case "design":
                view = components.design;
                break;
            case "support":
                view = components.support;
                break;
            default: view = components.demo;
        }
        return view;
    };

    render() {
        const { type } = this.state;
        return type === null ? (
            <div className='propaganda'>
                <div className="content">
                    <div className='logo' />
                    <p className='slogan'>微训，将学习快乐到底！</p>
                    <ul className="btnGroups">
                        <li>
                            <div className='btn btn-1' onTouchEnd={ () => this.todo("ourIdea") } />
                            <div className='btn btn-2' onTouchEnd={ () => this.todo("design") } />
                        </li>
                        <li>
                            <div className='btn btn-3' onTouchEnd={ () => this.todo("support") } />
                            <div className='btn btn-4' onTouchEnd={ () => this.todo("demo") } />
                        </li>
                    </ul>
                </div>
            </div>
        ) : (this.switch(type, {
            ourIdea: <OurIdea backHome={ this.backHome } />,
            design: <Design backHome={ this.backHome } />,
            support: <Support backHome={ this.backHome } />,
            demo: <Demo backHome={ this.backHome } />,
        }));
    }
}

export default withRouter(Propaganda);