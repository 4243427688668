import React, { Component } from 'react';
import PreloadVideo from "../component/PreloadVideo";
import '../css/suntwo.css';
import {Link} from "react-router-dom";

class Suntwo extends Component {

    componentDidMount(){

    }

    render(){
        return(
            <div className='sunbactwo'>
                <div className="videoContainer"><PreloadVideo url='https://micro-weixun.oss-cn-beijing.aliyuncs.com/micro_mobile_propaganda/video/jiyu.mp4' /></div>
                <Link to='Sun'><div className="twoone"></div></Link>
                <Link to='Sunthree'><div className="twotwo"></div></Link>
                <div className="twothree"></div>
                <Link to='Sunfour'><div className="twofour"></div></Link>
                <Link to='Anim'><div className='twobottom'></div></Link>
            </div>

    )
    }
}
export default Suntwo
