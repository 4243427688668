import React, { Component } from 'react';
import '../css/suntwo.css';
import Bigbac from '../photo/long.jpg';
import Onech from "../photo/onech.gif";
import Twoch from "../photo/twoch.gif";
import Threech from '../photo/threech.gif';
import Fourch from '../photo/fourch.gif';
import Fivech from '../photo/fivech.gif';
import Sixch from '../photo/sixch.gif';

import zoulu from '../photo/zoulu.gif';
class Animtwo extends Component{
    state={
        one:'block',
        two:'none',
        three:'none',
        four:'none',
        five:'none',
        six:'none',
        seven:'none'
    }
    thebiga(){
        let bigone=this.refs.bigone;
        let onech=this.refs.onech;
        let sudu=0;
        onech.src=zoulu;
        var that=this;
        let theone=setInterval(function () {
            sudu=sudu-1;
            bigone.style.left=sudu+'%';
            if(sudu<-99){
                that.setState({
                    one:'none',
                    two:'block'
                });
                clearInterval(theone);
            }
        },30);
        bigone.style.left='-100%';
    }
    thebigc(){
        let bigone=this.refs.bigone;
        let threech=this.refs.threech;
        let sudu=-200;
        threech.src=zoulu;
        var that=this;
        let theone=setInterval(function () {
            sudu=sudu-1;

            bigone.style.left=sudu+'%';
            threech.style.left='5%';
            threech.style.width='60%';
            if(sudu<-299){
                that.setState({
                    three:'none',
                    four:'block'
                });
                clearInterval(theone);
            }
        },30);
        bigone.style.left='-300%';
    }
    thebigb(){
        let bigone=this.refs.bigone;
        let twoch=this.refs.twoch;
        let sudu=-100;
        twoch.src=zoulu;
        var that=this;
        let theone=setInterval(function () {
            sudu=sudu-1;
            bigone.style.left=sudu+'%';
            twoch.style.left='5%';
            twoch.style.width='60%';
            if(sudu<-199){
                that.setState({
                    two:'none',
                    three:'block'
                });
                clearInterval(theone);
            }
        },30);
        bigone.style.left='-200%';
    }
    thebigd(){
        let bigone=this.refs.bigone;
        let fourch=this.refs.fourch;
        let sudu=-300;
        fourch.src=zoulu;
        var that=this;
        let theone=setInterval(function () {
            sudu=sudu-1;
            fourch.style.left='5%';
            fourch.style.width='60%';
            bigone.style.left=sudu+'%';
            if(sudu<-399){
                that.setState({
                    four:'none',
                    five:'block'
                });
                clearInterval(theone);
            }
        },30);
        bigone.style.left='-400%';
    }
    thebige(){
        let bigone=this.refs.bigone;
        let fivech=this.refs.fivech;
        let sudu=-400;
        fivech.src=zoulu;
        var that=this;
        let theone=setInterval(function () {
            sudu=sudu-1;
            fivech.style.left='5%';
            fivech.style.width='60%';
            bigone.style.left=sudu+'%';
            if(sudu<-499){
                that.setState({
                    five:'none',
                    six:'block'
                });
                clearInterval(theone);
            }
        },30);
        bigone.style.left='-500%';
    }
    thebigf(){
        let bigone=this.refs.bigone;
        let sixch=this.refs.sixch;
        let sudu=-500;
        sixch.src=zoulu;
        var that=this;
        let theone=setInterval(function () {
            sudu=sudu-1;
            sixch.style.left='5%';
            sixch.style.width='60%';
            bigone.style.left=sudu+'%';
            if(sudu<-599){
                that.setState({
                    six:'none',
                    seven:'block'
                });
                clearInterval(theone);
            }
        },30);
        bigone.style.left='-600%';
    }
    render() {

        return (
            <div className='a'>
                <div>
                    <img ref='bigone' className='animtwobac'  draggable="false" src={Bigbac} onDragStart={
                        (e) => {
                            if (e && e.preventDefault) {
                                e.preventDefault();
                            } else {
                                window.event.returnValue = false;
                            }
                        }
                    }/>
                </div>
                <img ref='onech' className="anim-onech" style={{display:this.state.one}} src={Onech} onClick={this.thebiga.bind(this)}/>
                <img ref='twoch' className="anim-twoch" style={{display:this.state.two}} onClick={this.thebigb.bind(this)} src={Twoch}/>
                <img ref='threech' className="anim-threech" style={{display:this.state.three}} onClick={this.thebigc.bind(this)} src={Threech}/>
                <img ref='fourch' className="anim-fourch" style={{display:this.state.four}} onClick={this.thebigd.bind(this)} src={Fourch}/>
                <img ref='fivech' className="anim-fivech" style={{display:this.state.five}} onClick={this.thebige.bind(this)} src={Fivech}/>
                <img ref='sixch' className="anim-sixch" style={{display:this.state.six}} onClick={this.thebigf.bind(this)} src={Sixch}/>

            </div>
        )
    }
}

export default Animtwo;
