import React, { Component } from "react";
import "../css/gameList.scss";

class GameList extends Component {

    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidMount() {
        document.title = "游戏学习";
    }

    render() {
        return (
            <div className="gameList">
                <div className="game-item" onClick={() => window.location.href = "http://m.wx798.com/H5/demo2/index.html"}>
                    <div className="game-index">
                        <img src="https://micro-weixun.oss-cn-beijing.aliyuncs.com/micro_mobile/home/game-3.PNG" alt=""/>
                    </div>
                    <p className="gameName">消防培训</p>
                </div>
                <div className="game-item" onClick={() => window.location.href = "https://learning.wx798.com/pc_sun"}>
                    <div className="game-index">
                        <img src="https://micro-weixun.oss-cn-beijing.aliyuncs.com/micro_mobile/home/game-1.PNG" alt=""/>
                    </div>
                    <p className="gameName">阳阳闯关</p>
                </div>
                <div className="game-item" onClick={() => window.location.href = "http://m.wx798.com/H5/demo1/index.html"}>
                    <div className="game-index">
                        <img src="https://micro-weixun.oss-cn-beijing.aliyuncs.com/micro_mobile/home/game-4.PNG" alt=""/>
                    </div>
                    <p className="gameName">数字大冒险</p>
                </div>
                <div className="game-item" onClick={() => window.location.href = "https://learning.wx798.com/newme"}>
                    <div className="game-index">
                        <img src="https://micro-weixun.oss-cn-beijing.aliyuncs.com/micro_mobile/home/game-2.PNG" alt=""/>
                    </div>
                    <p className="gameName">新员工培训</p>
                </div>
            </div>
        )
    }
}

export default GameList;