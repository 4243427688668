import React, {Component} from 'react';
import Course from './pages/course'
import Test from './pages/Test'
import Video from './pages/Video'
import Video2 from './pages/Video2'
import {Route, Switch} from 'react-router-dom'

import * as user from './API/user'
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {isLogin, setRegisterData} from './action';
import Exam from './pages/Exam';
import CertificateDownload from './pages/CertificateDownload';
import ToPcDownload from './pages/ToPcDownload';

import Sun from './pages/Sun';
import Sunone from './pages/Sunone';
import Suntwo from './pages/Suntwo';
import Sunthree from './pages/Sunthree';
import Sunfour from './pages/Sunfour';
import Animtwo from './pages/Animtwo';
import Dakehu from './pages/Dakehu';
import Dakehuone from './pages/Dakehuone';
import Dakehutwo from './pages/Dakehutwo';
import Dakehuthree from './pages/Dakehuthree';
import Dakehufour from './pages/Dakehufour';
import Dakehufive from './pages/Dakehufive';
import Dakehusix from './pages/Dakehusix';
import Dakehuseven from './pages/Dakehuseven';
import Dakehueight from './pages/Dakehueight';
import Dakehucourse from './pages/Dakehucourse';
import Dakehuteacher from './pages/Dakehuteacher';
import Anim from "./pages/Anim";
import Newme from "./pages/newme";
import Zhengshu from "./pages/Zhengshu";
import Newmeone from "./pages/newmeone";
import Newmetwo from "./pages/newmetwo";
import Newmethree from "./pages/newmethree";
import Newmefour from "./pages/newmefour";
import CourseHome from "./pages/course-home";
import MyCourse from "./pages/MyCourse/MyCourse";
import MyCourse2 from "./pages/MyCourse/MyCourse2";
import Treasure from "./pages/Treasure"
import Propaganda from "./pages/Propaganda";
import PropagandaCourse from "./pages/PropagandaCourse";
import Login from "./pages/Login";
import {Button} from "antd-mobile"
import Buy from "./pages/Buy";
import CourseList from "./pages/CourseList";
import GameList from "./pages/GameList";
import Personal2 from "./pages/Personal2";
import Personal from "./pages/Personal";
import History from './pages/History/History'
import {Dialog} from 'antd-mobile'
import {WhiteSpace} from 'antd-mobile-v2'
import Agreement from './pages/Agreement/Agreement'
import AddPhone from './pages/AddPhone/AddPhone'
import IntegralApi from './pages/IntegralApi'
import Integral from './pages/Integral'
import Information from './pages/Information'
import Myshare from './pages/Myshare'
import Details from './pages/Details'
import Withdraw from './pages/Withdraw'
import ChangeInformation from './pages/ChangeInformation'
import Money from './pages/Money'
import TopUp from './pages/TopUp'
import WithdrawCash from './pages/WithdrawCash'
import * as CourseAPI from "./API/CoursesAPI";
import './App.css';
import * as Base64 from "js-base64";
import SearchResult from './pages/SearchResult';
import CourseTabs from './pages/CourseTabs';
import Videoplayer from './pages/Videoplayer'
import SavePicture from './pages/SavePicture'

import Writequestionnaire from './pages/writequestionnaire';
import Questionnaireinfo from './pages/questionnaireinfo';

class App extends Component {

    constructor(props) {
        super(props);
        this.state = {
            wxConfig: null,
            selected: true
        }
    }

    // nologin=['/newmefour','/newmethree','/newmetwo','/newmeone','/newme','/sunone','/Anim','/sun','/add_phone','/propaganda','/propaganda/ourIdea','/propaganda/thoughtDesign','/propaganda/support','/propaganda/demo','/gettreasure','/propagandacourse/1','/propagandacourse/2','/propagandacourse/3','/propagandacourse/4','/propagandacourse/5']

    loginPath = ['/buy/'];
    // loginPath = [];

    componentDidUpdate(prevProps, prevState, snapshot) {
        // 路由變化
        if (this.props['location'].pathname !== prevProps.location.pathname)
            this.isLogin();
    }

    isLogin = () => {
        let path = this.props.location.pathname;
        let willLogin = false;
        for (let key in this.loginPath) {
            console.log(path.indexOf(this.loginPath[key]) !== -1);
            if (path.indexOf(this.loginPath[key]) !== -1) {
                willLogin = true;
                break;
            }
        }

        user.isLogin().then(data => {
            if (data.status === 0) {
                this.props.isLogin(data.data);
                this.setState({selected: data.data.user.fromCompany === -1 || (data.data.type !== null)}, () => {
                    if (true) {
                        CourseAPI.getMyBusinessCourses(1, 10, 2)
                            .then(_data => {
                                console.log(_data)
                                if (_data.status === 0) {
                                    if (_data.data.count!== 0) {
                                        localStorage.noFirst = true;

                                        Dialog.show({
                                            header: (
                                            <div style={{width:"100%",height:"130px"}}>
                                            <img src="https://micro-weixun.oss-cn-beijing.aliyuncs.com/micro_pc_fe/micro-www-logo.png"  style={{margin:"50px auto 0",objectFit:"cover",width:"100px"}}/>
                                            </div>
                                            ),
                                            image:"http://micro-weixun.oss-cn-beijing.aliyuncs.com/micro_mobile/home/welcome.JPG",
                                            title: `欢迎您，${data.data.user.realname || data.data.user.wechatNickname || data.data.user.username}`,
                                            content:(
                                                   <div>
                                                  <div>`您是${data.data.company.companyName}公司的员工，公司管理员已经为您选择了 ${_data.data.count}门课程`</div>
                                                  </div>
                                                ),
                                            closeOnAction: true,
                                            actions: [
                                                [
                                                    {
                                                        key: 'exit',
                                                        text: '取消'
                                                    },
                                                    {
                                                        key: 'toLearn',
                                                        text: '去学习',
                                                        onClick: () => {
                                                            this.props.history.push('/mycourses');
                                                        }
                                                    }
                                                ]
                                            ]
                                        });
                                    }
                                }
                            })
                    }
                });
            } else {
                if (data.msg === "用户未登录,无法获取当前用户的信息")
                    if (willLogin)
                        this.props.history.push('/login?from=' + window.location.href);
                else if (data.msg === '未注册')
                    this.props.setRegisterData(data.data);
            }
        });
    }

    componentDidMount() {
        console.log(Base64)
      let clone = Base64.encode(JSON.stringify({"aa":'1',"bb":2}))
         let clone1 = JSON.parse(Base64.decode(clone))
         this.setState({arr:clone1})

         console.log(clone)
         console.log(clone1)
    
        // console.log(Base64.decode("eyJtc2ciOiLnmbvlvZXmiJDlip8iLCJ1bmlvbmlkIjoiMTIzNDU2In0="));
        if (!this.state.wxConfig) {
            fetch('/api/wxConfig.do', {
                headers: {
                    'Content-Type': 'application/json'
                },
                method: 'POST',
                body: JSON.stringify({"url": encodeURIComponent(window.location.href.split("#")[0])})
            }).then(res => res.json()).then(res => {
                if (res.status === 0) {
                    this.setState({
                        wxConfig: JSON.parse(res.data)
                    }, () => {
                        const config = this.state.wxConfig;
                        window.wx.config({
                            // 开启调试模式,调用的所有api的返回值会在客户端alert出来
                            appId: config.appId, // 必填，公众号的唯一标识
                            timestamp: config.timestamp, // 必填，生成签名的时间戳
                            nonceStr: config.nonceStr, // 必填，生成签名的随机串
                            signature: config.signature,// 必填，签名，见附录1
                            jsApiList: [
                                "onMenuShareAppMessage"
                            ] // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
                        });
                    });
                }
            })
        }
        let path = this.props.location.pathname
        let para = window.location.search.split("&");
        if (para[0] !== "") {


            if (para[0].startsWith("?uu")) {

                let uu = para[0].substring(4)
                if (path === '/login') {
                    return;
                }
                if (path === '/add_phone') {
                    // let res=JSON.parse(Base64.decode(uu))
                    // localStorage.token=res.data.unionid;
                    return;
                }

                if (path === '/dakehu') {
                    // let res=JSON.parse(Base64.decode(uu))
                    // localStorage.token=res.data.unionid;
                    return;
                }


                if (path === '/newme' || path === '/Anim' || path === '/newmefour') {
                    // let res=JSON.parse(Base64.decode(uu))
                    // localStorage.newmetoken=res.data.unionid+'newme';
                    localStorage.newmetoken = uu + 'newme';
                    window.location.href = 'https://' + window.location.host + window.location.pathname
                    return;
                } else {
                    console.log(Base64)
                    let res = JSON.parse(Base64.decode(uu))
                    localStorage.token = res.data.unionid;
                    window.location.href = 'https://' + window.location.host + window.location.pathname
                    return;
                }
            } else {


            }
        }
        this.isLogin();
    }

    // 切換用戶類型
    selectPerson = (callBack) => {
        user.choosetype(0).then(data => {
            if (data.status === 0) {
                this.setState({
                    selected: true
                },callBack);
            } else {

                Dialog.alert({
                    title: "提示",
                    content: data.msg,
                    closeOnMaskClick: true,
                });
                            }
        })
    };

    // 切換用戶類型
    selectBusiness = (callBack) => {
        user.choosetype(1).then(data => {
            if (data.status === 0) {
                this.setState({
                    selected: true
                },callBack)
            } else {
                Dialog.alert({
                    title: "提示",
                    content: data.msg,
                    closeOnMaskClick: true,
                });
            }
        })
    };

    componentWillReceiveProps(nextProps) {
        let shareInfo = {}
        console.log(shareInfo)
        if (nextProps.shareInfo === undefined || JSON.stringify(nextProps.shareInfo) === '{}') {
            shareInfo.title = '微训网新员工培训'
            shareInfo.img = 'https://micro-weixun.oss-cn-beijing.aliyuncs.com/micro_white.jpg'
            shareInfo.des = '游戏式学习'
        } else {
            shareInfo.title = nextProps.shareInfo.title
            shareInfo.img = nextProps.shareInfo.img
            shareInfo.des = nextProps.shareInfo.des
        }

        window.wx.ready(function () {
            window.wx.onMenuShareTimeline({
                title: shareInfo.title, // 分享标题
                link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                imgUrl: shareInfo.img, // 分享页的缩略图（绝对路径/在线引用路径）
                success: function () {
                    // alert("成功")
                    // 用户点击了分享后执行的回调函数
                    alert("分享成功!!!");
                }
            });
            window.wx.onMenuShareAppMessage({
                title: shareInfo.title, // 分享标题
                desc: shareInfo.des, // 分享描述
                link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                imgUrl: shareInfo.img, // 分享页的缩略图（绝对路径/在线引用路径）
                type: '', // 分享类型,music、video或link，不填默认为link
                dataUrl: '', // 如果type是music或video，则要提供数据链接，默认为空
                success: function () {
                    // 用户点击了分享后执行的回调函数
                    alert("分享成功!!!");
                }
            });
        });
    }




    render() {
        const {userInfo} = this.props;
        return (
            <div className="app">
                {
                    !this.state.selected &&
                    <div className="selected-type">
                        <div className="content">
                            <p className="selected-type-title">登录提示</p>
                            <p className="des">&nbsp;&nbsp;&nbsp;&nbsp;您是<strong>{userInfo.company ? userInfo.company.companyName : "未知公司"}</strong>的员工，请您完成公司给您制定的学习任务，您也可以选择以个人身份登录学习！
                            </p>
                            <Button style={{width: "100%"}} size="large" color="primary" onClick={ () => this.selectBusiness(() => {
                                window.location.reload()

                            })}>企业账户</Button>
                            <WhiteSpace />
                            <WhiteSpace />
                            <Button style={{width: "100%"}} size="large" onClick={ () => this.selectPerson(() => {
                                window.location.reload()
                            })}>个人账户</Button>
                        </div>
                    </div>
                }
                <Switch>
                    <Route path="/editquestionnaire" render={() => (<Writequestionnaire/>)}/>
                    <Route path="/questionnaire" render={() => (<Questionnaireinfo/>)}/>
                    <Route path="/xiaoshou/:chapter/:name" render={() => (<Video2/>)}/>
                    <Route path="/anim" render={() => (<Anim/>)}/>
                    <Route path="/zhengshu" render={() => (<Zhengshu/>)}/>
                    <Route path="/dakehu" render={() => (<Dakehu/>)}/>
                    <Route path="/dakehuone" render={() => (<Dakehuone/>)}/>
                    <Route path="/dakehutwo" render={() => (<Dakehutwo/>)}/>
                    <Route path="/dakehuthree" render={() => (<Dakehuthree/>)}/>
                    <Route path="/dakehufour" render={() => (<Dakehufour/>)}/>
                    <Route path="/dakehufive" render={() => (<Dakehufive/>)}/>
                    <Route path="/dakehusix" render={() => (<Dakehusix/>)}/>
                    <Route path="/dakehuseven" render={() => (<Dakehuseven/>)}/>
                    <Route path="/dakehueight" render={() => (<Dakehueight/>)}/>
                    <Route path="/dakehucourse" render={() => (<Dakehucourse/>)}/>
                    <Route path="/dakehuteacher" render={() => (<Dakehuteacher/>)}/>
                    <Route path="/animtwo" render={() => (<Animtwo/>)}/>
                    <Route path="/sunfour" render={() => (<Sunfour/>)}/>
                    <Route path="/sunthree" render={() => (<Sunthree/>)}/>
                    <Route path="/suntwo" render={() => (<Suntwo/>)}/>
                    <Route path="/sunone" render={() => (<Sunone/>)}/>
                    <Route path="/newme" render={() => (<Newme/>)}/>
                    <Route path="/newmeone" render={() => (<Newmeone/>)}/>
                    <Route path="/newmetwo" render={() => (<Newmetwo/>)}/>
                    <Route path="/newmethree" render={() => (<Newmethree/>)}/>
                    <Route path="/newmefour" render={() => (<Newmefour/>)}/>
                    <Route path="/buy/:id" render={() => (<Buy/>)}/>
                    <Route path="/courseList" render={() => (<CourseList/>)}/>
                    <Route path="/gameList" render={() => (<GameList/>)}/>
                    <Route path="/sun" render={() => (<Sun/>)}/>
                    <Route exact path='/login' component={Login}/>
                    <Route path="/propagandaCourse/:id" render={() => (<PropagandaCourse/>)}/>
                    <Route path="/gettreasure" render={() => (<Treasure/>)}/>
                    {/*<Route path="/mycourses" render={() => (<MyCourse2/>)}/>*/}
                    <Route path="/mycourses" render={() => (<MyCourse/>)}/>
                    <Route path="/history" render={() => (<History/>)}/>
                    <Route path="/video/:lessonId/:videoId" render={() => (<Video/>)}/>
                    <Route path="/Videoplayer/:lessonId/:videoId" render={() => (<Videoplayer/>)}/>
                    <Route path="/course/:id" render={() => (<Course selectPerson={this.selectPerson}/>)}/>
                    <Route path="/exam/:id" render={() => (<Exam/>)}/>
                    <Route exact path="/propaganda" render={() => (<Propaganda/>)}/>
                    {/*<Route exact path="/personal" render={() => (<Personal2 selectBusiness={ this.selectBusiness } selectPerson={ this.selectPerson } />)}/>*/}
                    <Route exact path="/personal" render={() => (<Personal selectBusiness={ this.selectBusiness } selectPerson={ this.selectPerson } />)}/>
                    <Route path="/download-certificate/:id" render={() => (<CertificateDownload/>)}/>
                    <Route path="/to-pc-download" render={() => (<ToPcDownload/>)}/>
                    <Route path='/add_phone' render={() => (<AddPhone />)}/>
                    <Route exact path='/agreement' render={() => (<Agreement />)}/>
                    <Route exact path='/search_result' render={()=>(<SearchResult />)}/>
                    <Route exact path='/integral_api' render={()=>(<IntegralApi />)}/>
                    <Route exact path='/information' render={()=>(<Information />)}/>
                    <Route exact path='/integral' render={()=>(<Integral />)}/>
                    <Route exact path='/myshare' render={()=>(<Myshare />)}/>
                    <Route exact path='/details' render={()=>(<Details/>)}/>
                    <Route exact path='/withdraw' render={()=>(<Withdraw />)} />
                    <Route exact path='/changeInformation' render={()=>(<ChangeInformation />)} />
                    <Route exact path='/money' render={()=>(<Money />)} />
                    <Route exact path='/withdrawcash' render={()=>(<WithdrawCash/>)} />
                    <Route exact path='/top_up' render={()=>(<TopUp/>)} />
                    <Route exact path='/course_tabs' render={()=>(<CourseTabs/>)} />
                    <Route exact path='/video_player' render={()=>(<Videoplayer/>)} />
                    <Route exact path='/save_picture/:id' render={()=>(<SavePicture />)}/>
                    {/* TODO 新版首页 */}
                    {/*<Route path="/demo-home" render={ ()=>(<DemoHome/>) } />*/}
                    {/* TODO 临时首页 课程页面 */}
                    <Route exact path="/" render={() => <CourseHome selectBusiness={this.selectBusiness} />}/>
                </Switch>
            </div>
        );
    }
}

export default connect(
    ({ login, WeixinInfo }) => ({
        shareInfo: WeixinInfo,
        userInfo: login,
    }),
    dispatch => ({
        isLogin: data => dispatch(isLogin(data)),
        setRegisterData: data => dispatch(setRegisterData(data))
    })
)(withRouter(App));