import React, { Component } from 'react';
import { Input, Button } from 'antd';
import '../css/coupon.css'
import * as CoursesAPI from '../API/CoursesAPI';
import * as Base64 from "js-base64";
class Coupon extends Component {

	componentDidMount(){
	}

	state={
		res:"",
		code:""
	}

	handleResChange(e) {
		this.setState({
			res: e.target.value
		})
	}

	handleCodeChange(e) {
		this.setState({
			code: e.target.value
		})
	}

	render(){
		return(
			<div className="coupon_enclose-bg" onClick={this.props.cancelVerify}>
				<div className='coupon_enclose' onClick={event => event.stopPropagation()}>
					<h2 className='title'>验证优惠码</h2>
					<div>
						<img width="80" height="32" src={"/api/verifyCode.do?coursesId="+this.props.courseId+"&head="+localStorage.token} />
					</div>
					<div className='captcha'>
						请输入结果:<Input placeholder='验证码' onChange={this.handleResChange.bind(this)} value={this.state.res}/>
					</div>
					<div>
						输入优惠码:<Input placeholder='优惠码' onChange={this.handleCodeChange.bind(this)} value={this.state.code}/>
					</div>
					<div className='buttonGroup'>
						<Button className='left' type='primary' onClick={this.getCourseByCoupon.bind(this)}>确定</Button>
						<Button className='right' type='danger' onClick={this.props.cancelVerify}>取消</Button>
					</div>
				</div>
			</div>
		)
	}

	getCourseByCoupon(){
		CoursesAPI.getCourseBycoupon(this.props.courseId,this.state.res,this.state.code).then(data=>{
			if(data.status===0){
				this.props.cancelVerify();
				this.props.handleCouponPay();
			}
		})
	}
}

export default Coupon;
