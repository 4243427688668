import React, { Component } from "react";
import { connect } from "react-redux";
import { List, Avatar, Button, Dialog,Mask, SpinLoading} from "antd-mobile";
import * as CourseAPI from "../API/CoursesAPI";
import {DollarCircleOutlined, LineHeightOutlined } from '@ant-design/icons'
import { ContentOutline, ClockCircleOutline, PayCircleOutline,SendOutline ,UserOutline} from "antd-mobile-icons";
import { withRouter } from "react-router-dom";
import "../css/personal2.css";
import * as user from '../API/user'
import {WhiteSpace} from 'antd-mobile-v2'
import { Pie, measureTextWidth } from '@ant-design/plots';

class Personal2 extends Component {

    constructor(props) {
        super(props);
        this.state = {
            courses:[],
            page:1,
            loading:false,
            pageSize:5,
            hasMore:false,
            configData:[],
            config:null,
            partB:0,
            partA:0,
            total:0
        }
    }
    renderStatistic(containerWidth, text, style) {
        const { width: textWidth, height: textHeight } = measureTextWidth(text, style);
        const R = containerWidth / 2; // r^2 = (w / 2)^2 + (h - offsetY)^2
    
        let scale = 1;
    
        if (containerWidth < textWidth) {
          scale = Math.min(Math.sqrt(Math.abs(Math.pow(R, 2) / (Math.pow(textWidth / 2, 2) + Math.pow(textHeight, 2)))), 1);
        }
    
        const textStyleStr = `width:${containerWidth}px;`;
        return `<div style="${textStyleStr};font-size:${scale}em;line-height:${scale < 1 ? 1 : 'inherit'};">${text}</div>`;
      }

    componentDidMount(){
        document.title = "用户中心"
        this.getList()
        console.log(this.state.courses)
        console.log(this.props.login)
        console.log(this.props.userType)
        CourseAPI.getBacklog()
          .then(data=>{
            console.log(data)
            let _data=[
              {'key':"课程",value:data.data.course},
              {'key':"测评",value:data.data.preview}
            ]
            let total=data.data.course+data.data.preview

            this.setState({configData:_data,partA:data.data.course,partB:data.data.preview,total})
            console.log(this.state.configData)
                let config = {
                        legend: false, // 图例
                        animation: false,
                        angleField: 'value',
                        radius: 1,
                        colorField: 'key',
                        color:['blue','#fadb14'],
                        appendPadding: 5,
                        innerRadius: 0.5,
                        label: {
                            type: 'inner',
                            labelHeight: 28,
                            content: (_re) => {return(`${_re.key}\n${_re.value}`)}
                        },
                        interactions: [{ type: 'tooltip', enable: false }],data:this.state.configData,
                        statistic: {
                          title: false,
                          content: {
                            style: {
                              whiteSpace: 'pre-wrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              fontSize:"13px"
                            },
                            content: '待办汇总',
                          }
                        }
                        }
            this.setState({config:config})
            console.log(this.state.config)
          })
    }

     getList = () => {
        this.setState({
            page: 1,
            loading: true
        }, () => {
            CourseAPI.getMyBusinessCourses(1, 7, 1)
                .then(data => {
                    if (data.status===0){
                             this.setState({
                            courses: data.data.list,
                            hasMore: data.data.list.length === 7
                        });
                        }
                       
                    console.log(data)
                }).finally(() => this.setState({loading: false}))
        });
    }

    // TODO 获取更多
    appendList = () => {
        const { page, pageSize, courses } = this.state;
        this.setState({
            page: page + 1,
            loading: true
        }, () => {
            CourseAPI.getMyBusinessCourses(page + 1, 7, 1)
                .then(data => {
                    if (data.status === 0) {
                        const temp = [...courses];
                        temp.push(data.data.list);
                        if(data.list.length!==0){
                         this.setState({
                            courses: temp,
                            hasMore: data.data.list.length === 7
                        });
                            
                            
                        }
                       
                    }
                }).finally(() => this.setState({loading: false}))
        })
    }


    checkUserType = type => {
        if (type === 0)
            this.props.selectPerson(() => {
                window.location.href = `https://${window.location.hostname}/personal`;
            })
         else
            this.props.selectBusiness(() => {
                window.location.href = `https://${window.location.hostname}/personal`;
            })
    };

    logout = () => {
        Dialog.confirm({
            title: "退出登录",
            content: '你确定要退出登录吗？',
            confirmText: '退出',
            onConfirm: () => {
                user.logout()
                    .finally(() => {
                        delete localStorage.token;
                        delete localStorage.noFirst;
                        window.location.href = `https://${window.location.hostname}/personal`;
                    })
            },
        })
    };
    renderStatistic=(containerWidth, text, style)=>{
    const { width: textWidth, height: textHeight } = measureTextWidth(text, style);
    const R = containerWidth / 2; // r^2 = (w / 2)^2 + (h - offsetY)^2

    let scale = 1;

    if (containerWidth < textWidth) {
      scale = Math.min(Math.sqrt(Math.abs(Math.pow(R, 2) / (Math.pow(textWidth / 2, 2) + Math.pow(textHeight, 2)))), 1);
    }

    const textStyleStr = `width:${containerWidth}px;`;
    return `<div style="${textStyleStr};font-size:${scale}em;line-height:${scale < 1 ? 1 : 'inherit'};">${text}</div>`;
  }

    // data = [
    //     {
    //       type: '分类一',
    //       value: 27,
    //     },
    //     {
    //       type: '分类二',
    //       value: 25,
    //     },
    //   ];
    //   config = {
    //     legend:false,
    //     appendPadding: 10,
    //     data:this.data,
    //     angleField: 'value',
    //     colorField: 'type',
    //     radius: 1,
    //     innerRadius: 0.64,
    //     label: {
    //         type: 'spider',
    //         labelHeight: 1000,
    //     },
        
        
    //   };

      // data = [
      //   {
      //     key: "asdf",
      //     value: 27,
      //   },
      //   {
      //       key: "zxcvxzc",
      //     value: 25,
      //   },
      // ];
    //   config = {
    //     width:100,
    //     height:100,
    //     autoFit:false,
    //     appendPadding: 10,
    //     data:this.data,
    //     angleField: 'value',
    //     colorField: 'type',
    //     radius: 1,
    //     innerRadius: 0.64,
    //     meta: {
    //       value: {
    //         formatter: (v) => `${v} ¥`,
    //       },
    //     },
    //     label: {
    //       type: 'inner',
    //       offset: '-50%',
    //       style: {
    //         textAlign: 'center',
    //       },
    //       autoRotate: false,
    //       content: '{value}',
    //     },
   
    
              // config = {
              //           appendPadding:10,
              //           legend: false, // 图例
              //           animation: false,
              //           angleField: 'value',
              //           radius: 0.6,
              //           colorField: 'key',
              //           appendPadding: 10,
              //           innerRadius: 0.8,
              //           label: {
              //               type: 'spider',
              //               labelHeight: 28,
              //               content: (_re) => {return(`${_re.name}\n${_re.value}`)}
              //           },
              //           interactions: [{ type: 'tooltip', enable: false }],data:this.data
              //           }
      
  
    render() {
        const { isLogin, userInfo, userType, company } = this.props;
        console.log(userType)
        console.log(this.state.config)
        return (
            <div style={{width:'100%',height:'100%',position:'relative'}}>
            
                <div className="head" style={{backgroundImage:"url("+require("../img/blue_img.png")+")"}}>
                    <div className="slogan">
                        <div className="left">欢迎登录微训网</div>
                        <div className="right">切换身份</div>
                    </div>
                    <div className="Welcome">
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{"您是"+company.companyName+"的员工，"+"请您完成公司给您的学习任务,您也可以选择个人身份登录学习"}
                    </div>
                </div>
                <div class="backlog">
                    <div style={{width:'150px',height:'150px',float:'left',marginTop:"5px"}}>
                        {this.state.config?<Pie {...this.state.config} {...this.statistic} {...this.interactions}/>
                        :<Mask visible={true} opacity={0.8} color="white">
                           <SpinLoading style={{ '--size': '48px',margin:'auto',lineHeight:"200px"}} color='primary'/>
                        </Mask>
                        }
                    </div>
                    
                    <div style={{position:'absolute',left:'170px',top:'30px',width:'140px',height:'112px'}}>
                        <p style={{float:'left'}}>我的待办任务:{this.state.total}</p>
                        <div style={{position:'absolute',left:'0px',top:'82px',width:'100%',height:'20px'}}>
                            <p style={{float:'left'}}><div style={{width:'10px',height:'10px',borderRadius:'50%',background:'blue',display:'inline-block'}}></div>课程&nbsp;{this.state.partA}</p>
                            <p style={{float:'left',marginLeft:'15px'}}><div style={{width:'10px',height:'10px',borderRadius:'50%',background:'#fadb14',display:'inline-block'}}></div>测评&nbsp;{this.state.partB}</p>
                        </div>
                    </div>
                </div>
                <div class="check">
                    <Button onClick={()=>{this.props.history.push('/mycourses2')}} className="bottom" >查看任务</Button>
                </div>
            </div>
        )
    }
}

export default connect(
    ({ login}) => ({
        isLogin: login.isLogin,
        userInfo: login.userInfo,
        userType: login.type,
        company: login.company,
    })
)(withRouter(Personal2));