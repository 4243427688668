import React, { Component } from 'react';
import { withRouter,Link } from "react-router-dom";
import '../css/PropagandaCourse.css'
import { SharedInfo } from '../action'
import { connect } from 'react-redux';




class PropagandaCourse extends Component {


	imgs=['/course/1.jpg','/course/2.jpg','/course/3.jpg','/course/4.jpg','/course/5.jpg']
	imgs=['/course/5.jpg','/course/1.jpg','/course/2.jpg','/course/3.jpg','/course/4.jpg']
	id=[21,22,23,24,25]
	chapter=['打造你的职业优势','高效倾听表达与说服影响','向上沟通','KP5原则','高效协调获取支持']
	lessonName=['职业素养化','非职权影响力','逆向管理','商务沟通','团队冲突管理']
	des=['职业化有哪些具体的表现，如何提升自己的职业能力，如何快速地提升职业素养，打造自己的职业发展优势，本课程将详细阐述！',
		 '现代企业中，与同事协调是非常重要的能力。在影响他人时，需要充分运用非职权的影响力，做到换位思考，理解对方的利益和想法，合理运用策略和技巧，推动同事或者合作伙伴支持我们，以提升执行力，达成业务目标。',
		 '逆向管理，把领导作为自己的资源，最终帮助我们达成业务目标，是职场的重要能力，本课程将从思维，策略和技巧等方面介绍如何与领导进行交流，如何有效说服领导，提升我们的综合能力！',
		 '本课程主要介绍沟通中的5项关键技能，可以帮助我们在不同地场合下，提高我们的沟通效率，提升业务能力！',
		 '如何理解冲突的本质和来源，如何在不同的冲突场景下，采用不同的冲突策略，本课程将有详细地解读'
	]

	state={
		currentImg:null,
		id:null,
		chapter:null,
		lessonName:null,
		des:null
	}

	componentDidMount() {
    	const id = this.props.match.params.id;
    	this.setState({
    		currentImg:this.imgs[id-1],
    		id:this.id[id-1],
    		chapter:this.chapter[id-1],
    		lessonName:this.lessonName[id-1],
    		des:this.des[id-1]
    	})
    	let SharedInfo={}
      	SharedInfo.title=this.lessonName[id-1]
      	SharedInfo.img="https://micro-weixun.oss-cn-beijing.aliyuncs.com"+this.imgs[id-1]
      	SharedInfo.des='游戏式学习'
    	this.props.setSharedInfo(SharedInfo)
	}

	render(){
		return(
			<div className="PropagandaCourse">
				<Link to={`/test/0/${this.state.lessonName}/${this.state.chapter}/${this.state.id}`}>
					<div style={{width:'100%',height:'13%',position:'absolute',zIndex:100}}></div>
				</Link>
				<img src={"https://micro-weixun.oss-cn-beijing.aliyuncs.com/"+this.state.currentImg} style={{height:'100%',width:'100%',position:'absolute',top:'50%',left:'50%',transform:'translate(-50%,-50%)'}}/>
				<Link to={`/test/0/${this.state.lessonName}/${this.state.chapter}/${this.state.id}`}><div style={{width:'85%',height:'27%',position:'absolute',top:'68%',left:'50%',transform:'translateX(-50%)',overflow:'scroll',fontSize:'18px',paddingTop:'3%',paddingBottom:'10px',color:'rgb(128,128,128)',textIndent:'2em',paddingLeft:'3%',paddingRight:'3%'}}>
					{this.state.des}
				</div></Link>
			</div>
		)
	}

}

function mapStateToProps(/*默认值加解构数组*/) {
  return {
   

  }
}

function mapDispatchToProps(dispatch) {
  return {
    setSharedInfo:(data)=>{dispatch(SharedInfo(data))},
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PropagandaCourse));





