import React, {Component} from 'react';
import {connect} from 'react-redux';
import * as CoursesAPI from '../API/CoursesAPI'
import {getCourse, SharedInfo} from '../action'
import {withRouter} from "react-router-dom";
import Coupon from '../component/Coupon';
import {Comment,Tooltip} from "antd"
import { ShareAltOutlined } from '@ant-design/icons'
import {Avatar, Button, Dialog, Tabs, Tag, Toast,Popup,Form,Input,List} from "antd-mobile"
import uuid from "uuid";
import { CheckCircleOutline, ClockCircleOutline } from "antd-mobile-icons"
import Loading from "../component/Loading/Loading";
import '../css/Information.scss'
import zhCN from 'antd/lib/locale/zh_CN';
import moment from 'moment';

class Information extends Component {

    constructor(props) {
        super(props);
        this.state={

        }
    }
    componentDidMount(){
     document.title="个人信息"
     console.log(this.props.login)
    }

    render(){
        const {userInfo,userType,company} = this.props;
        return(
          <div className="information">
          <div className="information-header">
          <div className="header-name">
                        <div onClick={ () => this.props.history.push("/") } className="header-link">首页</div>&nbsp;>&nbsp;
                        <div onClick={ () => this.props.history.push("/personal") } className="header-link">用户中心</div>&nbsp;>&nbsp;
                        <div onClick={ () => this.props.history.push("/information") } className="header-link">个人信息</div>&nbsp;>&nbsp;
                        </div>
                    <div className="header-user" onClick={ () => this.props.history.push("/personal") }>
                    </div>
            </div>
            <div style={{background:"white"}}>
            <List.Item prefix={<Avatar src={userInfo.headUrl} />} style={{lineHeight:"40px",marginLeft:"20px"}}>
                  <span style={{color: "#1890ff",marginLeft:"20px"}}>{userInfo.realname || userInfo.wechatnickname || userInfo.username}{ userType !== 0 && ( <span style={{color: "#AAAAAA"}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{company.companyName}</span>)}</span>
            </List.Item>
             </div>
             <div className="information-content">
             <div className="content-avatar">
              <Avatar src={userInfo.headUrl} style={{ '--size': '60px' }}/>
             </div>
             <div><span style={{color: "black",marginLeft:"20px"}}>昵称：{userInfo.wechatnickname || userInfo.username}</span></div>
             <div><span style={{color: "black",marginLeft:"20px"}}>真实姓名：{userInfo.realname}</span></div>
             <div><span style={{color: "black",marginLeft:"20px"}}>单位：{company.companyName}</span></div>
             <div><span style={{color: "black",marginLeft:"20px"}}>手机号：{userInfo.phone}</span></div>
             <div><span style={{color: "black",marginLeft:"20px"}}>邮箱：</span></div>
             <div><span style={{color: "black",marginLeft:"20px"}}>我的银行卡账户：</span></div>
             <div className="change-information"><Button onClick={()=>this.props.history.push("/changeInformation")}>修改个人信息</Button></div>
             </div>
          </div>
            )
    }
}

    export default connect(
    ({login, course}) => ({
        isLogin: login.isLogin,
        userInfo: login.userInfo,
        course: course.data,
        userType: login.type,
        company: login.company,
        login,
    }), dispatch => ({
        setSharedInfo: (data) => dispatch(SharedInfo(data)),
    })
)(withRouter(Information));
