import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Input, Button } from 'antd';
import '../css/certificateDownload.scss';

class CertificateDownload extends Component {

    constructor (props) {
        super(props);
        this.state = {
            name: '',
            targetName: ''
        }
    }

    handleInputChanged = event => {
        this.setState({

            name: event.target.value.trim()

        })
    };

    getCertificate = event => {

        const { name } = this.state;
        if (name !== "") {
            this.setState({
                targetName: name
            });

        } else {
            alert("姓名为空！");
        }
    }

    render () {
        const { targetName } = this.state;
        return (
            <div className='download_certificate'>

                <h1 className='title'>获取证书</h1>
                <div className='inputGroup'>
                    <Input className='input' onChange={ this.handleInputChanged } size='large' value={ this.state.name } placeholder='输入姓名' />
                    <Button type="primary" size='large' className='btn' onClick={ this.getCertificate }>生成证书</Button>
                </div>
                {
                    targetName && (
                        <img width={'100%'} src={`/api/getZhengshu.do?name=${targetName}&lesson_id=${this.props.match.params.id}`} alt="生成证书" />
                    )
                }

            </div>
        )
    }

}

export default withRouter(
    CertificateDownload
)
