export const create = (ids,from)=>
 fetch("/api/create.do",{
 	method:'POST',
 	headers:{
            
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': localStorage.token,
          },
          mode:'cors',
          body: JSON.stringify({ids,from})
 }).then((res)=>(res.json()))

 export const payOrder=(outTradeNo,openid)=>
 	fetch("/api/wxPay/payUrl_mob.do?outTradeNo="+outTradeNo+"&openid="+openid,{
 	method:'GET',
 	headers:{
          
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': localStorage.token
          },
          mode:'cors',
 }).then((res)=>(res.json()))

export const getdakehu=(id)=>
    fetch("/api/getdakehu.do?id=-1",{
        method:'GET',
        headers:{

            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': localStorage.token
        },
        mode:'cors',
    }).then((res)=>(res.json()))