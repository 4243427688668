import React, { Component } from 'react';
import * as CourseAPI from "../../API/CoursesAPI";
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Avatar, Ellipsis, List, Tabs, DotLoading, Divider, Empty } from 'antd-mobile';
import "./myCourse.scss";

class MyCourse extends Component {

    constructor(props) {
        super(props);
        this.state = {
            courses: [],
            type: "1",
            page: 1,
            loading: true,
            hasMore: true,
            pageSize: 10
        };
    }

    componentDidMount() {
        document.title = "我的任务";
        this.setState({
            pageSize: Math.ceil((document.documentElement.clientHeight - 90) / 88) + 1
        }, this.getList);
    }

    getList = () => {
        const { type, pageSize } = this.state;
        this.setState({
            page: 1,
            loading: true
        }, () => {
            CourseAPI.getMyBusinessCourses(1, pageSize, type)
                .then(data => {
                    if (data.status===0){
                             this.setState({
                            courses: data.data.list,
                            hasMore: data.data.list.length === pageSize
                        });
                        }
                       
                    console.log(data)
                }).finally(() => this.setState({loading: false}))
        });
    }

    // TODO 获取更多
    appendList = () => {
        const { page, pageSize, type, courses } = this.state;
        this.setState({
            page: page + 1,
            loading: true
        }, () => {
            CourseAPI.getMyBusinessCourses(page + 1, pageSize, type)
                .then(data => {
                    if (data.status === 0) {
                        const temp = [...courses];
                        temp.push(data.data.list);
                        if(data.list.length!==0){
                         this.setState({
                            courses: temp,
                            hasMore: data.data.list.length === pageSize
                        });
                            
                            
                        }
                       
                    }
                }).finally(() => this.setState({loading: false}))
        })
    }

    render() {
        const { courses, type, hasMore, loading } = this.state;
        const { userInfo } = this.props;
        console.log(courses)
        return (
            <div className="myCourse">

                <div className="course-header">
                    <div className="header-name">
                        <div onClick={ () => this.props.history.push("/") } className="header-link">首页</div>
                        &nbsp;>&nbsp;
                        <div onClick={ () => this.props.history.push("/personal") } className="header-link">个人中心</div>
                    </div>
                    <div className="header-user" onClick={ () => this.props.history.push("/personal") }>
                        <Avatar style={{ '--size': '30px' }} src={userInfo.headUrl} />
                    </div>
                </div>

                <h2 style={{paddingLeft: "10px"}}>我的任务</h2>
                <Tabs style={{'--content-padding': 0, backgroundColor: "white"}} activeKey={ type } onChange={ type => this.setState({type}, this.getList) }>
                    <Tabs.Tab title='进行中' key='1'>
                        <List style={{'--border-top': "none"}}>
                            {
                                courses.map((item, index) => (
                                    <List.Item
                                        key={index}
                                        onClick={ () => this.props.history.push("/course/"+item.lesson.id) }
                                        prefix={<div style={{width: "112px", height: "63px"}}><img src={item.lesson.lessonIcon} alt=""/></div>}
                                        description={
                                            <Ellipsis direction='end' rows={2} content={ item.lesson.lessonDescription} />
                                        }
                                    >{item.lesson.lessonName}</List.Item>
                                ))
                            }
                        </List>
                    </Tabs.Tab>
                    <Tabs.Tab title='已完成' key='0'>
                        <List style={{'--border-top': "none"}}>
                            {
                                courses.map((item, index) => (
                                    <List.Item
                                        key={index}
                                        onClick={ () => this.props.history.push("/course/"+item.lesson.id) }
                                        prefix={<div style={{width: "112px", height: "63px"}}><img src={item.lesson.lessonIcon} alt=""/></div>}
                                        description={
                                            <Ellipsis direction='end' rows={2} content={ item.lesson.lessonDescription} />
                                        }
                                    >{item.lesson.lessonName}</List.Item>
                                ))
                            }
                        </List>
                    </Tabs.Tab>
                </Tabs>
                {
                    courses.length === 0 ? (
                        <Empty
                            style={{ padding: '64px 0', backgroundColor: "white" }}
                            imageStyle={{ width: 128 }}
                            description='暂无数据'
                        />
                    ):(hasMore ? (
                        <div className="getMore">
                            {
                                loading ? (
                                    <span style={{ fontSize: 18 }}>
                                        <DotLoading /> 加载中...
                                    </span>
                                ): (
                                    <span onClick={ this.appendList } style={{ fontSize: 18 }}>
                                        点击此处获取更多
                                    </span>
                                )
                            }
                        </div>
                    ): (
                        <Divider>我有底线的</Divider>
                    ))
                }
            </div>
        );
    }
}

export default connect(
    ({ login }) => ({
        isLogin: login.isLogin,
        userInfo: login.userInfo
    })
)(withRouter(MyCourse));