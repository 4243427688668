import React from 'react';
import { WhiteSpace, Toast } from 'antd-mobile-v2';
import { Button, List, Input } from "antd-mobile";
import { Icon } from 'antd';
import * as LoginAPI from '../API/user';
import { isLogin } from '../action';
import { connect } from "react-redux";
import uuid from 'uuid';
import {withRouter} from "react-router-dom";
import '../css/IntegralApi.scss';
import Loading from '../component/Loading/Loading'

class IntegralApi extends React.Component {
 constructor(props){
 	super(props);
 	this.state={

 	}
 }

componentDidMount(){
console.log(11)
}
render(){
	return(
      <div className="content">
      <h2 style={{width:"100%",textAlign:"center"}}>积分政策</h2>
      <p>根据《微训网积分政策》</p>
      <p>1、参与推广课程的获得与课程价格等值的积分。</p>
      <p>2、积分可以用于学习课程</p>
      <p>3、积分大于2000时，可以申请积分兑换现金</p>
      <p>4、自交易成功之日起180天(含）内,买家可在作出信用评价后追加评论,内容不得修改也不影响卖家信用积分。</p>
      <p>5、根据《淘宝网评价规范》第五条规定：在信用评价中,评价人若给予好评,则被评价人信用积分加1分;若给予差评,则减1分;若给子中评或15天内双方均未评价，则信用积分不变。</p>
      <Button className="button1" onClick={()=>this.props.history.push("/integral")}>我的积分</Button>
      <Button className="button2"  onClick={()=>this.props.history.go(-1)}>取消</Button>
      </div>
		)
}
}
export default connect(
    (dispatch) =>({
        isLogin: data => { dispatch(isLogin(data))},
    })
)(withRouter(IntegralApi));