export function cssTransform(el,val) {
	console.log(el.style.webkitTransform.replace(/[^0-9\-,]/g,'').split(','))
	return el.style.webkitTransform.replace(/[^0-9\-,]/g,'').split(',')
	// if(!el.transform){
	// 	el.transform = {}
	// }
	// e
	// if(typeof val == "undefined"){
	// 	if(typeof el.transform[attr] == "undefined"){
	// 		switch(attr) {
	// 			case "scale":
	// 			case "scaleX":
	// 			case "scaleY":
	// 				el.transform[attr] = 100;
	// 				break;
	// 			default:
	// 				el.transform[attr] = 0;	
	// 		}
	// 	}
	// 	return el.transform[attr];
	// } else {
	// 	var transformVal = "";
	// 	el.transform[attr] = Number(val);
	// 	for(var s in el.transform){
	// 		switch(s){
	// 			case "rotate":
	// 			case "rotateX":
	// 			case "rotateY":
	// 			case "rotateZ":
	// 			case "skewX":
	// 			case "skewY":
	// 				transformVal += " "+s+"("+el.transform[s]+"deg)";
	// 				break;
	// 			case "translateX":
	// 			case "translateY":
	// 			case "translateZ":
	// 				transformVal += " "+s+"("+el.transform[s]+"px)";
	// 				break;
	// 			case "scale":
	// 			case "scaleX":
	// 			case "scaleY":
	// 				transformVal += " "+s+"("+el.transform[s]/100+")";
	// 				break;
	// 		}
	// 	}
	// 	el.style.WebkitTransform = el.style.transform = transformVal;
	// }
}