import React, {Component} from 'react';
import {connect} from 'react-redux';
import * as CoursesAPI from '../API/CoursesAPI'
import {getCourse, SharedInfo} from '../action'
import {withRouter} from "react-router-dom";
import Coupon from '../component/Coupon';
import {Comment,Tooltip} from "antd"
import { ShareAltOutlined } from '@ant-design/icons'
import {Avatar, Button, Dialog, Tabs, Tag, Toast,Popup,Form,Input} from "antd-mobile"
import uuid from "uuid";
import { ReceiptOutline,BillOutline,CheckCircleOutline, ClockCircleOutline,ContentOutline, PayCircleOutline,SendOutline ,UserOutline,HandPayCircleOutline,TextOutline} from "antd-mobile-icons"
import Loading from "../component/Loading/Loading";
import '../css/Money.scss'
import moment from 'moment';

class Money extends Component {

    constructor(props) {
        super(props);
        this.state={

        }
    }
    componentDidMount(){
     document.title="我的资金"

    }

    render(){
        const {userInfo,userType,company} = this.props
        return(
      <div className="Money">
            <div className="integral-header">
                    <div className="header-name">
                        <div onClick={ () => this.props.history.push("/") } className="header-link">首页</div>&nbsp;>&nbsp;
                        <div onClick={ () => this.props.history.push("/personal") } className="header-link">用户中心</div>&nbsp;>&nbsp;
                        <div onClick={ () => this.props.history.push("/money") } className="header-link">我的资金</div></div>
                    <div className="header-user" onClick={ () => this.props.history.push("/personal") }>
                        <Avatar style={{ '--size': '30px' }} src={userInfo.headUrl} />
                    </div>
                </div>
                <div className="myWallet"><span><PayCircleOutline/>总额：</span>&nbsp;&nbsp;&nbsp;<span>￥{this.props.userType===1?this.props.company.restBalance:this.props.userInfo.priv}</span></div>
                <div className="integral-content">
                <Button  className="top-up" onClick={()=>this.props.history.push("/top_up")}><ReceiptOutline />充值</Button>
                <Button  className="detail" onClick={()=>this.props.history.push("/withdrawcash")}><BillOutline />提现</Button> 
                </div>
          </div>
            )
    }
}

    export default connect(
    ({login, course}) => ({
        isLogin: login.isLogin,
        userInfo: login.userInfo,
        course: course.data,
        userType: login.type,
        company: login.company
    }), dispatch => ({
        setSharedInfo: (data) => dispatch(SharedInfo(data)),
    })
)(withRouter(Money));
