import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import * as CoursesAPI from "../API/CoursesAPI";
import { connect } from "react-redux";
import {Avatar, Button, Result} from "antd-mobile";
import { HandPayCircleOutline } from "antd-mobile-icons";
import { Icon } from "antd";
import * as Pay from '../API/Pay'
import "../css/buy.scss";
import Loading from "../component/Loading/Loading";

class Buy extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            courses: [],
            total: 0,
            payMethod: "wechat-pay",
            orderId: "",
            isBuy: false
        };
    }

    componentDidMount() {
        document.title = "确认订单";
        const id = this.props.match.params.id;
        CoursesAPI.getall(parseInt(id)).then(data => {
            if (data.status === 0) {
                let courses = [],
                    total = 0;
                courses.push(data.data);
                // 计算总价 为购物车准备
                for (let key in courses)
                    total += parseFloat(courses[key].lessonPrice);

                if (data.msg === "已购买")
                    this.setState({isBuy: 1, courses, total});
                else
                    this.setState({courses, total });
                Pay.create([parseInt(id)], "https://learning.wx798.com/course/"+id).then((data) => {
                    if (data.status === 0)
                        this.setState({ orderId: data.data.order_id })
                }).finally(() => this.setState({loading: false}));
            }
        });
    }

    toPay = () => {
        if (this.props.isLogin) {
            Pay.payOrder(this.state.orderId, this.props.userInfo.openId).then(data => {
                let code = {
                    "appId": "wxd033f96f0d37892d",
                    "timeStamp": data.timeStamp,
                    "nonceStr": data.nonceStr,
                    "package": data.package,
                    "signType": "MD5",
                    "paySign": data.sign
                }
                JSON.stringify(code)
                let me = this
                if (typeof WeixinJSBridge === "undefined") {
                    if (document.addEventListener) {
                        document.addEventListener('WeixinJSBridgeReady', this.onBridgeReady.bind(null, code), false);
                    } else if (document.attachEvent) {
                        document.attachEvent('WeixinJSBridgeReady', this.onBridgeReady.bind(null, code));
                        document.attachEvent('onWeixinJSBridgeReady', this.onBridgeReady.bind(null, code));
                    }
                } else {
                    this.onBridgeReady(code, me);
                }
            })
        } else
            alert("未登录");
    };

    onBridgeReady(code, me) {
        window.WeixinJSBridge.invoke('getBrandWCPayRequest', code,
            function (res) {
                if (res.err_msg === "get_brand_wcpay_request:ok") {
                    me.setState({
                        isBuy: true,
                    })
                }
            }
        );
    }

    render() {
        const { loading, courses, total, payMethod, isBuy } = this.state;
        const { userInfo } = this.props;
        return isBuy ? (
            <div className="buy">
                <div className="pay-success">
                    <Result
                        status='success'
                        title='支付成功'
                    />
                    <div style={{padding: "0 50px"}}>
                        <Button onClick={ () => this.props.history.goBack() } block size="large" color="primary" fill="none">确&nbsp;&nbsp;定</Button>
                    </div>
                </div>
            </div>
        ) : (
            <div className="buy">
                <Loading loading={loading} />
                <div className="course-header">
                    <div className="header-name">
                        <div onClick={ () => this.props.history.push("/") } className="header-link">首页</div>
                        &nbsp;>&nbsp;
                        <div onClick={ () => this.props.history.push("/courseList") } className="header-link">精品课程</div>
                        &nbsp;>&nbsp;
                        <div onClick={ () => this.props.history.goBack() } className="header-link">{ loading ? "正在获取订单..." : "确认订单"}</div>
                    </div>
                    <div className="header-user" onClick={ () => this.props.history.push("/personal") }>
                        <Avatar style={{ '--size': '30px' }} src={userInfo.headUrl} />
                    </div>
                </div>
                <h2 className="buy-title"><HandPayCircleOutline />购买商品</h2>
                <div className="courses">
                    {
                        courses.map((item, index) => (
                            <div className="course-item" key={index}>
                                <div className="courseIndex">
                                    <img src={ item.lessonIcon } alt=""/>
                                </div>
                                <div className="info">
                                    <p className="lessonName">{item.lessonName}</p>
                                </div>
                                <div className="coursePrice">¥{item.lessonPrice}</div>
                            </div>
                        ))
                    }
                    <div className="total">总计:&nbsp; ¥{total}</div>
                </div>
                <h3 className="buy-title">支付方式</h3>
                <div className="pay-method">
                    <div className="wechat-pay" onClick={ () => this.setState({payMethod: "wechat-pay"})}>
                        <Icon style={{fontSize: "25px", color: "rgb(101, 171, 66)"}} type="wechat" />&nbsp;<div className="pay-name">微信</div>
                        <div className={`check ${payMethod === 'wechat-pay' ? 'checked': 'no-check'}`} />
                    </div>
                    <div className="alipay" onClick={ () => this.setState({payMethod: "alipay"})}>
                        <Icon style={{fontSize: "25px", color: "rgb(56, 117, 246)"}} type="alipay-circle" />&nbsp;<div className="pay-name">支付宝</div>
                        <div className={`check ${payMethod === 'alipay' ? 'checked': 'no-check'}`} />
                    </div>
                </div>

                <div className="pay">
                    <div className="trust-amount">
                        {
                            payMethod === "wechat-pay" ? `实付款: ¥${total}`: "暂不支持支付宝"
                        }
                    </div>
                    { payMethod === "wechat-pay" && <div className="toPay" onClick={ this.toPay }>立即支付</div> }
                </div>
            </div>
        )
    }

}

export default connect(
    ({ login }) => ({
        isLogin: login.isLogin,
        userInfo: login.userInfo
    })
)(withRouter(Buy));