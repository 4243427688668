import React, { Component } from "react";
import * as naire from "../API/naire";
class Questionnaireinfo extends Component{
    page = 1
    state = {
        naires:[]
    }
    s(){
        console.log("123")
    }
    componentDidMount(){
        naire.getnaire(this.page).then((data)=>{
            console.log(data.data)
            this.setState({
                naires:data.data
            })
        })
    }
    onScrollHandle(){
        const scrollTop = this.scrollRef.scrollTop;
        const clientHeight = this.scrollRef.clientHeight;
        const scrollHeight = this.scrollRef.scrollHeight;
        const isBottom = scrollTop + clientHeight === scrollHeight;
        
        if (isBottom) {
            this.page++
            naire.getnaire(this.page).then((data)=>{
                let newdata = this.state.naires.concat(data.data)
                this.setState({
                    naires:newdata
                })
            })
        }
    }
    render(){
        return (
            <div style = {{width:'100%',height:'100%'}}>
                <div style = {{width:'100%',height:'5%',top:'0%',position:"fixed" }}>
                    <div style = {{width:'30%',height:'100%',position:'absolute',textAlign:'center',border:'1px solid black'}}><span style={{position:'absolute',left:'50%',top:'50%',transform:'translate(-50%,-50%)'}} >姓名</span></div>
                    <div style = {{width:'33.3%',height:'100%',position:'absolute',left:'30%',textAlign:'center',border:'1px solid black'}}><span style={{position:'absolute',left:'50%',top:'50%',transform:'translate(-50%,-50%)'}}>手机</span></div>
                    <div style = {{width:'36%',height:'100%',position:'absolute',left:'63.3%',textAlign:'center',border:'1px solid black'}}><span style={{position:'absolute',left:'50%',top:'50%',transform:'translate(-50%,-50%)'}}>邮箱</span></div>
                </div>
                <div ref={c => {this.scrollRef = c;}} style = {{width:'100%',height:'95%',top:'5%',position:"relative",overflow:'auto'}} onScroll = {this.onScrollHandle.bind(this)}>
                    {this.state.naires.map((naire,i)=>{
                            return <div key = {i} style = {{width:'100%',height:'5%',position:"relative"}}>
                                <div style = {{width:'30%',height:'100%',position:'absolute',textAlign:'center',border:'1px solid black'}}><span style={{position:'absolute',left:'50%',top:'50%',transform:'translate(-50%,-50%)'}} >{naire.name}</span></div>
                                <div style = {{width:'33.3%',height:'100%',position:'absolute',textAlign:'center',border:'1px solid black',left:'30%'}}><span style={{position:'absolute',left:'50%',top:'50%',transform:'translate(-50%,-50%)'}} >{naire.phone}</span></div>
                                <div style = {{width:'36%',height:'100%',position:'absolute',textAlign:'center',border:'1px solid black',left:'63.3%',overflow:'auto'}}><span style={{position:'absolute',left:'50%',top:'50%',transform:'translate(-50%,-50%)'}} >{naire.email}</span></div>
                            </div>
                    })}
                </div>
            </div>
        )
    }



}


export default Questionnaireinfo;