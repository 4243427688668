import React, { Component } from "react";
import '../../css/propagandaOurIdea.scss';

class OurIdea extends Component {

    constructor(props) {
        super(props);
        this.state = {
            currentPage: 1,
            maxPage: 3
        }
    }

    // TODO 上一页
    toTop = () => { const { currentPage } = this.state;if (currentPage !== 1) this.setState({currentPage: currentPage - 1});}

    // TODO 下一页
    toNext = () => { const { currentPage, maxPage } = this.state; if (currentPage !== maxPage) this.setState({currentPage: currentPage + 1});}

    // TODO 回到主页
    backHome = () => this.props.backHome();

    render() {
        const { currentPage, maxPage } = this.state;
        return (
            <div className='ourIdea'>
                <header className="header">
                    <div className="home" onTouchEnd={ this.backHome } />
                    <div className="title">{currentPage === 3 ? "游戏式学习的设计要素" : "微训让学习快乐到底"}</div>
                </header>
                <div className="content">
                    {currentPage === 1 && (<div className='container-1'>
                        <ul className="info_list">
                            <li>
                                <div className="name">企业</div>
                                <div className="detail">提升培训效果结果落地</div>
                            </li>
                            <li>
                                <div className="name">培训师</div>
                                <div className="detail">创作有价值的课程</div>
                            </li>
                            <li>
                                <div className="name">学员</div>
                                <div className="detail">提升兴趣主动参与</div>
                            </li>
                            <li>
                                <div className="name">微训</div>
                                <div className="detail">微训采用生动的人物和画面，采用互动式的界面，配合酷炫的VR技术，以及积分排名的竞赛方式，将原本枯燥的学习，变成生动的游戏</div>
                            </li>
                        </ul>
                    </div>)}
                    {currentPage === 2 && (<div className='container-2'>
                        <ul className="info_list">
                            <li>
                                <div className="name">标准化</div>
                                <div className="detail">面向企业、社会提供寓教于乐的游戏式学习的课程</div>
                            </li>
                            <li>
                                <div className="name">定制</div>
                                <div className="detail">为高端企业<br/>客户提供定制课程</div>
                            </li>
                            <li>
                                <div className="name">SAAS(自创服务)</div>
                                <div className="detail">未来将提供模块化SAAS功能，支持企业自创在线游戏式培训</div>
                            </li>
                        </ul>
                    </div>)}
                    {currentPage === 3 && (<div className='container-3'>
                        <ul className="info_list">
                            <li>
                                <div className="icon icon-1">场景</div>
                                <div className="detail">将学习内容结构成场景</div>
                            </li>
                            <li>
                                <div className="icon icon-2">技术</div>
                                <div className="detail">保障设计思路的实现</div>
                            </li>
                            <li>
                                <div className="icon icon-3">成本</div>
                                <div className="detail">在效果和成本上达成平衡</div>
                            </li>
                        </ul>
                    </div>)}
                    { currentPage !== 1 && (<div className='toTop' onTouchEnd={ this.toTop } />) }
                    { currentPage !== maxPage && (<div className='toNext' onTouchEnd={ this.toNext } />) }
                </div>
            </div>
        )
    }
}

export default OurIdea;