import React from 'react';
import { WhiteSpace, Toast } from 'antd-mobile-v2';
import { Button, List, Input  } from "antd-mobile";
import { Icon,Checkbox } from 'antd';
import * as LoginAPI from '../API/user';
import { isLogin } from '../action';
import { connect } from "react-redux";
import uuid from 'uuid';
import '../css/login.scss';
import Loading from '../component/Loading/Loading'
import { Link } from 'react-router-dom';
import {serviceAgree, personelAgree} from '../util/agree'

class Login extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            sub: 0,
            phone: '',
            loginPassword: '',
            messageLogin: false,
            loginVal: '',
            isSendByLogin: true,
            sendTimeByLogin: 0,
            loading: false,
            loadingTip: '',
            regVal: '',
            regPassword: '',
            regPasswordAgain: '',
            isBusiness: false,
            email: '',
            realName: '',
            isAgree: false,
            registerSuccess: false,
            chooseregAgree:false,
            showPersonelAgree:false,
            showServiceAgree:false
        }
    }
    showS = (e)=>{
        e.stopPropagation()
        this.setState({
            showServiceAgree:true
        })
    }
    showP = (e)=>{
        e.stopPropagation()
        this.setState({
            showPersonelAgree:true
        })
    }
    closeP = ()=>{
        this.setState({
            showPersonelAgree:false
        })
    }
    closeS =()=>{
        this.setState({
            showServiceAgree:false
        })
    }


    s = serviceAgree
    p = personelAgree
    next="https://learning.wx798.com"
    componentDidMount() {

        const next=this.getParams();
        this.next=next.from?next.from:this.next;
        // console.log(next)
        // let para=window.location.search.split("&")

    }

    // TODO 短信登录
    loginByMsg = () => {
        const { phone, loginVal } = this.state;
        if (this.isPhone(phone)) {
            if (loginVal.length === 6) {
                this.setState({
                    loading: true,
                    loadingTip: '正在登录...'
                }, () => {
                    LoginAPI.loginByValCode({
                        phone,
                        val: loginVal
                    }).then(res => {
                        if (res.status === 0) {
                            const data = {...res.data};
                            this.doSuccess("登录成功！");
                            localStorage.token = data.ticket;
                            this.props.isLogin({data});
                            window.location.href = !this.getParams().from ? 'https://learning.wx798.com' : this.getParams().from;;
                        } else
                            this.doError(res.msg);
                    }).finally(() => this.setState({loading: false}));
                });
            } else
                this.doError("验证码有误");
        } else
            this.doError("手机号码有误");
    };

   


    getParams = () => {
        let params = {}, parStr = window.location.href;
        params["from"] = parStr.split("=")[1];
        return params;
    }
    // TODO 登录
    login = () => {
        const { phone, loginPassword } = this.state;
        if (this.isPhone(phone)) {
            if (loginPassword.length >= 8) {
                this.setState({
                    loading: true,
                    loadingTip: '正在登录...'
                }, () => {
                    LoginAPI.login({
                        phone,
                        password: loginPassword
                    }).then(res => {
                        if (res.status === 0){
                            if(res.data.user.status===0){
                            this.doSuccess("登录成功");
                            localStorage.token = res.ticket;
                            window.location.href = !this.getParams().from? 'https://learning.wx798.com' : this.getParams().from;
                            }else if(res.data.user.status===1){
                             alert("您这是首次登录，请验证手机号并修改密码")
                             window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxd033f96f0d37892d&redirect_uri=https://backing.wx798.com/back/mobile.do?next='+this.next+'&response_type=code&scope=snsapi_userinfo&state=0&connect_redirect=1#wechat_redirect'
                            }
                        } else
                            this.doError(res.msg);
                    }).finally(() => {
                        this.setState({ loading: false });
                    });
                })
            } else
                this.doError("密码错误");
        } else
            this.doError("手机号码有误");
    };
    // TODO 注册
    register = () => {
        const {
            isAgree,
            phone,
            regVal,
            regPassword,
            regPasswordAgain,
            isBusiness,
            realName,
            email
        } = this.state;
        if (isAgree) {
            let body = {};
            let isRegister = false;
            if (this.isPhone(phone)) {
                if (regVal.length === 6) {
                    if (regPassword.length >= 8) {
                        if (regPasswordAgain === regPassword) {
                            if (isBusiness) {
                                if (realName.length !== 0) {
                                    if (/^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/.test(email)) {
                                        // TODO 企业用户验证通过
                                        body = {
                                            "realName": realName,
                                            "phone": phone,
                                            "password": regPassword,
                                            "val": regVal,
                                            "email": email,
                                            "usertype": 1
                                        };
                                        isRegister = true;
                                    } else
                                        this.doError("邮箱错误");
                                } else
                                    this.doError("请输入你的真实姓名");
                            } else {
                                // TODO 个人用户验证通过
                                body = {
                                    "phone": phone,
                                    "password": regPassword,
                                    "val": regVal,
                                    "usertype": 0
                                };
                                isRegister = true;
                            }
                        } else
                            this.doError("两次密码输入不一致");
                    } else
                        this.doError("密码过短");
                } else
                    this.doError("验证码错误");
            } else
                this.doError("请输入正确的手机号码");
            if (isRegister) {
                body['name'] = uuid().substring(0, 5);
                this.setState({
                    loading: true,
                    loadingTip: '正在注册...'
                }, () => {
                    LoginAPI.register(body)
                        .then(res => {
                            if (parseInt(res.status) === 0) {
                                // TODO 注册成功
                                this.setState({registerSuccess: true});
                            } else this.doError(res.msg);
                        }).finally(() => this.setState({ loading: false }));
                });
            }
        }
    };
    // TODO 错误消息
    doError = msg => {
        Toast.fail(msg, 2);
    };
    // TODO 成功消息
    doSuccess = msg => {
        Toast.success(msg, 2);
    };
    // TODO 发送验证码
    sendCaptcha = () => {
        const { isSendByLogin, phone } = this.state;
        if (isSendByLogin) {
            if (this.isPhone(phone)) {
                this.setState({
                    loading: true,
                    loadingTip: '正在发送验证码...'
                }, () => {
                    LoginAPI.sendCaptcha(phone)
                        .then(res => {
                            if (res === 'true') {
                                this.setState({
                                    isSendByLogin: false,
                                    sendTimeByLogin: 60
                                }, () => {
                                    this.intimer = window.setInterval(() => {
                                        this.setState({sendTimeByLogin: this.state.sendTimeByLogin - 1});
                                    }, 1000);
                                    this.timer = window.setTimeout(() => {
                                        this.setState({sendTimeByLogin: 0, isSendByLogin: true});
                                        window.clearInterval(this.intimer);
                                    }, 60000);
                                });
                            } else this.doError("验证码发送失败！");
                        }).finally(() => this.setState({loading: false}));
                });
            } else this.doError("手机号有误!");
        }
    };
    // TODO 验证手机号
    isPhone = number => {
        let phoneReg = /^[1][3-9][0-9]{9}$/img;
        return phoneReg.test(number);
    };

    // TODO login byWeChat
    loginByWechat = () => {
        window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxd033f96f0d37892d&redirect_uri=https://backing.wx798.com/back/mobile.do?next='+this.next+'&response_type=code&scope=snsapi_userinfo&state=0&connect_redirect=1#wechat_redirect'
    };

    changeAgree=()=>{
        let agree = this.state.chooseregAgree
        this.setState({
            chooseregAgree:!agree
        })
    }

    render() {
        const {
            phone,
            loginPassword,
            messageLogin,
            loginVal,
            isSendByLogin,
            sendTimeByLogin,
            loading,
        } = this.state;
        return (
            <div className='login'>
                <h1 className='form-title'>欢迎登录微训网</h1>
                <div className="login-content">
                    <div className='loginBlock'>
                        {
                            !messageLogin ? (
                                <div>
                                    <List>
                                        <List.Item prefix='手机号'>
                                            <Input value={ phone }
                                                   maxLength={ 11 }
                                                   placeholder='请输入手机号'
                                                   clearable
                                                   onChange={ event => this.setState({phone: event.replace(/[^0-9]/, '')}) }/>
                                        </List.Item>
                                        <List.Item prefix={<span>密&nbsp;&nbsp;&nbsp;&nbsp;码</span>}>
                                            <Input
                                                value={ loginPassword }
                                                type="password"
                                                placeholder='请输入密码'
                                                clearable
                                                maxLength={18}
                                                onChange={ loginPassword => this.setState({loginPassword}) }/>
                                        </List.Item>
                                    </List>
                                    <WhiteSpace />
                                    <WhiteSpace />
                                    <Button color="primary" size="large" className='btn' type='primary' onClick={ this.login }>登&nbsp;录</Button>
                                    <WhiteSpace />
                                    <Button size="large" color="default" className='btn' disabled={!this.state.chooseregAgree} onClick={ this.loginByWechat }>注&nbsp;册</Button>
                                    <WhiteSpace />
                                    <Checkbox style={{marginLeft:'10px',fontSize:'13px'}} onChange={this.changeAgree}>
                                        我已同意
                                    </Checkbox>
                                    <span style={{color:'blue',zIndex:999,fontSize:'13px'}} onClick = {this.showS}>微训平台用户服务协议</span>
                                    <span>,</span>
                                    <span style={{color:'blue',zIndex:999,fontSize:'13px'}} onClick = {this.showP}>微训用户隐私保护政策</span>
                                    
                                    {this.state.showServiceAgree&&<div style={{position:'fixed',top:'50%',left:'50%',transform:'translate(-50%,-50%)',width:'80%',height:'80%',background:'rgb(240,240,240)',borderRadius:'20px',overflow:'auto',whiteSpace:'pre-wrap',padding:'10px 10px'}}>
                                        {this.s}
                                        <div><Button onClick={this.closeS} style={{width:'100%'}}>关闭</Button></div>
                                    </div>}
                                    {this.state.showPersonelAgree&&<div style={{position:'fixed',top:'50%',left:'50%',transform:'translate(-50%,-50%)',width:'80%',height:'80%',background:'rgb(240,240,240)',borderRadius:'20px',overflow:'auto',whiteSpace:'pre-wrap',padding:'10px 10px'}}>
                                        {this.p}
                                        <div><Button onClick={this.closeP} style={{width:'100%'}}>关闭</Button></div>
                                    </div>}
                                </div>
                            ) : (<div>
                                <List >
                                    <List.Item prefix='手机号'>
                                        <Input value={ phone }
                                               maxLength={ 11 }
                                               placeholder='请输入手机号'
                                               clearable
                                               onChange={ event => this.setState({phone: event.replace(/[^0-9]/, '')}) }/>
                                    </List.Item>
                                    <List.Item prefix='验证码' extra={ <Button disabled={!isSendByLogin} color="primary" fill="none" onClick={this.sendCaptcha}>
                                        {isSendByLogin ? '发送验证码' : `重新获取(${sendTimeByLogin}S)`}
                                    </Button> }>
                                        <Input
                                            value={ loginVal }
                                            maxLength={ 6 }
                                            placeholder='请输入验证码'
                                            clearable
                                            onChange={ loginVal => this.setState({loginVal: loginVal.replace(/[^0-9]/img, "")}) }/>
                                    </List.Item>
                                </List>
                                <WhiteSpace />
                                <WhiteSpace />
                                <Button size="large" color="primary" className='btn' onClick={ this.loginByMsg }>登&nbsp;录</Button>
                            </div>)
                        }
                        <div className="other-login">
                            <div className="login-type">
                                {
                                    messageLogin ? (
                                        <div className="login-type-container">
                                            <div className="login-message" onClick={() => this.setState({ messageLogin: false })}>
                                                <Icon type="lock" />
                                            </div>
                                            <p>密码登录</p>
                                        </div>
                                    ): (
                                        <div className="login-type-container">
                                            <div className="login-message" onClick={() => this.setState({ messageLogin: true })}>
                                                <Icon type="message" />
                                            </div>
                                            <p>短信登录</p>
                                        </div>
                                    )
                                }
                            </div>
                            <div className="login-type">
                                <div className="login-type-container">
                                    <div className="login-wechat" onClick={ this.loginByWechat }>
                                        <Icon type="wechat" />
                                    </div>
                                    <p>微信</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Loading loading={ loading } />
            </div>
        )
    }
}

export default connect(
    undefined,
    (dispatch) =>({
        isLogin: data => { dispatch(isLogin(data)) },
    })
)(Login);