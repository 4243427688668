import React, {Component} from 'react'
import {connect} from 'react-redux'
import * as CoursesAPI from '../API/CoursesAPI'
import {getCourse, SharedInfo} from '../action'
import {withRouter} from "react-router-dom"
import Coupon from '../component/Coupon'
import {Table} from "antd"
import {ShareAltOutlined, DollarCircleTwoTone} from '@ant-design/icons'
import {Avatar, Button, Dialog, Tabs, Tag, Toast, Popup, Form, Input,SpinLoading} from "antd-mobile"
import uuid from "uuid"
import {
    CheckCircleOutline,
    ClockCircleOutline,
    ContentOutline,
    PayCircleOutline,
    SendOutline,
    UserOutline,
    HandPayCircleOutline,
    TextOutline,
    CheckCircleFill,
    StarFill
} from "antd-mobile-icons"
import Loading from "../component/Loading/Loading"
import '../css/Integral.scss'
import zhCN from 'antd/lib/locale/zh_CN'
import moment from 'moment'
import QRCode from 'qrcode.react'
import html2canvas from "html2canvas"
import * as util from "../util/Utils"
import savePicture from "../css/savePicture.scss"


class SavePicture extends Component {

    constructor(props) {
        super(props)
        this.state = {
            showDetail: true,
            isAppendChild: false,
            baseurl: null,
            shareImg: null,
            lessonName: null,
            teacherName: null,
            description: null,
            bak: 0,
            result: null,
            margin: "0px",
            shareList: [
                {
                    url: "https://micro-weixun-input.oss-cn-beijing.aliyuncs.com/share-bg/share-bg-1.PNG",
                    color: "#ffffff",
                    qrColor: "#b06100"
                },
                {
                    url: "https://micro-weixun-input.oss-cn-beijing.aliyuncs.com/share-bg/share-bg-2.PNG",
                    color: "#8c8c8c",
                    qrColor: "#8c8c8c"
                },
                {
                    url: "https://micro-weixun-input.oss-cn-beijing.aliyuncs.com/share-bg/share-bg-3.PNG",
                    color: "#ffffff",
                    qrColor: "#b34538"
                },
                {
                    url: "https://micro-weixun-input.oss-cn-beijing.aliyuncs.com/share-bg/share-bg-4.PNG",
                    color: "#000000",
                    qrColor: "#2b4c81"
                }
            ],
            loading: true
        }
    }

    componentDidMount() {

        document.title = "分享给好友"
        console.log(this.props.match.params.id)
        CoursesAPI.getall(this.props.match.params.id).then(
            data => {
                console.log(data)
                if(data.data){

                let copy = data.data.lessonDescription
                if (copy.length > 57) {
                    copy = copy.slice(0, 57) + "..."
                }
                this.setState({
                    lessonName: data.data.lessonName,
                    teacherName: data.data.teacherName,
                    description: copy
                }, this.handlePreview)
                }else{
                    alert("没有此课程")
                }
               
            })

        // let margin = this.content1.current.offsetWidth-180 + "px"
        // this.setState({margin:margin},console.log(this.state.margin))
    }

    checkImg = (index = 0) => {
        if (index !== this.state.bak) {
            this.setState({bak: index}, this.handlePreview)
        }
    }

    handlePreview = () => {
        const {shareList, bak, lessonName, teacherName, description} = this.state
        this.setState({loading: true},
            () => {
                util.merge(shareList[bak].url, document.getElementById("bill_qr_code_url"), lessonName, teacherName, description, shareList[bak].color).then(
                    (res) => {
                        this.setState({result: res})
                    }
                ).catch(e => alert(`error`)).finally(() => this.setState({loading: false}))
            }
        )

    }

    ImageToCanvas = (image) => {
        var canvas = document.createElement("canvas")
        canvas.width = image.width
        canvas.height = image.height
        canvas.getContext("2d").drawImage(image, 0, 0)//0, 0参数画布上的坐标点，图片将会拷贝到这个地方

        return canvas
    }

    savePicture = () => {
        util.merge(this.props.lessonIcon, document.getElementById("bill_qr_code_url")).then(
            (res) => {
                this.setState({shareImg: res})
                alert(res)
            }
        ).catch(e => alert(`error`))
    }


    render() {
        const {course, isBuy, company, userInfo, isLogin} = this.props
        const {shareList, bak, loading, result} = this.state
        console.log(this.state.baseurl)
        return (
            <div className="savePicture">

                {/*  <div ref={picture => this.picture = picture} style={{background:"#002329",position:"relative",padding:"10px"}} crossOrigin="Anonymous">
                <h2>保存图片分享课程</h2>*/}


                {/*{this.state.shareImg&&<img src={this.state.shareImg} style={{width:"100%"}}/>}*/}
                {/*</div>*/}
                <div className="main-content" ref={content1 => this.content1 = content1}>
                    <canvas ref={canvas1 => this.canavs1 = canvas1} width="360" height="640" style={{
                        width: "180px",
                        height: "320px",
                        position: "absolute",
                        marginLeft: this.state.margin,
                        display: "none"
                    }}>
                    </canvas>
                    <QRCode
                        id="bill_qr_code_url"
                        value={`http://www.wx798.com/course/${this.props.match.params.id}`} //value参数为生成二维码的链接 我这里是由后端返回
                        size={50} //二维码的宽高尺寸
                        fgColor={shareList[bak].qrColor}  //二维码的颜色
                        style={{
                            position: "absolute",
                            bottom: "60px",
                            left: "50%",
                            transform: "translateX(-50%)",
                            display: "none"
                        }}
                        ref={qrcode => this.qrcode = qrcode}
                    />
                    <img src={result} />
                    {loading&&<div className="image-loading"><div className="image-loading-icon"><SpinLoading style={{ '--size': '32px' }} color='#1890ff'/></div><div className="image-loading-text">图片生成中</div></div>}
               
                </div>
                <div className="bottom">
                    <div className="bottom-text"><span>长按上图保存邀请卡发送好友</span><a>更多邀请方式</a></div>
                    <div className="bottom-content">
                        {
                            shareList.map((item, index) => (
                                <div className="bottom-content-item "
                                     onClick={()=>{if(!loading){this.checkImg(index)}}}>{bak === index && <StarFill
                                    style={{
                                        position: "absolute",
                                        top: "30%",
                                        left: "50%",
                                        transform: "translateX(-50%)",
                                        fontSize: "26px",
                                        color: "#ffec3d"
                                    }}/>}<img src={item.url}/></div>

                            ))
                        }
                        <div className="bottom-content-item"></div>
                    </div>
                </div>
            </div>
        )
    }
}


export default connect(
    ({login, course, lessonIcon}) => ({
        isLogin: login.isLogin,
        userInfo: login.userInfo,
        course: course.data,
        userType: login.type,
        company: login.company,
        lessonIcon
    }), dispatch => ({
        setSharedInfo: (data) => dispatch(SharedInfo(data))
    })
)(withRouter(SavePicture));
