import React, { Component } from 'react';
import '../css/dakehu.css';
import {withRouter,Link} from 'react-router-dom';
import phone from '../util/phone';
import { connect } from 'react-redux';
class Dakehufour extends Component{
    state={
        dakehuonem:'none',
        videoone:['https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/4/1.mp4','https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/4/2.mp4','https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/4/3.mp4','https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/4/4.mp4','https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/4/5.mp4','https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/4/6.mp4','https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/4/7.mp4','https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/4/8.mp4','https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/4/9.mp4','https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/4/10.mp4','https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/4/11.mp4','https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/4/12.mp4','https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/4/13.mp4','https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/4/14.mp4','https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/4/15.mp4','https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/4/16.mp4','https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/4/17.mp4','https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/4/18.mp4','https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/4/19.mp4','https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/4/20.mp4'],
        videos:'',
        dakehufous:'block',
        dakehufourns:'none',
        shenqing:'none'
    }
    panduan(videonum){
        if(phone.indexOf(this.props.userInfo.userInfo.phone)==-1){
            this.setState({
                shenqing:'block'
            })
            return -1;
        }else{
            console.log('成功')
            this.setState({
                dakehuonem:'block',
                videos:videonum
            });
            return 2;
        }
    }
     firstm(){
        var videonum=this.state.videoone[0];
        this.panduan(videonum);
    }
    secondm(){
        var videonum=this.state.videoone[1];
        this.panduan(videonum);
    }
    thirdm(){
        var videonum=this.state.videoone[2];
        this.panduan(videonum);
    }
    fourm(){
        var videonum=this.state.videoone[3];
        this.panduan(videonum);
    }
    fivem(){
       var videonum=this.state.videoone[4];
        this.panduan(videonum);
    }
    sixm(){
       var videonum=this.state.videoone[5];
        this.panduan(videonum);
    }
    sevenm(){
       var videonum=this.state.videoone[6];
        this.panduan(videonum);
    }
    eightm(){
        var videonum=this.state.videoone[7];
        this.panduan(videonum);
    }
    ninem(){
        var videonum=this.state.videoone[8];
        this.panduan(videonum);
    }
    shim(){
       var videonum=this.state.videoone[9];
        this.panduan(videonum);
    }
    shiyim(){
        var videonum=this.state.videoone[10];
        this.panduan(videonum);
    }
    shierm(){
        var videonum=this.state.videoone[11];
        this.panduan(videonum);
    }
    shisanm(){
        var videonum=this.state.videoone[12];
        this.panduan(videonum);
    }
    shisim(){
        var videonum=this.state.videoone[13];
        this.panduan(videonum);
    }
    shiwum(){
        var videonum=this.state.videoone[14];
        this.panduan(videonum);
    }
    shilium(){
        var videonum=this.state.videoone[15];
        this.panduan(videonum);
    }
    shiqim(){
        var videonum=this.state.videoone[16];
        this.panduan(videonum);
    }
    shibam(){
        var videonum=this.state.videoone[17];
        this.panduan(videonum);
    }
    shijium(){
       var videonum=this.state.videoone[18];
        this.panduan(videonum);
    }
    ershim(){
        var videonum=this.state.videoone[19];
        this.panduan(videonum);
    }
    closes(){
        var dakehuvideo=document.getElementById('dakehuvideo1');
        dakehuvideo.pause();
        this.setState({
            dakehuonem:'none'
        });
    }
    dakehufournext(){
        var that=this;
        that.setState({
            dakehufourbac:'url("https://weixun-demo.oss-cn-beijing.aliyuncs.com/dakehu/dakehufourfour.jpg")',
            dakehufourns:'block',
            dakehufous:'none'
        });
    }
    dakehufourback(){
        var that=this;
        that.setState({
            dakehufourbac:'url("https://weixun-demo.oss-cn-beijing.aliyuncs.com/dakehu/dakehufour.jpg")',
            dakehufous:'block',
            dakehufourns:'none'
        });
    }
    componentDidMount() {
        var dakehuvideo = document.getElementById('dakehuvideo1');
        var that=this;
        dakehuvideo.addEventListener('ended', function () {
            for(var i=0;i<that.state.videoone.length;i++){
                if(that.state.videos==that.state.videoone[i]){
                    if(that.state.wancheng.indexOf(i)!=-1){
                        console.log('包含');
                    }else{
                        that.setState({
                            wancheng:that.state.wancheng+i
                        })
                    }
                    console.log(that.state.wancheng);
                }

            }
        });
        dakehuvideo.addEventListener("timeupdate",function(){
            var timeDisplay;
            timeDisplay = Math.floor(dakehuvideo.currentTime);
        },true)
    }
    render(){
        return(
            <div className='dakehufourbac' style={{backgroundImage:this.state.dakehufourbac}}>
                <Link to='dakehu'><div className='dakehutui'></div></Link>
                <div className='dakehufourcourseyi' onClick={this.firstm.bind(this)} style={{display:this.state.dakehufous}}></div>
                <div className='dakehufourcourseer' onClick={this.secondm.bind(this)} style={{display:this.state.dakehufous}}></div>
                <div className='dakehufourcoursesan' onClick={this.thirdm.bind(this)} style={{display:this.state.dakehufous}}></div>
                <div className='dakehufourcoursesi' onClick={this.fourm.bind(this)} style={{display:this.state.dakehufous}}></div>
                <div className='dakehufourcoursewu' onClick={this.fivem.bind(this)} style={{display:this.state.dakehufous}}></div>
                <div className='dakehufourcourseliu' onClick={this.sixm.bind(this)} style={{display:this.state.dakehufous}}></div>
                <div className='dakehufourcourseqi' onClick={this.sevenm.bind(this)} style={{display:this.state.dakehufous}}></div>
                <div className='dakehufourcourseba' onClick={this.eightm.bind(this)} style={{display:this.state.dakehufous}}></div>
                <div className='dakehufourcoursejiu' onClick={this.ninem.bind(this)} style={{display:this.state.dakehufous}}></div>
                <div className='dakehufourcourseshi' onClick={this.shim.bind(this)} style={{display:this.state.dakehufous}}></div>
                <div className='dakehufournext' onClick={this.dakehufournext.bind(this)} style={{display:this.state.dakehufous}}></div>

                <div className='dakehufourcourseshiyi' onClick={this.shiyim.bind(this)} style={{display:this.state.dakehufourns}}></div>
                <div className='dakehufourcourseshier' onClick={this.shierm.bind(this)} style={{display:this.state.dakehufourns}}></div>
                <div className='dakehufourcourseshisan' onClick={this.shisanm.bind(this)} style={{display:this.state.dakehufourns}}></div>
                <div className='dakehufourcourseshisi' onClick={this.shisim.bind(this)} style={{display:this.state.dakehufourns}}></div>
                <div className='dakehufourcourseshiwu' onClick={this.shiwum.bind(this)} style={{display:this.state.dakehufourns}}></div>
                <div className='dakehufourcourseshiliu' onClick={this.shilium.bind(this)} style={{display:this.state.dakehufourns}}></div>
                <div className='dakehufourcourseshiqi' onClick={this.shiqim.bind(this)} style={{display:this.state.dakehufourns}}></div>
                <div className='dakehufourcourseshiba' onClick={this.shibam.bind(this)} style={{display:this.state.dakehufourns}}></div>
                <div className='dakehufourcourseshijiu' onClick={this.shijium.bind(this)} style={{display:this.state.dakehufourns}}></div>
                <div className='dakehufourcourseershi' onClick={this.ershim.bind(this)} style={{display:this.state.dakehufourns}}></div>
                <div className='dakehufourback' onClick={this.dakehufourback.bind(this)} style={{display:this.state.dakehufourns}}></div>
                <div className='dakehushenqing' style={{display:this.state.shenqing}} ></div>
                <video className='dakehuvideo1' id='dakehuvideo1'  style={{display:this.state.dakehuonem}} controls='controls'  autoPlay='true' src={this.state.videos}/>
                <div className='dakehuclose' onClick={this.closes.bind(this)} style={{display:this.state.dakehuonem}}>X</div>
            </div>

        )
    }
}

function mapStateToProps({ login}/*默认值加解构数组*/) {

    console.log(login)
    return {
        userInfo: login

    }
}

export default withRouter(connect(mapStateToProps)(Dakehufour));
