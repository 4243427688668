import React, { Component } from 'react';
import '../css/video.css'
import { withRouter } from 'react-router-dom';
import { Player, BigPlayButton } from 'video-react';
import "video-react/dist/video-react.css";
import * as CoursesAPI from '../API/CoursesAPI';
import { getVideo ,setFullScreen} from '../action'
import { connect } from 'react-redux';
import { Button, Checkbox, Icon } from 'antd';
import CourseTabs from './CourseTabs';
import MicroPlayer from "../component/MicroPlayer/MicroPlayer.jsx"
class Video extends Component {
	lowerCaseOpation = ['a', 'b', 'c', 'd', 'e', 'f'];
	upperCaseOpation = ['A', 'B', 'C', 'D', 'E', 'F'];
	isAndriod = true;

	state = {

		// 需要显示的题目
		question: {
			isShow: false,
			index: -1,
			timer: false
		},
		answer: {
			singleValue: '',
			chooseValue: []
		},
		answerResult: '',
		isLoading: false,
		questionStyle: { opacity: '0', transform: 'scale(1)' }
	};



	start = null

	isSend = false;

	currentTime = null

	componentWillUnmount() {
	}

	componentDidMount() {
		

		const lessonId = this.props.lessonId;

		const videoId = this.props.videoId;

		const chapter = this.props.match.params.chapter;

		const firstPlay = true

		const playState = "ready"

		this.start = new Date().getTime();
		let that = this



		
		function updatedata() {
			console.log("234234")
			if(this.videoState === "pause"){
				return;
			}
			if(document.visibilityState !== 'hidden'){
				return;
			}
			let now = new Date().getTime();
			let data = parseInt((now - that.startTime) / 1000);
			let headers = {
				type: 'application/x-www-form-urlencoded'
			};

			let para = new FormData();
			para.append('lengthoftime', data);
			para.append('lessonId', that.props.lessonId);
			para.append('videoId', that.props.video.id);
			para.append('token', localStorage.token);
			para.append('time', that.currentTimes);
			para.append('name', this.props.video.name);
			para.append('chapter', this.props.video.chapter);
			console.log(data)
			console.log(that.props.location.pathname.startsWith("/video"))
			if (that.props.location.pathname.startsWith("/course")) {
				console.log(document.visibilityState)
				if (that.isSend === false && data > 10) {
					if (!navigator.sendBeacon) {
						var client = new XMLHttpRequest();
						// 第三个参数表示同步发送
						client.open('POST', '/api/record.do', false);
						client.setRequestHeader('Content-Type', 'text/plain;charset=UTF-8');
						client.send(data);
					}
					else {
						localStorage.tt = navigator.sendBeacon("/api/record.do", para);
					}
					localStorage.a = data
					that.start = new Date().getTime();
					that.isSend = true;
				}
				else {
					that.start = new Date().getTime();
					that.isSend = false;
				}
			}
		}
		function updatedata2() {
			console.log("234")
			if(this.videoState === "pause"){
				return;
			}
			let now = new Date().getTime();
			let data = parseInt((now - that.startTime) / 1000);
			let headers = {
				type: 'application/x-www-form-urlencoded'
			};

			let para = new FormData();
			para.append('lengthoftime', data);
			para.append('lessonId', this.props.lessonId);
			para.append('videoId', that.props.video.id);
			para.append('token', localStorage.token);
			para.append('time', that.currentTimes);
			para.append('name', that.props.video.name);
			para.append('chapter', that.props.video.chapter);
			console.log(data)
			console.log(that.props.location.pathname.startsWith("/video"))
			if (that.props.location.pathname.startsWith("/course")) {
				console.log(document.visibilityState)
				if (that.isSend === false && data > 10) {
					if (!navigator.sendBeacon) {
						var client = new XMLHttpRequest();
						// 第三个参数表示同步发送
						client.open('POST', '/api/record.do', false);
						client.setRequestHeader('Content-Type', 'text/plain;charset=UTF-8');
						client.send(data);
					}
					else {
						localStorage.tt = navigator.sendBeacon("/api/record.do", para);
					}
					localStorage.a = data
					that.start = new Date().getTime();
					that.isSend = true;
				}
				else {
					that.start = new Date().getTime();
					that.isSend = false;
				}
			}
		}

		function setTime() {

			that.time = that.player.currentTime.toString()
		}

		window.addEventListener('popstate', updatedata2)
		document.addEventListener('visibilitychange', updatedata)
		window.addEventListener('pagehide', updatedata)
		
		// 是否为安卓手机
		
			this.props.getVideo(this.props.videoData);
			// TODO 如果没有题目就不对视频进行监听
			// TODO 视频定位
			if(this.props.videoData.data){
            if (this.props.videoData.data.playtime !== null && this.props.videoData.data.playtime !== "null") {
				this.MicroPlayer.videoPlayer.currentTime = this.props.videoData.data.playtime;
			}
            }
	}

	// TODO 单选答案改变
	handleSingleOptionChanged = _answer => {

		this.changeAnswerResult("");
		const { answer } = Object.assign(this.state);
		answer.singleValue = _answer;
		this.setState({
			answer,
		})
	};
	// TODO 多选答案改变
	handleChooseOptionChanged = (checked) => {
		this.changeAnswerResult("");
		const { answer } = Object.assign(this.state);
		answer.chooseValue = checked;
		this.setState({
			answer,
		})
	};
	/*
	* 设置答题结果
	* '' 未答题
	* 'ok' 回答正确
	* 'error' 回答错误
	* */
	changeAnswerResult = (value) => {
		const answerResult = value;
		this.setState({
			answerResult,
		})
	};

	// TODO 关闭答题窗口
	closeQuestion = () => {
		// 如果答题之前是全屏状态 就设置回到全屏

		// TODO 回复未答题状态
		if (this.state.answerResult)

			this.changeAnswerResult("");
		this.MicroPlayer.play();
		let index = this.state.question.index
		this.handleChangeQuestion(index, false, false);
		// TODO 还原默认选项
		this.setState({
			answer: {
				singleValue: '',
				chooseValue: [],
			}
		})
	};
	// TODO 视频监听
	currentTimes
	handleStateChange = (currentTime) => {
		const { questions } = this.props.video;
		// TODO 此处三目构成方法重载
		const playTime = ~~currentTime;
		this.currentTimes = ~~currentTime
		questions.map((item, index) => {
			if (playTime === Number(item.time) && this.state.question.timer === false) {
				console.log(this.MicroPlayer)
				this.MicroPlayer.pause();
				this.handleChangeQuestion(index, true,true);
				return;
			}
		});
	};
	// TODO 修改选项
	handleChangeQuestion = (index, isShow,timer) => {
		this.setState({
			question: timer ? {
				index,
				isShow,
				timer
			}: {
				index,
				isShow,
			}
		}, () => {
			setTimeout(() => {
				this.setState({
					questionStyle: isShow ? {
						opacity: '1',
						transform: 'scale(1)'
					} : {
						opacity: '0',
						transform: 'scale(.8)'
					}
				})
			}, 100)
			setTimeout(() => this.setState({
				question: timer ? {...this.state.question} : {
					...this.state.question,
					timer
				}
			}), 1000)
		})
	};

	// TODO 提交答案 SINGLE
	submitSingleAnswers = () => {
		console.log(this.props.video.questions[this.state.question.index].answer,this.state.answer.singleValue)
		if (this.props.video.questions[this.state.question.index].answer === this.state.answer.singleValue) {
			this.changeAnswerResult("ok");
		} else {
			this.changeAnswerResult("error");
		}
		// 提交到服务器
		if (this.state.answer.singleValue !== "") {
			CoursesAPI.setAnswer(this.props.video.id, this.props.video.questions[this.state.question.index].qId).then(data => {
			})
		}
	};
	// TODO 提交答案 CHOOSE
	submitChooseAnswers = () => {
		const question = Object.assign(this.props.video.questions[this.state.question.index]);
		if (question.answer === this.state.answer.chooseValue.join(',')) {
			this.changeAnswerResult("ok");
		} else {
			this.changeAnswerResult("error");
		}
		// TODO 提交到服务器
		if (this.state.answer.chooseValue.length !== 0) {
			CoursesAPI.setAnswer(this.props.video.id, this.props.video.questions[this.state.question.index].qId).then(data => {
			})
		}
	};
	onPlaying=(currentTime,totalTime)=>{
       this.handleStateChange(currentTime);
	}
	startTime
	videoState = "pause"
	setStartTime(){
		this.startTime = new Date();
		this.videoState = "play"
		console.log(this.props.course)
		console.log(this.props.video)
	}

	sendData(){
		this.videoState = "pause"
		let data = (new Date() - this.startTime)/1000
		let para = {};
		para.lengthoftime = data;
		para.lessonId = this.props.lessonId;
		para.videoId = this.props.videoId;
		para.token= localStorage.token;
		para.time = this.currentTimes;
		para.name = this.props.video.name;
		para.chapter = this.props.video.chapter;
		CoursesAPI.record(para)
	}

	render() {
		let questionsList = {
			choose: null,
			answer: null,
			index: null,
			questions: null,
			submitType: null
		};
		// TODO 初始化题目
		if (this.state.question.isShow) {
			let that = this
			questionsList.index = this.state.question.index;
			questionsList.questions = this.props.video.questions[questionsList.index];
			questionsList.choose = questionsList.questions.choose.split(",");
			questionsList.answer = questionsList.questions.answer;
			switch (questionsList.questions.type) {
				case 'single':
					questionsList.submitType = <Button className='submit-single' onClick={this.state.answerResult !== 'ok' ? this.submitSingleAnswers : this.closeQuestion} type='primary'>{this.state.answerResult !== 'ok' ? '提交' : '回到课程'}</Button>
					break;
				case 'choose':
					questionsList.submitType = <Button className='submit-single' onClick={this.state.answerResult !== 'ok' ? this.submitChooseAnswers : this.closeQuestion} type='primary'>{this.state.answerResult !== 'ok' ? '提交' : '回到课程'}</Button>
					break;
			}
		} else {
			questionsList = null;
		}
		const {  isLoading } = this.state;
		return (
			<div className="v_video">
				{this.props.videoData&&<MicroPlayer sendData = {this.sendData.bind(this)} setStartTime = {this.setStartTime.bind(this)} handleChangeQuestion={this.handleChangeQuestion} exp={(ref)=>{this.MicroPlayer = ref}} proportion={16/9} onPlaying={this.onPlaying} address={this.props.videoData.data.address} />}
				{this.state.question.isShow ? (
					<div className='question-mask' style={this.state.questionStyle}>
						<div className='question'>
							<span className='close' onClick={this.closeQuestion}><Icon type='left' /></span>
							<h3>123选择题</h3>
							<p>{questionsList.questions.question}</p>
							{
								questionsList.questions.type === "single" ? (
									<ul className='question_wrapper'>
										{
											questionsList.choose.map((item, index) => (
												<li key={index} className={`choose_item ${this.state.answer.singleValue === this.upperCaseOpation[index] ? 'selected' : 'select'}`} onTouchEnd={() => this.handleSingleOptionChanged(this.upperCaseOpation[index])}>{item}</li>
											))
										}
									</ul>
								) : questionsList.questions.type === "choose" ? (
									<Checkbox.Group className='question_wrapper' onChange={this.handleChooseOptionChanged} >
										{
											questionsList.choose.map((item, index) => (
												<div className='list_item' key={index}>
													<Checkbox value={this.upperCaseOpation[index]}>{this.upperCaseOpation[index]}. {item}</Checkbox>
												</div>
											))
										}
									</Checkbox.Group>
								) : null /* TODO 其他类型题目预留view */
							}
							{this.state.answerResult === 'ok' ? (
								<p className='answer_result' style={{ color: 'green' }}>回答正确</p>
							) : (this.state.answerResult === 'error' ? (
								<p className='answer_result' style={{ color: 'red' }}>回答错误</p>
							) : null)
							}
							{questionsList.submitType}
						</div>
					</div>
				) : null}
			</div>
		)
	}
}
{/* TODO 秒数序列化 00：00：00 */}
const timeFormat = s => {
	if (s >= 3600) {
		let hour = ~~(s / 3600);
		let minS = s % 3600;
		let min = ~~(minS / 60);
		let ss = ~~(minS % 60);
		return `${hour > 10 ? hour : `0${hour}`}:${min > 10 ? min : `0${min}`}:${ss > 10 ? ss : `0${ss}`}`;

	} else {
		let min = ~~(s / 60);
		let ss = ~~(s % 60);
		return `00:${min > 10 ? min : `0${min}`}:${ss > 10 ? ss : `0${ss}`}`;
	}
};

export default connect (
	({login, course }) => ({
		userInfo: login.userInfo,
		msg: course.msg,
		video: course.video,
		course:course
	}), dispatch => ({
		getVideo: (data) => dispatch(getVideo(data))
		}))
	(withRouter(Video));