import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { Avatar, Dialog } from 'antd-mobile'
import * as user from '../API/user'
import '../css/courseHome.scss';

class CourseHome extends Component {

	constructor(props) {
		super(props);
		this.state = {}
	}

	componentDidMount() {
		document.title = "微训网";
	}

	logout = () => {
		Dialog.confirm({
			title: "退出登录",
			content: '你确定要退出登录吗？',
			confirmText: '退出',
			onConfirm: () => {
				user.logout()
					.finally(() => {
						window.location.href = `https://${window.location.hostname}`;
					})
			},
		})
	};

	render() {
		const { isLogin, userInfo, userType } = this.props;
		return (
			<div className='course-home' ref={e => this.e = e}>
				{
					isLogin && (
						<div className="logout" onClick={ () => this.props.history.push("/personal") }>
							<Avatar style={{ '--size': '30px' }} src={userInfo.headUrl ? userInfo.headUrl :'https://micro-weixun.oss-cn-beijing.aliyuncs.com/user.png' } />
						</div>
					)
				}
				<div className="logo">
					<img src="https://micro-weixun.oss-cn-beijing.aliyuncs.com/micro_mobile/home/logo.png" alt=""/>
				</div>
				<div className="plane">
					<img src="https://micro-weixun.oss-cn-beijing.aliyuncs.com/micro_mobile/home/plane.png" alt=""/>
				</div>
				<div className="icons">
					<div className="icon-item" onClick={ () => this.props.history.push("/courseList")} >
						<img  src="https://micro-weixun.oss-cn-beijing.aliyuncs.com/micro_mobile/home/icon-1.png" alt=""/>
					</div>
					<div className="icon-item" onClick={ () => this.props.history.push("/gameList")} >
						<img src="https://micro-weixun.oss-cn-beijing.aliyuncs.com/micro_mobile/home/icon-2.png" alt=""/>
					</div>
					<div className="icon-item" onClick={ () => {
						if (!isLogin) this.props.history.push("/login")

						else {
							console.log(userType)
							if (userInfo.type>0) {
								if (userType === 1)
									this.props.selectBusiness(() => {
										window.location.href = `https://${window.location.hostname}/personal`;
									});
								else {
									Dialog.show({
										title: "提示",
										content: <div style={{textAlign: "center"}}>
											<div>&nbsp;&nbsp;&nbsp;&nbsp;您尚未被企业邀请。</div>
											<div>您可以向您的企业HR推荐</div>
										</div>,
										closeOnAction: true,
										actions: [{key: 'exit', text: '返回'}]
									})
								}
							}
						}
					}} >
						<img src="https://micro-weixun.oss-cn-beijing.aliyuncs.com/micro_mobile/home/icon-3.png" alt=""/>
					</div>
				</div>
				<div className="courses" style={{height: "800px"}}>
					<div className="course-item course-1" onClick={() => this.props.history.push("/newme")}>
						<img src="https://micro-weixun.oss-cn-beijing.aliyuncs.com/micro_pc_fe/home/lesson-3.gif" alt=""/>
					</div>
					{/*<div className="course-item course-2">
						<img src="https://micro-weixun.oss-cn-beijing.aliyuncs.com/micro_mobile/home/course-2.png" alt=""/>
					</div>*/}
					{/*<div className="course-item course-3" onClick={ () => this.props.history.push("/sun")}>
						<img src="https://micro-weixun.oss-cn-beijing.aliyuncs.com/micro_mobile/home/course-3.png" alt=""/>
					</div>*/}
					<div className="course-item course-4" onClick={ () => this.props.history.push("/course/2")}>
						<img src="https://micro-weixun.oss-cn-beijing.aliyuncs.com/micro_pc_fe/home/lesson-11.gif" alt=""/>
					</div>
					{/*<div className="course-item course-5">
						<img src="https://micro-weixun.oss-cn-beijing.aliyuncs.com/micro_mobile/home/course-5.png" alt=""/>
					</div>*/}
					<div className="course-item course-6" onClick={ () => this.props.history.push("/course/50")}>
						<img src="https://micro-weixun.oss-cn-beijing.aliyuncs.com/micro_mobile/home/time-manage.png" alt=""/>
					</div>
					<div className="course-item course-7" onClick={ () => window.location.href = "http://m.wx798.com/H5/demo2/index.html"}>
						<img src="https://micro-weixun.oss-cn-beijing.aliyuncs.com/static/xiaofang.gif" alt=""/>
					</div>
					<div className="course-item course-8" onClick={ () => this.props.history.push("/course/1")}>
						<img src="https://micro-weixun.oss-cn-beijing.aliyuncs.com/micro_pc_fe/home/lesson-9.gif" alt=""/>
					</div>
					<div className="course-item course-9" onClick={ () => this.props.history.push("/course/3")}>
						<img src="https://micro-weixun.oss-cn-beijing.aliyuncs.com/micro_pc_fe/home/lesson-2.gif" alt=""/>
					</div>
					<div className="course-item user-central" onClick={ () => this.props.history.push("/personal")}>
						<img src="https://micro-weixun.oss-cn-beijing.aliyuncs.com/micro_pc_fe/home/lesson-10.gif" alt=""/>
					</div>
					<div className="course-footer" />
				</div>
			</div>
		)
	}
}

export default connect(
	({ login }) => ({
		isLogin: login.isLogin,
		userInfo: login.userInfo,
		userType: login.type
	})
)(withRouter(CourseHome));
