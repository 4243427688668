import React, { Component } from 'react';
import '../css/dakehu.css';
import {withRouter,Link} from 'react-router-dom';
import * as user from "../API/user";
import * as email from "../API/email";
import * as pay from "../API/Pay";
import {connect} from "react-redux";
import phone from '../util/phone';
import Newmefour from "./newmefour";
class Dakehu extends Component{
    state={
        dakehup:'none',
        youxiang:'none',
        youxiangmengban:'none',
        email:'',
        buy: false,
        isBuy: '已购买',
    }
    dakehuzhengshu(){
        var that=this;
        that.setState({
            dakehup:'block'
        })
    }
    zhengshumengban(){

    }
    dakehuziliao(){
        var that=this;
        that.setState({
            youxiang:'block',
            youxiangmengban:'block'
        })
    }
    youxiangmeng(){
        var that=this;
        that.setState({
            youxiang:'none',
            youxiangmengban:'none'
        })
    }
    onBridgeReady(code, me) {
        window.WeixinJSBridge.invoke(
            'getBrandWCPayRequest', code,
            function (res) {

                if (res.err_msg === "get_brand_wcpay_request:ok") {
                    me.setState({
                        buy: false,
                        isBuy: '已购买',
                    })
                }

            }
        );
    }

    componentDidMount(){
    }

    dakehuchengji(){
        var that=this;
        var num=this.props.userInfo.data.user.openId;
            setTimeout(function () {

                pay.create([-1]).then((datas)=>{
                    console.log(datas)
                    console.log(datas.data.order_id)
                    console.log(num)
                    pay.payOrder(datas.data.order_id,num).then(data2 => {
                        let code = {
                            "appId": "wxd033f96f0d37892d",
                            "timeStamp": data2.timeStamp,
                            "nonceStr": data2.nonceStr,
                            "package": data2.package,
                            "signType": "MD5",
                            "paySign": data2.sign
                        }
                        JSON.stringify(code)
                        let me = this
                        if (typeof WeixinJSBridge === "undefined") {
                            if (document.addEventListener) {
                                document.addEventListener('WeixinJSBridgeReady', this.onBridgeReady.bind(null, code), false);
                            } else if (document.attachEvent) {
                                document.attachEvent('WeixinJSBridgeReady', this.onBridgeReady.bind(null, code));
                                document.attachEvent('onWeixinJSBridgeReady', this.onBridgeReady.bind(null, code));
                            }
                        } else {
                            that.onBridgeReady(code, me);
                            pay.getdakehu(-1).then(data3=>{
                                }).catch(
                                    error=>console.log(error)
                            )
                        }
                    })
                }).catch(
                    error=>console.log(error)
                )
            },200)



    }

    youxiangqueren(){


        var inputyouxiang=document.getElementById('inputyouxiang').value;
        console.log(inputyouxiang);
        fetch('https://www.51-interview.com:11000/cemailss',{
            method: 'POST',

            mode: 'cors',
            headers:{
                'Content-Type':'application/x-www-form-urlencoded'
        },
            body:"youxiang="+inputyouxiang+"&oi=45",

        }).then(res => {
            return res.json();
        }).then(json => {
            console.log('获取的结果', json.data);
            return json;
        }).catch(err => {
            console.log('请求错误', err);
        })
        /*
        email.loginByValCode(bodys).then((data)=>{
            console.log(data)
        }).catch(
            error=>console.log(error)
        )
        */
    }

    jump(route){
        window.location.href='http://'+window.location.host+'/'+route
        // window.location.href='https://'+window.location.host+'/'+route
    }

    render(){
        return(

            <div className='dakehubac'>
                <img className="dakehubacgif" src='https://weixun-demo.oss-cn-beijing.aliyuncs.com/sun/dakehubac.gif'/>
                <div className='dakehuone' onClick={this.jump.bind(this,'dakehuone')}></div>
                <div className='dakehutwo' onClick={this.jump.bind(this,'dakehutwo')}></div>
                <div className='dakehuthree' onClick={this.jump.bind(this,'dakehuthree')}></div>
                <div className='dakehufour' onClick={this.jump.bind(this,'dakehufour')}></div>
                <div className='dakehufive' onClick={this.jump.bind(this,'dakehufive')}></div>
                <div className='dakehusix' onClick={this.jump.bind(this,'dakehusix')}></div>
                <div className='dakehuseven' onClick={this.jump.bind(this,'dakehuseven')}></div>
                <div className='dakehueight' onClick={this.jump.bind(this,'dakehueight')}></div>
                <div className='dakehucourse' onClick={this.jump.bind(this,'dakehucourse')}></div>
                <div className='dakehuteacher' onClick={this.jump.bind(this,'dakehuteacher')}></div>
                <div className='dakehuchengji' onClick={this.dakehuchengji.bind(this)}></div>
                <div className='dakehuzhengshu' onClick={this.dakehuzhengshu.bind(this)}></div>
                <div className='dakehuziliao' onClick={this.dakehuziliao.bind(this)}></div>

                <div className='dakehuyouxiang' style={{display:this.state.youxiang}}></div>
                <input id='inputyouxiang' className='youxianginput' type='text' style={{display:this.state.youxiangmengban}}></input>
                <div className='youxiangmengban' onClick={this.youxiangmeng.bind(this)} style={{display:this.state.youxiangmengban}}></div>
                <div className='zhengshutitle' style={{display:this.state.dakehup}}></div>
                <div className='youxiangqueren' onClick={this.youxiangqueren.bind(this)} style={{display:this.state.youxiangmengban}}></div>
                <Link to='zhengshu'><div className='zhengshumengban' style={{display:this.state.dakehup}}></div></Link>
            </div>

        )
    }
}

function mapStateToProps({ login}/*默认值加解构数组*/) {

    console.log(login)
    return {
        userInfo: login

    }
}

export default Dakehu
