import React, { Component } from 'react';
import PreloadVideo from "../component/PreloadVideo";
import '../css/newmetwo.css';
import {Link} from "react-router-dom";

class Newmetwo extends Component {

    componentDidMount(){

    }

    render(){
        return(
            <div className='bactwo' id='bactwo'>
                <PreloadVideo url='https://micro-weixun.oss-cn-beijing.aliyuncs.com/micro_mobile_propaganda/video/jiyu.mp4' height='90%' showVideo={true} poster={"https://micro-weixun.oss-cn-beijing.aliyuncs.com/static/poster2.jpg"}/>
                <Link to='newme'><div className='newmeonebactui'></div></Link>
            </div>

        )
    }
}
export default Newmetwo