import React, { Component } from 'react';
import '../css/newmeone.css';
import {Link} from "react-router-dom";
import oneneirong from '../photo/sunone.png';
class Newmeone extends Component {

    render(){
        return(
            <div className='newmebacone'>
                <div className='newmeonebac'>
                    <img className='newmeonebacs' src='https://weixun-demo.oss-cn-beijing.aliyuncs.com/dakehu/sunone.png'/>
                </div>
                <Link to='/newme'> <div className='newmeonebactuis'></div></Link>
            </div>

        )
    }
}
export default Newmeone
