import React, { Component } from 'react';
import '../css/videoComponent.css'
import {withRouter} from 'react-router-dom';
import { Player, BigPlayButton } from 'video-react';
import { Radio, Button, Checkbox, Icon } from 'antd';

class VideoComponent extends Component {

	lowerCaseOpation = ['a', 'b', 'c', 'd', 'e', 'f'];
	upperCaseOpation = ['A', 'B', 'C', 'D', 'E', 'F'];
	isAndriod = null;
	state={
		// 需要显示的题目
		question: {
			isShow: false,
			index: 0,
			timer: false
		},
		answer: {
			singleValue: '',
			chooseValue: []
		},
		answerResult: '',
		isAndroid: null,
		fullScreen: false,
		androidIsPlay: false,
		showCtrlBar: false,
		isLoading: false,
		currentTime: '00:00:00',
		totalTime: '00:00:00',
		questionStyle: {opacity: '0', transform: 'scale(1)'}
	};

	start=null

	isSend=false;

	componentDidMount() {
		
		
		// 是否为安卓手机
		this.isAndriod = navigator.appVersion.indexOf("Android") !== -1;
		console.log(this.isAndriod)
		this.setState({isAndroid: this.isAndriod});
		// const id = this.props.match.params.id;
		// const name = this.props.match.params.name;
		// const chapter = this.props.match.params.chapter;
		if (this.isAndriod) {
					this.androidJTing();
					this.openPlayerCtrlBar();
					// TODO 视频进行缓冲
					this.player.onwaiting = () => {
						if (this.player.readyState === 2)
							this.setState({isLoading: true});
					};
					// TODO 缓冲结束
					this.player.onplaying = () => {
						console.log("123456")
						if (this.player.readyState === 3||this.player.readyState === 4)
							this.setState({isLoading: false});
					};
					// TODO 播放结束
					this.player.onended = () => {
						this.setState({
							androidIsPlay: false,
							showCtrlBar: false,
							isLoading: false,
							currentTime: '00:00:00',
							totalTime: '00:00:00'
						})
					};
		} else {
			console.log(this.state.isAndroid)
			console.log(this.player)
			this.player.subscribeToStateChange(this.handleStateChange);
		}
		console.log()
		// this.androidJTing();
	}

	// TODO 进入全屏 IOS
	fullScreen = () => {
		const { video } = this.player.video;
		if (video.requestFullscreen) {
			video.requestFullscreen();
		} else if (video.mozRequestFullScreen) {
			video.mozRequestFullScreen();
		} else if (video.webkitRequestFullScreen) {
			video.webkitRequestFullScreen();
		}
	};

	// TODO 唤起播放器控制条 (2S 自动隐藏) ANDROID
	openPlayerCtrlBar = () => {
		this.setState({
			showCtrlBar: true,
		}, () => {
			this.ctrlBarTimer = setTimeout(() => {
				this.setState({showCtrlBar: false});
			}, 2000);
		});
	};

	// TODO 立即隐藏控制条 ANDROID
	hiddenPlayerCtrlBar = () => {
		clearTimeout(this.ctrlBarTimer);
		if (this.state.showCtrlBar)
			this.setState({showCtrlBar: false});
	};

	// TODO 延长隐藏事件 ANDROID
	extendPlayerCtrlTimer = () => {
		clearTimeout(this.ctrlBarTimer);
		this.ctrlBarTimer = setTimeout(() => {
			this.setState({showCtrlBar: false});
		}, 2000);
	};

	// TODO 退出全屏 IOS
	exitFullscreen = () => {
		const { video } = this.player.video;
		if (video.webkitExitFullScreen) {
			video.webkitExitFullScreen();
		} else if (video.exitFullscreen) {
			video.exitFullscreen();
		} else if (video.mozCancelFullScreen) {
			video.mozCancelFullScreen();
		} else {}
	};

	// TODO 单选答案改变
	handleSingleOptionChanged = _answer => {
		this.changeAnswerResult("");
		const { answer } = Object.assign(this.state);
		answer.singleValue = _answer;
		this.setState({
			answer,
		})
	};
	// TODO 多选答案改变
	handleChooseOptionChanged = (checked) => {
		this.changeAnswerResult("");
		const { answer } = Object.assign(this.state);
		answer.chooseValue = checked;
		this.setState({
			answer,
		})
	};
	/*
	* 设置答题结果
	* '' 未答题
	* 'ok' 回答正确
	* 'error' 回答错误
	* */
	changeAnswerResult = (value) => {
		const answerResult = value;
		this.setState({
			answerResult,
		})
	};

	// TODO 关闭答题窗口
	closeQuestion = () => {
		// 如果答题之前是全屏状态 就设置回到全屏
		if(!this.isAndriod){
			if (this.isFullscreen)
				this.fullScreen();
		}
		// TODO 回复未答题状态
		if (this.state.answerResult)
			this.changeAnswerResult("");
		this.keepOn();
		this.handleChangeQuestion(null, false);
		// TODO 还原默认选项
		this.setState({
			answer: {
				singleValue: '',
				chooseValue: [],
			}
		})
	};
	// TODO 视频监听
	handleStateChange = (state) => {
		const { questions } = this.props.video;
		// TODO 此处三目构成方法重载
		console.log(questions)
		console.log(this.props.video)
		const playTime = parseInt(this.isAndriod ? ~~state : ~~state.currentTime);
		questions.map((item, index) => {
			console.log(playTime)
			if(playTime === Number(item.time) && this.state.question.timer === false) {
				this.pause();
				this.handleChangeQuestion(index, true);
				return;
			}
		});
	};
	// TODO 修改选项
	handleChangeQuestion = (index, isShow) => {
		this.setState({
			question: {
				index,
				isShow
			}
		}, () => setTimeout(() => this.setState({questionStyle: isShow ? {opacity: '1',  transform: 'scale(1)'} : {opacity: '0', transform: 'scale(.8)'}}), 100))
	};
	// TODO 视频监听 ANDROID
	androidJTing = () => {
		
		this.player.ontimeupdate = () => {
			const { currentTime, duration } = this.player;
			
			this.handleStateChange(currentTime);
			this.setState({
				currentTime: timeFormat(currentTime),
				totalTime: timeFormat(duration)
			});
		};
	};
	// TODO 暂停 IOS OR ANDROID
	pause = () => {
		// 如果全屏退出全屏并记录此时状态以便于关闭答题时返回答题前状态
		if (this.isAndriod) {
			this.androidPause();
			this.androidExitFullScreen();
		} else {
			if(document.webkitIsFullScreen || document.mozIsFullScreen || this.player.video.video.webkitDisplayingFullscreen) {
				this.exitFullscreen();
				this.setState({fullScreen: true});
			} else
				this.setState({fullScreen: false});
			this.player.pause();
		}
	};
	
	// TODO 继续 IOS OR ANDROID
	keepOn = () => {
		this.state.question.timer = true;
		if (this.isAndriod) {
			if (this.currentFullScreenState)
				this.androidFullScreen();
			this.androidPlay();
		} else {
			this.fullScreen();
			this.player.play();
		}
		// TODO 答题之后设置3秒不在显示本题
		window.setTimeout(() => {
			this.state.question.timer = false;
		}, 3000);
	};
	// TODO 提交答案 SINGLE
	submitSingleAnswers = () => {
		if(this.props.video.questions[this.state.question.index].answer === this.state.answer.singleValue){
			this.changeAnswerResult("ok");
		} else {
			this.changeAnswerResult("error");
		}
		// 提交到服务器
		if (this.state.answer.singleValue !== "") {
			// CoursesAPI.setAnswer(this.props.video.id, this.props.video.questions[this.state.question.index].qId).then(data => {
			// 	console.log(data);
			// })
		}
	};
	// TODO 提交答案 CHOOSE
	submitChooseAnswers = () => {
		const question = Object.assign(this.props.video.questions[this.state.question.index]);
		console.log(this.state.answer.chooseValue);
		if(question.answer === this.state.answer.chooseValue.join(',')) {
			this.changeAnswerResult("ok");
		} else {
			this.changeAnswerResult("error");
		}
		// TODO 提交到服务器
		if (this.state.answer.chooseValue.length !== 0) {
			// CoursesAPI.setAnswer(this.props.video.id, this.props.video.questions[this.state.question.index].qId).then(data => {
			// 	console.log(data);
			// })
		}
	};
	// TODO 全屏 ANDROID
	androidFullScreen = () => {
		const { clientWidth } = document.documentElement;
		this.player.style = `
		width: ${document.documentElement.clientHeight}px;
		height: ${clientWidth}px;`;
		this.setState({fullScreen: true});
	};
	// TODO 退出全屏 ANDROID
	androidExitFullScreen = () => {
		this.player.style = "";
		this.currentFullScreenState = this.state.fullScreen;
		this.setState({fullScreen: false});
	};
	// TODO 播放 ANDROID
	androidPlay = () => {
		this.player.play();
		this.setState({androidIsPlay: true});
	};
	// TODO 暂停 ANDROID
	androidPause = () => {
		this.player.pause();
		this.setState({androidIsPlay: false});
	};

	render(){

		let questionsList = {
			choose: null,
			answer: null,
			index: null,
			questions: null,
			submitType: null
		};
		// TODO 初始化题目
		console.log(this.state.isAndroid)
		if(this.state.question.isShow) {
			let that=this
			questionsList.index = this.state.question.index;
			questionsList.questions = this.props.video.questions[questionsList.index];
			questionsList.choose = questionsList.questions.choose.split(",");
			questionsList.answer = questionsList.questions.answer;
			switch (questionsList.questions.type) {
				case 'single' :
					questionsList.submitType = <Button className='submit-single' onClick={ this.state.answerResult!=='ok'?this.submitSingleAnswers:this.closeQuestion } type='primary'>{this.state.answerResult!=='ok'?'提交':'回到课程'}</Button>
					break;
				case 'choose' :
					questionsList.submitType = <Button className='submit-single' onClick={ this.state.answerResult!=='ok'?this.submitSingleAnswers:this.closeQuestion } type='primary'>{this.state.answerResult!=='ok'?'提交':'回到课程'}</Button>
					break;
			}
		} else {
			questionsList = null;
		}
		if (this.state.isAndroid!=null) {
			this.androidJTing();
		}
		const { isAndroid, fullScreen, androidIsPlay, showCtrlBar, isLoading, currentTime, totalTime } = this.state;


		return(
			<div className="my_video">
				<div className="video_content">
					{
						this.state.isAndroid!=null&&this.state.isAndroid ? (
							<div onClick={ showCtrlBar ? this.hiddenPlayerCtrlBar : this.openPlayerCtrlBar } className={`android-display ${fullScreen ? 'full' : 'no-full'}`}>
								<video height={230} x5-video-player-type='h5-page' ref={ player => this.player = player } className="player" src={this.props.video&&this.props.video.address} />
								{
									isLoading && (<div className='playBtn'>
										<Icon type='loading' />
									</div>)
								}
								{
									androidIsPlay ? (
										<button hidden={ isLoading } style={ showCtrlBar ? {} : {opacity: 0, zIndex: '-1'} } className="playBtn" onClick={ e => { e.stopPropagation(); this.androidPause(); this.extendPlayerCtrlTimer()} }>
											<i className='iconfont icon-pasue-play' />
										</button>
									) : (
										<button hidden={ isLoading } style={ showCtrlBar ? {} : {opacity: 0, zIndex: '-1'} } className="playBtn" onClick={ e => { e.stopPropagation(); this.androidPlay(); this.extendPlayerCtrlTimer()} }>
											<i className='iconfont icon-play' />
										</button>
									)
								}
								<div hidden={ isLoading } onClick={ e => { e.stopPropagation(); this.extendPlayerCtrlTimer() } } style={ showCtrlBar ? {} : {opacity: 0, zIndex: '-1'} } className="touch_bar">
									<div className='video-ctrl'>
										<span className='time'>{ currentTime }</span>
										<i className='iconfont icon-daoTui' onClick={ () => this.player.currentTime -= 10 } />
										{
											androidIsPlay ? (
												<i className='iconfont icon-pasue-play' onClick={ this.androidPause } />
											) : (
												<i className='iconfont icon-play' onClick={ this.androidPlay }/>
											)
										}
										<i className='iconfont icon-kuaijin' onClick={ () => this.player.currentTime += 10 } />
										<span className='time'>{ totalTime }</span>
									</div>
									{
										fullScreen ? (
											<button onClick={ this.androidExitFullScreen }><Icon type="fullscreen-exit" /></button>
										): (
											<button onClick={ this.androidFullScreen }><Icon type="fullscreen" /></button>
										)
									}
								</div>
							</div>
						) : (<Player width={'100%'} height='230px' fluid={false} ref={ player => this.player = player } src={this.props.video&&this.props.video.address}>
							<BigPlayButton position="center" />
						</Player>)
					}
				</div>
				{ this.state.question.isShow ? (
					<div className='question-mask' style={this.state.questionStyle}>
						<div className='question'>
							<span className='close' onClick={ this.closeQuestion }><Icon type='left' /></span>
							<h3>123选择题</h3>
							<p>{ questionsList.questions.question }</p>
							{
								questionsList.questions.type === "single" ? (
									<ul className='question_wrapper'>
										{
											questionsList.choose.map((item, index) => (
												<li key={index} className={`choose_item ${this.state.answer.singleValue === this.lowerCaseOpation[index] ? 'selected' : 'select'}`} onTouchEnd={ () => this.handleSingleOptionChanged(this.lowerCaseOpation[index]) }>{ item }</li>
											))
										}
									</ul>
								) : questionsList.questions.type === "choose" ? (
									<Checkbox.Group className='question_wrapper' onChange={ this.handleChooseOptionChanged } >
										{
											questionsList.choose.map((item, index) => (
												<div className='list_item' key={ index }>
													<Checkbox value={ this.lowerCaseOpation[index] }>{ this.upperCaseOpation[index] }. { item }</Checkbox>
												</div>
											))
										}
									</Checkbox.Group>
								) : null /* TODO 其他类型题目预留view */
							}
							{ this.state.answerResult === 'ok' ? (
								<p className='answer_result' style={{ color: 'green' }}>回答正确</p>
							): (this.state.answerResult === 'error' ? (
								<p className='answer_result' style={{ color: 'red' }}>回答错误</p>
							) : null)
							}
							{ questionsList.submitType }
						</div>
					</div>
				) : null }
			</div>
		);
	}
}

const timeFormat = s => {
	if (s >= 3600) {
		let hour = ~~ (s / 3600);
		let minS = s % 3600;
		let min = ~~ (minS / 60);
		let ss = ~~ (minS % 60);
		return `${hour > 10 ? hour : `0${hour}`}:${min > 10 ? min : `0${min}`}:${ss > 10 ? ss : `0${ss}`}`;

	} else {
		let min = ~~ (s / 60);
		let ss = ~~ (s % 60);
		return `00:${min > 10 ? min : `0${min}`}:${ss > 10 ? ss : `0${ss}`}`;
	}
};

export default VideoComponent;




