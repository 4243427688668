import React, { Component } from 'react';
import '../css/dakehu.css';
import {withRouter,Link} from 'react-router-dom';
import phone from '../util/phone';
import { connect } from 'react-redux';
class Dakehufive extends Component{
    state={
        dakehuonem:'none',
        videoone:['https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/5/1.mp4','https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/5/2.mp4','https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/5/3.mp4','https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/5/4.mp4','https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/5/5.mp4','https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/5/6.mp4','https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/5/7.mp4','https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/5/8.mp4','https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/5/9.mp4','https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/5/10.mp4','https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/5/11.mp4','https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/5/12.mp4','https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/5/13.mp4','https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/5/14.mp4','https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/5/15.mp4','https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/5/16.mp4','https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/5/17.mp4','https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/5/18.mp4','https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/5/19.mp4','https://micro-weixun.oss-cn-beijing.aliyuncs.com/xiaoshou128/5/20.mp4'],
        videos:'',
        dakehufivs:'block',
        dakehufivens:'none',
        shenqing:'none'
    }
    panduan(videonum){
        if(phone.indexOf(this.props.userInfo.userInfo.phone)==-1){
            this.setState({
                shenqing:'block'
            })
            return -1;
        }else{
            console.log('成功')
            this.setState({
                dakehuonem:'block',
                videos:videonum
            });
            return 2;
        }
    }
      firstm(){
        var videonum=this.state.videoone[0];
        this.panduan(videonum);
    }
    secondm(){
       var videonum=this.state.videoone[1];
        this.panduan(videonum);
    }
    thirdm(){
       var videonum=this.state.videoone[2];
        this.panduan(videonum);
    }
    fourm(){
       var videonum=this.state.videoone[3];
        this.panduan(videonum);
    }
    fivem(){
        var videonum=this.state.videoone[4];
        this.panduan(videonum);
    }
    sixm(){
        var videonum=this.state.videoone[5];
        this.panduan(videonum);
    }
    sevenm(){
       var videonum=this.state.videoone[6];
        this.panduan(videonum);
    }
    eightm(){
        var videonum=this.state.videoone[7];
        this.panduan(videonum);
    }
    ninem(){
       var videonum=this.state.videoone[8];
        this.panduan(videonum);
    }
    shim(){
       var videonum=this.state.videoone[9];
        this.panduan(videonum);
    }
    shiyim(){
        var videonum=this.state.videoone[10];
        this.panduan(videonum);
    }
    shierm(){
        var videonum=this.state.videoone[11];
        this.panduan(videonum);
    }
    shisanm(){
        var videonum=this.state.videoone[12];
        this.panduan(videonum);
    }
    shisim(){
        var videonum=this.state.videoone[13];
        this.panduan(videonum);
    }
    shiwum(){
       var videonum=this.state.videoone[14];
        this.panduan(videonum);
    }
    shilium(){
        var videonum=this.state.videoone[15];
        this.panduan(videonum);
    }
    shiqim(){
        var videonum=this.state.videoone[16];
        this.panduan(videonum);
    }
    shibam(){
        var videonum=this.state.videoone[17];
        this.panduan(videonum);
    }
   
    closes(){
        var dakehuvideo=document.getElementById('dakehuvideo1');
        dakehuvideo.pause();
        this.setState({
            dakehuonem:'none'
        });
    }
    dakehufivenext(){
        var that=this;
        that.setState({
            dakehufivebac:'url("https://weixun-demo.oss-cn-beijing.aliyuncs.com/dakehu/dakehufivefive.jpg")',
            dakehufivens:'block',
            dakehufivs:'none'
        });
    }
    dakehufiveback(){
        var that=this;
        that.setState({
            dakehufivebac:'url("https://weixun-demo.oss-cn-beijing.aliyuncs.com/dakehu/dakehufive.jpg")',
            dakehufivs:'block',
            dakehufivens:'none'
        });
    }
    componentDidMount() {
        var dakehuvideo = document.getElementById('dakehuvideo1');
        var that=this;
        dakehuvideo.addEventListener('ended', function () {
            for(var i=0;i<that.state.videoone.length;i++){
                if(that.state.videos==that.state.videoone[i]){
                    if(that.state.wancheng.indexOf(i)!=-1){
                        console.log('包含');
                    }else{
                        that.setState({
                            wancheng:that.state.wancheng+i
                        })
                    }
                    console.log(that.state.wancheng);
                }

            }
        });
        dakehuvideo.addEventListener("timeupdate",function(){
            var timeDisplay;
            timeDisplay = Math.floor(dakehuvideo.currentTime);
        },true)
    }
    render(){
        return(
            <div className='dakehufivebac' style={{backgroundImage:this.state.dakehufivebac}}>
                <Link to='dakehu'><div className='dakehutui'></div></Link>
                <div className='dakehufivecourseyi' onClick={this.firstm.bind(this)}></div>
                <div className='dakehufivecourseer' onClick={this.secondm.bind(this)}></div>
                <div className='dakehufivecoursesan' onClick={this.thirdm.bind(this)}></div>
                <div className='dakehufivecoursesi' onClick={this.fourm.bind(this)}></div>
                <div className='dakehufivecoursewu' onClick={this.fivem.bind(this)}></div>
                <div className='dakehufivecourseliu' onClick={this.sixm.bind(this)}></div>
                <div className='dakehufivecourseqi' onClick={this.sevenm.bind(this)}></div>
                <div className='dakehufivecourseba' onClick={this.eightm.bind(this)}></div>
                <div className='dakehufivecoursejiu' onClick={this.ninem.bind(this)}></div>
                <div className='dakehufivenext' onClick={this.dakehufivenext.bind(this)} style={{display:this.state.dakehufivs}}></div>

                <div className='dakehufivecourseshi' onClick={this.shim.bind(this)}></div>
                <div className='dakehufivecourseshiyi' onClick={this.shiyim.bind(this)}></div>
                <div className='dakehufivecourseshier' onClick={this.shierm.bind(this)}></div>
                <div className='dakehufivecourseshisan' onClick={this.shisanm.bind(this)}></div>
                <div className='dakehufivecourseshisi' onClick={this.shisim.bind(this)}></div>
                <div className='dakehufivecourseshiwu' onClick={this.shiwum.bind(this)}></div>
                <div className='dakehufivecourseshiliu' onClick={this.shilium.bind(this)}></div>
                <div className='dakehufivecourseshiqi' onClick={this.shiqim.bind(this)}></div>
                <div className='dakehufivecourseshiba' onClick={this.shibam.bind(this)}></div>
                <div className='dakehushenqing' style={{display:this.state.shenqing}} ></div>
                <div className='dakehufiveback' onClick={this.dakehufiveback.bind(this)} style={{display:this.state.dakehufivens}}></div>
                <video className='dakehuvideo1' id='dakehuvideo1'  style={{display:this.state.dakehuonem}} controls='controls' autoPlay='true' src={this.state.videos}/>
                <div className='dakehuclose' onClick={this.closes.bind(this)} style={{display:this.state.dakehuonem}}>X</div>
            </div>

        )
    }
}

function mapStateToProps({ login}/*默认值加解构数组*/) {

    console.log(login)
    return {
        userInfo: login

    }
}

export default withRouter(connect(mapStateToProps)(Dakehufive));
