export const answer = lessonId =>
    fetch(`/api/answer.do`, {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': localStorage.token
      }
    }).then(
        res => res.json()
    );

export const getVideoBlob = url => fetch(url,{
    		"Access-Control-Allow-Credentials" : true,
    		headers:{
       			 "Access-Control-Allow-Origin" : "*" 
    		},
    		method:'get',
    		responseType: 'blob',
			mode:'cors'
		}).then(
			res=> {console.log(res); return res.blob()}
		).then(blob=>{
			
			let bl = new Blob([blob], {type: "video/mp4"});
			return bl;
			// this.refs.video.src=window.URL.createObjectURL(bl);
			// this.refs.video.playsInline = true
		})
