import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getExamQuestions } from '../action';
import * as CoursesAPI from '../API/CoursesAPI';
import uuid from 'uuid';
import { Spin, Button, Icon } from 'antd';
import '../css/exam.scss';
import {grader} from "../API/CoursesAPI";

const optionTypes = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H'];

class Exam extends Component {

    constructor (props) {
        super(props);
        this.state = {
            examIndex: 0,
            isLoading: true,
            questions: [],
            progress: 0,
            complete: 0,
            isExam: false,
            score: 100,
            isSubmit: false,
            scoreLoading: true
        };
    }

    componentDidMount () {
        CoursesAPI.getScore(this.props.match.params.id).then(data => {
            this.setState({
                scoreLoading: false
            }, () => {
                if (data.status === 1)
                    this.setState({ isExam: true }, this.getExam);
                else
                    this.setState({ score: data.data });
            });
        });
    }
    getExam = () => {
        CoursesAPI.getExamQuestions(this.props.match.params.id).then(data => {
            this.setState({
                isLoading: false,
                questions: data.data
            });
        });
    };

    click = event => {
        this.props.history.push(`/download-certificate/${this.props.match.params.id}`, this.state);
    };

    next = () => {
        this.setState({examIndex: this.state.examIndex + 1});
    };

    prev = () => {
        this.setState({examIndex: this.state.examIndex - 1});
    };

    render () {
        const { examIndex, isLoading, questions, progress, complete, isExam, score, isSubmit, scoreLoading } = this.state;
        const question = questions.length !== 0 ? questions[examIndex] : null;
        if (!isExam)
            return (
                <div className='scoreShow'>
                    <Spin spinning={ scoreLoading } tip={'获取成绩单..'}>
                        <h1 className='title'>成绩单</h1>
                        <h2 className='score' style={
                            score < 60 ? {backgroundColor: 'rgb(244, 18, 29)'} : ((score < 80 && score >= 60) ? {backgroundColor: 'rgb(249, 164, 8)'} : {backgroundColor: 'rgb(67, 189, 12)'} )

                        }>{score}分</h2>
                        <div className='content'>
                            <div className='getZs'>
                                <Button className='getZs-btn' disabled={ score < 80 } onClick={ this.click }>获取证书</Button>
                                { score < 80 && (<p className='tips'>需要大于80分才可以获取证书！</p>) }
                            </div>
                            <div className='reExam'>
                                <Button onClick={ this.resetExam }>重新考试</Button>
                            </div>
                        </div>
                    </Spin>
                </div>
            );
        return (
            <div className='exam'>
                <h1 className='title'>考试开始</h1>
                <Spin spinning={ isLoading } tip={'题目加载中..'}>
                    {
                        question && (
                            question.type === 'single' ? (
                            <div className='single'>
                                <p className='question-header'><b>[{ examIndex + 1 }]&nbsp;.&nbsp;(单选)&nbsp;{ question.question }</b></p>
                                <ul className='options'>
                                    {
                                        question.choose.split(';').map((option, optionIndex) => (
                                            <li style={ question.answer === optionTypes[optionIndex] ? { backgroundColor: '#EEEEEE', color: '#3385ff' } : null } key={ uuid() } onClick={ () => this.singleAnswerClick(examIndex, optionTypes[optionIndex]) }>{ optionTypes[optionIndex] }.{ option }</li>
                                        ))
                                    }
                                </ul>
                            </div>
                        ): (
                            <div className='choose'>
                                <p className='question-header'><b>[{ examIndex + 1 }]&nbsp;.&nbsp;(多选)&nbsp;{ question.question }</b></p>
                                <ul className='options'>
                                    {
                                        question.choose.split(';').map((option, optionIndex) => (
                                            <li style={ question.answer.indexOf(optionTypes[optionIndex]) !== -1 ? { backgroundColor: '#EEEEEE', color: '#3385ff' } : null } key={ uuid() } onClick={ question.answer.indexOf(optionTypes[optionIndex]) !== -1 ? (() => this.chooseRemoveAnswer(examIndex, optionTypes[optionIndex]) ) : (() => this.chooseAnswerClick(examIndex, optionTypes[optionIndex])) }>{ optionTypes[optionIndex] }.{ option }</li>
                                        ))
                                    }
                                </ul>
                            </div>
                        )
                    )}
                    <div className='progress-header'>
                        <span className='progress-header-text'>进度</span>
                        {
                            complete === questions.length ?
                                (
                                    <span className='progress-header-information' style={ {color: 'rgb(67, 189, 12)'} }>完成</span>
                                ) : (
                                    <span className='progress-header-information'>{complete}&nbsp;&nbsp;/&nbsp;&nbsp; {questions.length }</span>
                                )
                        }
                    </div>
                    <div className='progress'>
                        <div className='complete' style={ progress === 100 ? { width: '100%', backgroundColor: 'rgb(67, 189, 12)' }: {width: `${progress}%`} } />
                    </div>
                    <div className='ctrl-btn-group'>
                        <div className='to-prev' disabled={ examIndex === 0 } onClick={ examIndex !== 0 ? (() => this.prev()) : null }>上一题</div>
                        <div className='to-next' disabled={ (examIndex + 1) === questions.length } onClick={  (examIndex + 1) !== questions.length ? (() => this.next()) : null }>下一题</div>
                    </div>
                </Spin>
                <Button disabled={ isSubmit } style={ {marginTop: '20px'} } onClick={ this.submit }>{isSubmit ? (<span><Icon type='loading' />提交中..</span>) : '确认交卷'} </Button>
            </div>
        )
    }
    // TODO 单选题选答案
    singleAnswerClick = (qIndex, answer) => {
        const copyQuestions = [...this.state.questions];
        copyQuestions[qIndex].answer = answer;
        this.setState({ questions: copyQuestions }, this.refreshProgress);
    };

    // TODO 多选
    chooseAnswerClick = (qIndex, answer) => {
        const copyQuestions = [...this.state.questions];
        if (copyQuestions[qIndex].answer === '')
            copyQuestions[qIndex].answer = `${answer}`;
        else {
            copyQuestions[qIndex].answer += `;${answer}`;
            const arr = copyQuestions[qIndex].answer.split(';');
            copyQuestions[qIndex].answer = arr.sort().join(";");
        }
        this.setState({ questions: copyQuestions }, this.refreshProgress);
    };

    // TODO 删除答案选项
    chooseRemoveAnswer = (examIndex, answer) => {
        const copyQuestions = [...this.state.questions];
        const arr = copyQuestions[examIndex].answer.split(';');
        arr.splice(arr.indexOf(answer), 1);
        copyQuestions[examIndex].answer = arr.join(';');
        this.setState({ questions: copyQuestions }, this.refreshProgress);
    };

    // TODO 刷新进度
    refreshProgress = () => {
        const { questions } = this.state;
        let complete = 0;
        for (let i in questions) {
            if (questions[i].answer !== '') {
                complete += 1;
            }
        }
        const progress = parseFloat((complete / questions.length).toFixed(2)) * 100;
        console.log(progress);
        console.log(complete);
        this.setState({complete, progress});
    };

    // TODO 交卷
    submit = () => {
        let flag = true;
        const { questions } = this.state;
        for (let i in questions) {
            if (questions[i].answer === "") {
                flag = false;
                break;
            }
        }
        if (!flag)
            alert('还有没完成的题目');
        else {
            this.setState({
                isSubmit: true
            }, () => {
                grader(this.state.questions).then(data => {
                    this.setState({
                        isExam: false,
                        score: data.data,
                        isSubmit: false
                    })
                })
            });
        }
    };

    resetExam = () => {
        this.setState({
            isExam: true,
            score: 100,
            complete: 0,
            progress: 0,
            examIndex: 0
        }, this.getExam);
    }
}
function mapStateToProps ({ login, exam }) {
    console.log(login)
    return {
        userInfo: login,
        questions: exam.data
      }
  }

function mapDispatchToProps (dispatch) {
    return {
        getExamQuestions: (data) => {dispatch(getExamQuestions(data))},
    }
}

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(Exam)
);
