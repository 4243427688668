import React, { Component } from 'react';
import { connect } from 'react-redux';


import '../css/newme.css';
import srcbasrc from '../photo/newmestart.png';
import {withRouter,Link} from 'react-router-dom';

import {isLogin} from '../action';
 import { Base64 } from 'js-base64';

class newme extends Component{
    // componentDidMount(){
    //     let path=this.props.location.pathname
    //     // console.log(this.nologin.includes(path))
    //     // window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxd033f96f0d37892d&redirect_uri=https://backing.wx798.com/back/mobile.do?next='+window.location.href+'&response_type=code&scope=snsapi_userinfo&state=1&connect_redirect=1#wechat_redirect'
    //     // let para=
    //     console.log(path)
    //     let para=window.location.search.split("&");
    //     const length=para.length-1;
    //     if (para[0]!=="") {
    //         console.log(para)
    //         if (para[0].startsWith("?uu")) {

    //             let uu=para[0].substring(4)
    //             if (path!=='/newme') {
    //              let res=JSON.parse(Base64.decode(uu))
    //              localStorage.token=res.data.unionid;
    //              return;
    //             }
    //             localStorage.token=uu;
    //             // console.log(res)
    //             // if (res.msg==='登录成功') {

    //             // }
    //         }
    //         else{

    //         }
    //     }
    //     user.isLogin().then((data)=>{
    //         console.log(data.msg==="用户未登录,无法获取当前用户的信息")

    //         if (data.msg==="用户未登录,无法获取当前用户的信息") {
    //             console.log("126");
    //             if (path==='/newme') {
    //                 window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxd033f96f0d37892d&redirect_uri=https://backing.wx798.com/back/mobile.do?next='+window.location.href+'&response_type=code&scope=snsapi_userinfo&state=3&connect_redirect=1#wechat_redirect'
    //                 return;
    //             }
    //             if (path==='/login') {
    //                 return;
    //             }
    //             if (path==='/add_phone') {
    //                 return;
    //             }

    //             this.props.history.push('/login?from='+window.location.href)
    //         }

    //         else
    //         {
    //             console.log(data)
    //             this.props.isLogin(data)
    //         }
    //     }).catch(
    //         error=>console.log(error)
    //     )

    // }

    render(){


        return(
            <div className='bac'>
                <div className='banner'></div>
                <div className='title'></div>
                <Link to='newmeone'><div className='btnone'></div></Link>
                <Link to='newmeone'><img className='newmedeng' src='https://weixun-demo.oss-cn-beijing.aliyuncs.com/photo/newmedeng.gif' alt='gif'></img></Link>
                <Link to='newmetwo'><div className='btntwo'></div></Link>
                <Link to='newmetwo'><img className='newmeshan' src='https://weixun-demo.oss-cn-beijing.aliyuncs.com/photo/newmeshan.gif' alt='gif'></img></Link>
                <Link to='newmethree'><div className='btnthree'></div></Link>
                <Link to='newmefour'><div className='newmebottoms'>
                    <img className='bottomsimg' alt='gif' src={srcbasrc}/>
                </div></Link>
            </div>
        )
    }
}
function mapDispatchToProps (dispatch) {
    return {
      isLogin: (data) => {dispatch(isLogin(data))},
    }
}

function mapStateToProps({ login }/*默认值加解构数组*/) {
    console.log(login);
    return {
        userInfo: login,
    }
}
export default withRouter(connect(mapStateToProps,mapDispatchToProps)(newme));
