import React, { Component } from 'react';

import '../css/newmetwo.css';
import {Link} from "react-router-dom";

class Sunthree extends Component {
    state={
        one:'sunthreebtnone',
        two:'sunthreebtntwo',
        three:'sunthreebtnthree',
        four:'sunthreebtnfour',
        five:'sunthreebtnfive',
        six:'sunthreebtnsix',
        tui:'none',
        onemengban:'none',
        twomengban:'none',
        threemengban:'none',
        fourmengban:'none',
        fivemengban:'none',
        sixmengban:'none',
        neirongone:'none',
        neirongone1p:'none',
        neirongone2p:'none',
        neirongone3p:'none',
        neirongtwo:'none',
        neirongtwo1p:'none',
        neirongtwo2p:'none',
        neirongtwo3p:'none',
        neirongthree:'none',
        neirongthree1p:'none',
        neirongthree2p:'none',
        neirongthree3p:'none',
        neirongfour:'none',
        neirongfour1p:'none',
        neirongfour2p:'none',
        neirongfive:'none',
        neirongfive1p:'none',
        neirongfive2p:'none',
        neirongsix:'none',
        neirongsix1p:'none',
        neirongsix2p:'none',
        neirongsix3p:'none',
        neirong:'none'

    }
    a(){
       
        this.setState({
            one:'sunthreebtnones',
            two:'sunthreebtntwo',
            three:'sunthreebtnthree',
            four:'sunthreebtnfour',
            five:'sunthreebtnfive',
            six:'sunthreebtnsix',
            tui:'block',
            neirongone:'-webkit-box'
        })
        
    }
    logo1one(){
        this.setState({
            neirongone1p:'block',
            onemengban:'block'
        })
    }
    logo2one(){
        this.setState({
            neirongone2p:'block',
            onemengban:'block'
        })
    }
    logo3one(){
        this.setState({
            neirongone3p:'block',
            onemengban:'block'
        })
    }
    onemengban(){
        this.setState({
            neirongone1p:'none',
            neirongone2p:'none',
            neirongone3p:'none',
            onemengban:'none'
        })
    }
    oneend(){
        this.setState({
            neirongone:'none'
        })

    }
    b(){
        
        this.setState({
            one:'sunthreebtnone',
            two:'sunthreebtntwos',
            three:'sunthreebtnthree',
            four:'sunthreebtnfour',
            five:'sunthreebtnfive',
            six:'sunthreebtnsix',
            tui:'block',
            neirongtwo:'-webkit-box'
        }) 
    }
    logo1two(){
        this.setState({
            neirongtwo1p:'block',
            twomengban:'block'
        })
    }
    logo2two(){
        this.setState({
            neirongtwo2p:'block',
            twomengban:'block'
        })
    }
    logo3two(){
        this.setState({
            neirongtwo3p:'block',
            twomengban:'block'
        })
    }
    twomengban(){
        this.setState({
            neirongtwo1p:'none',
            neirongtwo2p:'none',
            neirongtwo3p:'none',
            twomengban:'none'
        })
    }
    twoend(){
        this.setState({
            neirongtwo:'none'
        })

    }

    c(){
        
        this.setState({
            one:'sunthreebtnone',
            two:'sunthreebtntwo',
            three:'sunthreebtnthrees',
            four:'sunthreebtnfour',
            five:'sunthreebtnfive',
            six:'sunthreebtnsix',
            tui:'block',
            neirongthree:'-webkit-box'
        })
        
    }
     logo1three(){
        this.setState({
            neirongthree1p:'block',
            threemengban:'block'
        })
    }
    logo2three(){
        this.setState({
            neirongthree2p:'block',
            threemengban:'block'
        })
    }
    logo3three(){
        this.setState({
            neirongthree3p:'block',
            threemengban:'block'
        })
    }
    threemengban(){
        this.setState({
            neirongthree1p:'none',
            neirongthree2p:'none',
            neirongthree3p:'none',
            threemengban:'none'
        })
    }
    threeend(){
        this.setState({
            neirongthree:'none'
        })
    }
    d(){

        this.setState({
            one:'sunthreebtnone',
            two:'sunthreebtntwo',
            three:'sunthreebtnthree',
            four:'sunthreebtnfours',
            five:'sunthreebtnfive',
            six:'sunthreebtnsix',
            tui:'block',
            neirongfour:'-webkit-box'
        });

    }
    logo1four(){
        this.setState({
            neirongfour1p:'block',
            fourmengban:'block'
        })
    }
    logo2four(){
        this.setState({
            neirongfour2p:'block',
            fourmengban:'block'
        })
    }
    fourmengban(){
        this.setState({
            neirongfour1p:'none',
            neirongfour2p:'none',
            fourmengban:'none'
        })
    }
    fourend(){
        this.setState({
            neirongfour:'none'
        })
    }
    e(){

        this.setState({
            one:'sunthreebtnone',
            two:'sunthreebtntwo',
            three:'sunthreebtnthree',
            four:'sunthreebtnfour',
            five:'sunthreebtnfives',
            six:'sunthreebtnsix',
            tui:'block',
            neirongfive:'-webkit-box'
        })

    }
    logo1five(){
        this.setState({
            neirongfive1p:'block',
            fivemengban:'block'
        })
    }
    logo2five(){
        this.setState({
            neirongfive2p:'block',
            fivemengban:'block'
        })
    }
    fivemengban(){
        this.setState({
            neirongfive1p:'none',
            neirongfive2p:'none',
            neirongfive3p:'none',
            fivemengban:'none'
        })
    }
    fiveend(){
        this.setState({
            neirongfive:'none'
        })
    }
    f(){

        this.setState({
            one:'sunthreebtnone',
            two:'sunthreebtntwo',
            three:'sunthreebtnthree',
            four:'sunthreebtnfour',
            five:'sunthreebtnfive',
            six:'sunthreebtnsixs',
            tui:'block',
            neirongsix:'-webkit-box'
        })

    }
    logo1six(){
        this.setState({
            neirongsix1p:'block',
            sixmengban:'block'
        })
    }
    logo2six(){
        this.setState({
            neirongsix2p:'block',
            sixmengban:'block'
        })
    }
    logo3six(){
        this.setState({
            neirongsix3p:'block',
            sixmengban:'block'
        })
    }
    sixmengban(){
        this.setState({
            neirongsix1p:'none',
            neirongsix2p:'none',
            neirongsix3p:'none',
            sixmengban:'none'
        })
    }
    sixend(){
        this.setState({
            neirongsix:'none'
        })
    }
    g(){
        this.setState({
            tui:'none',
            neirong:'none'
        })
    }
    render(){
        return(
            <div className='bacthree'>
                <div className='sunthreetop'></div>
                <div id='sunthreebtnone' className={this.state.one} onClick={this.a.bind(this)} ></div>
                <div id="neirongone" className='newmethreejuxingone' style={{display:this.state.neirongone}}>
                    <div id='neirongtitleone' className='neirongtitleone' style={{display:this.state.neirongone}}></div>
                    <div id='neironglogo1one' className='neironglogo1one' onClick={this.logo1one.bind(this)} style={{display:this.state.neirongone}}></div>
                    <div id='neironglogo1onep' className='neironglogo1onep'  style={{display:this.state.neirongone1p}}></div>
                    <div id='neironglogo2one' className='neironglogo2one' onClick={this.logo2one.bind(this)} style={{display:this.state.neirongone}}></div>
                    <div id='neironglogo2onep' className='neironglogo2onep' style={{display:this.state.neirongone2p}}></div>
                    <div id='neironglogo3one' className='neironglogo3one' onClick={this.logo3one.bind(this)} style={{display:this.state.neirongone}}></div>
                    <div id='neironglogo3onep' className='neironglogo3onep' style={{display:this.state.neirongone3p}}></div>
                    <div id='neironglogoonemengban' className='neironglogoonemengban' onClick={this.onemengban.bind(this)} style={{display:this.state.onemengban}}></div>
                    <div id='neirongendone' className='neirongendone' onClick={this.oneend.bind(this)} style={{display:this.state.neirongone}}></div>
                </div>
                <div id='sunthreebtntwo' className={this.state.two} onClick={this.b.bind(this)}></div>
                <div id="neirongtwo" className='newmethreejuxingtwo' style={{display:this.state.neirongtwo}}>
                    <div id='neirongtitletwo' className='neirongtitletwo' style={{display:this.state.neirongtwo}}></div>
                    <div id='neironglogo1two' className='neironglogo1two' onClick={this.logo1two.bind(this)} style={{display:this.state.neirongtwo}}></div>
                    <div id='neironglogo1twop' className='neironglogo1twop'  style={{display:this.state.neirongtwo1p}}></div>
                    <div id='neironglogo2two' className='neironglogo2two' onClick={this.logo2two.bind(this)} style={{display:this.state.neirongtwo}}></div>
                    <div id='neironglogo2twop' className='neironglogo2twop' style={{display:this.state.neirongtwo2p}}></div>
                    <div id='neironglogo3two' className='neironglogo3two' onClick={this.logo3two.bind(this)} style={{display:this.state.neirongtwo}}></div>
                    <div id='neironglogo3twop' className='neironglogo3twop' style={{display:this.state.neirongtwo3p}}></div>
                    <div id='neironglogotwomengban' className='neironglogotwomengban' onClick={this.twomengban.bind(this)} style={{display:this.state.twomengban}}></div>
                    <div id='neirongendtwo' className='neirongendtwo' onClick={this.twoend.bind(this)} style={{display:this.state.neirongtwo}}></div>
                </div>

                <div id='sunthreebtnthree' className={this.state.three} onClick={this.c.bind(this)}></div>
                <div id="neirongthree" className='newmethreejuxingthree' style={{display:this.state.neirongthree}}>
                    <div id='neirongtitlethree' className='neirongtitlethree' style={{display:this.state.neirongthree}}></div>
                    <div id='neironglogo1three' className='neironglogo1three' onClick={this.logo1three.bind(this)} style={{display:this.state.neirongthree}}></div>
                    <div id='neironglogo1threep' className='neironglogo1threep'  style={{display:this.state.neirongthree1p}}></div>
                    <div id='neironglogo2three' className='neironglogo2three' onClick={this.logo2three.bind(this)} style={{display:this.state.neirongthree}}></div>
                    <div id='neironglogo2threep' className='neironglogo2threep' style={{display:this.state.neirongthree2p}}></div>
                    <div id='neironglogo3three' className='neironglogo3three' onClick={this.logo3three.bind(this)} style={{display:this.state.neirongthree}}></div>
                    <div id='neironglogo3threep' className='neironglogo3threep' style={{display:this.state.neirongthree3p}}></div>
                    <div id='neironglogothreemengban' className='neironglogothreemengban' onClick={this.threemengban.bind(this)} style={{display:this.state.threemengban}}></div>
                    <div id='neirongendthree' className='neirongendthree' onClick={this.threeend.bind(this)} style={{display:this.state.neirongthree}}></div>
                </div>

                <div id='sunthreebtnfour' className={this.state.four} onClick={this.d.bind(this)}></div>
                <div id="neirongfour" className='newmethreejuxingfour' style={{display:this.state.neirongfour}}>
                    <div id='neirongtitlefour' className='neirongtitlefour' style={{display:this.state.neirongfour}}></div>
                    <div id='neironglogo1four' className='neironglogo1four' onClick={this.logo1four.bind(this)} style={{display:this.state.neirongfour}}></div>
                    <div id='neironglogo1fourp' className='neironglogo1fourp'  style={{display:this.state.neirongfour1p}}></div>
                    <div id='neironglogo2four' className='neironglogo2four' onClick={this.logo2four.bind(this)} style={{display:this.state.neirongfour}}></div>
                    <div id='neironglogo2fourp' className='neironglogo2fourp' style={{display:this.state.neirongfour2p}}></div>
                    <div id='neironglogofourmengban' className='neironglogofourmengban' onClick={this.fourmengban.bind(this)} style={{display:this.state.fourmengban}}></div>
                    <div id='neirongendfour' className='neirongendfour' onClick={this.fourend.bind(this)} style={{display:this.state.neirongfour}}></div>
                </div>


                <div id='sunthreebtnfive' className={this.state.five} onClick={this.e.bind(this)}></div>
                <div id="neirongfive" className='newmethreejuxingfive' style={{display:this.state.neirongfive}}>
                    <div id='neirongtitlefive' className='neirongtitlefive' style={{display:this.state.neirongfive}}></div>
                    <div id='neironglogo1five' className='neironglogo1five' onClick={this.logo1five.bind(this)} style={{display:this.state.neirongfive}}></div>
                    <div id='neironglogo1fivep' className='neironglogo1fivep'  style={{display:this.state.neirongfive1p}}></div>
                    <div id='neironglogo2five' className='neironglogo2five' onClick={this.logo2five.bind(this)} style={{display:this.state.neirongfive}}></div>
                    <div id='neironglogo2fivep' className='neironglogo2fivep' style={{display:this.state.neirongfive2p}}></div>
                    <div id='neironglogofivemengban' className='neironglogofivemengban' onClick={this.fivemengban.bind(this)} style={{display:this.state.fivemengban}}></div>
                    <div id='neirongendfive' className='neirongendfive' onClick={this.fiveend.bind(this)} style={{display:this.state.neirongfive}}></div>
                </div>

                <div id='sunthreebtnsix' className={this.state.six} onClick={this.f.bind(this)}></div>
                <div id="neirongsix" className='newmethreejuxingsix' style={{display:this.state.neirongsix}}>
                    <div id='neirongtitlesix' className='neirongtitlesix' style={{display:this.state.neirongsix}}></div>
                    <div id='neironglogo1six' className='neironglogo1six' onClick={this.logo1six.bind(this)} style={{display:this.state.neirongsix}}></div>
                    <div id='neironglogo1sixp' className='neironglogo1sixp'  style={{display:this.state.neirongsix1p}}></div>
                    <div id='neironglogo2six' className='neironglogo2six' onClick={this.logo2six.bind(this)} style={{display:this.state.neirongsix}}></div>
                    <div id='neironglogo2sixp' className='neironglogo2sixp' style={{display:this.state.neirongsix2p}}></div>
                    <div id='neironglogo3six' className='neironglogo3six' onClick={this.logo3six.bind(this)} style={{display:this.state.neirongsix}}></div>
                    <div id='neironglogo3sixp' className='neironglogo3sixp' style={{display:this.state.neirongsix3p}}></div>
                    <div id='neironglogosixmengban' className='neironglogosixmengban' onClick={this.sixmengban.bind(this)} style={{display:this.state.sixmengban}}></div>
                    <div id='neirongendsix' className='neirongendsix' onClick={this.sixend.bind(this)} style={{display:this.state.neirongsix}}></div>
                </div>

                <div className='newmethreetui' style={{display:this.state.tui}} onClick={this.g.bind(this)}></div>

                <Link to='newme'><div className='newmeonebactui'></div></Link>
            </div>

        )
    }
}
export default Sunthree
