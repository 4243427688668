import React, {Component} from 'react';
import {connect} from 'react-redux';
import * as CoursesAPI from '../API/CoursesAPI'
import {getCourse, SharedInfo,setLessonIcon,setFirstPlay} from '../action'
import {withRouter} from "react-router-dom";
import Coupon from '../component/Coupon';
import {Comment,Tooltip} from "antd"
import { ShareAltOutlined } from '@ant-design/icons'
import {Avatar, Button, Dialog, Tabs, Tag, Toast,Popup,Form,Input,Rate} from "antd-mobile"
import uuid from "uuid";
import { CheckCircleOutline, ClockCircleOutline } from "antd-mobile-icons"
import Loading from "../component/Loading/Loading";
import '../css/course.scss'
import zhCN from 'antd/lib/locale/zh_CN';
import moment from 'moment';
import Video from "./Video"
import MicroPlayer from "../component/MicroPlayer/MicroPlayer.jsx"
 
class Course extends Component {

    constructor(props) {
        super(props);
        this.state = {
            course: {},
            loading: true,
            chapters: [],
            code: "",
            orderId: "",
            data: {},
            buy: false,
            err_msg: "",
            openid: "",
            visible: false,
            isVip: false,
            showDialog: false,
            verify: false,
            sky:'1',
            ExampleComment:null,
            showIntegral:false,
            comments:[
            {headUrl:"",name:"",content:"",time:"",replyFrom:[{headUrl:"",name:"",content:"",time:"",replyFrom:[]}] }
            ],
            showVideo:false,
            videoData:null,
            videoId:"1",
            comments:[
            {name:"shelby",headUrl:"https://thirdwx.qlogo.cn/mmopen/vi_32/O8NQicTgtA3gjM9Rt4g6AaSmIvZIS60UAwbFib0odR3xGsLcP5RYHy5DvwQZNY7Fia4b7Ca2ylxeoyCLpgDOxaaQw/132",content:"我的评价是不如bug",createTime:"2022-02-02 14:02:56", children:[{name:"shelby",headUrl:"https://thirdwx.qlogo.cn/mmopen/vi_32/O8NQicTgtA3gjM9Rt4g6AaSmIvZIS60UAwbFib0odR3xGsLcP5RYHy5DvwQZNY7Fia4b7Ca2ylxeoyCLpgDOxaaQw/132",content:"我的评价是不如bug",createTime:"2022-02-02 14:02:56"},{name:"shelby",headUrl:"https://thirdwx.qlogo.cn/mmopen/vi_32/O8NQicTgtA3gjM9Rt4g6AaSmIvZIS60UAwbFib0odR3xGsLcP5RYHy5DvwQZNY7Fia4b7Ca2ylxeoyCLpgDOxaaQw/132",createTime:"2022-02-02 14:02:56",content:"我的评价是不如bug"}]},
            {name:"shelby",headUrl:"https://thirdwx.qlogo.cn/mmopen/vi_32/O8NQicTgtA3gjM9Rt4g6AaSmIvZIS60UAwbFib0odR3xGsLcP5RYHy5DvwQZNY7Fia4b7Ca2ylxeoyCLpgDOxaaQw/132",content:"我的评价是不如bug",createTime:"2022-02-02 14:02:56",children:[{name:"shelby",headUrl:"https://thirdwx.qlogo.cn/mmopen/vi_32/O8NQicTgtA3gjM9Rt4g6AaSmIvZIS60UAwbFib0odR3xGsLcP5RYHy5DvwQZNY7Fia4b7Ca2ylxeoyCLpgDOxaaQw/132",createTime:"2022-02-02 14:02:56",content:"我的评价是不如bug"},{name:"shelby",headUrl:"https://thirdwx.qlogo.cn/mmopen/vi_32/O8NQicTgtA3gjM9Rt4g6AaSmIvZIS60UAwbFib0odR3xGsLcP5RYHy5DvwQZNY7Fia4b7Ca2ylxeoyCLpgDOxaaQw/132",createTime:"2022-02-02 14:02:56",content:"我的评价是不如bug"}]},
            {name:"shelby",headUrl:"https://thirdwx.qlogo.cn/mmopen/vi_32/O8NQicTgtA3gjM9Rt4g6AaSmIvZIS60UAwbFib0odR3xGsLcP5RYHy5DvwQZNY7Fia4b7Ca2ylxeoyCLpgDOxaaQw/132",content:"我的评价是不如bug",createTime:"2022-02-02 14:02:56",children:[{name:"shelby",headUrl:"https://thirdwx.qlogo.cn/mmopen/vi_32/O8NQicTgtA3gjM9Rt4g6AaSmIvZIS60UAwbFib0odR3xGsLcP5RYHy5DvwQZNY7Fia4b7Ca2ylxeoyCLpgDOxaaQw/132",createTime:"2022-02-02 14:02:56",content:"我的评价是不如bug"},{name:"shelby",headUrl:"https://thirdwx.qlogo.cn/mmopen/vi_32/O8NQicTgtA3gjM9Rt4g6AaSmIvZIS60UAwbFib0odR3xGsLcP5RYHy5DvwQZNY7Fia4b7Ca2ylxeoyCLpgDOxaaQw/132",createTime:"2022-02-02 14:02:56",content:"我的评价是不如bug"}]},
            {name:"shelby",headUrl:"https://thirdwx.qlogo.cn/mmopen/vi_32/O8NQicTgtA3gjM9Rt4g6AaSmIvZIS60UAwbFib0odR3xGsLcP5RYHy5DvwQZNY7Fia4b7Ca2ylxeoyCLpgDOxaaQw/132",content:"我的评价是不如bug",createTime:"2022-02-02 14:02:56",children:[{name:"shelby",headUrl:"https://thirdwx.qlogo.cn/mmopen/vi_32/O8NQicTgtA3gjM9Rt4g6AaSmIvZIS60UAwbFib0odR3xGsLcP5RYHy5DvwQZNY7Fia4b7Ca2ylxeoyCLpgDOxaaQw/132",createTime:"2022-02-02 14:02:56",content:"我的评价是不如bug"},{name:"shelby",headUrl:"https://thirdwx.qlogo.cn/mmopen/vi_32/O8NQicTgtA3gjM9Rt4g6AaSmIvZIS60UAwbFib0odR3xGsLcP5RYHy5DvwQZNY7Fia4b7Ca2ylxeoyCLpgDOxaaQw/132",createTime:"2022-02-02 14:02:56",content:"我的评价是不如bug"}]},
            {name:"shelby",headUrl:"https://thirdwx.qlogo.cn/mmopen/vi_32/O8NQicTgtA3gjM9Rt4g6AaSmIvZIS60UAwbFib0odR3xGsLcP5RYHy5DvwQZNY7Fia4b7Ca2ylxeoyCLpgDOxaaQw/132",content:"我的评价是不如bug",createTime:"2022-02-02 14:02:56",children:[{name:"shelby",headUrl:"https://thirdwx.qlogo.cn/mmopen/vi_32/O8NQicTgtA3gjM9Rt4g6AaSmIvZIS60UAwbFib0odR3xGsLcP5RYHy5DvwQZNY7Fia4b7Ca2ylxeoyCLpgDOxaaQw/132",createTime:"2022-02-02 14:02:56",content:"我的评价是不如bug"},{name:"shelby",headUrl:"https://thirdwx.qlogo.cn/mmopen/vi_32/O8NQicTgtA3gjM9Rt4g6AaSmIvZIS60UAwbFib0odR3xGsLcP5RYHy5DvwQZNY7Fia4b7Ca2ylxeoyCLpgDOxaaQw/132",createTime:"2022-02-02 14:02:56",content:"我的评价是不如bug"}]}
            ],
            commentReply:{},
            placeholder:"评论课程",
            sendTo:"course",
        }
    }

    // TODO 向管理员推荐课程
    shareToAdmin = () => {
        this.setState({
            loading: true
        }, () => {
            CoursesAPI.sendMessage(this.props.course.lessonName)
                .then(data => {
                    Toast.show({
                        icon: data.status === 0 ? "success": "fail",
                        content: data.msg
                    });
                }).finally(() => this.setState({loading: false}))
        })
    };

    // TODO 切换个人用户身份
    checkUserType = () => this.setState({ loading: true }, () => this.props.selectPerson(() => window.location.href = ""));

    componentDidMount() {
       
        if(this.props.course.lessonName){

        }
        const res = this.props.location.search.split("&", 1)
        const from = res[0].split("=")[1]
        sessionStorage.setItem("from", from);
        const id = this.props.match.params.id;
        CoursesAPI.getall(id).then(data => {
    

            if (data.status === 0){
                const course = data.data,
                    {isChapter, result} = this.format(course.chapters, course.ids);
                course.isChapter = isChapter;
                course[course.isChapter ? 'chapters' : 'selections'] = result;
                this.props.getCourse({msg: data.msg, data: course});
                const { isLogin, userType } = this.props;
                // TODO 判断是否为企业管理员推荐此课程
                if (isLogin && (userType === 1) && (data.msg !== '已购买') && (this.props.userInfo.fromCompany !== -1)) {
                    this.setState({
                        showDialog: true
                    });
                }
                document.title = course.lessonName;
                if (data.msg === "已购买")
                    this.setState({tabKey: "2"});
                // if (this.props.userInfo.data !== undefined && (this.props.userInfo.data.userName === "王梓恒" || this.props.userInfo.data.userName === "韬" || this.props.userInfo.data.userName === "Jingjing" || this.props.userInfo.data.userName === "miya祝娟" || this.props.userInfo.data.userName === "崔小屹" || this.props.userInfo.data.userName === "闫静" || this.props.userInfo.data.userName === "monica" || this.props.userInfo.data.userName === "张瑜儿" || this.props.userInfo.data.userName === "王蘅" || this.props.userInfo.userName === "宗" || this.props.userInfo.data.userName === "赵纪" || this.props.userInfo.data.userName === "火风鼎（guobinzhang）" || this.props.userInfo.data.userName === "王慧🍃" || this.props.userInfo.data.userName === "?" || this.props.userInfo.data.userName === "良园")) {
                //     this.setState({
                //         isBuy: "已购买",
                //     })
                //     alert("您是本站vip，所有课程免费浏览")
                // } else {
                //     this.setState({
                //         isBuy: data.msg,
                //     })
                // }
                let SharedInfo = {}
                SharedInfo.title = data.data.lessonName
                SharedInfo.img = data.data.lessonIcon
                SharedInfo.des = data.data.lessonDescription
                this.props.setSharedInfo(SharedInfo)
            }
        }).finally(() => this.setState({loading: false}));
        CoursesAPI.getVideo(this.props.match.params.id,this.state.videoId).then(data => {
        this.setState({videoData:data})
       })
        
        let replyStatus={}
       for(let i in this.state.comments){
           replyStatus[i]=false
       }
       this.setState({commentReply:replyStatus})

    }
    setvisible=()=>{
        this.setState({visible:true})
    }

    showTeacher = () => {
        this.setState({
            icon1: true,
        });
    }

    showMore = () => {
        this.setState({
            icon2: true,
        });
    }

    hide1 = () => {
        this.setState({
            icon1: false,
        });
    }

    hide2 = () => {
        this.setState({
            icon2: false,
        });
    }

    handleCancel = (e) => {
        this.setState({
            visible: false,
        });
    }

    createOrder() {
        if (this.props.isLogin)
            this.props.history.push(`/buy/${this.props.course.id}`);
        else
            this.props.history.push(`/login?from=${window.location.href}`);
    }

    // 去考试
    toExam = () => {
        CoursesAPI.getExamPermission(this.props.course.id)
            .then(data => {
                if (data.status === 0) {
                    this.props.history.push(`/exam/${this.props.match.params.id}`, this.state)
                } else {
                    alert(data.msg);
                }
            });
    }

    showVerify() {
        if (this.props.isLogin)
            this.setState({
                verify: true
            })
        else
            this.props.history.push(`/login?from=${window.location.href}`);
    }

    cancelVerify() {
        this.setState({
            verify: false,
        });
    }

    handleCouponPay() {
        this.setState({
            isBuy: "已购买",
        })
    }

    componentWillUnmount = () => {
        this.setState = (state,callback)=>{
            return;
        };
    }
    sky=(key)=>{  //简介 目录 老师介绍 tabs组件onChange
        if(key!=='5'){
         this.setState({sky:key})
        }else{
            this.setState({visible:true})
        }
    }

    format = (arr, ids) => {
        let result = [];
        if (arr.length !== 0 && arr[0]['chapter_sequence'] === 0) {
            for (let i = 0; i < arr.length; i++) {
                const selectionIndex = arr[i]['selection_sequence'];
                result[selectionIndex] = {
                    key: uuid(),
                    id: arr[i].videoId,
                    name: arr[i].name,
                    free: arr[i].videoFree === 1,
                };
            }
            return {isChapter: false, result};
        } else {
            for (let i = 0; i < arr.length; i++) {
                let chapterIndex = arr[i]['chapter_sequence'];
                let selectionIndex = arr[i]['selection_sequence'];
                if (result[chapterIndex]) {
                    result[chapterIndex].selections[selectionIndex] = {
                        key: uuid(),
                        id: arr[i].videoId,
                        name: arr[i].name,
                        free: arr[i].videoFree === 1,
                    };
                } else {
                    const selections = [];
                    selections[selectionIndex] = {
                        key: uuid(),
                        id: arr[i].videoId,
                        name: arr[i].name,
                        free: arr[i].videoFree === 1,
                    };
                    result[chapterIndex] = {
                        key: uuid(),
                        chapterName: arr[i]['chapter'],
                        selections
                    }
                }
            }
            return {isChapter: true, result};
        }
    }
    switchVideo=(id)=>{
       CoursesAPI.getVideo(this.props.match.params.id,id).then(data => {
        this.setState({videoData:data,videoId:id,showVideo:true})

         this.props.setFirstPlay(true)
       })
      

    }
    handelIntegral=()=>{
        this.setState({showIntegral:true})
    }

    openReply=(index1)=>{
       let copy={...this.state.commentReply}
       copy[index1]=true
       this.setState({commentReply:copy})
    
    }
    closeReply=(index1)=>{
       let copy={...this.state.commentReply}
       copy[index1]=false
       this.setState({commentReply:copy})
    }
    replyTo=(index1)=>{
            this.replyInput.focus({
              preventScroll: true,
            });
           let placeholder="回复"+this.state.comments[index1].name
        this.setState({placeholder})
    }
    handleSkipTo=()=>{
    this.props.history.push(`/save_picture/${this.props.match.params.id}`)
    this.props.setLessonIcon(this.props.course.lessonIcon)
 
    }
    
    handleComment=(value)=>{
       this.setState({commentText:value})
    }
    submitComment=()=>{
   let aa= moment()

         let copy={}
         copy.name=this.props.userInfo.wechatnickname
         copy.headUrl=this.props.userInfo.headUrl
         copy.content=this.state.commentText
 
         // copy.createTime=
         if(this.state.sendTo==="course"){
           return 0
         }
    }
    renderVideoList = (videos, course) =>{
   
        return   videos.map((item, index) => {
                   if (item.free) {
                    return (<div className='video video-ok' key={index}>
                        <div className='ok-play' onClick={() => {this.switchVideo(item.id)}}>
                            <div className="video-after"><Tag fill="outline" color="primary" style={{fontSize:"14px",lineHeight:"16px"}}>视频</Tag></div>
                            <div className="video-name" style={{ width: `${document.documentElement.clientWidth-132}px` }}>{item.name}</div>
                            <div className="video-state">
                                <Tag color="warning">免费</Tag>
                            </div>
                        </div>
                    </div>);
                } else {
                    return (
                        <div className={`video video-ok`} key={index}>
                            {
                                this.props.isBuy ?
                                    course&&<div className="ok-play" onClick={() => this.switchVideo(item.id)}>
                                        <div className="video-after"><Tag fill="outline" color="primary" style={{fontSize:"14px",lineHeight:"16px"}}>视频</Tag></div>
                                        <div className="video-name" style={{ width: `${document.documentElement.clientWidth-132}px` }}>{item.name}</div>
                                        {course.progress && <div className="video-state">{course.progress[index] === "y" ?
                                        <CheckCircleOutline style={{color: "green"}}/> : <ClockCircleOutline/>}</div>}
                                    </div>
                                    :
                                    <div className="ok-play" onClick={ () => {
                                        if (this.props.isLogin) {
                                            Dialog.alert({
                                                title: "提示",
                                                content: "请购买后学习！"
                                            })
                                        } else
                                            this.props.history.push(`/login?from=https://learning.wx798.com/course/${course.id}`);
                                    }}>
                                        <div className="video-after"><Tag fill="outline" color="primary">视频</Tag></div>
                                        <div className="video-name" style={{ width: `${document.documentElement.clientWidth-132}px` }}>{item.name}</div>
                                        {course.progress && <div className="video-state">{course.progress[index] === "y" ?
                                            <CheckCircleOutline style={{color: "green"}}/> : <ClockCircleOutline/>}</div>}
                                    </div>
                            }
                        </div>
                    )
                }
            }
        )
    }

    render() {
        const { course, isBuy, company, userInfo, isLogin } = this.props;
        const {
            lessonIcon,
            teacher,
            lessonName,
            lessonDescription,
            lessonPrice,
            lessonMore,
            chapters,
            isChapter,
            selections,
            teacherName,
            teacher_icon
        } = course;
        const { loading, showDialog, tabKey } = this.state;
        return (
            <div className="course">
            {this.state.showIntegral&&<div style={{position:"absolute",width:"100%",height:"100%",backgroundColor:"white",zIndex:20}}>555</div>}
                <div className="course-header">

                    <div className="header-name">
                        <div onClick={ () => this.props.history.push("/") } className="header-link">首页</div>&nbsp;&nbsp;<div onClick={ () => this.props.history.push("/courseList") } className="header-link">精品课程</div></div>
                    <div className="header-user" onClick={ () => this.props.history.push("/personal") }>
                        <Avatar style={{ '--size': '30px' }} src={userInfo.headUrl} />
                    </div>
                </div>
                <Loading loading={ loading } />
                <div className="courseIcon" style={{ height: `${document.documentElement.clientWidth/(16/9)}px` }}>
                    {
                        (this.state.showVideo===false||this.state.videoData.msg==="未购买")?<img src={lessonIcon} alt=""/>
                        :
                        this.state.videoData&&
                        <Video 
                        lessonId={this.props.match.params.id} 
                        videoId={this.state.videoId} 
                        videoData={this.state.videoData} 
                        poster={lessonIcon} 
                        />
                    }

                </div>

                {(!this.props.isLogin||!this.props.fullScreen)&&<Tabs style={{backgroundColor: "white", "--content-padding": "0",top:"15px"}} tabs={[{title: '简介', sub: '1'}, {title: '课程类表', sub: '2'}, {title: '老师介绍', sub: '3'},{title:'评论',sub:'4'}]} initialPage={0} activeKey={this.state.sky} onChange={this.sky}>
                    <Tabs.Tab title='简介' key='1' >
                        <div style={{ padding: '10px', backgroundColor: "#f5f5f9"}}>
                            <h5 className="block-title">课程简介</h5>
                            <div className="lessonInfo">
                                <h3 className="lessonName">{lessonName}</h3>
                                <p className="lessonDescription">{lessonDescription}</p>
                                <p className="lessonPrice">¥{lessonPrice}</p>
                            </div>
                            <h5 className="block-title">课程介绍</h5>
                            
                            <div dangerouslySetInnerHTML={{__html: lessonMore}}></div>
                        </div>
                    </Tabs.Tab>
                    <Tabs.Tab title='课程列表' key='2'>
                        <div className="video-list" style={{ padding: '10px', backgroundColor: "#f5f5f9"}}>
                            {
                                isChapter ? (
                                        chapters.map((chapter, chapterIndex)=>(
                                            <div className="chapter-item" key={chapterIndex}>
                                                <div className="chapterName">{chapter.chapterName}</div>
                                                {this.renderVideoList(chapter.selections, course.isBuy)}
                                            </div>
                                        ))
                                    ) : this.renderVideoList(selections, course)
                            }
                            {
                                (isLogin && isBuy) && (
                                    <Button size="large" block onClick={this.toExam}>去考试</Button>
                                )
                            }
                        </div>
                    </Tabs.Tab>
                    <Tabs.Tab title='老师介绍' key='3' >
                        <div style={{ padding: '10px', backgroundColor: "#f5f5f9"}}>
                            <h3 className="lessonName" style={{paddingLeft: "10px"}}>{teacherName}</h3>
                            {teacher.length<70&&<img src={teacher} className="lessonInfo" />}
                            {teacher.length>70&&<div dangerouslySetInnerHTML={{__html:teacher}}></div>}
                        </div>
                    </Tabs.Tab>
                    <Tabs.Tab title='评价' key='4'>
                    <div style={{padding: '10px', backgroundColor: "#f5f5f9"}}>
                            <div style={{width:"90%"}}>
                            <div style={{width:"110%",fontSize:"12px",color:"black"}}>
                             <Rate readOnly allowHalf value={4.5}   style={{'--star-size': '15px'}}/>&nbsp;&nbsp;&nbsp;&nbsp;
                             平均4.5 &nbsp;&nbsp;&nbsp;&nbsp;500人参与&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                             <Tag color="success" onClick={async () => {
              const result = await Dialog.confirm({
                title:"课程评分",
                content: (
                  <>
                   <Rate allowHalf   style={{'--star-size': '18px'}} onChange={val => Toast.show(val.toString())}/>
                  </>
                ), 
              })
              if (result) {
                Toast.show({ content: '评分成功', position: 'bottom' })
              } else {
              }
            }} style={{float:"right"}}>我要评分</Tag>
                            </div>
                            <hr style={{width:"130%",marginLeft:"-10%"}}/>
                        {
                            this.state.comments.map((item1,index1)=>(

                        <Comment
                        actions={[<><span key={index1} onClick={()=>this.replyTo(index1)}>回复</span>{this.state.commentReply[index1]===false?<span onClick={()=>this.openReply(index1)}>展开</span>:<span onClick={()=>this.closeReply(index1)}>收起</span>}</>]}
                        // author={this.props.userType===1?<a>{this.props.company.companyName}</a>:<a>{this.props.userInfo.realname||this.props.userInfo.wechatnickname}</a>}
                        author={item1.name}
                        // avatar={<Avatar src={this.props.userInfo.headUrl}/>}
                        avatar={<Avatar src={item1.headUrl} />}
                        content={
                          <p>
                            {item1.content}
                          </p>
                        }
                        datetime={
                              <Tooltip title={moment().subtract(1, 'days').format('YYYY-MM-DD HH:mm:ss')}>
                                <span>{moment(item1.createTime).subtract(1, '天').fromNow()}</span>
                              </Tooltip>
                            }
                       >
                    {this.state.commentReply[index1]&&item1.children.map((item2,index2)=>(
                        <Comment
                        // author={this.props.userType===1?<a>{this.props.company.companyName}</a>:<a>{this.props.userInfo.realname||this.props.userInfo.wechatnickname}</a>}
                        author={item2.name}
                        // avatar={<Avatar src={this.props.userInfo.headUrl} />}
                        avatar={<Avatar src={item2.headUrl}/>}
                        content={
                          <p>
                            {item2.content}
                          </p>
                        }
                        datetime={
                              <Tooltip title={moment().subtract(1, 'days').format('YYYY-MM-DD HH:mm:ss')}>
                                <span>{moment(item2.createTime).subtract(1, '天').fromNow()}</span>
                              </Tooltip>
                            }
                       >
                      </Comment>
                        ))}
                       

                      </Comment>
                            ))
                        }
                        

                        
                            </div>
                
                        <div className="send_comments"><div style={{width:"70%",float:"left",marginLeft:"15px"}}><Input  placeholder={this.state.placeholder} ref={replyInput =>  this.replyInput = replyInput } onChange={(e)=>this.handleComment(e.target.value)}/></div><Button onClick={this.submitComment}>发送</Button></div>
                           
                    </div>
                    </Tabs.Tab>
                
                <Tabs.Tab title={<ShareAltOutlined />} key='5'>
                       555
                    </Tabs.Tab>
                
                </Tabs>
            }
                <Popup
              visible={this.state.visible}
              onMaskClick={() => {this.setState({visible:false})}}
              bodyStyle={{
                borderTopLeftRadius: '40px',
                borderTopRightRadius: '40px',
                minHeight: '30vh',
              }}
            >
            <div style={{height:"30px",width:"60%",marginLeft:"20%",marginTop:"30px",color:"black"}}>你可以将课程推荐给有需要的人！推荐成功后你将获得<a onClick={()=>this.props.history.push("/integral_api")}>积分</a>!</div>
            <div className="share">
            <Button className="shareWechat"><img src={require("../photo/微信.png")}/></Button>
            <Button className="shareWechatCircle"><img src={require("../photo/朋友圈.png")}/></Button>
            <Button className="savePhoto" onClick={this.handleSkipTo}><img src={require("../photo/图片.png")}/></Button>
            </div>
            </Popup>
            
             {this.state.sky!=='4'&&!this.props.fullScreen&&<div className="mobile_footer">
                    <div className='buy-btn-group'>
                        <div className="mobile_buy_button1" style={isBuy ? {right: 0} : {right: 120}} onClick={!isBuy ? this.createOrder.bind(this) : ()=>this.sky('2')}>
                            <p className="mobile_buy_button_text">{!isBuy && '¥' + lessonPrice}&nbsp;&nbsp;{isBuy ? '学习' : '付费学习'}</p>
                        </div>
                        {
                            !isBuy &&
                                <div className="mobile_buy_button2" onClick={this.showVerify.bind(this)}>
                                    <p className='mobile_buy_button_text'>优惠码学习</p>
                                </div>
                        }
                    </div>
                </div>}

                {this.state.verify &&
                    <Coupon courseId={this.props.course.id} cancelVerify={this.cancelVerify.bind(this)}
                            handleCouponPay={this.handleCouponPay.bind(this)}/>}
                <Dialog
                    
                    visible={ showDialog }
                    image="http://micro-weixun.oss-cn-beijing.aliyuncs.com/micro_mobile/home/title-icon.png"
                   
                    content={
                        <div style={{height:"300px"}}>
                        <br/>
                        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;您是{company.companyName}公司的员工，贵公司尚未为您选择本门课程。</p>
                        <div style={{width:"100%",height:"170px",display:"flex",justifyContent:"center"}}>
                        <div style={{width:"33%"}} onClick={() => {this.setState({showDialog: false}, () => this.shareToAdmin())}}>
                        <img src="http://micro-weixun.oss-cn-beijing.aliyuncs.com/micro_mobile/home/icon-27.svg" />
                        <p style={{width:"100%",textAlign:"center",fontSize:"16px",color:"#000000",lineHeight:"12px"}}>推荐给</p>
                        <p style={{width:"100%",textAlign:"center",fontSize:"13px",color:"#cccccc",lineHeight:"5px"}}>企业管理员</p>
                        </div>
                        <div style={{width:"33%"}} onClick={() =>{this.setState({showDialog: false}, () => this.checkUserType())}}>
                        <img src="http://micro-weixun.oss-cn-beijing.aliyuncs.com/micro_mobile/home/icon-28.svg" />
                        <p style={{width:"100%",textAlign:"center",fontSize:"16px",color:"#000000",lineHeight:"12px"}}>切换至</p>
                        <p style={{width:"100%",textAlign:"center",fontSize:"13px",color:"#cccccc",lineHeight:"5px"}}>个人付费学习</p>
                        </div>
                        <div style={{width:"33%"}} onClick={()=>{this.setState({showDialog:false},()=>this.props.history.push("/mycourses"))}}>
                        <img src="http://micro-weixun.oss-cn-beijing.aliyuncs.com/micro_mobile/home/icon-29.svg" />
                        <p style={{width:"100%",textAlign:"center",fontSize:"16px",color:"#000000",lineHeight:"12px"}}>去学习</p>
                        <p style={{width:"100%",textAlign:"center",fontSize:"13px",color:"#cccccc",lineHeight:"5px"}}>公司已选课程</p>
                        </div>

                        </div>
                        <Button style={{marginLeft:"10%",width:"80%",height:"40px",border:"1px solid #cccccc",borderRadius:"5px"}} onClick={()=>{this.setState({showDialog:false})}}>取消</Button>
                        </div>
                }
                />
            </div>

        )
    }

}

export default connect(
    ({login, course,fullScreen,lessonIcon}) => ({
        isLogin: login.isLogin,
        userInfo: login.userInfo,
        isBuy: course.msg === "已购买",
        course: course.data,
        userType: login.type,
        company: login.company,
        fullScreen,
        lessonIcon
    }), dispatch => ({
        setSharedInfo: (data) => dispatch(SharedInfo(data)),
        getCourse: (data) => dispatch(getCourse(data)),
        setLessonIcon:(data)=>dispatch(setLessonIcon(data)),
        setFirstPlay:(data)=>dispatch(setFirstPlay(data))
    })
)(withRouter(Course));