export const parseQueryString = url => {
    let obj = {},
    keyValue = [],
    key = '',
    value = '';

    if (url.indexOf("?") === -1)
        return {};

    let paraString = url.substring(url.indexOf("?") + 1,url.length).split("&");

    for(let i in paraString) {
        keyValue = paraString[i].split("=");
        key = keyValue[0];
        value = keyValue[1];
        obj[key]=value;
    }

    return obj;
}

// export const  merge=(backgroudUrl, qrCodeCanvas)=>{
//     console.log(backgroudUrl,qrCodeCanvas)
//             return new Promise((resolve, reject) => {
//                 try {
//                 const qrcodeBase64 = qrCodeCanvas.toDataURL('image/png');
//                 const qrcodeImg = new Image();
//                 qrcodeImg.addEventListener("load", () => {
//                     const backgroundImg = new Image();
//                     console.log("load1")
//                     backgroundImg.addEventListener('load', () => {
//                         console.log("load2")
//                         const canvas = document.createElement('canvas');
//                         canvas.width = backgroundImg.width;
//                         canvas.height = backgroundImg.height + qrcodeImg.height;

//                         const ctx = canvas.getContext('2d');
//                         ctx.drawImage(backgroundImg, 0, 0);
//                         ctx.fillStyle="#ffffff"
//                         ctx.fillRect(0,backgroundImg.height,backgroundImg.width,qrCodeCanvas.height)
//                         ctx.drawImage(qrcodeImg, (backgroundImg.width - qrcodeImg.width) / 2, backgroundImg.height, qrcodeImg.width, qrcodeImg.height);

//                         resolve(canvas.toDataURL('image/png'));
//                     });
//                     backgroundImg.src = backgroudUrl;
//                     backgroundImg.crossOrigin = "anonymous";
//                     console.log(backgroundImg)
//                 })
//                 qrcodeImg.src = qrcodeBase64;
//                 }catch(e) {
//                     reject(e)
//                 }

//             });
//         }


   export const  merge=(backgroudUrl, qrCodeCanvas,lessonName,teacherName,description,color)=>{  //图片与canvas合成图片
    console.log(backgroudUrl,qrCodeCanvas)

    let strLength=(str)=>{
                          // var b1 = document.getElementById("b1").value;
                          // var str = "坦克是塔nk9，,的音译";
                          var len = str.match(/[^ -~]/g) == null ? str.length : str.length + str.match(/[^ -~]/g).length;
                          return len
                      }

                      return new Promise((resolve, reject) => {
                        try {
                            const qrcodeBase64 = qrCodeCanvas.toDataURL('image/png');
                            const qrcodeImg = new Image();
                            qrcodeImg.addEventListener("load", () => {
                                const backgroundImg = new Image();
                                console.log("load1")
                                backgroundImg.addEventListener('load', () => {

                                    const canvas = document.createElement('canvas');
                                    backgroundImg.height = backgroundImg.width/(9/16)
                                    canvas.width = backgroundImg.width;
                                    canvas.height = backgroundImg.width*16/9;

                                    const ctx = canvas.getContext('2d');
                                    ctx.drawImage(backgroundImg, 0, 0, backgroundImg.width, backgroundImg.height)

                                    ctx.fillStyle=color;

                                    ctx.font="bold 120px Arial"
                                    ctx.textAlign="center"
                                    ctx.textBaseline="middle"
                                    ctx.fillText(lessonName,backgroundImg.width/2,790)

                                    ctx.font=" 100px cursive"
                                    ctx.textAlign="center"
                                    ctx.textBaseline="middle"
                                    ctx.fillText(teacherName,backgroundImg.width/2,990)

                                    ctx.font=" 90px Arial"
                                    ctx.textAlign="left"

                                    let row = 1
                                    let Len = 2
                                    let flag = []
                                    
                                    for(let i=0; i<description.length; i++){
                                           Len += strLength(description[i])
                                           if(Len>=27||i===description.length-1){
                                             flag.push(i)
                                            if(row===1){
                                             ctx.fillText(description.slice(0,flag[0]),435,1200)
                                             console.log(strLength( description.slice(0,flag[0]) ),Len)
                                             row++
                                             Len=0
                                             }else if(row>1){
                                             ctx.fillText(description.slice(flag[row-2],flag[row-1]),255,1200+(row-1)*120)
                                             console.log(strLength(description.slice(flag[row-2],flag[row-1])),Len)
                                             row++
                                             Len=0
                                             }
                                             
                                          }
                                           
                                 }
                                // let rows=Math.ceil(description.length/16)
                                // for (let i = 0; i <= rows; i++) {
                                //      if(i===0){
                                //        ctx.fillText("\xa0\xa0\xa0\xa0\xa0"+description.slice(0,11),375,1200+i*120)
                                //      }else{
                                //        ctx.fillText(description.slice(13*(i)-2,13*(i+1)-2),295,1200+i*120)
                                //      }
                                // }

                        ctx.font="  100px cursive"
                        ctx.textAlign="center"
                        ctx.textBaseline="middle"
                        ctx.fillText("欢迎扫码学习",backgroundImg.width/2,2450)


                        ctx.drawImage(qrcodeImg,(canvas.width-qrcodeImg.width)/2,1920,qrcodeImg.width,qrcodeImg.height);


                        
                        const res = canvas.toDataURL('image/png')
                        console.log(res)
                        resolve(res);
                    });
                                backgroundImg.src = backgroudUrl;
                                backgroundImg.crossOrigin = "anonymous";
                                console.log(backgroundImg)
                            })
                            qrcodeImg.src = qrcodeBase64;
                            qrcodeImg.width = 420
                            qrcodeImg.height = 420
                        }catch(e) {
                            reject(e)
                        }

                    });
}

