import React, { Component } from 'react';
import '../css/newmeone.css';
import {Link} from "react-router-dom";
import oneneirong from '../photo/sunone.png';
class Sunone extends Component {

    render(){
        return(
            <div className='bacone'>
                <div className='sunbanner'></div>
                <div className='onebac'>
                    <img className='onebacs' src={oneneirong}/>
                </div>
                <Link to='Sun'> <div className='onebactui'></div></Link>
                <div className='baconebottom'></div>
                <div className="twoone"></div>
                <Link to='Sunthree'><div className="twotwo"></div></Link>
                <Link to='Suntwo'><div className="twothree"></div></Link>
                <Link to='Sunfour'><div className="twofour"></div></Link>
                <Link to='Anim'><div className='twobottom'></div></Link>
            </div>

        )
    }
}
export default Sunone
