export const isLogin = ()=>
fetch("/api/back/isLogin.do",{
          method: 'POST',
          headers:{
            'Content-Type': 'application/json',
            'Accept': 'application/json',
              'Authorization': localStorage.token===undefined?null:localStorage.token
          },
          mode: 'cors',
}).then(res=>res.json());


export const newmeisLogin = ()=>
fetch("/api/back/isLogin.do",{
          method: 'POST',
          headers:{
            'Content-Type': 'application/json',
            'Accept': 'application/json',
              'Authorization': localStorage.newmetoken===undefined?null:localStorage.newmetoken
          },
          mode: 'cors',
}).then(res=>res.json())

export const choosetype = (type)=>
    fetch("/api/choosetypemobile.do?type="+type,{
        method: 'GET',
        headers:{
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': localStorage.token===undefined?null:localStorage.token
        },
    }).then((res)=>(res.json()))

export const login = body => fetch("/api/login.do", {
    method: 'POST',
    headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        'Accept': 'application/json'
    },
    mode: 'cors',
    body: JSON.stringify(body)
}).then(result => result.json())

export const sendCaptcha = phone => fetch('/api/val.do?phone='+phone, {
    method: 'GET',
    headers:{
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': localStorage.token
    },
}).then(result => result.json());

export const getMycourse = (page,limit) => fetch('/api/getMybusinessCourses.do?page='+page+'&limit='+limit, {
    method: 'GET',

    headers:{
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': localStorage.token
    },

}).then(result => result.json());

export const toRegister = requestBody => fetch('/api/regester.do', {

    method: 'POST',
    headers:{
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': localStorage.token
    },
    mode: 'cors',
    body: JSON.stringify(requestBody)
}).then(result => result.json());


export const valMobile = (val,mobile) => fetch('/api/valMobile.do?val='+val+'&mobile='+mobile, {
    method: 'GET',
    headers:{
        'Content-Type': 'application/json',
        'Accept': 'application/json',
    },
}).then(result => result.json());

export const loginByValCode = body => fetch('/api/loginByvalcode.do', {

    method: 'POST',
    headers:{
        'Content-Type': 'application/json',
        'Accept': 'application/json',

    },
    body: JSON.stringify(body)
}).then(result => result.json());

export const register = body => fetch("/api/regester.do", {
    method: 'POST',
    headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        'Accept': 'application/json'
    },
    mode: 'cors',
    // body: JSON.stringify({
    // 	phone: this.state.phone,
    // 	password: this.state.password,
    // 	val: this.state.val,
    // 	name: name
    // })
    body: JSON.stringify(body)
}).then(result => result.json());

export const logout = ()=>
    fetch("/api/logout.do",{
        method: 'POST',
        headers:{
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': localStorage.token
        },
        mode: 'cors',
    }).then((res)=>(res.json()))

