import React, {Component} from 'react';
import {connect} from 'react-redux';
import * as CoursesAPI from '../API/CoursesAPI'
import {getCourse, SharedInfo} from '../action'
import {withRouter} from "react-router-dom";
import Coupon from '../component/Coupon';
import {Comment,Tooltip} from "antd"
import { ShareAltOutlined } from '@ant-design/icons'
import {Avatar, Button, Dialog, Tabs, Tag, Toast,Popup,Form,Input,List} from "antd-mobile"
import uuid from "uuid";
import { CheckCircleOutline, ClockCircleOutline,QuestionCircleOutline } from "antd-mobile-icons"
import Loading from "../component/Loading/Loading";
import '../css/Withdraw.scss'
import zhCN from 'antd/lib/locale/zh_CN';
import moment from 'moment';
import "moment/locale/zh-cn"

moment.locale("zh_cn")
class Withdraw extends Component {

    constructor(props) {
        super(props);
        this.state={
           sum:0,
           showModal:false,
           showMessage:false,
        }
    }
    componentDidMount(){
       document.title="积分兑换"
    }
    handelInput=(value)=>{
       this.setState({sum:value})

       console.log(this.state.sum)
    }
    submit=()=>{
          if(this.state.sum>1&&this.state.sum<5001){
               this.setState({showModal:true})
               Dialog.alert({
                content: <p style={{padding:"10px 15px"}}>您已经成功提交微豆兑换资金的申请。系统将在三个工作日内汇款至您的账户！</p>,
                onConfirm: () => {
                  console.log('Confirmed')
                },
              })
          }
    }

    render(){
        const {userInfo,userType,company} = this.props
        return(
          <div className="withdraw">

          <div className="withdraw-header">
          <div className="header-name">
                        <div onClick={ () => this.props.history.push("/") } className="header-link">首页</div>&nbsp;>&nbsp;
                        <div onClick={ () => this.props.history.push("/personal") } className="header-link">用户中心</div>&nbsp;>&nbsp;
                        <div onClick={ () => this.props.history.push("/integral") } className="header-link">我的积分</div>&nbsp;>&nbsp;
                        <div onClick={ () => this.props.history.push("/withdraw") } className="header-link">积分兑换</div>&nbsp;>&nbsp;
                        </div>
                    <div className="header-user" onClick={ () => this.props.history.push("/personal") }>
                    </div>
            </div>
            <div style={{background:"white"}}>
            <List.Item prefix={<Avatar src={userInfo.headUrl} />} style={{lineHeight:"40px",marginLeft:"20px"}}>
                                <span style={{color: "#1890ff",marginLeft:"20px"}}>{userInfo.realname || userInfo.wechatnickname || userInfo.username}{ userType !== 0 && ( <span style={{color: "#AAAAAA"}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{company.companyName}</span>)}</span>
                            </List.Item>
             </div> 
             <Form 
            footer={
                <Button block type="submit" color="primary" size='large' onClick={this.submit}>
                提交
                </Button>
            }>
            <Form.Header>积分兑换资金</Form.Header>
            <Form.Item name="name" label="金额" rules={[{required:true}]}>
            <Input placeholder="请输入金额" type="number" onChange={(val)=>this.handelInput(val)} min={1} max={50000} />
            </Form.Item>
            </Form>
            <p style={{width:"100%",textAlign:"center",fontSize:"12px"}}><span style={{color:"#f5222d"}}>*</span>积分兑换后会在三个工作日内到账到您的资金&nbsp;&nbsp;&nbsp;&nbsp;</p>
            <p style={{width:"100%",textAlign:"center",fontSize:"12px"}}><span style={{color:"#f5222d"}}>*</span>点击<a onClick={()=>this.setState({showMessage:true})}><QuestionCircleOutline /></a>查看更多兑换规则&nbsp;&nbsp;&nbsp;&nbsp;</p>
           <Popup
            visible={this.state.showMessage}
              onMaskClick={() => {this.setState({showMessage:false})}}
              bodyStyle={{
                minHeight: '30vh',
                padding: '10px 15px'
              }}>
              <h3 style={{textAlign:"center"}}>兑换规则</h3>
              <p>每20微豆可兑换1元人民币到您的资金账户中</p>
           </Popup>

          </div>
            )
    }
}

    export default connect(
    ({login, course}) => ({
        isLogin: login.isLogin,
        userInfo: login.userInfo,
        course: course.data,
        userType: login.type,
        company: login.company
    }), dispatch => ({
        setSharedInfo: (data) => dispatch(SharedInfo(data)),
    })
)(withRouter(Withdraw));
