import { IS_LOGIN, COURSE, VIDEO, ORDER, EXAM, SHARE, SET_REGISTER_DATA, SET_AGREE, SET_ADD_PHONE_FORM,SET_FULL_SCREEN,SET_LESSON_ICON,SET_FIRSTPLAY} from '../action'
import { combineReducers } from 'redux'

const defUser = {
    isLogin: false,
    userInfo: {
        id: null,
        realname: null,
        username: null,
        password: null,
        phone: null,
        companyName: null,
        salt: null,
        nickName: null,
        fromCompany: null,
        headUrl: null,
        wechatId: null,
        wechatNickname: null,
        bouns: null,
        job: null,
        type: 0,
        priv: -1,
        beans:0
    },
    company: {
        companyId: 7,
        companyName: null,
        companyLogo: null,
        createTime: null,
        userCount: 0,
        groupsCount: 0,
        completionRate: 0
    },
    register: {
        headUrl: "",
        openId: "",
        state: 1,
        unionid: "",
        username: ""
    },
    addPhoneForm: {
        nickname: '',
        realName: "",
        phone: "",
        captcha: "",
        email: "",
        password: "",
    },
    type: 0,
    agree: false,
}

const defCourse = {
    msg: "",
    data: {
        lessonName: '',
        lessonIcon: '',
        lessonPrice: 0,
        teacher: '<p></p>',
        lessonDescription: '',
        lessonMore: '<p></p>',
        selections: [],
        chapters: [],
        isChapter: false
    },
    video: {
        address: "",
        belong: "",
        chapter: null,
        finished: false,
        id: null,
        name: "面向未来的跨文化沟通与合作",
        playtime: "",
        questions: []
    }
}

export function login (state = defUser, action) {
    const copyState = {...state};
    switch (action.type) {
        case IS_LOGIN :
            copyState.isLogin = true;
            copyState.userInfo = action.userInfo.user;
            copyState.type = action.userInfo.type;
            if (action.userInfo.company)
                copyState.company = action.userInfo.company;
            break;
        case SET_REGISTER_DATA:
            copyState.register = action.data;
            copyState.addPhoneForm.nickname = action.data.username;
            break;
        case SET_AGREE:
            copyState.agree = action.data;
            break;
        case SET_ADD_PHONE_FORM:
            copyState.addPhoneForm = action.data;
            break;
        default: break;
    }
    return copyState;
}

export function WeixinInfo(state = {},action){
  const {data}=action

  switch(action.type){
    case SHARE:
      return data;
    default :
      return state
  }
}

export function SharedInfo(state = {},action){
  const {data}=action

  switch(action.type){
    case SHARE:
      return data;
    default :
      return state
  }
}

export function fullScreen(state = false,action){
    const {data}=action
    switch(action.type){
        case SET_FULL_SCREEN:
          return data
        default :
          return state
    }
}

export function firstPlay(state=true,action){
    const {data}=action
    switch(action.type){
        case SET_FIRSTPLAY:
           return data
        default :
           return state
    }
}

export function course(state = defCourse, action){
    const copyState = {...state};
    switch(action.type){
        case COURSE:
            copyState.msg = action.data.msg;
            copyState.data = action.data.data;
          break;
        case VIDEO:
            copyState.video = action.data.data;
            break;
        default :break;
    }
    return copyState;
}

export function pay(state = {}, action){
  const {data}=action

  switch(action.type){
    case ORDER:
      return data;
    default :
      return state
  }
}

export function exam (state = {}, action) {
  const { data } = action
  switch(action.type){
    case EXAM:
      return data;
    default :
      return state
  }
}

export function lessonIcon (state="",action) {
  const {data}=action
  console.log(data)
  switch(action.type){
    case SET_LESSON_ICON:
    return data;
    default :
    return state
  }
}

export default combineReducers({
  login, course, pay, exam,WeixinInfo,fullScreen,lessonIcon,firstPlay
})