import React, { Component } from "react";
import '../../css/propagandaDesign.scss';
import PreloadVideo from "../PreloadVideo";

class Design extends Component {

    constructor(props) {
        super(props);
        this.state = {
            currentPage: 1,
            maxPage: 4
        }
    }

    // TODO 上一页
    toTop = () => { const { currentPage } = this.state;if (currentPage !== 1) this.setState({currentPage: currentPage - 1});}

    // TODO 下一页
    toNext = () => { const { currentPage, maxPage } = this.state; if (currentPage !== maxPage) this.setState({currentPage: currentPage + 1});}

    // TODO 回到主页
    backHome = () => this.props.backHome();

    render() {
        const { currentPage, maxPage } = this.state;
        return (
            <div className='design'>
                <header className="header">
                    <div className="home" onTouchEnd={ this.backHome } />
                    <div className="title">{ currentPage === 1 ? "场景设计原则" : (currentPage === 2 ? "游戏场景设计" : (currentPage === 3 ? "闯关游戏" : "场景设计基础-学习心电图")) }</div>
                </header>
                <div className="content">
                    {currentPage === 1 && (<div className='container-1'>
                        <ul className="info_list">
                            <li>
                                <div className="index">1</div>
                                <div className="detail">调动情绪情感</div>
                            </li>
                            <li>
                                <div className="index">2</div>
                                <div className="detail">与现实工作强关联</div>
                            </li>
                            <li>
                                <div className="index">3</div>
                                <div className="detail">幽默生动</div>
                            </li>
                            <li>
                                <div className="index">4</div>
                                <div className="detail">与时俱进</div>
                            </li>
                        </ul>
                        <div className='info-img' />
                    </div>)}
                    {currentPage === 2 && (<div className='container-2'>
                        <ul className="info_list">
                            <li>微训自有研发团队，既对培训有深刻的理解，也熟悉互联网技术的实施手段，可以设计生动可执行的游戏场景</li>
                            <li>培训中的每个知识点可以分散为"接受、应用和校验"几个阶段，每个阶段合理地进行场景设计</li>
                            <li>接受阶段，借助故事，运用生动有趣的方式将知识点传递给学员</li>
                            <li>应用阶段，设计成闯关、竞赛的形式，让学员进入到游戏的场景中</li>
                            <li>检验阶段，可以通过竞赛结果评估学习结果，并进行复盘反馈</li>
                        </ul>
                        <div className='info-images'>
                            <div>
                                <img src="https://micro-weixun.oss-cn-beijing.aliyuncs.com/micro_mobile_propaganda/header/core-2-2-2.png" alt=""/>
                            </div>
                            <div>
                                <img src="https://micro-weixun.oss-cn-beijing.aliyuncs.com/micro_mobile_propaganda/header/core-2-2-1.png" alt=""/>
                            </div>
                            <div>
                                <img src="https://micro-weixun.oss-cn-beijing.aliyuncs.com/micro_mobile_propaganda/header/core-2-2-3.png" alt=""/>
                            </div>
                        </div>
                    </div>)}
                    {currentPage === 3 && (<div className='container-3'>
                        <p className='detail'>可将考试设计为闯关式游戏，学员将进入有视频或者VR构建的场景中，根据故事情节，弹出互动窗口提问场景的处理方法，学员选择处理，后台将根据学员的表现进行记分</p>
                        <div className='videoContainer'><PreloadVideo url='https://micro-weixun.oss-cn-beijing.aliyuncs.com/micro_mobile_propaganda/video/video-2-3.mp4' /></div>
                        <p className='info'>请点击左侧视频，出现播放按钮后，点击观看DEMO，或者全屏状态下播放</p>
                    </div>)}
                    {currentPage === 4 && (<div className='container-4'>
                        <p>可将考试设计为闯关游戏，学员进入由视频或者VR构建的场景中，根据故事情节，弹出互动窗口提问场景的处理办法，学员选择处理，后台根据学员的表现进行记分</p>
                        <img src="https://micro-weixun.oss-cn-beijing.aliyuncs.com/micro_mobile_propaganda/header/hart.png" alt=""/>
                    </div>)}
                    { currentPage !== 1 && (<div className='toTop' onTouchEnd={ this.toTop } />) }
                    { currentPage !== maxPage && (<div className='toNext' onTouchEnd={ this.toNext } />) }
                </div>
            </div>
        )
    }
}

export default Design;