import React, { Component } from 'react';
import * as CourseAPI from "../../API/CoursesAPI";
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Avatar, Ellipsis, List, Tag, DotLoading, Divider, Empty } from 'antd-mobile';
import "./history.scss";

class History extends Component {

    constructor(props) {
        super(props);
        this.state = {
            courses:[{isFinished:true,lesson:{id:1,lessonIcon:2,lessonDescription:"5555",lessonName:"66"}}],
            page: 1,
            loading: true,
            hasMore: true,
            pageSize: 10
        };
    }

    componentDidMount() {
        document.title = "我的记录";
        this.setState({
            pageSize: Math.ceil((document.documentElement.clientHeight - 90) / 88) + 1
        }, this.getList);
    }

    getList = () => {
        const { pageSize } = this.state;
        this.setState({
            page: 1,
            loading: true
        }, () => {
            CourseAPI.getMyBusinessCourses(1, pageSize, 2)
                .then(data => {
                    if (data.status === 0)
                        this.setState({
                            courses: data.data.list,
                            hasMore: data.data.loading === pageSize
                        });
                }).finally(() => this.setState({loading: false}))
        });
    }

    // TODO 获取更多
    appendList = () => {
        const { page, pageSize, courses } = this.state;
        this.setState({
            page: page + 1,
            loading: true
        }, () => {
            CourseAPI.getMyBusinessCourses(page + 1, pageSize, 2)
                .then(data => {
                    if (data.status === 0) {
                        const temp = [...courses];
                        temp.push(data.data);
                        this.setState({
                            courses: temp,
                            hasMore: data.data.length === pageSize
                        });
                    }
                }).finally(() => this.setState({loading: false}))
        })
    }

    render() {
        const { courses, hasMore, loading } = this.state;
        const { userInfo } = this.props;

        return (
            <div className="myCourse">

                <div className="course-header">
                    <div className="header-name">
                        <div onClick={ () => this.props.history.push("/") } className="header-link">首页</div>
                        &nbsp;>&nbsp;
                        <div onClick={ () => this.props.history.push("/personal") } className="header-link">个人中心</div>
                    </div>
                    <div className="header-user" onClick={ () => this.props.history.push("/personal") }>
                        <Avatar style={{ '--size': '30px' }} src={userInfo.headUrl} />
                    </div>
                </div>

                <h2 style={{paddingLeft: "10px"}}>我的记录</h2>
                <List style={{'--border-top': "none"}}>
                    {
                        courses.map((item, index) => (
                            <List.Item
                                key={index}
                                onClick={ () => this.props.history.push("/course/"+item.lesson.id) }
                                prefix={<div style={{width: "112px", height: "63px"}}><img src={item.lesson.lessonIcon} alt=""/></div>}
                                description={
                                <div>
                                    <Ellipsis direction='end' rows={2} content={ item.lesson.lessonDescription} />
                                </div>
                                }
                            ><div style={{height: "30px"}}>
                                {
                                    item.isFinished ? (
                                        <Tag style={{position:"relative", top: "-1px"}} fill={'outline'} color={ 'success' }>已完成</Tag>
                                    ): (
                                        <Tag style={{position:"relative", top: "-1px"}} fill={'outline'} color={ 'primary' }>学习中</Tag>
                                    )
                                }&nbsp;&nbsp;<span style={{lineHeight: "20px"}}>{item.lesson.lessonName}</span>
                            </div></List.Item>
                        ))
                    }
                </List>
                {
                    courses.length === 0 ? (
                        <Empty
                            style={{ padding: '64px 0', backgroundColor: "white" }}
                            imageStyle={{ width: 128 }}
                            description='暂无数据'
                        />
                    ):(hasMore ? (
                        <div className="getMore">
                            {
                                loading ? (
                                    <span style={{ fontSize: 18 }}>
                                        <DotLoading /> 加载中...
                                    </span>
                                ): (
                                    <span onClick={ this.appendList } style={{ fontSize: 18 }}>
                                        点击此处获取更多
                                    </span>
                                )
                            }
                        </div>
                    ): (
                        <Divider>我有底线的</Divider>
                    ))
                }
            </div>
        );
    }
}

export default connect(
    ({ login }) => ({
        isLogin: login.isLogin,
        userInfo: login.userInfo
    })
)(withRouter(History));