import React, { Component } from 'react';
import { connect } from 'react-redux';
import loads from '../photo/load.jpg';
import loadgif from '../photo/loadgif.gif';

import zoulu from '../photo/zoulu.gif';
import '../css/suntwo.css';

import {isLogin} from '../action';
import {withRouter} from "react-router-dom";
class Anim extends Component{

    state={
        loadin:'https://weixun-demo.oss-cn-beijing.aliyuncs.com/sun/load.jpg',
        one:'none',
        oneq:'none',
        oneok:'none',
        quesan:'',
        chuxian:'',
        two:'none',
        twoq:'none',
        twook:'none',
        twoch:'none',
        three:'none',
        threeq:'none',
        threeok:'none',
        threech:'none',
        four:'none',
        fourq:'none',
        fourok:'none',
        fourch:'none',
        five:'none',
        fiveq:'none',
        fiveok:'none',
        fivech:'none',
        six:'none',
        sixq:'none',
        sixok:'none',
        sixch:'none',
        seven:'none',
        sevenq:'none',
        sevench:'none',
        sevenok:'none',
        eight:'none',
        nine:'none',
        isBegin:false,
        second:0,
        min:0,
        seconds:0,
        isStart:false,
        load:'block',
        btn:'none',
        animbac:'block',
        miaoshu:0,
        miaoshuone:0,
        miaoshutwo:0,
        username:''
    }
    jishi=null;
    time(abc){

        if (this.state.isBegin===false) {
            let that=this;
            this.setState({
                isBegin:true
            });
            let second = this.state.second;
            let seconds = this.state.seconds;
            let min = this.state.min;
            this.jishi=setInterval(function () {
                second = parseInt(second);
                console.log(abc);

                if (second === 60) {
                    min = min + 1;
                    second = 1;
                }
                else {
                    seconds='';
                    second = second + 1;
                    if (second < 10) {
                        second = '0' + second;
                    }
                }
                that.setState({
                    second: second,
                    seconds:seconds,
                    min: min
                })
            },1000);
        }
    }

    componentDidMount(){

        var that=this;

        setTimeout(function () {
            ['https://weixun-demo.oss-cn-beijing.aliyuncs.com/sun/twoch.png','https://weixun-demo.oss-cn-beijing.aliyuncs.com/sun/sevenbac.png','https://weixun-demo.oss-cn-beijing.aliyuncs.com/sun/sevenbac.png','https://weixun-demo.oss-cn-beijing.aliyuncs.com/sun/sixbac.png','https://weixun-demo.oss-cn-beijing.aliyuncs.com/sun/fivebac.jpg','https://weixun-demo.oss-cn-beijing.aliyuncs.com/sun/fourbac.png','https://weixun-demo.oss-cn-beijing.aliyuncs.com/sun/threebac.png','https://weixun-demo.oss-cn-beijing.aliyuncs.com/sun/twobac.png','https://weixun-demo.oss-cn-beijing.aliyuncs.com/sun/onech.gif','https://weixun-demo.oss-cn-beijing.aliyuncs.com/sun/threech.png','https://weixun-demo.oss-cn-beijing.aliyuncs.com/sun/fourch.png','https://weixun-demo.oss-cn-beijing.aliyuncs.com/sun/sevench.png',zoulu].forEach((v,idx,arr)=>{
                var img = new Image();
                img.src = v;
                img.onload = ()=>{
                    console.log(arr.successLength);
                    arr.successLength = (arr.successLength || 0)+1;
                    if(arr.successLength/arr.length===1){
                            that.setState({
                                load:'none',
                                one:'block',
                                btn:'block',
                            });

                    }
                }
            });
        },3000);



        /*

        */
    }
    firstbtn(){
        var that=this;
        console.log(this.props.userInfo.data.user.username);
        that.setState({
            btn:'none',
            username:this.props.userInfo.data.user.username
        });
        that.time(0);
        setTimeout(function () {
            that.setState({
                oneq:'block',
                quesan:'dialogSlipToUp 0.6s ease-in forwards',
                chuxian:"chuxian 0.8s ease-in forwards"
            });
        },500)
    }
    onea(){
        this.setState({
            quesan:'shaky-slow 0.7s',
            chuxian:"shaky-slow 0.7s"
        });

        var that=this;

        setTimeout(function () {
            that.setState({
                quesan:'',
                chuxian:''
            })
        },1000);

    }
    oneb(){

        var zoulu='https://weixun-demo.oss-cn-beijing.aliyuncs.com/sun/zoulu.gif';
        let onech=this.refs.onech;
        let twoch=this.refs.twoch;

        let sudu=20;
        let twosudu=-59;
        onech.src=zoulu;
        onech.style.width='70%';
        onech.style.bottom='0%';
        var that=this;
        that.setState({
            oneok:'block',
        });
        let theone=setInterval(function(){
            sudu=sudu+1;
            //onech.style.left=sudu+'%';
            if(sudu>60){
                that.setState({
                    one:'none',
                    oneq:'none',
                    oneok:'none',
                    two:'block'
                });
                clearInterval(theone);
            }
        }, 30);
        if(that.state.two='block'){
            twoch.style.display='block';
            twoch.style.width='70%';
            twoch.style.left='-59%';
            twoch.src=zoulu;
            setTimeout(function () {
                this.setState({
                    twoq:'block',
                    quesan:'dialogSlipToUp 0.6s ease-in forwards',
                    chuxian:"chuxian 0.8s ease-in forwards"
                });
            }.bind(this),2000);
            let thetwo=setInterval(function () {
                twosudu=twosudu+1;
                if(twosudu>-10){
                    twoch.src='https://weixun-demo.oss-cn-beijing.aliyuncs.com/sun/twoch.png';
                    twoch.style.width='100%';
                    twoch.style.left='0%';
                    clearInterval(thetwo);
                }
            },30)


        }

    }
    /*
    oneb(){
        let onech=this.refs.onech;
        let twoch=this.refs.twoch;
        let sudu=20;
        let twosudu=-59;
        onech.src=zoulu;
        onech.style.width='70%';
        onech.style.bottom='0%';
        var that=this;
        let theone=setInterval(function(){
            sudu=sudu+1;
            onech.style.left=sudu+'%';
            if(sudu>50){
                that.setState({
                    one:'none',
                    oneq:'none',
                    two:'block'
                });
                clearInterval(theone);
            }
        }, 30);
        if(that.state.two='block'){
            twoch.style.display='block';
            twoch.style.width='70%';
            twoch.style.left='-59%';
            twoch.src=zoulu;
            setTimeout(function () {
                this.setState({
                    twoq:'block',
                    quesan:'dialogSlipToUp 0.6s ease-in forwards',
                    chuxian:"chuxian 0.8s ease-in forwards"
                });
            }.bind(this),2500);
            let thetwo=setInterval(function () {
                twosudu=twosudu+1;
                twoch.style.left=twosudu+'%';
                if(twosudu>20){
                    twoch.src=Twoch;
                    twoch.style.width='115%';
                    twoch.style.left='-9%';
                    clearInterval(thetwo);
                }
            },30)


        }

    }
*/
    twoa(){
        this.setState({
            quesan:'shaky-slow 0.7s',
            chuxian:"shaky-slow 0.7s"
        });
        var that=this;
        setTimeout(function () {
            that.setState({
                quesan:'',
                chuxian:''
            })
        },1000);
    }
    /*
    twob(){
        let twoch=this.refs.twoch;
        let threech=this.refs.threech;
        let sudu=20;
        var that=this;
        twoch.src=zoulu;

        let theone=setInterval(function () {
            sudu=sudu+1;
            twoch.style.width='70%';
            twoch.style.left=sudu+'%';
            if(sudu>56){
                that.setState({
                    two:'none',
                    twoq:'none',
                    three:'block'
                });
                clearInterval(theone);
            }
        },30);
        if(that.state.three='block'){
            threech.style.display='block';
            threech.style.width='70%';
            threech.style.left='-59%';
            threech.src=zoulu;
            setTimeout(function () {
                this.setState({
                    threeq:'block',
                    quesan:'dialogSlipToUp 0.6s ease-in forwards',
                    chuxian:"chuxian 0.8s ease-in forwards"
                });
            }.bind(this),2500);
            let twosudu=-59;
            let thetwo=setInterval(function () {
                twosudu=twosudu+1;
                threech.style.left=twosudu+'%';
                if(twosudu>-1.2){
                    threech.src=Threech;
                    threech.style.width='100%';
                    threech.style.bottom='2%';
                    clearInterval(thetwo);
                }
            },30)


        }
    }
    */
    twob(){
        let twoch=this.refs.twoch;
        let threech=this.refs.threech;
        let sudu=20;
        var that=this;
        twoch.style.width='70%';
        twoch.src=zoulu;
        that.setState({
            twook:'block',
        });
        let theone=setInterval(function () {
            sudu=sudu+1;

            //twoch.style.left=sudu+'%';
            if(sudu>56){
                that.setState({
                    two:'none',
                    twoq:'none',
                    twook:'none',
                    three:'block'
                });
                clearInterval(theone);
            }
        },30);
        if(that.state.three='block'){
            threech.style.display='block';
            threech.style.width='70%';
            threech.style.left='-59%';
            threech.src=zoulu;
            setTimeout(function () {
                this.setState({
                    threeq:'block',
                    quesan:'dialogSlipToUp 0.6s ease-in forwards',
                    chuxian:"chuxian 0.8s ease-in forwards"
                });
            }.bind(this),2000);
            let twosudu=-59;
            let thetwo=setInterval(function () {
                twosudu=twosudu+1;
                //threech.style.left=twosudu+'%';
                if(twosudu>-1.2){
                    threech.src='https://weixun-demo.oss-cn-beijing.aliyuncs.com/sun/threech.png';
                    threech.style.left='7%';
                    threech.style.width='85%';
                    threech.style.bottom='2%';
                    clearInterval(thetwo);
                }
            },30)


        }
    }
    threea(){
        this.setState({
            quesan:'shaky-slow 0.7s',
            chuxian:"shaky-slow 0.7s"
        });
        var that=this;
        setTimeout(function () {
            that.setState({
                quesan:'',
                chuxian:''
            })
        },1000);
    }
    threeb(){
        this.setState({
            quesan:'shaky-slow 0.7s',
            chuxian:"shaky-slow 0.7s"
        });
        var that=this;
        setTimeout(function () {
            that.setState({
                quesan:'',
                chuxian:''
            })
        },1000);
    }
    threec(){
        let threech=this.refs.threech;
        let fourch=this.refs.fourch;
        let sudu=-4;
        var that=this;
        threech.style.width='70%';
        threech.style.bottom='0%';
        threech.src=zoulu;
        that.setState({
            threeok:'block',
        });
        let theone=setInterval(function () {
            sudu++;
            //threech.style.left=sudu+'%';
            if(sudu>51){
                that.setState({
                    three:'none',
                    threeq:'none',
                    threeok:'none',
                    four:'block'
                });
                clearInterval(theone);
            }
        },30);
        if(that.state.four='block'){

            fourch.style.display='block';
            fourch.style.width='70%';
            fourch.style.left='-59%';
            setTimeout(function () {
                this.setState({
                    fourq:'block',
                    quesan:'dialogSlipToUp 0.6s ease-in forwards',
                    chuxian:"chuxian 0.8s ease-in forwards"
                });
            }.bind(this),2000);
            fourch.src=zoulu;
            let twosudu=-59;
            let thetwo=setInterval(function () {
                twosudu=twosudu+1;
                //fourch.style.left=twosudu+'%';
                if(twosudu>-1){
                    fourch.style.left='0%';
                    fourch.src='https://weixun-demo.oss-cn-beijing.aliyuncs.com/sun/fourch.png';
                    fourch.style.width='100%';
                    clearInterval(thetwo);
                }
            },30)
        }
    }
    /*
    threec(){
        let threech=this.refs.threech;
        let fourch=this.refs.fourch;
        let sudu=-4;
        var that=this;
        threech.style.width='70%';
        threech.style.bottom='0%';
        threech.src=zoulu;
        let theone=setInterval(function () {
            sudu++;
            threech.style.left=sudu+'%';
            if(sudu>51){
                that.setState({
                    three:'none',
                    threeq:'none',
                    four:'block'
                });
                clearInterval(theone);
            }
        },30);
        if(that.state.four='block'){

            fourch.style.display='block';
            fourch.style.width='70%';
            fourch.style.left='-59%';
            setTimeout(function () {
                this.setState({
                    fourq:'block',
                    quesan:'dialogSlipToUp 0.6s ease-in forwards',
                    chuxian:"chuxian 0.8s ease-in forwards"
                });
            }.bind(this),2500);
            fourch.src=zoulu;
            let twosudu=-59;
            let thetwo=setInterval(function () {
                twosudu=twosudu+1;
                fourch.style.left=twosudu+'%';
                if(twosudu>-1){
                    fourch.src=Fourch;
                    fourch.style.width='100%';
                    clearInterval(thetwo);
                }
            },30)
        }
    }
    */
    foura(){
        this.setState({
            quesan:'shaky-slow 0.7s',
            chuxian:"shaky-slow 0.7s"
        });
        var that=this;
        setTimeout(function () {
            that.setState({
                quesan:'',
                chuxian:''
            })
        },1000);
    }
    fourb(){
        let fourch=this.refs.fourch;
        let fivech=this.refs.fivech;
        let sudu=0;
        var that=this;
        that.setState({
            fourok:'block',
        });
        fourch.src=zoulu;
        fourch.style.width='70%';
        fourch.style.bottom='0%';
        let theone=setInterval(function () {
            sudu++;
            //fourch.style.left=sudu+'%';
            if(sudu>65){
                that.setState({
                    four:'none',
                    fourq:'none',
                    fourok:'none',
                    five:'block'
                });
                clearInterval(theone)
            }
        },30);
        fivech.style.display='block';
        fivech.style.left='-101%';
        fivech.style.bottom='0%';
        fivech.src=zoulu;
        setTimeout(function () {
            this.setState({
                fiveq:'block',
                quesan:'dialogSlipToUp 0.6s ease-in forwards',
                chuxian:"chuxian 0.8s ease-in forwards"
            });
        }.bind(this),2000);
        let twosudu=-101;
        let thetwo=setInterval(function () {
            twosudu=twosudu+1;
           //fivech.style.left=twosudu+'%';
            if(twosudu>-40){
                fivech.src='https://weixun-demo.oss-cn-beijing.aliyuncs.com/sun/fivech.png';
                fivech.style.width='90%';
                fivech.style.left='10%';
                fivech.style.bottom='6%';
                clearInterval(thetwo);
            }

        },30)
    }

    fivea(){
        this.setState({
            quesan:'shaky-slow 0.7s',
            chuxian:"shaky-slow 0.7s"
        });
        var that=this;
        setTimeout(function () {
            that.setState({
                quesan:'',
                chuxian:''
            })
        },1000);
    }
    fiveb(){
        let fivech=this.refs.fivech;
        let sixch=this.refs.sixch;
        let sudu=21;
        var that=this;
        fivech.src=zoulu;
        fivech.style.width='70%';
        fivech.style.bottom='0%';
        that.setState({
            fiveok:'block',
        });
        let theone=setInterval(function () {
            sudu++;
            //fivech.style.left=sudu+'%';
            if(sudu>64){
                that.setState({
                    five:'none',
                    fiveq:'none',
                    fiveok:'none',
                    six:'block'
                });
                clearInterval(theone);
            }
        },30)
        sixch.style.display='block';
        sixch.style.left='-98%';
        sixch.style.width='70%';
        sixch.style.bottom='0%';
        sixch.src=zoulu;
        setTimeout(function () {
            this.setState({
                sixq:'block',
                quesan:'dialogSlipToUp 0.6s ease-in forwards',
                chuxian:"chuxian 0.8s ease-in forwards"
            });
        }.bind(this),2000);
        let twosudu=-98;
        let thetwo=setInterval(function () {
            twosudu=twosudu+1;
            //sixch.style.left=twosudu+'%';
            if(twosudu>-1){
                sixch.src='https://weixun-demo.oss-cn-beijing.aliyuncs.com/sun/sixch.png';
                sixch.style.left='5%';
                sixch.style.width='90%';

                clearInterval(thetwo);
            }
        },30)

    }
/*
    fiveb(){
        let fivech=this.refs.fivech;
        let sixch=this.refs.sixch;
        let sudu=21;
        var that=this;
        fivech.src=zoulu;
        fivech.style.bottom='0%';
        let theone=setInterval(function () {
            sudu++;
            fivech.style.left=sudu+'%';
            if(sudu>64){
                that.setState({
                    five:'none',
                    fiveq:'none',
                    six:'block'
                });
                clearInterval(theone);
            }
        },30)
        sixch.style.display='block';
        sixch.style.left='-98%';
        sixch.style.width='70%';
        sixch.style.bottom='0%';
        sixch.src=zoulu;
        setTimeout(function () {
            this.setState({
                sixq:'block',
                quesan:'dialogSlipToUp 0.6s ease-in forwards',
                chuxian:"chuxian 0.8s ease-in forwards"
            });
        }.bind(this),2500);
        let twosudu=-98;
        let thetwo=setInterval(function () {
            twosudu=twosudu+1;
            sixch.style.left=twosudu+'%';
            if(twosudu>-1){
                sixch.src=Sixch;
                sixch.style.width='120%';
                sixch.style.bottom='-2%';
                clearInterval(thetwo);
            }
        },30)

    }
    */
    fivec(){
        this.setState({
            quesan:'shaky-slow 0.7s',
            chuxian:"shaky-slow 0.7s"
        });
        var that=this;
        setTimeout(function () {
            that.setState({
                quesan:'',
                chuxian:''
            })
        },1000);
    }


    sixa(){
        this.setState({
            quesan:'shaky-slow 0.7s',
            chuxian:"shaky-slow 0.7s"
        });
        var that=this;
        setTimeout(function () {
            that.setState({
                quesan:'',
                chuxian:''
            })
        },1000);

    }

    sixb(){
        this.setState({
            quesan:'shaky-slow 0.7s',
            chuxian:"shaky-slow 0.7s"
        });
        var that=this;
        setTimeout(function () {
            that.setState({
                quesan:'',
                chuxian:''
            })
        },1000);
    }
    sixc(){
        let sixch=this.refs.sixch;
        let sevench=this.refs.sevench;
        let sudu=5;
        var that=this;
        sixch.src=zoulu;
        sixch.style.width='70%';
        sixch.style.bottom='0%';
        that.setState({
            sixok:'block',
        });
        let theone=setInterval(function () {
            sudu++;
            //sixch.style.left=sudu+'%';
            if(sudu>69){
                that.setState({
                    six:'none',
                    sixq:'none',
                    sixok:'none',
                    seven:'block'
                });
                clearInterval(theone);
            }
        },30)
        sevench.style.display='block';
        sevench.style.left='-90%';
        sevench.style.width='70%';
        sevench.src=zoulu;
        setTimeout(function () {
            this.setState({
                sevenq:'block',
                quesan:'dialogSlipToUp 0.6s ease-in forwards',
                chuxian:"chuxian 0.8s ease-in forwards"
            });
        }.bind(this),2500);
        let twosudu=-90;
        let thetwo=setInterval(function () {
            twosudu=twosudu+1;
            //sevench.style.left=twosudu+'%';
            if(twosudu>10){
                sevench.src='https://weixun-demo.oss-cn-beijing.aliyuncs.com/sun/sevench.png';
                sevench.style.left='23%';
                sevench.style.width='41%';
                sevench.style.bottom='1%';
                clearInterval(thetwo);
            }
        },30);

    }
    /*
    sixc(){
        let sixch=this.refs.sixch;
        let sevench=this.refs.sevench;
        let sudu=5;
        var that=this;
        sixch.src=zoulu;
        sixch.style.width='70%';
        sixch.style.bottom='0%';
        let theone=setInterval(function () {
            sudu++;
            sixch.style.left=sudu+'%';
            if(sudu>69){
                that.setState({
                    six:'none',
                    sixq:'none',
                    seven:'block'
                });
                clearInterval(theone);
            }
        },30)
        sevench.style.display='block';
        sevench.style.left='-90%';
        sevench.style.width='70%';
        sevench.src=zoulu;
        setTimeout(function () {
            this.setState({
                sevenq:'block',
                quesan:'dialogSlipToUp 0.6s ease-in forwards',
                chuxian:"chuxian 0.8s ease-in forwards"
            });
        }.bind(this),2500);
        let twosudu=-90;
        let thetwo=setInterval(function () {
            twosudu=twosudu+1;
            sevench.style.left=twosudu+'%';
            if(twosudu>10){
                sevench.src=Sevench;
                sevench.style.width='68%';
                sevench.style.bottom='1%';
                clearInterval(thetwo);
            }
        },30);

    }
    */
    sevena(){
        this.setState({
            sevenq:'none',
            eight:'block'
        });
        clearInterval(this.jishi);
        var that=this;
        var miaoshu=parseInt(this.state.second)+60*parseInt(this.state.min);
        var miaoshuone=miaoshu-8;
        var miaoshutwo=miaoshu-4;
        setTimeout(function () {
            that.setState({
                eight:'none',
                animbac:'none',
                nine:'block',

                miaoshu:miaoshu,
                miaoshuone:miaoshuone,
                miaoshutwo:miaoshutwo
            })
        },3000)
    }
    sevenb(){
        this.setState({
            quesan:'shaky-slow 0.7s',
            chuxian:"shaky-slow 0.7s"
        });

        var that=this;
        setTimeout(function () {
            that.setState({
                quesan:'',
                chuxian:''
            })
        },1000);
    }



        render(){

        return(
            <div className='anim' >
                <div className='loadimg' src={loads}   style={{display:this.state.load}}></div>
                <img className='loadimggif' src={loadgif} style={{display:this.state.load}}/>
                <audio autoPlay src='https://weixun-demo.oss-cn-beijing.aliyuncs.com/sun/music.mp3'></audio>
                <div className='animbac' style={{display:this.state.animbac}}>
                    {this.state.isBegin&&<div ref='clock' style={{width:'100%',fontSize:'200%',position:'absolute',top:'50%',left:'0px',textAlign:'center',transform:'translateY(-50%)'}}>
                        {'0'+this.state.min+':'+this.state.second+this.state.seconds}
                    </div>}
                </div>
                <div className='animfirst' style={{display:this.state.btn}}></div>
                <div className='animfirstbtn' onClick={this.firstbtn.bind(this)} style={{display:this.state.btn}}></div>
                <div className="anim-onebac"  style={{backgroundImage:'url('+'https://weixun-demo.oss-cn-beijing.aliyuncs.com/sun/sevenbac.png'+')',display:this.state.one}}>
                    <img ref='onech' className="anim-onech" src='https://weixun-demo.oss-cn-beijing.aliyuncs.com/sun/onech.gif'/>
                </div>
                <div className="anim-oneques" style={{animation:this.state.quesan,display:this.state.oneq}}></div>

                <div className="anim-oneq" style={{animation:this.state.chuxian}}>朋友叫参加活动</div>

                <div className="anim-oneaa" onClick={this.onea.bind(this)} style={{display:this.state.oneq}}>A</div>
                <div className="anim-onea" onClick={this.onea.bind(this)} style={{display:this.state.oneq}}>打麻将</div>
                <div className="anim-onebb" onClick={this.oneb.bind(this)} style={{display:this.state.oneq}}>B</div>
                <div className="anim-oneb" onClick={this.oneb.bind(this)} style={{display:this.state.oneq}}>打羽毛球</div>
                <div className="anim-oneok" onClick={this.oneb.bind(this)} style={{display:this.state.oneok}}></div>
                <div className="anim-twobac" style={{backgroundImage:'url('+'https://weixun-demo.oss-cn-beijing.aliyuncs.com/sun/twobac.png'+')',display:this.state.two}}>
                    <img ref='twoch' className="anim-twoch" style={{display:this.state.twoch}} src={'https://weixun-demo.oss-cn-beijing.aliyuncs.com/sun/twoch.png'}/>
            </div>
                <div className="anim-twoques" style={{animation:this.state.quesan,display:this.state.twoq}}></div>
                <div className="anim-twoq" style={{animation:this.state.chuxian}}>工作比较忙，公司通知参加培训</div>
                <div className="anim-twoaa" onClick={this.twoa.bind(this)} style={{display:this.state.twoq}}>A</div>
                <div className="anim-twoa" onClick={this.twoa.bind(this)} style={{display:this.state.twoq}}>推掉培训，专心工作</div>
                <div className="anim-twobb" onClick={this.twob.bind(this)} style={{display:this.state.twoq}}>B</div>
                <div className="anim-twob" onClick={this.twob.bind(this)} style={{display:this.state.twoq}}>力争参加培训，将工作委托给其他同事或者稍后在做</div>
                <div className="anim-twook" onClick={this.twob.bind(this)} style={{display:this.state.twook}}></div>
                <div className="anim-threebac" style={{backgroundImage:'url('+'https://weixun-demo.oss-cn-beijing.aliyuncs.com/sun/threebac.png'+')',display:this.state.three}}>
                    <img ref='threech' className="anim-threech" style={{display:this.state.threech}} src={'https://weixun-demo.oss-cn-beijing.aliyuncs.com/sun/threech.png'}/>
                </div>
                <div className="anim-threeques" style={{animation:this.state.quesan,display:this.state.threeq}}></div>
                <div className="anim-threeq" style={{animation:this.state.chuxian}}>同事提出一个看上去并不是特别有效的建议，我们应该</div>
                <div className="anim-threeaa" onClick={this.threea.bind(this)} style={{display:this.state.threeq}}>A</div>
                <div className="anim-threea" onClick={this.threea.bind(this)} style={{display:this.state.threeq}}>直接拒绝</div>
                <div className="anim-threebb" onClick={this.threea.bind(this)} style={{display:this.state.threeq}}>B</div>
                <div className="anim-threeb" onClick={this.threeb.bind(this)} style={{display:this.state.threeq}}>指出ta很幼稚</div>
                <div className="anim-threecc" onClick={this.threec.bind(this)} style={{display:this.state.threeq}}>C</div>
                <div className="anim-threec" onClick={this.threec.bind(this)} style={{display:this.state.threeq}}>感谢，并考虑其可能的帮助</div>
                <div className="anim-threeok" onClick={this.threeb.bind(this)} style={{display:this.state.threeok}}></div>
                <div className="anim-fourbac" style={{backgroundImage:'url('+'https://weixun-demo.oss-cn-beijing.aliyuncs.com/sun/fourbac.png'+')',display:this.state.four}}>
                    <img className="anim-fourch" ref='fourch' style={{display:this.state.fourch}} src={'https://weixun-demo.oss-cn-beijing.aliyuncs.com/sun/fourch.png'}/>
                </div>
                <div className="anim-fourques" style={{animation:this.state.quesan,display:this.state.fourq}}></div>
                <div className="anim-fourq" style={{animation:this.state.chuxian}}>跨部门的同事工作中遇到了困难，我们</div>
                <div className="anim-fouraa" onClick={this.foura.bind(this)} style={{display:this.state.fourq}}>A</div>
                <div className="anim-foura" onClick={this.foura.bind(this)} style={{display:this.state.fourq}}>当做没看见，减少自己的工作量</div>
                <div className="anim-fourbb" onClick={this.fourb.bind(this)} style={{display:this.state.fourq}}>B</div>
                <div className="anim-fourb" onClick={this.fourb.bind(this)} style={{display:this.state.fourq}}>主动伸出援手，帮助解决</div>
                <div className="anim-fourok" onClick={this.fourb.bind(this)} style={{display:this.state.fourok}}></div>

                <div className="anim-fivebac" style={{backgroundImage:'url('+'https://weixun-demo.oss-cn-beijing.aliyuncs.com/sun/fivebac.jpg'+')',display:this.state.five}}>
                    <img className="anim-fivech" ref='fivech' style={{display:this.state.fivech}} src={'https://weixun-demo.oss-cn-beijing.aliyuncs.com/sun/fivech.png'}/>
                </div>
                <div className="anim-fiveques" style={{animation:this.state.quesan,display:this.state.fiveq}}></div>
                <div className="anim-fiveq" style={{animation:this.state.chuxian}}>在领导征求意见时，我们和领导的思路不完全一致</div>
                <div className="anim-fiveaa" onClick={this.fivea.bind(this)} style={{display:this.state.fiveq}}>A</div>
                <div className="anim-fivea" onClick={this.fivea.bind(this)} style={{display:this.state.fiveq}}>应该表示一切听领导意见</div>
                <div className="anim-fivebb" onClick={this.fiveb.bind(this)} style={{display:this.state.fiveq}}>B</div>
                <div className="anim-fiveb" onClick={this.fiveb.bind(this)} style={{display:this.state.fiveq}}>坦诚表达自己的观点，但是注意语气</div>
                <div className="anim-fivecc" onClick={this.fivec.bind(this)} style={{display:this.state.fiveq}}>C</div>
                <div className="anim-fivec" onClick={this.fivec.bind(this)} style={{display:this.state.fiveq}}>讲出自己的观点，和领导据理力争</div>
                <div className="anim-fiveok" onClick={this.fiveb.bind(this)} style={{display:this.state.fiveok}}></div>
                <div className="anim-sixbac" style={{backgroundImage:'url('+'https://weixun-demo.oss-cn-beijing.aliyuncs.com/sun/sixbac.png'+')',display:this.state.six}}>
                    <img className="anim-sixch" ref='sixch' style={{display:this.state.sixch}} src={'https://weixun-demo.oss-cn-beijing.aliyuncs.com/sun/sixch.png'}/>
                </div>
                <div className="anim-sixques" style={{animation:this.state.quesan,display:this.state.sixq}}></div>
                <div className="anim-sixq" style={{animation:this.state.chuxian}}>如果有老乡或者同学我们应该</div>
                <div className="anim-sixaa" onClick={this.sixa.bind(this)} style={{display:this.state.sixq}}>A</div>
                <div className="anim-sixa" onClick={this.sixa.bind(this)} style={{display:this.state.sixq}}>工作中多照顾</div>
                <div className="anim-sixbb" onClick={this.sixb.bind(this)} style={{display:this.state.sixq}}>B</div>
                <div className="anim-sixb" onClick={this.sixb.bind(this)} style={{display:this.state.sixq}}>泄露或者商讨机密的信息</div>
                <div className="anim-sixcc" onClick={this.sixc.bind(this)} style={{display:this.state.sixq}}>C</div>
                <div className="anim-sixc" onClick={this.sixc.bind(this)} style={{display:this.state.sixq}}>生活中多交流，互相照顾，工作中一视同仁</div>
                <div className="anim-sixok" onClick={this.sixb.bind(this)} style={{display:this.state.sixok}}></div>
                <div className="anim-sevenbac" style={{backgroundImage:'url('+'https://weixun-demo.oss-cn-beijing.aliyuncs.com/sun/sevenbac.png'+')',display:this.state.seven}}>
                    <img className="anim-sevench" ref='sevench' style={{display:this.state.sevench}} src={'https://weixun-demo.oss-cn-beijing.aliyuncs.com/sun/sevench.png'}/>
                </div>
                <div className="anim-sevenques" style={{animation:this.state.quesan,display:this.state.sevenq}}></div>
                <div className="anim-sevenq" style={{animation:this.state.chuxian}}>如果下班以后有公益活动，是否应该参加</div>
                <div className="anim-sevenaa" onClick={this.sevena.bind(this)} style={{display:this.state.sevenq}}>A</div>
                <div className="anim-sevena" onClick={this.sevena.bind(this)} style={{display:this.state.sevenq}}>要去参加</div>
                <div className="anim-sevenbb" onClick={this.sevenb.bind(this)} style={{display:this.state.sevenq}}>B</div>
                <div className="anim-sevenb" onClick={this.sevenb.bind(this)} style={{display:this.state.sevenq}}>自己多休息吧</div>
                <div className="anim-sevenok" onClick={this.sevenb.bind(this)} style={{display:this.state.sevenok}}></div>
                <div className='anim-sucone' style={{display:this.state.eight}}></div>

                <div className='anim-suc' style={{display:this.state.nine}}>
                    <div className='anim-suconep' style={{display:this.state.nine}}>李晓亮</div>
                    <div className='anim-suconep2' style={{display:this.state.nine}}>{this.state.miaoshuone}秒</div>
                    <div className='anim-suctwop' style={{display:this.state.nine}}>赵志刚</div>
                    <div className='anim-suctwop2' style={{display:this.state.nine}}>{this.state.miaoshutwo}秒</div>
                    <div className='anim-sucthreep' style={{display:this.state.nine}}>{this.state.username}</div>
                    <div className='anim-sucthreep2' style={{display:this.state.nine}}>{this.state.miaoshu}秒</div>
                    <div className='anim-sucfourp' style={{display:this.state.nine}}>郑新宇</div>
                    <div className='anim-sucfourp2' style={{display:this.state.nine}}>{parseInt(this.state.miaoshu)+5}秒</div>
                    <div className='anim-sucfivep' style={{display:this.state.nine}}>张明辉</div>
                    <div className='anim-sucfivep2' style={{display:this.state.nine}}>{parseInt(this.state.miaoshu)+7}秒</div>
                    <div className='anim-sucsixp' style={{display:this.state.nine}}>孙建军</div>
                    <div className='anim-sucsixp2' style={{display:this.state.nine}}>{parseInt(this.state.miaoshu)+9}秒</div>
                    <div className='anim-sucsevenp' style={{display:this.state.nine}}>吕国良</div>
                    <div className='anim-sucsevenp2' style={{display:this.state.nine}}>{parseInt(this.state.miaoshu)+12}秒</div>
                    <div className='anim-suceightp' style={{display:this.state.nine}}>甜泽强</div>
                    <div className='anim-suceightp2' style={{display:this.state.nine}}>{parseInt(this.state.miaoshu)+14}秒</div>
                    <div className='anim-sucninep' style={{display:this.state.nine}}>向远方</div>
                    <div className='anim-sucninep2' style={{display:this.state.nine}}>{parseInt(this.state.miaoshu)+16}秒</div>
                </div>
            </div>
        )
    }
}
function mapDispatchToProps (dispatch) {
    return {
        isLogin: (data) => {dispatch(isLogin(data))},
    }
}

function mapStateToProps({ login }/*默认值加解构数组*/) {
    console.log(login);
    return {
        userInfo: login,
    }

}
export default withRouter(connect(mapStateToProps,mapDispatchToProps)(Anim));

