export let personelAgree = ""
export let serviceAgree = `只要注册用户点击“同意”按钮并完成注册，注册用户就已接受了本注册用户协议及本站公布的各项服务规则（包括填写实名的联系方式等等），并愿意受其约束。如果有违反，册用户不得以未仔细阅读为由进行抗辩，并且微训网有权利终止或中断您的使用资格保留追究相关法律责任的权利，如果条款变更，微训网会通知用户，您可随时登录微训网官方网站查阅最新版服务条款。
 
一、产品保护条款
       1、微训网产品是归属微训网公司版权所有。微训网产品的一切版权以及与产品的周边内容，包括但不限于视频，音频，图片，文字，测评等均受著作权法和国际著作权条约以及其他知识产权法律法规的保护。
       2、您须明白，设计本协议微训网产品涉及到互联网服务，可能会受到各个方面不稳定因素影响。因此本站对任何直接、间接、偶然、特殊及继起的损害不负责任，这些损害可能来自：不正当使用网络服务，在网上购买商品或进行同类型服务，在网上进行交易，非法使用网络服务或注册用户传送的信息有所变动。

      

 3、如微训网的服务发生故障并且影响本协议运行时，微训网将在第一时间内与各相关单位合作，努力修复，但中间引起您的经济损失微训网不负责任。此外，微训网保留因为维护保养等因素暂停本协议的权利
       4、除了本站的使用条件中规定的其它限制和除外情况之外，在中国法律法规所允许的限度内，对于因交易而引起的或与之有关的任何直接的、间接的、特殊的、附带的、后果性的或惩罚性的损害，或任何其它性质的损害，本站、本站的董事、管理人员、雇员、代理或其它代表在任何情况下都不承担责任。
 5、本站对注册用户的电子邮件、手机号等隐私资料进行保护，承诺不会在未获得注册用户许可的情况下擅自将注册用户的个人资料信息出租或出售给任何第三方，但以下情况除外：注册用户同意让第三方共享资料；注册用户同意公开其个人资料，享受为其提供的产品和服务；本站需要听从法庭传票、法律命令或遵循法律程序；本站发现注册用户违反了本站服务条款或本站其它使用规定。关于注册用户隐私的具体协议以本站的隐私声明为准。如果注册用户提供的资料包含有不正确的信息，本站保留结束注册用户使用网络服务资格的权利。


 

二、用户使用须知
　　特别提醒您，请在法律允许的范围内使用互联网，如刑法、国家安全法、保密法、计算机信息系统安全保护条例等，并且保护国家的利益和安全，如违法使用互联网，您将负全部责任
　　1、禁止使用微训网或周边产品发布或传播任何妨碍社会治安或非法、虚假、骚扰性、侮辱性、恐吓性、伤害性、破坏性、挑衅性、淫秽色情性，敏感等违反国家法律的信息。
2、注册用户一旦注册成功，成为本站的合法的注册用户。您可随时根据需要改变您的密码。注册用户将对注册用户名和密码安全负全部责任。另外，每个注册用户都要对以其注册用户名进行的所有活动和事件负全责。微训网公司无法对非法或未经您授权使用您账号及密码的行为做出甄别，因此微训网公司不承担任何责任。注册用户若发现任何非法使用注册用户帐户或存在安全漏洞的情况，请立即通告本站。
　　3、您不得采用盗取他人账号等任何非法手段，对他人进行骚扰。
　　5、任何经由本协议以上传的内容，均由内容提供者承担责任。同时，为了提高、改进微训网各种服务的用户体验，您同意微训网对凡是您上传过的内容进行无偿的修改、复制、传播等使用。微训网无法监控经由本协议上传的内容，与用户的行为，因此不保证内容的品质（合法，正确，真实）等；您使用本服务时，可能会接触到令人不快之内容，并同意将自行风险，而不依赖于微训网。但在任何情况下，若您违反上述协议发布内容，微训网公司有权依法停止设计本协议的服务，并保存有关记录，并向有关机关报告。
　　6、微训网不负责您与任何注册微训网的用户的任何纠纷
 
三、关于用户学习服务
　　在微训网产品中，提供了大量的视频学习课程，测评等。您使用时需同意以下条款
　　1、您不得做进行任何侵犯微训网知识产权或者损害其他用户的行为，或：
　　　（1）修改或盗录微训网的课程
　　　（2）任何影响微训网学习公平性或其他用户正常秩序的行为，例如：刷积分，使用作弊软件，利用bug位自己牟利，或者利用各种渠道公布bu，任何外挂，作弊软件
　　　（3）利用各种技术入侵服务器，或擅自修改服务器中的任何技术或者使服务器超负荷运行；
　　　（4）进行任何诸如发布广告等商业行为，或者进行任何非法的侵害微训网利益的行为等；
　　　（5）发布虚假诈骗信息
　　　（6）发表、转发、传播诋毁，谩骂或其他危害他人权益或危害国家安全或利益的信息
　　　（7）在微训网进行恶意刷分，评论评论，答复，灌水等破坏生微训网生态秩序的言论或行为
　　　（8）在微训网发布任何恶意的计算机程序，或侵犯第三方知识产权隐私等资料
　　2、在用户学习期间产生的数据将会影响微训网的性能，因此微训网会定期迁移或删除此部分数据
　　
3、如果您有任何违反本协议或国家法规的行为，微训网将采取如下措施：　　　（1）终止或终止您的服务
　　　（2）暂时禁止您凭借当前使用的微训网账户登录微训网学习使用；
　　　（3）清楚或降低您在本站的积分
　　　（4）删除您发布的内容，和您的学习数据
　    （5）采取上列措施之外的其他的措施。
     
     
4、用户信息保护
　　本站对注册用户的电子邮件、手机号等隐私资料进行保护，承诺不会在未获得注册用户许可的情况下擅自将注册用户的个人资料信息出租或出售给任何第三方，但以下情况除外：注册用户同意让第三方共享资料；注册用户同意公开其个人资料，享受为其提供的产品和服务；本站需要听从法庭传票、法律命令或遵循法律程序；本站发现注册用户违反了本站服务条款或本站其它使用规定。

 









四、用户付费协议
      请用户在付费之前仔细阅读此协议
      1、用户规则
        用户对其账号下所产生的行为和事件负全部责任
          （1）用户如在微训网消费，需先注册账号，并登录后。且您在使用微训网服务时账号是您唯一的身份标识
          （2）用户理解并同意：微训网的有偿内容均为付费后再提供服务，用户需及时，足额，合法地支付
          （3）微训网的课程支付一次可在有效期内无限次观看，并且微训网有权利在此期间对课程进行更新修改，用户无权修改，录制贩卖出售，分发，租赁若违反微训网有权终止其账号并收缴其非法所得和通过法律手段追究赔偿。
          
          （4）用户了解并同意：微训网可能不定期的对收费课程进行更新（包括但不限于：更新课程内容、改进课程安排）而无需经过用户的事先同意。
          （5）微信是用户支付款项的支付平台。用户可以通过微训网支付页面提示，选用支付平台账号支付，并承担交易所产生的法律后果。
          （6）请您保管好自己的账号密码，如未保管好，出现问题用户全责
          （8） 微训网暂不支持退课  
     4、协议的修订
       微训网有权在任何时间对本协议进行调整，一般情况微训网新推出的服务均适用本协议，特殊情况会做说明
    5、其他约定
          （1）所有权及知识产权：微训网上所有内容均受法律保护未经微训网书面同意用户不得擅自使用、修改、复制、传播、改变、散布、发行或发表上述内容。如有违反，用户同意承担法律责任并赔偿给由此造成的微训网的损失
          （2）微训网有权使用用户在此站发布的任何内容，并无需支付任何费用。
          （3）本协议与中华人民共和国的法律有冲突时，违反部分按以中华人民共和国的法律为准
          （4）本协议自发布起变生效，若想流畅使用本站服务，需遵循本站法律
          （5）微训网若不行使、或及时行使本协议的内容所享有的权利，不应被视为放弃行使，未来依然有权利形式 
          （6）若您对本协议存疑，请发送至此邮箱：
 
 
 六、服务声明
       特别提醒，微训网拥有随时修改或中断服务的权利，并且不需要通知您，且不许对任何用户和第三方负责，您必须同意，微训网才开始对您提供方服务。
 
七、适用法律
　　本服务条款的解释，效力及纠纷的解决，适用于中华人民共和国大陆法律。
　　若您和微训网之间发生任何纠纷或争议，首先应友好协商解决，协商不成的，您在此完全同意将纠纷或争议提交微训网所在地北京市朝阳区人民法院管辖。`

